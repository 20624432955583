import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  Link,
  Grid,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import authService from "../../services/authService";
import styles from "./EorderDialog.module.css";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import CloseIcon from "@mui/icons-material/Close";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import { Formik } from "formik";
import { PatientInput, DoctorInput, EorderPatientInput } from "../../graphql/types/gql-global-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import GetDoctorDataQuery from "./GetDoctorDataQuery";
import { GetDoctorData } from "./types/GetDoctorData";
import { EORDER_BLACKLIST, EORDER_UPLOAD_CONSENT, EORDER_USE_IFRAME, getSiteOption } from "../../utils/siteOptions";
import { GetStates, GetStates_getStates } from "./types/GetStates";
import GetStatesQuery from "./GetStatesQuery";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { GetStateFromSuburb } from "../AddOnTest/types/GetStateFromSuburb";
import GetStateFromSuburbQuery from "../AddOnTest/GetStateFromSuburbQuery";
import * as yup from "yup";
import { validateProvider } from "../../utils/provider";
import { GenerateEordersUrl, GenerateEordersUrlVariables } from "./types/GenerateEordersUrl";
import GenerateEordersUrlMutation from "./GenerateEordersUrlMutation";
import GetOrderPdfQuery from "../PreviousTestRequests/GetOrderPdfQuery";
import { GetOrderPdf } from "../PreviousTestRequests/types/GetOrderPdf";
import { useHistory } from "react-router";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import * as Constants from "../../constants";
import { validateMedicare } from "../../utils/medicare";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MobileNumberField from "../common/InputField/MobileNumberField";
import Tooltips from "../common/Tooltip/Tooltip";
import clsx from "clsx";
import { b64toBlob } from "../PrintableReports/pdfUtils";
import { downloadHelper } from "../../utils/downloadHelper";
import { validateDvaCode } from "../../utils/dvacode";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import dayjs, { Dayjs } from "dayjs";

interface EorderDialogProps {
  open: boolean;
  fullscreen: boolean;
  onClose: () => void;
  patient?: GetPatient_patient;
  reportingProviderNo?: string;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
}

type EorderFormInput = {
  firstname: string;
  middlename: string;
  surname: string;
  dob: string | number | Dayjs | Date | null | undefined;
  gender: string;
  medicare: string;
  dvacode: string;
  mobile: string;
  email: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  providerno: string;
};

const EorderDialog: React.FC<EorderDialogProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();
  const isMobileView = !isDesktop || isMobility;
  const [stepLast, setStepLast] = useState<boolean>(false);
  const [stepFinal, setStepFinal] = useState<boolean>(false);
  const [provNo, setProvNo] = useState("0");
  const [doctorDetail, setDoctorDetail] = useState<DoctorInput>({});
  const [states, setStates] = useState<GetStates_getStates[]>([]);
  const [suburbState, setSuburbState] = useState("");
  const [providers, setProviders] = useState(authService.getProviders());
  const [uploadConsent, setUploadConsent] = useState(false);
  const eOrderUploadConsent = (getSiteOption(EORDER_UPLOAD_CONSENT) as string) === "ON";
  const eOrderUseIframe = (getSiteOption(EORDER_USE_IFRAME) as string) === "ON";
  const [urlWithToken, setUrlWithToken] = useState("about:blank");
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [reload, setReload] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const history = useHistory();

  const [getOrderPdf, { data: pdfData, loading: pdfLoading, error: pdfError }] = useLazyQuery<GetOrderPdf>(
    GetOrderPdfQuery,
    {
      variables: { eorderId: "" },
      fetchPolicy: "network-only",
    },
  );

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  useEffect(() => {
    const receiveMessage = async (event: any) => {
      if (event.data && typeof event.data === "string" && event.data.toString().startsWith("eOrderComplete")) {
        setStepFinal(true);

        const eOrder = (event.data as string).split("=");
        const value = JSON.stringify({ id: eOrder[1] });
        const action = "EORDER_REQUEST";

        auditLog({
          variables: {
            audit: {
              value,
              action,
            },
          },
        });

        if (eOrder[0] === "eOrderCompleteDownload") {
          setIsDownload(true);

          await getOrderPdf({
            variables: { eorderId: eOrder[1], id: 0 },
            fetchPolicy: "network-only",
            onCompleted: (data) => {
              const blobData = b64toBlob(data.getPdf, "application/pdf");
              downloadHelper(blobData, `${eOrder[1]}.pdf`);
              setIsDownload(false);
            },
          });
        }
      }

      if (event.data && typeof event.data === "string" && (event.data as string) === "eOrderClose") {
        setReload(true);
      }
    };

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  useEffect(() => {
    if (props.reportingProviderNo && props.reportingProviderNo !== "") {
      if (authService.getProviders().includes(props.reportingProviderNo)) {
        if (validateProvider(props.reportingProviderNo)) {
          setProvNo(props.reportingProviderNo);
        } else {
          props.setReady(true);
        }
      } else {
        props.setReady(true);
      }
    } else {
      props.setReady(true);
    }

    const validProviders = providers.filter((p) => {
      return validateProvider(p);
    });

    setProviders(validProviders);
  }, [props.reportingProviderNo]);

  const { data: statesData } = useQuery<GetStates>(GetStatesQuery, {});

  const [getDoctorDataQuery, { data: doctorData, loading: doctorLoading, error: doctorError }] =
    useLazyQuery<GetDoctorData>(GetDoctorDataQuery, {
      variables: {
        provider: "",
      },
    });

  const [getGetStateFromSuburbQuery, { data: suburbStateData, loading: suburbStateLoading, error: suburbStateError }] =
    useLazyQuery<GetStateFromSuburb>(GetStateFromSuburbQuery, {
      variables: {
        suburb: "",
        postcode: "",
      },
    });

  const [generateEordersUrl, { data: genData, loading: genLoading, error: genError, reset: genReset }] = useMutation<
    GenerateEordersUrl,
    GenerateEordersUrlVariables
  >(GenerateEordersUrlMutation);

  const getEordersUrl = async (
    patient: PatientInput,
    doctor: DoctorInput,
    generateEordersUrl: MutationTuple<GenerateEordersUrl, GenerateEordersUrlVariables>[0],
  ) => {
    try {
      await generateEordersUrl({
        variables: {
          input: {
            patient,
            doctor,
          },
        },
        fetchPolicy: "network-only",
      });
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (genData && genData.generateEordersUrl) {
      let url = genData.generateEordersUrl;
      if (process.env.REACT_APP_DEV_EORDER_URL) {
        url = url
          .replace("https://medorders-stage.clinicallabs.com.au/meddir", process.env.REACT_APP_DEV_EORDER_URL)
          .replace("https://medorders.clinicallabs.com.au/meddir", process.env.REACT_APP_DEV_EORDER_URL);
      }
      setUrlWithToken(url);
      setStepLast(true);
    }

    if (provNo !== "0") {
      getDoctorDataQuery({
        variables: {
          provider: provNo,
        },
      });
    }

    if (doctorDetail && doctorDetail.city) {
      const reqVariables = { suburb: "", postcode: "" };
      if (doctorDetail.city) {
        reqVariables.suburb = doctorDetail.city;
      }
      if (doctorDetail.postcode) {
        reqVariables.postcode = doctorDetail.postcode;
      }

      getGetStateFromSuburbQuery({
        variables: reqVariables,
      });
    }

    if (doctorData && doctorData.getDoctorData) {
      setDoctorDetail(doctorData.getDoctorData);
    }

    if (statesData && statesData.getStates) {
      setStates(statesData.getStates);
    }

    if (suburbStateData && suburbStateData?.getStateFromSuburb) {
      setSuburbState(suburbStateData?.getStateFromSuburb.state);

      if (doctorDetail && doctorDetail.providerNumber) {
        props.setReady(true);
      }
    }

    if (iframeLoaded && !isDownload) {
      const iframe: any = document.querySelector("#eorderui");
      const boundingRect: DOMRect | null = iframe.getBoundingClientRect();
      const windowRect = new DOMRect(0, 0, window.innerWidth, window.innerHeight);
      iframe.contentWindow.postMessage("parentRect:" + JSON.stringify(windowRect), "*");
      iframe.contentWindow.postMessage("iframeRect:" + JSON.stringify(boundingRect), "*");
    }

    if (reload) {
      window.location.reload();
    }
  }, [doctorData, provNo, statesData, doctorDetail, suburbStateData, iframeLoaded, genData, pdfData, reload]);

  if (doctorError || suburbStateError || genError || pdfError) {
    let error = "";
    if (doctorError) {
      error = doctorError.message;
    } else if (suburbStateError) {
      error = suburbStateError.message;
    } else if (genError) {
      error = genError.message || Constants.NO_DB_CONNECTION;
    } else if (pdfError) {
      error = pdfError.message;
    }

    return <ErrorPopover errorMessage={error} />;
  }

  const handleClose = () => {
    setIframeLoaded(false);
    setUploadConsent(false);
    setDoctorDetail({});
    genReset();
    props.onClose();
    setStepLast(false);
    setStepFinal(false);
  };

  const initialValues: EorderFormInput = {
    firstname: props.patient?.firstname as string,
    middlename: props.patient?.middlename as string,
    surname: props.patient?.surname as string,
    dob: props.patient?.dob ? dayjs(props.patient?.dob as string) : null,
    gender: props.patient?.sex as string,
    medicare: props.patient?.medicare as string,
    dvacode: props.patient?.dvacode as string,
    mobile: props.patient?.mobile as string,
    email: "",
    street: props.patient?.street as string,
    suburb: props.patient?.city as string,
    state: props.patient?.state as string,
    postcode: props.patient?.postcode as string,
    providerno: provNo,
  };

  const submit = (values: EorderFormInput) => {
    if (provNo !== "0") {
      const medicareno = values.medicare.split("/");

      const patientInput: EorderPatientInput = {
        Name: values.firstname,
        Surname: values.surname,
        Middlename: values.middlename,
        Dob: values.dob ? dayjs(new Date(values.dob as string | number | Date).getTime()).format("YYYY-MM-DD") : null,
        Gender: values.gender,
        Mobile: values.mobile,
        Email: values.email,
        Street: values.street,
        Suburb: values.suburb,
        Postcode: values.postcode,
        State: values.state,
        Medicare: medicareno[0],
        MedicareInd: medicareno[1],
        DvaCode: values.dvacode,
      };

      if (eOrderUploadConsent && uploadConsent) {
        patientInput.UploadConsent = "on";
      }

      const iPatentInput: PatientInput = {
        dob: values.dob ? dayjs(new Date(values.dob as string | number | Date).getTime()).format("YYYY-MM-DD") : null,
        firstname: values.firstname,
        mobile: values.mobile,
        sex: values.gender,
        street: values.street,
        city: values.suburb,
        postcode: values.postcode,
        state: values.state,
        surname: values.surname,
        emailAddress: values.email,
        medicareNo: medicareno[0],
        medicareInd: medicareno[1],
        dvaCode: values.dvacode,
        middlename: values.middlename,
      };

      const iDoctorInput: DoctorInput = {
        providerNumber: doctorDetail.providerNumber,
        surname: doctorDetail.surname,
        givenName: doctorDetail.givenName,
        middleName: doctorDetail.middleName,
        title: doctorDetail.title,
        sex: doctorDetail.sex,
        addressLine1: doctorDetail.addressLine1,
        addressLine2: doctorDetail.addressLine2,
        city: doctorDetail.city,
        postcode: doctorDetail.postcode,
        practiceTelephone: doctorDetail.practiceTelephone,
        emailAddress: doctorDetail.emailAddress,
        practiceId: doctorDetail.practiceId,
        status: doctorDetail.status,
        doctorCode: doctorDetail.doctorCode,
        specialtyCode: doctorDetail.specialtyCode,
        practiceName: doctorDetail.practiceName,
        state: suburbState,
      };

      if (eOrderUseIframe) {
        getEordersUrl(iPatentInput, iDoctorInput, generateEordersUrl);
      }
    }
  };

  const formValidationSchema = yup.object().shape({
    firstname: yup.string().required("First Name is required"),
    middlename: yup.string(),
    surname: yup.string().required("Last Name is required"),
    gender: yup.string().required("Gender is required"),
    street: yup.string().required("Street is required"),
    suburb: yup.string().required("Suburb is required"),
    state: yup.string().required("State is required"),
    dob: yup.date().required("Date of Birth is required").typeError("Date is invalid"),
    postcode: yup
      .string()
      .required("Postcode is required")
      .min(4, "4 Digit Postcode required")
      .max(4, "4 Digit Postcode required")
      .test("Postcode Test", "4 Digit Postcode required", (value: any) => /^\d+$/.test(value as string)),
    medicare: yup
      .string()
      .min(12, "11 Digit Number required")
      .test("oneOfRequired", "Either Medicare or DVA Code is required", (value: any, testContext: any) => {
        return value || testContext.parent.dvacode;
      })
      .test("Medicare Test", "Invalid Medicare Number", (value: any) =>
        value && value.length ? validateMedicare(value as string) : true,
      ),
    dvacode: yup
      .string()
      .min(8, "Minimum of 8 characters required")
      .test("oneOfRequired", "Either Medicare or DVA Code is required", (value: any, testContext: any) => {
        return testContext.parent.medicare || value;
      })
      .test("DVA Code Test", "Invalid DVA Code", (value: any) =>
        value && value.length ? validateDvaCode(value as string) : true,
      ),
    providerno: yup
      .string()
      .required("Provider No is required")
      .test("Provider Test", "Provider No is required", (value: any) => value !== "0"),
    mobile: yup
      .string()
      .test("oneOfRequired", "Either Mobile Number or Email is required", (value: any, testContext: any) => {
        return value || testContext.parent.email;
      })
      .test("Mobile Test", "Required format is 04XX XXX XXX", (value: any) =>
        value && value.length ? /^([0]{1})([4]{1})([0-9]{2}) ([0-9]{3}) ([0-9]{3})/g.test(value) : true,
      ),
    email: yup
      .string()
      .email()
      .test("oneOfRequired", "Either Mobile Number or Email is required", (value: any, testContext: any) => {
        return testContext.parent.mobile || value;
      }),
  });

  const handleChangeUploadConsent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadConsent(event.target.checked);
  };

  const handlePreviousOrders = () => {
    history.push("/previous-test-requests");
  };
  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="responsive-dialog-title"
      disableEscapeKeyDown={true}
      className={styles.sizeDialogDesltop}
      classes={{
        paperScrollPaper: clsx(
          (!isDesktop || isMobility) && styles.customMobileDialog,
          eOrderUseIframe && stepLast && !stepFinal && styles.customDialog2,
          !(eOrderUseIframe && stepLast && !stepFinal) && styles.customDialog,
        ),
        paper: styles.roundedBorder,
      }}
      BackdropProps={eOrderUseIframe && stepLast && !stepFinal ? { className: styles.customBackdrop } : {}}
      fullWidth={eOrderUseIframe && stepLast && !stepFinal}
      maxWidth={eOrderUseIframe && stepLast && !stepFinal ? "xl" : "sm"}
    >
      <DialogTitle
        className={eOrderUseIframe && stepLast && !stepFinal ? styles.dialogTitle : styles.dialogTitleWithMargin}
      >
        {!stepLast ? (
          <DialogBoxTitle
            title="New eOrder"
            supplementaryTitle=" - Patient Details"
            width={450}
            marginLeft={-3}
            closeButton
            handleClose={handleClose}
          />
        ) : eOrderUseIframe ? (
          !stepFinal ? (
            <Box
              display="flex"
              justifyContent={
                eOrderUseIframe && ((!stepLast && !stepFinal) || (stepLast && stepFinal)) ? "space-between" : "flex-end"
              }
              align-content="strech"
              mt={"0px"}
            >
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <IconButton
                  className={clsx({
                    [styles.closeIconButton]: true,
                    [styles.closeIconButtonMobile]: isMobileView,
                  })}
                  aria-label="close"
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon className={styles.closeIcon} />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <DialogBoxTitle title="Success" supplementaryTitle="" width={200} closeButton handleClose={handleClose} />
          )
        ) : (
          <DialogBoxTitle title="Success" supplementaryTitle="" width={200} closeButton handleClose={handleClose} />
        )}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Box
          m={2}
          className={clsx(
            [eOrderUseIframe && stepLast && !stepFinal && styles.box2],
            [isDesktop && eOrderUseIframe && stepLast && !stepFinal && styles.box3],
            [!(eOrderUseIframe && stepLast && !stepFinal) && styles.box],
            [!isDesktop || (isMobility && styles.boxDialogContentMobile)],
            [isDesktop && styles.boxDialogContentDesktop],
            [!isMobileView && styles.desktopBox],
          )}
        >
          {!stepLast ? (
            <Formik
              validationSchema={formValidationSchema}
              enableReinitialize={false}
              initialValues={initialValues}
              onSubmit={submit}
            >
              {({ setFieldValue, values, handleChange, handleBlur, touched, errors, handleSubmit }: any) => (
                <form onSubmit={handleSubmit}>
                  <Box m={2}>
                    <Grid container spacing={1}>
                      <Grid item sm={4} xs={6}>
                        <TextField
                          margin="dense"
                          id="firstname"
                          name="firstname"
                          label="First Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstname && Boolean(errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                        />
                      </Grid>
                      <Grid item sm={4} xs={6}>
                        <TextField
                          margin="dense"
                          id="middlename"
                          name="middlename"
                          label="Middle Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.middlename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.middlename && Boolean(errors.middlename)}
                          helperText={touched.middlename && errors.middlename}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          margin="dense"
                          id="surname"
                          name="surname"
                          label="Last Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.surname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.surname && Boolean(errors.surname)}
                          helperText={touched.surname && errors.surname}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            label="Date of Birth"
                            value={values.dob as Dayjs | null | undefined}
                            onChange={(values: Dayjs | null) => {
                              setFieldValue("dob", values as Date | null);
                            }}
                            slotProps={{
                              textField: {
                                error: touched.dob && Boolean(errors.dob),
                                helperText: touched.dob && errors.dob,
                                placeholder: "DD/MM/YYYY",
                                variant: "outlined",
                                style: { marginTop: "8px", width: "100%" },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          id="gender"
                          name="gender"
                          label="Gender"
                          type="text"
                          fullWidth
                          select
                          variant="outlined"
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.gender && Boolean(errors.gender)}
                          helperText={touched.gender && errors.gender}
                        >
                          <MenuItem key={0} value={"M"}>
                            Male
                          </MenuItem>
                          <MenuItem key={0} value={"F"}>
                            Female
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          id="medicare"
                          name="medicare"
                          label="Medicare"
                          type="text"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            maxLength: 12,
                          }}
                          value={values.medicare}
                          onChange={(val) => {
                            const onlyNums = val.target.value.replace(/[^0-9]/g, "");

                            if (onlyNums.length === 11) {
                              const maskedNums = onlyNums.replace(/(\d{10})(\d{1})/, "$1/$2");

                              setFieldValue("medicare", maskedNums);
                            } else {
                              setFieldValue("medicare", onlyNums);
                            }
                          }}
                          onBlur={handleBlur}
                          error={touched.medicare && Boolean(errors.medicare)}
                          helperText={touched.medicare && errors.medicare}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          margin="dense"
                          id="dvacode"
                          name="dvacode"
                          label="DVA Code"
                          type="text"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            maxLength: 9,
                          }}
                          value={values.dvacode}
                          onChange={(val) => {
                            const capitalize = val.target.value.toUpperCase();
                            setFieldValue("dvacode", capitalize);
                          }}
                          onBlur={handleBlur}
                          error={touched.dvacode && Boolean(errors.dvacode)}
                          helperText={touched.dvacode && errors.dvacode}
                        />
                      </Grid>
                      <Grid item sm={4} xs={6}>
                        <MobileNumberField
                          initState={values.mobile}
                          error={touched.mobile && Boolean(errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          inputChange={(value: any) => {
                            setFieldValue("mobile", value);
                          }}
                        />
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <TextField
                          margin="dense"
                          id="email"
                          name="email"
                          label="Email"
                          type="email"
                          fullWidth
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {eOrderUseIframe ? (
                          <Typography className={styles.disclaimer}>
                            <b>Important:</b> Your patient’s mobile number and email address may be required for the
                            electronic delivery of the pathology referral form and any COVID-19 result. Please ensure
                            details are correct before submitting.
                          </Typography>
                        ) : (
                          <Typography className={styles.disclaimer}>
                            <b>Important:</b> Your patient’s mobile number is required for the electronic delivery of
                            the pathology referral form and the COVID-19 result. Please ensure details are correct
                            before submitting.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          id="street"
                          name="street"
                          label="Street"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.street && Boolean(errors.street)}
                          helperText={touched.street && errors.street}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          margin="dense"
                          id="suburb"
                          name="suburb"
                          label="Suburb"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={values.suburb}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.suburb && Boolean(errors.suburb)}
                          helperText={touched.suburb && errors.suburb}
                        />
                      </Grid>
                      <Grid item sm={3} xs={6}>
                        <TextField
                          margin="dense"
                          id="state"
                          name="state"
                          label="State"
                          type="text"
                          fullWidth
                          select
                          variant="outlined"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.state && Boolean(errors.state)}
                          helperText={touched.state && errors.state}
                        >
                          {states.map((item: GetStates_getStates) => {
                            return (
                              <MenuItem key={item.id} value={item.state}>
                                {item.state}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item sm={3} xs={6}>
                        <TextField
                          margin="dense"
                          id="postcode"
                          name="postcode"
                          label="Postcode"
                          fullWidth
                          variant="outlined"
                          type="text"
                          inputProps={{
                            maxLength: 4,
                          }}
                          value={values.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.postcode && Boolean(errors.postcode)}
                          helperText={touched.postcode && errors.postcode}
                        />
                      </Grid>
                      <Grid item sm={4} xs={5}>
                        <TextField
                          id="providerno"
                          name="providerno"
                          label="Provider No"
                          select
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={values.providerno}
                          onChange={(val) => {
                            setFieldValue("providerno", val.target.value);
                            setProvNo(val.target.value);
                          }}
                          onBlur={handleBlur}
                          error={touched.providerno && Boolean(errors.providerno)}
                          helperText={touched.providerno && errors.providerno}
                        >
                          {providers
                            .filter((p) => !(getSiteOption(EORDER_BLACKLIST) as string[]).includes(p))
                            .map((item: any) => {
                              return (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </Grid>
                      <Grid item xs={1} pt={2}>
                        <Tooltips text="ProviderInfo" />
                      </Grid>
                      <Grid item sm={7} xs={6}>
                        {eOrderUploadConsent && !eOrderUseIframe && (
                          <>
                            <Checkbox
                              name="uploadconsent"
                              color="primary"
                              onChange={handleChangeUploadConsent}
                              checked={uploadConsent}
                            />{" "}
                            Do not send to My Health Record
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {!eOrderUseIframe && (
                          <Typography className={styles.disclaimer2}>
                            By submitting this form you are referring this patient for both COVID-19 & Respiratory PCR
                            tests.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} display={"flex"} justifyContent={"right"}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          className={styles.submitButton}
                          disabled={genLoading || doctorLoading || suburbStateLoading}
                        >
                          {genLoading || doctorLoading || suburbStateLoading ? (
                            <Loading
                              boxStyle={{ margin: "0" }}
                              iconStyle={{ color: "grey", height: "20px", width: "20px" }}
                            />
                          ) : (
                            `${eOrderUseIframe ? "Continue" : "Submit"}`
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          ) : eOrderUseIframe ? (
            !stepFinal ? (
              <>
                {!iframeLoaded && (
                  <Loading
                    boxStyle={{
                      backgroundColor: "black",
                      position: "absolute",
                      width: "98%",
                      zIndex: 100,
                      margin: 0,
                      height: "50%",
                      opacity: 0.5,
                      paddingTop: "20%",
                    }}
                  />
                )}
                <iframe
                  scrolling={"no"}
                  style={{
                    border: "0px",
                    background: "transparent",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "100%",
                    opacity: "1",
                  }}
                  hidden
                  src={urlWithToken}
                  title="eorderui"
                  id="eorderui"
                  name="eorderui"
                  onLoad={() => setIframeLoaded(true)}
                ></iframe>
              </>
            ) : (
              <Box m={2}>
                <Box className={styles.subTitle} mt={3} mb={3}>
                  <Typography variant="h6">
                    <b>Your new test request was successfully submitted.</b>
                  </Typography>
                </Box>
                <Box className={styles.subText} mb={4}>
                  <Typography>
                    To view your eOrders history please click &nbsp;
                    {
                      <Link color="primary" onClick={handlePreviousOrders}>
                        <b>Previous eOrders</b>
                      </Link>
                    }
                    &nbsp; via your &nbsp; <SettingsIcon /> &nbsp; account menu.
                  </Typography>
                  {pdfLoading && (
                    <Typography>
                      The referral form is being generated, please wait... &nbsp;
                      <Loading
                        boxStyle={{ margin: "0", display: "inline" }}
                        iconStyle={{ color: "grey", height: "20px", width: "20px" }}
                      />
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          ) : (
            <Box m={2}>
              <Box className={styles.productLabel2} bgcolor="primary.main" py={1} mt={1} ml={-2}>
                <Typography variant="h4">
                  <b>Success!</b>
                </Typography>
              </Box>
              <Box className={styles.subTitle} mt={3} mb={3}>
                <Typography variant="h6">
                  <b>Your test request was successfully submitted.</b>
                </Typography>
              </Box>
              <Box className={styles.subText} mb={4}>
                <Typography>
                  The pathology referral form has been sent to your patient’s mobile number (and email address, if
                  provided). You will receive all results via your usual delivery method, and your patient will receive
                  their COVID-19 result via SMS.
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="right">
                <Button type="submit" color="primary" variant="contained" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EorderDialog;
