import React, { useMemo, useState } from "react";
import { QueryResult } from "@apollo/client";
import { withRouter, RouteComponentProps } from "react-router";
import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./EpisodeListTable.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Episode from "./Episode";
import { CheckedEpisode } from "./EpisodeListPage";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";

type EpisodeListTableProps = {
  episodes: CheckedEpisode[];
  loadMore: () => QueryResult<any>;
  showLoadMore: boolean;
  isOpen: boolean;
  history: RouteComponentProps["history"];
  setEpisodes: React.Dispatch<React.SetStateAction<CheckedEpisode[]>>;
} & RouteComponentProps;

export const EpisodeListTable: React.FC<EpisodeListTableProps> = (props: EpisodeListTableProps) => {
  const { episodes, loadMore, showLoadMore, setEpisodes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const loadMoreClickHandler = () => {
    setIsLoading(true);
    loadMore();
  };

  const setChecked = (index: number) => {
    const newepisodes = [...episodes];
    setErrorMessage("");
    if (!newepisodes[index].checked) {
      if (newepisodes.filter((e) => e.checked).length >= 30) {
        setErrorMessage("Too many rows selected. You cannot select any more episodes.");
        return false;
      }
    }
    newepisodes[index].checked = !newepisodes[index].checked;
    setEpisodes(newepisodes);
    return true;
  };

  const episodeComponents = useMemo(() => {
    if (episodes) {
      setIsLoading(false);
      return episodes.map((episode: any, index: any) => (
        <Episode key={index} index={index} episode={episode} setChecked={setChecked} />
      ));
    }
  }, [episodes]);

  return (
    <>
      {episodes.length > 0 && (
        <>
          <Box my={2} id="episode-list-table">
            {episodeComponents}
          </Box>
          <Box width={1} display="flex" justifyContent="center" my={3}>
            {isLoading && <CircularProgress size={16} style={{ marginTop: "15px", marginBottom: "10px" }} />}
            {showLoadMore && !isLoading && (
              <Button
                variant="outlined"
                color="primary"
                className={styles.loadMoreButton}
                onClick={loadMoreClickHandler}
                endIcon={<ExpandMoreIcon />}
              >
                Load more results
              </Button>
            )}
          </Box>
          {errorMessage && <ErrorPopover errorMessage={errorMessage} />}
        </>
      )}
    </>
  );
};

export default withRouter(EpisodeListTable);
