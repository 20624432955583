import React, { useContext, createRef, Fragment, useEffect, useRef } from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";
import { GetPanelReports } from "./types/GetPanelReports";
import GetPanelReportsQuery from "./PanelReportsQuery";

import authService from "../../services/authService";
import { useQuery } from "@apollo/client";
import Loading from "../Loading/Loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppContext from "../../context/AppContext";
import { ValueWithLabel } from "../ValueWithLabel/ValueWithLabel";
import { animateScroll } from "react-scroll";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";
import { getStatusName } from "../../utils/getStatusName";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { formatDate } from "../../utils/date";
import * as Constants from "../../constants";
import thm from "../theme";

const SCROLL_DURATION = 200;

type PanelReportsPageProps = {
  panelId: number;
  patientId: number;
  date: string;
  providers: string[];
  allPatients: boolean;
};

const useStyles = makeStyles(() => ({
  headerBar: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light + " !important;",
    height: "161px",
  },
  title: {
    fontWeight: "bold",
  },
  reportPanel: {
    borderColor: "#e9e9e9",
    borderStyle: "solid",
    borderWidth: "0px 1px 1px 1px",
  },
  resultCard: {
    padding: "0 !important",
  },
  expansionPanelMt: {
    // marginTop: '108px !important'
  },
  expansionPanelMain: {
    padding: "0 !important",
    margin: "0 !important",
  },
  expansionPanelMainMobile: {
    padding: "0 !important",
    margin: "0 !important",
  },
  expandIcon: {
    color: thm.colorSchemes.light.palette.primary.main,
  },
  expandIconMb: {
    color: "black",
  },
  reportText: {
    fontSize: "0.75rem !important",
    width: "100%",
    overflowX: "auto",
  },
  expansionPanelDt: {
    padding: "0 !important",
    margin: "0 !important",
    marginTop: "0 !important",
    backgroundColor: thm.colorSchemes.light.palette.primary.main + " !important",
    minHeight: "30px !important",
    paddingRight: "8px !important",
  },

  expansionPanelMb: {
    padding: "0 !important",
    marginTop: "0 !important",
    backgroundColor: "white !important",
    margin: "0 !important",
    borderBottom: "1px solid #21262b !important",
    minHeight: "30px !important",
    paddingRight: "8px !important",
  },

  resultCardheader: {
    color: "#ffffff",
  },
  resultCardheaderMb: {
    width: "100% !important",
  },

  reportTestName: {
    fontWeight: "bold",
    fontFamily: "sans-serif",
    fontSize: "14px",
    color: "black",
  },
  headerColor: {
    color: "white",
    paddingLeft: "30px;",
  },
  headerColorPurple: {
    color: "purple",
    paddingLeft: "30px;",
  },
}));

const PanelReportsPage: React.FC<PanelReportsPageProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;

  const classes = useStyles();
  const selectedPanelId = "";
  const scroll = useRef(false);
  scroll.current = false;

  const {
    data: panelReportsData,
    loading,
    error,
  } = useQuery<GetPanelReports>(GetPanelReportsQuery, {
    variables: {
      panelId: props.panelId,
      patientId: props.patientId,
      date: props.date,
      providers: props.providers,
      allPatients:
        props.allPatients && authService.getAllowAllPatients() && authService.getAllowAllPatientsAcknownledged(),
    },
  });

  const reports = panelReportsData && panelReportsData.panelreports ? panelReportsData.panelreports.reports : undefined;
  const refs =
    reports !== undefined
      ? reports.reduce((acc: { [index: string]: any }, value1) => {
          acc[value1.reportid ? value1.reportid : selectedPanelId] = createRef();
          return acc;
        }, {})
      : {};

  const [expanded, setExpanded] = React.useState<string | true>(selectedPanelId);
  const [thisReport, setThisReport] = React.useState<boolean>(false);
  const { setLabnoDigitOnly } = useContext(PatientDetailsContext);
  const setError = useContext(AppContext).setError;

  useEffect(() => {
    if (!refs) return;
    const scrollElem: Element | null = document.querySelector("#scrollElemChild");
    if (!scrollElem) {
      const scrollBars = document.querySelector("#cumScrollBar");
      const scrollElem = scrollBars && scrollBars.children[0];
      if (scrollElem) scrollElem.id = "scrollElemChild";
    }

    const scrollToPanel = (id: any) => {
      const elem = refs[id] && refs[id].current;
      if (elem) {
        scroll.current = true;
        animateScroll.scrollTo(elem.offsetTop - 140, {
          duration: SCROLL_DURATION,
          containerId: "scrollElemChild",
        });

        setTimeout(() => {
          scroll.current = false;
        }, SCROLL_DURATION);
      }
    };

    if (expanded) {
      scrollToPanel(expanded);
    }
  }, [expanded, refs]);

  //if (!reports) return null;

  // const scrollToBottomDiv = (reportid: string) => () => {

  //     const elem = refs[reportid] && refs[reportid].current
  //     if (elem) {

  //         scroll.current = true
  //         animateScroll.scrollTo(elem.offsetTop - 150, { duration: 200, containerId: 'scrollElemChild' });
  //         setTimeout(() => {
  //             scroll.current = false
  //         }, 200)
  //     };

  // }

  const handleChange = (panel: string) => (_event: React.ChangeEvent<any>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : true);
    setThisReport(newExpanded);
  };

  setError("");
  if (loading) {
    return <Loading />;
  }
  if (error) {
    const errorMessage = Constants.NO_DB_CONNECTION;

    setError(errorMessage);
    return <ErrorPopover />;
  }

  const AccordionSummaryCustom = withStyles({
    content: {
      marginTop: "0",
      marginBottom: "0",
      "&$expanded": {
        marginTop: "0",
        marginBottom: "0",
      },
    },
    expanded: {},
  })(AccordionSummary);

  if (loading) {
    return <Loading />;
  }

  const viewEpisode = (episode: any) => {
    const labDigitOnly = episode.labnumber.substring(0, 10);
    setLabnoDigitOnly(labDigitOnly);
  };

  const reportPanels = () => {
    if (reports === undefined || reports.length === 0) {
      return (
        <Box mt={6} ml={10}>
          <Typography variant="h6" color="primary">
            No reports available
          </Typography>
        </Box>
      );
    } else {
      return (
        reports !== undefined &&
        reports.map((result, index: number) => {
          // const labnumber = result.labnumber || '-1'

          return (
            <Box
              key={index}
              className={classes.resultCard}
              id={String(result.reportid)}
              mb={[0, 0, 0]}
              onClick={() => viewEpisode(result)}
            >
              <Accordion
                key={Number(result.reportid)}
                ref={result.reportid && refs[result.reportid]}
                elevation={0}
                square
                className={
                  isDesktop
                    ? index === 0
                      ? classes.expansionPanelMt
                      : classes.expansionPanelMain
                    : classes.expansionPanelMainMobile
                }
                defaultExpanded={false}
                onChange={handleChange(String(result.reportid))}
              >
                <AccordionSummaryCustom
                  expandIcon={<ExpandMoreIcon className={classes.expandIconMb} />}
                  className={
                    expanded === String(result.reportid) && thisReport === true
                      ? classes.expansionPanelDt
                      : classes.expansionPanelMb
                  }
                  id={String(result.reportid) ? String(result.reportid) : ""}
                  aria-controls={result.testname ? result.testname : ""}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    position={"relative"}
                    className={isDesktop ? classes.resultCardheader : classes.resultCardheaderMb}
                  >
                    {/* <ValueWithLabel label="Ordered:"  value={formatDate(result.dateCollected, "DD MMMM YYYY HH:mm A", true)} /> */}

                    <Typography
                      className={
                        expanded === String(result.reportid) && thisReport === true
                          ? classes.headerColor
                          : classes.headerColorPurple
                      }
                    >
                      Ordered: {formatDate(result.dateCollected, "DD MMMM YYYY HH:mm A", true)}
                    </Typography>
                  </Box>
                </AccordionSummaryCustom>
                <AccordionDetails>
                  <Box width={1}>
                    {isDesktop && (
                      <div>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          align-content="strech"
                          position={"relative"}
                          mt={2}
                          overflow={"auto"}
                          width={"100%"}
                        >
                          <ValueWithLabel
                            label="Collected:"
                            value={formatDate(result.dateCollected, "DD MMMM YYYY HH:mm A", true)}
                          />
                        </Box>
                        <Box mt={2}>
                          <ValueWithLabel label="Status:" value={getStatusName(result.interimStatus || "")} />
                        </Box>
                      </div>
                    )}

                    <pre
                      className={isDesktop ? "" : classes.reportText}
                      dangerouslySetInnerHTML={{
                        __html: result.report || "Results Pending",
                      }}
                    ></pre>
                    {expanded === String(result.reportid) && thisReport === true && (
                      <Typography>
                        <hr></hr>
                      </Typography>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })
      );
    }
  };
  return (
    <Fragment>
      <Box>{reportPanels()}</Box>
    </Fragment>
  );
};

export default PanelReportsPage;
