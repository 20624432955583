import { gql } from "@apollo/client";

export default gql`
  mutation SendToDiabeticPatients($patientRequests: [CPDSendToPatientRequest!]!) {
    sendToDiabeticPatients(patientRequests: $patientRequests) {
      results {
        id
        status
        dateSent
      }
    }
  }
`;
