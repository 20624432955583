import React, { useContext, useEffect, useState } from "react";
import { GetCombinedReport_combinedReport } from "./types/GetCombinedReport";
import styles from "./CombinedReport.module.css";
import { Typography, Box } from "@mui/material";
import { formatDate } from "../../utils/date";
import AppContext from "../../context/AppContext";
import { CumulativeContext } from "../../context/CumulativeContext";
import { ValueWithLabel } from "../ValueWithLabel/ValueWithLabel";
import { getStatusName } from "../../utils/getStatusName";
import EpisodeStatus from "./EpisodeStatus";
import { INTERUM_STATUS } from "../../constants";
import DialogBoxComment from "../DialogBox/DialogBoxComment";
import CommentIcon from "@mui/icons-material/Comment";
import * as Constants from "../../constants";
import { CommentDialog } from "../DialogBox/CommentDialog";
import { AddPanelComment, AddPanelCommentVariables } from "./types/AddPanelComment";
import AddPanelCommentMutation from "./AddPanelCommentMutation";
import { useMutation } from "@apollo/client";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import Loading from "../Loading/Loading";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

type ResultsHeaderProps = {
  episode: GetCombinedReport_combinedReport;
};

const ResultsHeader: React.FC<ResultsHeaderProps> = (props) => {
  const { episode } = props;
  const { isDesktop } = useContext(AppContext);
  const { setIsCumulative, setPanelId, setTestname, setShowIndGraph, setCurrentReportId } =
    useContext(CumulativeContext);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [random, setRandom] = useState<number>(0);
  const [commentsPresent, setCommentsPresent] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);

  const [
    updatePanelCommentMutation,
    { data: updateCommentData, loading: isUpdateCommentLoading, error: updateCommentError },
  ] = useMutation<AddPanelComment, AddPanelCommentVariables>(AddPanelCommentMutation);

  const openCumulativePanel = (panelId: number, testname: string, reportId: number) => {
    setIsCumulative(true);
    setShowIndGraph(false);
    setPanelId(panelId);
    setTestname(testname);
    setCurrentReportId(reportId);
  };

  const isOffline = () => {
    if (episode) {
      if (episode.csPanelApprovalStatus === Constants.APPROVAL_STATUS.Offline) {
        return true;
      }
    }

    return false;
  };

  const addCommentClickHandler = () => {
    setRandom(random + 1);
    setIsCommentsOpen(true);
  };

  const statusValue = `${getStatusName(episode.reportStatus)}${
    episode.reportStatus === INTERUM_STATUS.Pending ? " - Combined Report View is not available" : ""
  }`;

  const handleClose = () => {
    setIsCommentsOpen(false);
  };

  useEffect(() => {
    if (updateCommentData) {
      setCommentsPresent(true);
    }
  }, [updateCommentData]);

  //handle errors
  if (updateCommentError) {
    return <ErrorPopover />;
  }

  const updateAtomicCommentHandler = (dlg: CommentDialog) => {
    if (episode && episode.panelId) {
      updatePanelCommentMutation({
        variables: {
          input: {
            reportId: Number(episode.reportId),
            comment: dlg.comment,
          },
        },
      });
    }

    setIsCommentsOpen(false);
  };

  return (
    <div className={styles.panelHeader}>
      <Box
        display="flex"
        justifyContent="space-between"
        align-content="strech"
        position={"relative"}
        mt={3}
        width={"100%"}
        flexDirection="row"
      >
        <Box sx={{ width: "45%" }}>
          <ValueWithLabel
            label="Collected:"
            value={formatDate(episode.reportDateCollected, "DD MMM YYYY HH:mm A", true)}
          />
          <ValueWithLabel label="Status:" value={statusValue} />
        </Box>
        <Box sx={{ width: "45%" }}>
          <EpisodeStatus episode={episode} />
        </Box>
        <Box>
          {isDesktop && episode.cumulative && (
            <>
              <Typography
                className={styles.cummulitiveBox}
                onClick={() => openCumulativePanel(episode.panelId, episode.reportTestName, episode.reportId)}
              >
                Cumulative View
                <StackedBarChartIcon style={{ margin: "0 0 -6px 5px" }} />
              </Typography>
            </>
          )}
        </Box>
        <Box pl={2}>
          {isUpdateCommentLoading || commentsLoading ? (
            <Loading iconStyle={{ width: "24px", height: "24px" }} mt={0} boxStyle={{ paddingRight: "10px" }} />
          ) : (
            <CommentIcon
              className={commentsPresent ? styles.commentYes : styles.commentNo}
              onClick={() => {
                addCommentClickHandler();
              }}
            />
          )}
        </Box>
      </Box>
      <DialogBoxComment
        open={isCommentsOpen}
        onClose={handleClose}
        onSubmit={(dlg: CommentDialog) => {
          updateAtomicCommentHandler(dlg);
        }}
        atomic={null}
        reportId={episode.reportId}
        random={random}
        isOffline={isOffline()}
        setCommentsPresent={setCommentsPresent}
        setCommentsLoading={setCommentsLoading}
      />
    </div>
  );
};

export default ResultsHeader;
