import React from "react";
import { Typography, Box } from "@mui/material";
import styles from "./ValueWithLabel.module.css";
type ValueWithLabelProps = {
  label: string;
  value: string;
  width?: number;
};
export const ValueWithLabel: React.FC<ValueWithLabelProps> = (props) => {
  const width = props.width ? props.width : 500;
  return (
    <Box display={"flex"} width={`${width}px`} justifyContent={"flex-start"}>
      <Typography display={"inline"} className={`${styles.labelText}`} variant={"body1"}>
        {" "}
        {props.label}
      </Typography>
      <Typography display={"inline"} className={`${styles.valueText}`} variant={"body1"}>
        {" "}
        {props.value}
      </Typography>
    </Box>
  );
};
