import React, { useContext, Fragment, createRef, useEffect, useRef } from "react";
import { Box, Typography, AccordionSummary, Accordion, AccordionDetails, CircularProgress, Fab } from "@mui/material";
import { withStyles } from "@mui/styles";
import AppContext from "../../context/AppContext";
import { ReportViewerProps } from "../ReportViewer/EpisodeReportsProps";
import styles from "./ReportViewer.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ValueWithLabel } from "../ValueWithLabel/ValueWithLabel";
import { animateScroll } from "react-scroll";
import { CumulativeContext } from "../../context/CumulativeContext";
import { Waypoint } from "react-waypoint";
import setViewed from "../../utils/setViewed";
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client";
import { MarkAsViewed } from "../common/MarkAsViewedMutation/types/MarkAsViewed";
import markAsViewedMutation from "../common/MarkAsViewedMutation/MarkAsViewedMutation";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import { useHistory } from "react-router";
import { updatePatientepisodesCache, updateEpisodeReportsCache } from "../../utils/updateApolloCache";
import cloneDeep from "lodash/cloneDeep";
import { upperFirst } from "lodash";
import { getStatusName } from "../../utils/getStatusName";
import { formatDate } from "../../utils/date";
// import { GetFoodPrintReport } from "../PrintableReports/types/GetFoodPrintReport";
// import GetFoodPrintReportQuery from "../PrintableReports/GetFoodPrintReportQuery";
import { GetPDFReport } from "../PrintableReports/types/GetPDFReport";
import GetPDFReportQuery from "../PrintableReports/GetPDFReportQuery";
import { b64toBlob } from "../PrintableReports/pdfUtils";
import authService from "../../services/authService";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Constants from "../../constants";
import { GetCovidCertificate } from "../PrintableReports/types/GetCovidCertificate";
import GetCovidCertificateQuery from "../PrintableReports/GetCovidCertificateQuery";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { INTERUM_STATUS } from "../../constants";
import { useLocation } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { isTenantVet } from "../../services/tenantService";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";
import theme from "../theme";
import { downloadHelper } from "../../utils/downloadHelper";
import { GetPrintableReports, GetPrintableReportsVariables } from "../PrintableReports/types/GetPrintableReports";
import getPrintableReports from "../PrintableReports/GetPrintableReportQuery";

const SCROLL_DURATION = 0;
const RESET_LAB_NUMBER = "RESET";
const SCROLL_THRESHOLD_MILLISECONDS = 400;

const PatientEpisodeResult: React.FC<ReportViewerProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const { setIsCumulative, setPanelId, setTestname, setShowIndGraph, setCurrentReportId } =
    useContext(CumulativeContext);
  const scroll = useRef(false);
  scroll.current = false;
  const waypointTimeout: any = useRef();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setError = useContext(AppContext).setError;
  const [foodprintLabnumber, setFoodprintLabnumber] = React.useState<string>("");
  const [covidLabnumber, setCovidLabnumber] = React.useState<string>("");
  const [covidProviderno, setCovidProviderno] = React.useState<string>("");
  const [pdfReportType, setPDFReportType] = React.useState<string>("");
  const [pdfReportId, setPDFReportId] = React.useState<number>(0);
  const location = useLocation();
  const { setLabnoDigitOnly } = useContext(PatientDetailsContext);

  const {
    reports: results,
    initialLabNo,
    setInitialLabNo,
    markAsViewed,
    updateEpisodeList,
    auditLog,
    histoImages: images,
    patientId,
    labnoDigitOnly,
    allPatients,
  } = props;
  const history = useHistory();
  const mobility = authService.isMobility();

  let selectedPanelId: string = initialLabNo;

  const refs =
    results &&
    results.reduce((acc: { [index: string]: any }, value1) => {
      acc[value1.labnumber ? value1.labnumber : selectedPanelId] = createRef();
      return acc;
    }, {});

  // const [getFoodprintReportQuery, { data, error: appoloError }] = useLazyQuery<GetFoodPrintReport>(
  //   GetFoodPrintReportQuery,
  //   {
  //     variables: { labnumber: foodprintLabnumber },
  //   },
  // );

  const [getPDFReportQuery, { data: pdfData, error: pdfError }] = useLazyQuery<GetPDFReport>(GetPDFReportQuery, {
    variables: { labnumber: foodprintLabnumber },
  });

  const [getCovidCertificateQuery, { data: data2, error: appoloError2 }] = useLazyQuery<GetCovidCertificate>(
    GetCovidCertificateQuery,
    {
      variables: { labnumber: covidLabnumber, providerno: covidProviderno },
      fetchPolicy: "network-only",
    },
  );

  const queryVariables = (): GetPrintableReportsVariables => ({
    patientId: patientId,
    providers: authService.getProviders(),
    labnoDigitOnly: labnoDigitOnly,
    includePdf: true,
    allPatients: allPatients,
    ids: [pdfReportId],
  });

  const [getPrintableReportsQuery, { data: printableData, error: printableError }] = useLazyQuery<GetPrintableReports>(
    getPrintableReports,
    {
      variables: queryVariables(),
    },
  );

  useEffect(() => {
    if (results && initialLabNo !== RESET_LAB_NUMBER) {
      const viewedNumber = initialLabNo || results[0].labnumber;
      const report = results.find((result) => result.labnumber === viewedNumber);
      setViewed(props.allPatients, markAsViewed, auditLog, updateEpisodeList, report);
    }
  }, [initialLabNo, results, markAsViewed, auditLog, updateEpisodeList, props.allPatients]);

  useEffect(() => {
    if (!refs) return;

    const scrollElem: Element | null = document.querySelector("#scrollElemChild");

    if (!scrollElem) {
      const scrollBars = document.querySelector("#scrollBars");
      const scrollElem = scrollBars && scrollBars.children[0];
      if (scrollElem) scrollElem.id = "scrollElemChild";
    }

    const scrollToPanel = (id: any) => {
      const elem = refs[id] && refs[id].current;
      if (elem) {
        scroll.current = true;
        animateScroll.scrollTo(elem.offsetTop - 108, {
          duration: SCROLL_DURATION,
          containerId: "scrollElemChild",
        });

        setTimeout(() => {
          scroll.current = false;
          setInitialLabNo && setInitialLabNo(RESET_LAB_NUMBER); // Reset the selected lab number
        }, SCROLL_DURATION);
      }
    };

    if (initialLabNo && isDesktop) {
      scrollToPanel(initialLabNo);
    }
  }, [results, isDesktop, initialLabNo, refs, setInitialLabNo]);

  useEffect(() => {
    const hasValidData = (data: GetPrintableReports | undefined) =>
      data && data.episodereports && data.episodereports.reports;

    if (pdfData && pdfData.pdfreport && hasValidData(printableData)) {
      if (pdfData.pdfreport.reportsize > 0) {
        const blobData = b64toBlob(pdfData.pdfreport.reportpdf, "application/pdf");
        downloadHelper(blobData, `${pdfReportType}-report-${foodprintLabnumber}.pdf`);
        setIsLoading(false);
      } else {
        setError(
          `No ${upperFirst(pdfReportType.replaceAll("-", " "))} Report is available now. Please try again later`,
        );
        setIsLoading(false);
      }
    }

    if (data2 && data2.covidtravelcertificate) {
      if (data2.covidtravelcertificate.reportsize > 0) {
        const blobData = b64toBlob(data2.covidtravelcertificate.reportpdf, "application/pdf");
        downloadHelper(blobData, `covid-certificate-${covidLabnumber}.pdf`);
      } else {
        setError(`No Covid Certificate is available now. Please try again later`);
      }
      setIsLoading(false);
    }

    // if (appoloError) {
    //   const errorMessage = Constants.NO_DB_CONNECTION;

    //   setError(errorMessage);
    //   setIsLoading(false);
    // }

    if (appoloError2) {
      const errorMessage = Constants.NO_DB_CONNECTION;

      setError(errorMessage);
      setIsLoading(false);
    }

    if (pdfError) {
      const errorMessage = Constants.NO_DB_CONNECTION;

      setError(errorMessage);
      setIsLoading(false);
    }
    if (printableError) {
      const errorMessage = Constants.NO_DB_CONNECTION;

      setError(errorMessage);
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [data2, appoloError2, pdfData, pdfError, printableData]);

  if (!selectedPanelId) {
    selectedPanelId = results && results.length > 0 && results[0].labnumber ? results[0].labnumber : "";
  }

  const [expanded, setExpanded] = React.useState<string | true>(selectedPanelId);

  const [currentLabnumber, setCurrentLabnumber] = React.useState<string | null>(
    results && results.length > 1 && results[1].labnumber ? results[1].labnumber : "",
  );

  const [bottomPageReached, setBottomPageReach] = React.useState<boolean>(false);

  const scrollToBottomDiv = (labnumber: string) => () => {
    const elem = refs && refs[labnumber] && refs[labnumber].current;
    if (elem) {
      scroll.current = true;
      animateScroll.scrollTo(elem.offsetTop - 108, {
        duration: 200,
        containerId: "scrollElemChild",
      });
      setTimeout(() => {
        scroll.current = false;
      }, 200);
    }
  };

  const handleChange = (panel: string) => (_event: React.ChangeEvent<any>, newExpanded: boolean) => {
    if (newExpanded && results) {
      const report = results.find((result) => result.labnumber === panel);
      setViewed(props.allPatients, markAsViewed, auditLog, updateEpisodeList, report);
    }
    setExpanded(newExpanded ? panel : true);
    scrollToBottomDiv(panel);
    setCurrentLabnumber(panel);
  };

  if (!results) return null;

  const AccordionSummaryCustom = withStyles({
    content: {
      marginTop: "0",
      marginBottom: "0",
      "&$expanded": {
        marginTop: "0",
        marginBottom: "0",
      },
    },
    expanded: {},
  })(AccordionSummary);

  const openCumulativePanel = (panelId: number, testname: string, reportId: number) => {
    setIsCumulative(true);
    setShowIndGraph(false);
    setPanelId(panelId);
    setTestname(testname);
    setCurrentReportId(reportId);
  };

  // const downloadFoodPrintReport = (labnumber: string) => {
  //   setFoodprintLabnumber(labnumber);
  //   getFoodprintReportQuery({ variables: { labnumber: labnumber } });
  //   setIsLoading(true);
  // };

  const downloadPDFReport = (reporttype: string, reportid: number, labnumber: string) => {
    setPDFReportType(reporttype);
    setPDFReportId(reportid);
    setFoodprintLabnumber(labnumber);
    getPrintableReportsQuery();
    getPDFReportQuery({ variables: { reporttype: reporttype, labnumber: labnumber } });
    setIsLoading(true);
  };

  const downloadCovidTravelCertificate = (labnumber: string, providerno: string) => {
    setCovidLabnumber(labnumber);
    setCovidProviderno(providerno);
    getCovidCertificateQuery({
      variables: { labnumber: labnumber, providerno: providerno },
    });
    setIsLoading(true);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const debounceMarkAsViewed = (callback: Function) => {
    clearTimeout(waypointTimeout.current);
    waypointTimeout.current = setTimeout(() => callback(), SCROLL_THRESHOLD_MILLISECONDS);
  };

  const onWaypointEnter = (waypointEvent: Waypoint.CallbackArgs, index: number) => {
    if (results.length > 1 && results[index + 1] && results[index + 1].labnumber && isDesktop) {
      setCurrentLabnumber(results[index + 1].labnumber);
    }
    if (!results[index + 1]) {
      setBottomPageReach(true);
    } else {
      setBottomPageReach(false);
    }

    if (isDesktop && !scroll.current) {
      if (waypointEvent.event) {
        if (waypointEvent.previousPosition === "below") {
          debounceMarkAsViewed(() =>
            setViewed(props.allPatients, markAsViewed, auditLog, updateEpisodeList, results[index]),
          );
        }
      }
    }
  };

  const onWaypointLeave = (waypointEvent: Waypoint.CallbackArgs, index: number) => {
    if (waypointEvent.event && waypointEvent.currentPosition === "below" && index > 0) setBottomPageReach(false);

    if (isDesktop && !scroll.current && waypointEvent.event) {
      if (waypointEvent.currentPosition === "below" && index > 0) {
        clearTimeout(waypointTimeout.current);
        debounceMarkAsViewed(() =>
          setViewed(props.allPatients, markAsViewed, auditLog, updateEpisodeList, results[index - 1]),
        );
      }
    }
  };

  const reportCards = () => {
    return (
      results !== undefined &&
      results.map((result, index: number) => {
        const labnumber = result.labnumber || "-1";

        return (
          <Waypoint
            fireOnRapidScroll={false}
            key={index}
            bottomOffset={"45%"}
            onEnter={(event: Waypoint.CallbackArgs) => onWaypointEnter(event, index)}
            onLeave={(event: Waypoint.CallbackArgs) => onWaypointLeave(event, index)}
          >
            <Box key={index} className={styles.resultCard} id={labnumber} mb={[0, 0, 2]}>
              <Accordion
                key={labnumber}
                ref={result.labnumber && refs && refs[labnumber]}
                elevation={0}
                square
                className={
                  isDesktop
                    ? index === 0
                      ? styles.expansionPanelMt
                      : styles.expansionPanelMain
                    : styles.expansionPanelMainMobile
                }
                defaultExpanded={isDesktop ? true : false}
                expanded={isDesktop ? undefined : expanded === labnumber}
                onChange={isDesktop ? scrollToBottomDiv(labnumber) : handleChange(labnumber)}
              >
                <AccordionSummaryCustom
                  expandIcon={<ExpandMoreIcon className={isDesktop ? styles.expandIcon : styles.expandIconMb} />}
                  className={isDesktop ? styles.expansionPanelDt : styles.expansionPanelMb}
                  id={result.labnumber ? result.labnumber : ""}
                  aria-controls={result.testname ? result.testname : ""}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    position={"relative"}
                    p={"16px"}
                    className={isDesktop ? styles.resultCardheader : styles.resultCardheaderMb}
                  >
                    <Typography display={"inline"} className={styles.reportTestName}>
                      {result.testname}
                      {result.urgentStatus && (
                        <>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <PriorityHighIcon
                            htmlColor={theme.colorSchemes.light.palette.error.main}
                            fontSize="small"
                            className={styles.urgentPanel}
                          />{" "}
                        </>
                      )}
                    </Typography>
                    <Typography display={"inline"}>
                      {isDesktop ? "" : formatDate(result.dateCollected, "DD/MM/YY", true)}
                    </Typography>
                  </Box>
                </AccordionSummaryCustom>
                <AccordionDetails>
                  <Box width={1}>
                    <div>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        align-content="strech"
                        position={"relative"}
                        mt={2}
                        overflow={"auto"}
                        width={"100%"}
                      >
                        <ValueWithLabel
                          width={!isDesktop ? 300 : undefined}
                          label="Collected:"
                          value={formatDate(result.dateCollected, "DD MMMM YYYY HH:mm A", true)}
                        />
                        <Box display="flex" justifyContent="space-between" align-content="strech" position={"relative"}>
                          {isDesktop && result.cumulative && result.report && (
                            <Typography
                              className={styles.cummulitiveBox}
                              onClick={() =>
                                openCumulativePanel(
                                  Number(result.panelId),
                                  result.testname as string,
                                  Number(result.reportid),
                                )
                              }
                            >
                              Cumulative View
                              <StackedBarChartIcon style={{ margin: "0 0 -6px 5px" }} />
                            </Typography>
                          )}
                          {isDesktop && result.pdfExist && result.pdfType === "foodprint" && result.reportid && (
                            <Box ml={2}>
                              {isLoading ? (
                                <Box textAlign="center" className={styles.foodPrintBox}>
                                  <CircularProgress size={16} />
                                </Box>
                              ) : (
                                <Typography
                                  className={styles.foodPrintBox}
                                  // onClick={() => downloadFoodPrintReport(result.labnumber as string)}
                                  onClick={() =>
                                    downloadPDFReport("foodprint", result.reportid || 0, result.labnumber as string)
                                  }
                                >
                                  Food Print Report
                                </Typography>
                              )}
                            </Box>
                          )}
                          {isDesktop &&
                            result.pdfExist &&
                            result.pdfType &&
                            result.pdfType !== "foodprint" &&
                            result.reportid && (
                              <Box ml={2}>
                                {isLoading ? (
                                  <Box textAlign="center" className={styles.foodPrintBox}>
                                    <CircularProgress size={16} />
                                  </Box>
                                ) : (
                                  <Typography
                                    className={styles.foodPrintBox}
                                    onClick={() =>
                                      downloadPDFReport(
                                        result.pdfExist && result.pdfType && result.pdfType !== "foodprint"
                                          ? result.pdfType
                                          : "",
                                        result.reportid || 0,
                                        result.labnumber as string,
                                      )
                                    }
                                  >
                                    Open Report
                                  </Typography>
                                )}
                              </Box>
                            )}
                          {isDesktop &&
                            result.referredProviderNo === "HSPCOVPO" &&
                            (result.interimStatus === INTERUM_STATUS.Final ||
                              result.interimStatus === INTERUM_STATUS.FinalCorrected) && (
                              <Box ml={2}>
                                {isLoading ? (
                                  <Box textAlign="center" className={styles.covidTravelBox}>
                                    <CircularProgress size={16} />
                                  </Box>
                                ) : (
                                  <Typography
                                    className={styles.covidTravelBox}
                                    onClick={() =>
                                      downloadCovidTravelCertificate(
                                        props.labnoDigitOnly as string,
                                        result.referredProviderNo as string,
                                      )
                                    }
                                  >
                                    COVID Travel Certificate
                                  </Typography>
                                )}
                              </Box>
                            )}
                        </Box>
                      </Box>
                      <Box mt={result.cumulative ? 0 : 3}>
                        <ValueWithLabel
                          width={!isDesktop ? 300 : undefined}
                          label="Status:"
                          value={getStatusName(result.interimStatus || "")}
                        />
                      </Box>
                    </div>

                    <pre
                      style={{
                        //minWidth: `${window.innerWidth - 16}px`,
                        width: "100%",
                        overflowX: "auto",
                      }}
                      className={styles.reportText}
                      dangerouslySetInnerHTML={{
                        __html: result.report || "Results Pending",
                      }}
                    ></pre>

                    {result.ccdoctors &&
                      result.ccdoctors.map((item, index) => {
                        const title = item.title ? `${item.title}. ` : "";
                        const provider = item.provider ? ` (${item.provider})` : "";
                        return (
                          <Typography key={index} className={styles.reportText} display={"inline"}>
                            CC : {title} &nbsp;{item.firstname} &nbsp; {item.surname}&nbsp; {provider} <br></br>
                          </Typography>
                        );
                      })}

                    {!isDesktop && (
                      <Box display="flex" justifyContent="center" mt={2} flexWrap={"wrap"}>
                        {result.cumulative && result.report && (
                          <Box display="flex" justifyContent="center" ml={1} mb={1}>
                            <Typography
                              className={styles.cummulitiveBox}
                              onClick={() => {
                                setShowIndGraph(false);
                                setLabnoDigitOnly(props.labnoDigitOnly);
                                setCurrentReportId(Number(result.reportid));
                                history.push(
                                  `/atomic/${props.patientId}/${props.allPatients ? "1" : "0"}/${result.panelId}/${
                                    props.labnoDigitOnly
                                  }?test=${result.testname}`,
                                  location.state,
                                );
                              }}
                            >
                              Cumulative View
                              <StackedBarChartIcon style={{ margin: "0 0 -6px 6px" }} />
                            </Typography>
                          </Box>
                        )}
                        {result.pdfExist && result.pdfType === "foodprint" && result.reportid && (
                          <Box display="flex" justifyContent="center" ml={1} mb={1}>
                            {isLoading ? (
                              <Box textAlign="center" className={styles.foodPrintBox}>
                                <CircularProgress size={16} />
                              </Box>
                            ) : (
                              <Typography
                                className={styles.foodPrintBox}
                                // onClick={() => downloadFoodPrintReport(result.labnumber as string)}
                                onClick={() =>
                                  downloadPDFReport("foodprint", result.reportid || 0, result.labnumber as string)
                                }
                              >
                                Food Print Report
                              </Typography>
                            )}
                          </Box>
                        )}
                        {result.pdfExist && result.pdfType && result.pdfType !== "foodprint" && result.reportid && (
                          <Box display="flex" justifyContent="center" ml={1} mb={1}>
                            {isLoading ? (
                              <Box textAlign="center" className={styles.foodPrintBox}>
                                <CircularProgress size={16} />
                              </Box>
                            ) : (
                              <Typography
                                className={styles.foodPrintBox}
                                onClick={() =>
                                  downloadPDFReport(
                                    result.pdfExist && result.pdfType && result.pdfType !== "foodprint"
                                      ? result.pdfType
                                      : "",
                                    result.reportid || 0,
                                    result.labnumber as string,
                                  )
                                }
                              >
                                Open Report
                              </Typography>
                            )}
                          </Box>
                        )}
                        {result.referredProviderNo === "HSPCOVPO" &&
                          (result.interimStatus === INTERUM_STATUS.Final ||
                            result.interimStatus === INTERUM_STATUS.FinalCorrected) && (
                            <Box display="flex" justifyContent="center" ml={1} mb={1}>
                              {isLoading ? (
                                <Box textAlign="center" className={styles.covidTravelBox}>
                                  <CircularProgress size={16} />
                                </Box>
                              ) : (
                                <Typography
                                  className={styles.covidTravelBox}
                                  onClick={() =>
                                    downloadCovidTravelCertificate(
                                      props.labnoDigitOnly as string,
                                      result.referredProviderNo as string,
                                    )
                                  }
                                >
                                  COVID Travel Certificate
                                </Typography>
                              )}
                            </Box>
                          )}
                      </Box>
                    )}

                    {result.panelDepartmentCode === "SP" && images && images?.length > 0 && (
                      <span>
                        {isLoading ? (
                          <Box textAlign="center" className={styles.foodPrintBox}>
                            <CircularProgress size={16} />
                          </Box>
                        ) : (
                          <span>
                            <Typography>
                              <p>
                                <strong>HISTOLOGY IMAGE(S):</strong>
                              </p>
                            </Typography>
                            <ImageList sx={{ width: "auto", height: "auto" }} cols={isDesktop ? 6 : 3} rowHeight="auto">
                              {images.map((image) => (
                                <ImageListItem key={image.file}>
                                  <img
                                    src={`data:${image.thumbnailMimetype};base64,${image.thumbnailBase64data}`}
                                    alt={image.file}
                                    loading="lazy"
                                    onClick={() => {
                                      if (!mobility) {
                                        window.open(
                                          `/patient/${props.patientId}/${result.reportid}/histo-image?filename=${image.file}`,
                                          "_blank",
                                        );
                                      } else {
                                        history.push(
                                          `/patient/${props.patientId}/${result.reportid}/histo-image?filename=${image.file}`,
                                          location.state,
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          </span>
                        )}
                      </span>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Waypoint>
        );
      })
    );
  };

  return (
    <Fragment>
      <Box width={"auto"} style={{ touchAction: "pan-y" }}>
        <>{reportCards()}</>
      </Box>
      {results.length > 1 && !bottomPageReached && (
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          className={`${styles.loadMoreButton} ${
            isTenantVet() ? styles.loadMoreButtonVet : styles.loadMoreButtonNormal
          }`}
          onClick={() => {
            if (currentLabnumber) {
              if (!isDesktop) {
                const report = results.find((result) => result.labnumber === currentLabnumber);
                setViewed(props.allPatients, markAsViewed, auditLog, updateEpisodeList, report);
                setExpanded(currentLabnumber);
              }
              const elem = refs && refs[currentLabnumber] && refs[currentLabnumber].current;
              if (elem) {
                scroll.current = true;
                if (isDesktop) {
                  animateScroll.scrollTo(elem.offsetTop - 108, {
                    duration: 200,
                    containerId: "scrollElemChild",
                  });
                } else {
                  setTimeout(() => {
                    //Timeout to make sure the accordion collapse before getting the top element.
                    animateScroll.scrollTo(elem.offsetTop - 140, {
                      duration: 200,
                      containerId: "scrollElemChild",
                    });
                  }, 800);
                }

                setTimeout(() => {
                  if (props.reports && props.reports.length > 1) {
                    const index = props.reports.findIndex((element) => {
                      if (element.labnumber === currentLabnumber) return true;
                      else return false;
                    });

                    if (props.reports[index + 1] && props.reports[index + 1].labnumber)
                      setCurrentLabnumber(props.reports[index + 1].labnumber);

                    if (!props.reports[index + 1]) setBottomPageReach(true);
                  }
                  scroll.current = false;
                }, 200);
              }
            }
          }}
        >
          <DownIcon className={styles.downArrowIcon} />
        </Fab>
      )}
    </Fragment>
  );
};

const updateReports = (reports: any, reportId: string) => {
  if (!reports.current) return;
  reports.current = reports.current.map((report: any) => {
    if (report.reportid === parseInt(reportId)) {
      return { ...report, dateViewed: "viewed" };
    }
    return report;
  });
};

const ReportViewer: React.FC<ReportViewerProps> = (props) => {
  const [markAsViewed] = useMutation<MarkAsViewed>(markAsViewedMutation, {
    ignoreResults: true,
  });
  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });
  const client = useApolloClient();
  // TODO: this ref is a bit of a hack, maybe better to check against the cached
  // apollo data, such as a client.readQuery
  const reports = useRef(props.reports && cloneDeep(props.reports));

  const onMarkAsViewed = (reportId: string) => {
    updateReports(reports, reportId);
    updateEpisodeReportsCache(client, reportId, {
      patientId: props.patientId,
      providers: authService.getProviders(),
      labnoDigitOnly: props.labnoDigitOnly,
      allPatients: props.allPatients,
      includePdf: false,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
    updatePatientepisodesCache(client, reportId, {
      patientId: props.patientId,
      providers: authService.getProviders(),
      allPatients: props.allPatients,
      sortBy: props.sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  return (
    <PatientEpisodeResult
      {...props}
      markAsViewed={markAsViewed}
      updateEpisodeList={onMarkAsViewed}
      auditLog={auditLog}
    />
  );
};

export default ReportViewer;
