/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";

// Reference: https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

type PatientDetailsContextProps = {
  initialLabNo: string;
  setInitialLabNo: React.Dispatch<React.SetStateAction<string>>;
  labnoDigitOnly: string;
  setLabnoDigitOnly: React.Dispatch<React.SetStateAction<string>>;
  labId: number;
  setLabId: React.Dispatch<React.SetStateAction<number>>;
};

export const PatientDetailsContext = React.createContext<PatientDetailsContextProps>({
  initialLabNo: "",
  setInitialLabNo: () => {},
  labnoDigitOnly: "",
  setLabnoDigitOnly: () => {},
  labId: 0,
  setLabId: () => {},
});

export const PatientDetailsContextProvider: React.FC<RouteComponentProps> = (props) => {
  const [initialLabNo, setInitialLabNo] = useState("");
  const [labnoDigitOnly, setLabnoDigitOnly] = useState("UNKNOWN");
  const [labId, setLabId] = useState(0);

  return (
    <PatientDetailsContext.Provider
      value={{
        initialLabNo,
        setInitialLabNo,
        labnoDigitOnly,
        setLabnoDigitOnly,
        labId,
        setLabId,
      }}
    >
      {props.children}
    </PatientDetailsContext.Provider>
  );
};

export default withRouter(PatientDetailsContextProvider);
