import React, { Fragment } from "react";
import MainNavigation from "../MainNavigation/MainNavigation";
import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router";
import queryString from "query-string";
import authService from "../../services/authService";
import {
  GetHistologyData as GetHistologyDataQueryType,
  GetHistologyData_patient,
  GetHistologyData_patientreportdata,
} from "./types/GetHistologyData";
import GetHistologyDataQuery from "./GetHistologyDataQuery";
import { formatDate } from "../../utils/date";
import { getStatusName } from "../../utils/getStatusName";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

type PathParams = {
  id: string;
  reportid: string;
};

const useStyles = makeStyles(() => ({
  container: {
    border: "2px solid #000000",
    padding: "10px",
  },
}));

const tableRow = {
  verticalAlign: "top",
} as const;

const tableLabelColumnLeft = {
  borderBottom: "none",
  width: "5%",
  fontWeight: "bold",
  fontSize: "0.8em",
} as const;

const tableValueColumnLeft = {
  borderBottom: "none",
  width: "35%",
  fontSize: "0.8em",
} as const;

const tableLabelColumnRight = {
  borderBottom: "none",
  width: "25%",
  fontWeight: "bold",
  fontSize: "0.8em",
} as const;

const tableValueColumnRight = {
  borderBottom: "none",
  width: "35%",
  fontSize: "0.8em",
} as const;

const HistoImagePage: React.FC<any> = (props) => {
  const { match } = props;
  const location = useLocation();
  const classes = useStyles();
  const query = queryString.parse(location.search);
  const mobility = authService.isMobility();
  const pathParams = match.params as PathParams;
  const id: number = parseInt(pathParams.id);
  const reportid: number = parseInt(pathParams.reportid);
  const [base64data, setBase64data] = React.useState<string>("");
  const [imgName, setImgName] = React.useState<string>("");
  const [mimeType, setMimeType] = React.useState<string>("");
  const [patient, setPatient] = React.useState<GetHistologyData_patient>();
  const [panel, setPanel] = React.useState<GetHistologyData_patientreportdata>();

  const { data, loading, error } = useQuery<GetHistologyDataQueryType>(GetHistologyDataQuery, {
    variables: {
      filename: query.filename,
      size: "normal",
      patientId: id,
      reportId: reportid,
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (data && !loading) {
      if (data.histoimage) {
        setBase64data(data.histoimage.base64data);
        setImgName(data.histoimage.filename);
        setMimeType(data.histoimage.mimetype);
      }

      if (data.patient) {
        setPatient(data.patient);
      }

      if (data.patientreportdata) {
        setPanel(data.patientreportdata);
      }
    }
  }, [data, loading]);

  if (error) {
    console.error("Error", error);
  }

  return (
    <Fragment>
      <MainNavigation hideTopMenu showBackButton={mobility} goBack />
      {loading ? (
        <Box style={{ textAlign: "center" }}>
          <CircularProgress color="primary" size={40} />
        </Box>
      ) : (
        <Box style={{ padding: "0 10px 10px 10px" }}>
          <Grid container spacing={1} className={classes.container}>
            <TableContainer>
              <Table padding="none">
                <TableBody>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft} rowSpan={3}>
                      Patient:
                    </TableCell>
                    <TableCell sx={tableValueColumnLeft} rowSpan={3}>
                      {patient?.fullname} <br /> {patient?.street} <br />{" "}
                      {patient?.city + " " + patient?.state + " " + patient?.postcode}
                    </TableCell>
                    <TableCell sx={tableLabelColumnRight}>Referred:</TableCell>
                    <TableCell sx={tableValueColumnRight}>
                      {formatDate(panel?.dateReferred as string, "DD/MM/YYYY", true)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnRight}>Collected:</TableCell>
                    <TableCell sx={tableValueColumnRight}>
                      {formatDate(panel?.dateCollected as string, "DD/MM/YYYY HH:mm:ss", true)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnRight}>Tested:</TableCell>
                    <TableCell sx={tableValueColumnRight}>
                      {formatDate(panel?.dateCollected as string, "DD/MM/YYYY HH:mm:ss", true)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft}>DOB:</TableCell>
                    <TableCell sx={tableValueColumnLeft}>
                      {formatDate(patient?.dob as string, "DD/MM/YYYY", true)}
                    </TableCell>
                    <TableCell sx={tableLabelColumnRight}>UR/MR No:</TableCell>
                    <TableCell sx={tableValueColumnRight}>{panel?.urno}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft}>Sex:</TableCell>
                    <TableCell sx={tableValueColumnLeft}>{patient?.sex}</TableCell>
                    <TableCell sx={tableLabelColumnRight}>Ward:</TableCell>
                    <TableCell sx={tableValueColumnRight}>{panel?.ward}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft}>Test</TableCell>
                    <TableCell sx={tableValueColumnLeft}>{panel?.testnameProperCase}</TableCell>
                    <TableCell sx={tableLabelColumnRight}>Lab No:</TableCell>
                    <TableCell sx={tableValueColumnRight}>{panel?.labnumber}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft} rowSpan={3}>
                      Lab:
                    </TableCell>
                    <TableCell sx={tableValueColumnLeft} rowSpan={3}>
                      {panel?.address} <br /> {panel?.suburb} <br /> {panel?.phone}
                    </TableCell>
                    <TableCell sx={tableLabelColumnRight}>Status:</TableCell>
                    <TableCell sx={tableValueColumnRight}>{getStatusName(panel?.interimStatus || "")}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnRight} rowSpan={2}>
                      Patient Belongs to User:
                    </TableCell>
                    <TableCell sx={tableValueColumnRight}>{panel?.referringDoctor}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableValueColumnRight}>{panel?.referredProviderNo}</TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                    <TableCell sx={tableLabelColumnLeft}>Reported:</TableCell>
                    <TableCell sx={tableValueColumnLeft}>
                      {formatDate(panel?.dateCreated as string, "DD/MM/YYYY HH:mm:ss", true)}
                    </TableCell>
                    <TableCell sx={tableLabelColumnRight}></TableCell>
                    <TableCell sx={tableValueColumnRight}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Box style={{ textAlign: "center", marginTop: "12px" }}>
            <img src={`data:${mimeType};base64,${base64data}`} style={{ maxWidth: "100%" }} alt={imgName} />
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default HistoImagePage;
