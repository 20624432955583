import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  TextField,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import styles from "./EditHospitalUser.module.css";
import get from "lodash/get";
import { Formik } from "formik";

import { UpdateUser, UpdateUserVariables } from "../HospAdminUserListTable/types/UpdateUser";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import UpdateUserMutation from "../HospAdminUserListTable/UpdateUserMutation";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import MobileNumberField from "../common/InputField/MobileNumberField";

export type editHospitalUserInputValues = {
  username: string;
  name: string;
  passconfirm: string;
  email: string;
  sessionTimeout: number;
  sharedAccount: boolean;
  enabled: boolean;
  notificationEmails: { id: number; email: string; enabled: boolean }[];
  resultNotification: number;
  notified: boolean;
  mobile: string;
  notificationMobiles: { id: number; mobile: string; enabled: boolean }[];
};

interface EditHospitalUserPageProps {
  open: boolean;
  fullscreen: boolean;
  onClose: () => void;
  hospitalUser: any;
}

const EditHospitalUserDialog: React.FC<EditHospitalUserPageProps> = (props) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [state, setState] = React.useState({
    username: "",
    name: "",
    passconfirm: "",
    email: "",
    sessionTimeout: 180,
    sharedAccount: false,
    enabled: false,
    notificationEmails: "",
    resultNotification: 1,
    notified: false,
    mobile: "",
    notificationMobiles: "",
  });
  const [emailNotifications, setEmailNotifications] = useState<{ id: number; email: string; enabled: boolean }[]>([]);
  const [mobileNotifications, setMobileNotifications] = useState<{ id: number; mobile: string; enabled: boolean }[]>(
    [],
  );
  //const [resultNotification, setResultNotification] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.hospitalUser.resultNotification === 2) props.hospitalUser.notified = true;
    else props.hospitalUser.notified = false;

    const formState: any = {
      username: props.hospitalUser.username,
      name: props.hospitalUser.name,
      passconfirm: "          ",
      email: props.hospitalUser.email,
      sessionTimeout: 180,
      sharedAccount: props.hospitalUser.sharedaccount,
      enabled: props.hospitalUser.enabled,
      notificationEmails: props.hospitalUser.notificationEmails,
      resultNotification: props.hospitalUser.resultNotification,
      mobile: props.hospitalUser.mobileNumber,
      notified: props.hospitalUser.notified,
      notificationMobiles: props.hospitalUser.notificationMobiles,
    };

    if (props.hospitalUser.notificationEmails) {
      setEmailNotifications(props.hospitalUser.notificationEmails);
    } else {
      setEmailNotifications([]);
    }

    if (props.hospitalUser.notificationMobiles) {
      setMobileNotifications(props.hospitalUser.notificationMobiles);
    } else {
      setMobileNotifications([]);
    }

    setState(formState);
  }, [props.hospitalUser, state.resultNotification]);

  const handleClose = () => {
    if (props.hospitalUser.resultNotification === 2) props.hospitalUser.notified = true;
    else props.hospitalUser.notified = false;

    setFeedbackMessage("");
    setShowPassword(false);
    props.onClose();
    const closeState: any = {
      username: props.hospitalUser.username,
      name: props.hospitalUser.name,
      password: props.hospitalUser.password,
      email: props.hospitalUser.email,
      sessionTimeout: 180,
      sharedAccount: props.hospitalUser.sharedaccount,
      enabled: props.hospitalUser.enabled,
      notificationEmails: props.hospitalUser.notificationEmails,
      resultNotification: props.hospitalUser.resultNotification,
      notified: props.hospitalUser.notified,
      notificationMobiles: props.hospitalUser.notificationMobiles,
    };

    setState(closeState);
  };

  const handleChangeSharedAccount = () => {
    const newState: any = {
      username: state.username,
      name: state.name,
      passconfirm: state.passconfirm,
      email: state.email,
      sessionTimeout: state.sessionTimeout,
      sharedAccount: state.sharedAccount,
      enabled: state.enabled,
      notificationEmails: emailNotifications,
      resultNotification: state.resultNotification,
      notified: state.notified,
      notificationMobiles: mobileNotifications,
    };

    if (newState.sharedAccount) {
      newState.sharedAccount = false;
    } else {
      newState.sharedAccount = true;
    }

    setState(newState);
  };

  const handleChangeEnabled = () => {
    const newState: any = {
      username: state.username,
      name: state.name,
      passconfirm: state.passconfirm,
      email: state.email,
      sessionTimeout: state.sessionTimeout,
      sharedAccount: state.sharedAccount,
      enabled: state.enabled,
      notificationEmails: emailNotifications,
      resultNotification: state.resultNotification,
      notified: state.notified,
      notificationMobiles: mobileNotifications,
    };

    if (newState.enabled) {
      newState.enabled = false;
    } else {
      newState.enabled = true;
    }

    setState(newState);
  };

  const initialValues: editHospitalUserInputValues = {
    username: state.username,
    name: state.name,
    passconfirm: state.passconfirm,
    email: state.email,
    sessionTimeout: state.sessionTimeout,
    sharedAccount: state.sharedAccount,
    enabled: state.enabled,
    notificationEmails: emailNotifications,
    resultNotification: state.resultNotification,
    notified: state.notified,
    mobile: state.mobile,
    notificationMobiles: mobileNotifications,
  };

  const [editHospitalUser, { loading, error }] = useMutation<UpdateUser, UpdateUserVariables>(UpdateUserMutation);

  if (error) {
    console.error("Error", error);
  }

  const handleEmailNotificationText = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    emailnotify[i].email = e.target.value.trim();
    setEmailNotifications(emailnotify);
  };

  const handleDeleteEmailNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const emailnotify = [...emailNotifications.slice(0, i), ...emailNotifications.slice(i + 1)];
    setEmailNotifications(emailnotify);
  };

  const handleEmailNotificationChecked = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    const updatedEmailNotify = emailnotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setEmailNotifications(updatedEmailNotify);
  };

  const handleAddEmailNotification = (e: any) => {
    e.preventDefault();
    const emailnotify = emailNotifications.concat([{ id: 0, email: "", enabled: true }]);
    setEmailNotifications(emailnotify);
  };

  const handleMobileNotificationText = (i: any, value: any) => {
    const mobileNotify = [...mobileNotifications];
    const onlyNums = value ? value.replace(/[^0-9]/g, "") : "";

    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, mobile: onlyNums.trim() } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  const handleDeleteMobileNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const mobileNotify = [...mobileNotifications.slice(0, i), ...mobileNotifications.slice(i + 1)];
    setMobileNotifications(mobileNotify);
  };

  const handleMobileNotificationChecked = (i: any) => (e: any) => {
    const mobileNotify = [...mobileNotifications];
    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  const handleAddMobileNotification = (e: any) => {
    e.preventDefault();
    const mobileNotify = mobileNotifications.concat([{ id: 0, mobile: "", enabled: true }]);
    setMobileNotifications(mobileNotify);
  };

  const submit = () => {
    // eslint-disable-next-line no-console
    let rnotify = 1;
    if (state.notified === false) rnotify = 1;
    else rnotify = 2;

    const username = state.username;
    const name = state.name;
    const password = state.passconfirm.trim() !== "" ? state.passconfirm : state.passconfirm.trim();
    const email = state.email;
    const sessionTimeout = state.sessionTimeout;
    const sharedAccount = state.sharedAccount;
    const enabled = state.enabled;
    const notificationEmails = emailNotifications;
    const resultNotification = rnotify;
    const mobileNumber = state.mobile ? state.mobile.replace(/[^0-9]/g, "") : "";
    const notificationMobiles = mobileNotifications;

    onSubmit(
      username,
      name,
      password,
      email,
      sessionTimeout,
      sharedAccount,
      enabled,
      notificationEmails,
      resultNotification,
      mobileNumber,
      notificationMobiles,
      editHospitalUser,
    );
  };

  const onSubmit = async (
    username: string,
    name: string,
    password: string,
    email: string,
    sessionTimeout: number,
    sharedAccount: boolean,
    enabled: boolean,
    notificationEmails: { id: number; email: string; enabled: boolean }[],
    resultNotification: number,
    mobileNumber: string,
    notificationMobiles: { id: number; mobile: string; enabled: boolean }[],
    editHospitalUser: MutationTuple<UpdateUser, UpdateUserVariables>[0],
  ) => {
    try {
      const result = await editHospitalUser({
        variables: {
          input: {
            username,
            name,
            email,
            password,
            sessionTimeout,
            sharedAccount,
            enabled,
            notificationEmails,
            resultNotification,
            mobileNumber,
            notificationMobiles,
          },
        },
      });

      if (get(result, "data.updateUser")) {
        if (get(result, "data.updateUser.error")) {
          setFeedbackMessage(get(result, "data.updateUser.message", ""));
        } else {
          setFeedbackMessage("User updated!");
          setShowPassword(false);
          props.onClose();
          window.location.reload();
        }
      }
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      } else {
        if (e.message) {
          setFeedbackMessage(e.message);
        }
      }
    }
  };

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ errors, handleSubmit, touched }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <DialogBoxTitle title="Edit User" width={200} marginLeft={-3} closeButton handleClose={handleClose} />
            </DialogTitle>

            <DialogContent>
              <Box m={3}>
                <TextField
                  margin="dense"
                  id="username"
                  name="username"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue={state.username}
                  required={true}
                  disabled={true}
                />
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue={state.name}
                  required={true}
                  onChange={(event) => {
                    const { value } = event.target;
                    const changeState = state;
                    changeState.name = value;
                    setState(changeState);
                  }}
                />
                {!props.hospitalUser.cognito && (
                  <TextField
                    margin="dense"
                    id="passconfirm"
                    name="passconfirm"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    variant="outlined"
                    required={false}
                    defaultValue={state.passconfirm}
                    onChange={(event) => {
                      const { value } = event.target;
                      const changeState = state;
                      changeState.passconfirm = value;
                      setState(changeState);
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            if (state.passconfirm.trim() === "") {
                              setShowPassword(false);
                            } else {
                              setShowPassword(showPassword ? false : true);
                            }
                          }}
                          onMouseDown={(event: any) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                )}

                <TextField
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  defaultValue={state.email}
                  required={true}
                  onChange={(event) => {
                    const { value } = event.target;
                    const changeState = state;
                    changeState.email = value;
                    setState(changeState);
                  }}
                />
                <MobileNumberField
                  initState={initialValues.mobile}
                  helperText={touched.mobile && errors.mobile}
                  inputChange={(value: any) => {
                    state.mobile = value;
                  }}
                  error={!!errors.mobile && touched.mobile}
                />
                <Box className={styles.notifyEmailHeader}>
                  <Typography>Addition email address to receive result notification</Typography>
                </Box>
                <Box className={styles.notifyEmailBox}>
                  <Button
                    variant="contained"
                    size="small"
                    className={styles.button}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddEmailNotification}
                  >
                    Add Email
                  </Button>
                  <br />
                  <table className={styles.fullWidthTable}>
                    <tbody>
                      {emailNotifications.map((emailNotification, index) => (
                        <tr key={index}>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <TextField
                              key={"email-" + index}
                              label="Email"
                              placeholder="Input Email"
                              variant="outlined"
                              required={emailNotifications.length > 0 ? true : false}
                              className={styles.specialInput}
                              onChange={handleEmailNotificationText(index)}
                              size="small"
                              value={emailNotification.email}
                              type="email"
                            />
                          </td>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <IconButton
                              color="secondary"
                              aria-label="Delete Email"
                              component="span"
                              size="small"
                              onClick={handleDeleteEmailNotification(index)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </td>
                          <td>
                            <Checkbox
                              key={"enabled-" + index}
                              color="primary"
                              checked={emailNotification.enabled ? emailNotification.enabled : false}
                              onChange={handleEmailNotificationChecked(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <br />
                <Box className={styles.notifyEmailHeader}>
                  <Typography>Addition mobile number(s) to receive result notification</Typography>
                </Box>
                <Box className={styles.notifyEmailBox}>
                  <Button
                    variant="contained"
                    size="small"
                    className={styles.button}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddMobileNotification}
                  >
                    Add Mobile Number
                  </Button>
                  <br />
                  <table className={styles.fullWidthTable}>
                    <tbody>
                      {mobileNotifications.map((mobileNotification, index) => (
                        <tr key={index}>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <MobileNumberField
                              initState={mobileNotification.mobile}
                              key={"mobile-" + index}
                              id={"mobile-" + index}
                              error={touched.mobile && Boolean(errors.mobile)}
                              helperText={touched.mobile && errors.mobile}
                              inputChange={(value: any) => {
                                handleMobileNotificationText(index, value);
                              }}
                              required={mobileNotifications.length > 0 ? true : false}
                            />
                          </td>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <IconButton
                              color="secondary"
                              aria-label="Delete Mobile"
                              component="span"
                              size="small"
                              onClick={handleDeleteMobileNotification(index)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </td>
                          <td>
                            <Checkbox
                              key={"enabled-" + index}
                              color="primary"
                              checked={mobileNotification.enabled ? mobileNotification.enabled : false}
                              onChange={handleMobileNotificationChecked(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                  Shared Account{" "}
                  <Checkbox
                    name="sharedaccount"
                    color="primary"
                    checked={state.sharedAccount}
                    onChange={handleChangeSharedAccount}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between" align-content="strech">
                  Enabled{" "}
                  <Checkbox name="enabled" color="primary" checked={state.enabled} onChange={handleChangeEnabled} />
                </Box>
              </Box>
              <Box m={3}>
                <Typography className={styles.MenuItem2}>{feedbackMessage}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box ml={3} mb={3} display="flex" justifyContent="space-between" align-content="strech">
                <Button
                  className={styles.spaceRight}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditHospitalUserDialog;
