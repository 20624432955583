export const validateProvider = (providerCode: string): boolean => {
  if (!/^[0-9]{6}[0-9ABCDEFGHJKLMNPQRTUVWXY][ABFHJKLTWXY]/.test(providerCode)) return false;

  const practiceLocationValues = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
  ];
  const remainderValues = ["Y", "X", "W", "T", "L", "K", "J", "H", "F", "B", "A"];
  const stemWeights = [3, 5, 8, 4, 2, 1];

  const stemNumbers = providerCode
    .substr(0, 6)
    .split("")
    .map((char) => {
      return parseInt(char);
    });
  const practiceLoc = practiceLocationValues.findIndex((e) => {
    return e == providerCode[6];
  });
  const checksum = providerCode[7];

  const zipped = stemWeights.map((x, i) => [x, stemNumbers[i]]);
  const sumWeights = zipped
    .map((y) => {
      return y[1] * y[0];
    })
    .reduce((total, num) => {
      return total + num;
    });
  const remainder = (sumWeights + practiceLoc * 6) % 11;

  const result = remainderValues[remainder];

  return result === checksum;
};
