import get from "lodash/get";

export const getStatusName = (statusCode: string): string => {
  const statusMap = {
    I: "Pending",
    X: "Cancelled",
    C: "Final Corrected",
    F: "Final",
    P: "Preliminary   (Final to Follow)",
  };
  let status = statusCode;
  status = get(statusMap, statusCode);
  return status;
};
