import { gql } from "@apollo/client";
export default gql`
  query GetCumulativeAtomicValues($providers: [String!], $patientId: Int!, $panelId: Int, $allPatients: Boolean) {
    atomicNames(providers: $providers, patientId: $patientId, panelId: $panelId, allPatients: $allPatients) {
      name
      loinc
      order
    }
    atomic(providers: $providers, patientId: $patientId, panelId: $panelId, allPatients: $allPatients) {
      reportId
      reportTestName
      reportLabNumber
      reportLabNumberDigitOnly
      reportDateCollected
      reportDateCreated
      reportPatientId
      hasComment
      atomicData {
        name
        value
        value_type
        measurement
        range
        abnormal
        order
        loinc
      }
    }
  }
`;
