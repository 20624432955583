import { gql } from "@apollo/client";

export default gql`
  query getCpdProgram {
    getCpdProgram {
      id
      name
      url
      hours
      enabled
      short_name
    }
  }
`;
