import React, { Fragment, useContext, useState, useEffect } from "react";
import authService from "../../services/authService";
import { withRouter, RouteComponentProps } from "react-router";
import AppContext from "../../context/AppContext";
import MainNavigation from "../MainNavigation/MainNavigation";
import { Box, Grid, Typography, Button, CircularProgress, Snackbar, Link, TextField } from "@mui/material";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import styles from "./MFAEnrolment.module.css";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { QRCodeSVG } from "qrcode.react";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";
import { OperationVariables, useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UpdateUserMFA, UpdateUserMFAVariables } from "./types/UpdateUserMFA";
import { VerifyMfaToken, VerifyMfaTokenVariables } from "./types/VerifyMfaToken";
import { SkipUserMfa, SkipUserMfaVariables } from "./types/SkipUserMfa";
import UpdateUserMFAMutation from "./UpdateUserMFAMutation";
import VerifyMFATokenMutation from "./VerifyMFATokenMutation";
import SkipUserMfaMutation from "./SkipUserMfa";
import Loading from "../Loading/Loading";
import OtpInput from "react18-input-otp";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { GetMfaProviders } from "./types/GetMfaProviders";
import getMfaProviders from "./GetMFAProvidersQuery";
import { omit } from "lodash";
import * as MFATypes from "../../model/MFATypes";
import MobileNumberField from "../common/InputField/MobileNumberField";
import { storeMobileDeviceToken } from "../LoginPage/storeMobileDeviceToken";
import {
  UpdateUserDeviceSettings,
  UpdateUserDeviceSettingsVariables,
} from "../LoginPage/types/UpdateUserDeviceSettings";
import UpdateUserDeviceSettingsMutation from "../LoginPage/UpdateUserDeviceSettingsMutation";
import { isTenantVet } from "../../services/tenantService";
import { getCpdProviders } from "../LoginPage/types/getCpdProviders";
import GetCpdProvidersQuery from "../LoginPage/GetCpdProvidersQuery";

const MFAEnrolment: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const isDesktop = useContext(AppContext).isDesktop;
  const [typeId, setTypeId] = useState<number>(0);
  const [code, setCode] = useState("");
  const [qr, setQR] = useState("");
  const [digit, setDigit] = useState(6);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isShowSkipBtn, setIsShowSkipBtn] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [linkedTypes, setLinkedTypes] = useState<number[]>([]);
  const mfaToken = jwt.decode(authService.fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  const [open, setOpen] = useState(false);
  const [newAccessToken, setNewAccessToken] = useState("");
  const setError = useContext(AppContext).setError;
  const { data: mfaData } = useQuery<GetMfaProviders>(getMfaProviders);
  const [mfaProviders, setMfaProviders] = useState(mfaToken.hospitalMfaSetup.mfaProviders);
  const client = useApolloClient();
  const [emailAddress, setEmailAddress] = useState(authService.getPrimaryEmail());
  const [mobileNumber, setMobileNumber] = useState(authService.getMobileNumber());
  const [validEmail, setValidEmail] = useState(
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(authService.getPrimaryEmail()),
  );
  const [validMobileNumber, setValidMobileNumber] = useState(
    /^([0]{1})([4]{1})([0-9]{2}) ([0-9]{3}) ([0-9]{3})/g.test(
      authService.getMobileNumber().replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3"),
    ),
  );

  const authTypes = [
    {
      typeid: 2,
      title: "Use an Authenticator app",
      buttonText: "Link",
      linkedText: "Linked",
      icon: <QrCode2Icon />,
    },
    {
      typeid: 3,
      title: "Email me a security code",
      buttonText: "Link",
      linkedText: "Linked",
      icon: <EmailIcon />,
    },
    {
      typeid: 4,
      title: "SMS me a security code",
      buttonText: "Link",
      linkedText: "Linked",
      icon: <PhoneIphoneIcon />,
    },
  ];

  const [updateUser, { loading: loadingUpdateUser, error: errorUpdateUser }] = useMutation<
    UpdateUserMFA,
    UpdateUserMFAVariables
  >(UpdateUserMFAMutation);

  const [VerifyMFAToken, { loading: loadingVerifyMFAToken, error: errorVerifyMfaToken }] = useMutation<
    VerifyMfaToken,
    VerifyMfaTokenVariables
  >(VerifyMFATokenMutation);

  const [updateUsersDeviceConfig] = useMutation<UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables>(
    UpdateUserDeviceSettingsMutation,
  );

  const [cpdProviders] = useLazyQuery<getCpdProviders, OperationVariables>(GetCpdProvidersQuery);

  const [SkipUserMfa] = useMutation<SkipUserMfa, SkipUserMfaVariables>(SkipUserMfaMutation);

  const ALLOWED_ERRORS = [
    "This email address is already registered for another account. Please use a different email address.",
  ];

  if (loadingUpdateUser || loadingVerifyMFAToken) {
    setError("");
  }

  if ((errorUpdateUser && !ALLOWED_ERRORS.includes(errorUpdateUser?.message)) || errorVerifyMfaToken) {
    setIsError(true);
  }

  const handleTypeChange = async (mfaId: number) => {
    setErrorMessage("");
    setIsError(false);
    setIsSuccess(false);
    if (mfaId > 0) {
      const status = 1;
      await updateUser({
        variables: {
          input: {
            mfaId,
            status,
            emailValue: emailAddress,
            mobileValue: mobileNumber,
          },
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          setErrorMessage(e.message || e);
          setIsError(true);
        })
        .then(async (data) => {
          if (data?.updateUserMfa.totpUrl === "Message not queued") {
            setErrorMessage(`Our services are currently undergoing maintenance. Please try again later`);
            setIsError(true);
          } else {
            const provider = mfaProviders.find((el) => el.id === mfaId);
            if (data?.updateUserMfa.totpUrl) {
              //Authenticator
              setTypeId(mfaId);
              setDigit(provider?.digits || 6);
              setQR(data?.updateUserMfa.totpUrl);
            } else if (mfaId === 3) {
              //Email Here
              setTypeId(mfaId);
              setIsSuccess(data?.updateUserMfa.sendEmail as boolean);
            } else if (mfaId === 4) {
              //SMS Here
              setTypeId(mfaId);
              setIsSuccess(data?.updateUserMfa.sendSMS as boolean);
            }
          }
        });
    } else {
      setTypeId(mfaId);
    }
  };

  const handleCodeText = (otp: any, digit: number) => {
    setIsError(false);
    setCode(otp);
    if (otp.length === digit) {
      handleAuthentication(otp);
    }
  };

  const handleSaveAndContinue = async () => {
    if (linkedTypes.length > 0) {
      for (const mfa of authTypes) {
        const mfaId = mfa.typeid;
        const status = 3;
        if (!linkedTypes.includes(mfaId)) {
          await updateUser({
            variables: {
              input: {
                mfaId,
                status,
              },
            },
          });
        }
      }
    }
    let from = "/login";
    if (linkedTypes.length > 0) {
      authService.clearToken();
      authService.persistToken(newAccessToken);
      from = "/patients";

      await storeMobileDeviceToken(updateUsersDeviceConfig, cpdProviders);
    } else {
      authService.clearAll();
      client.resetStore();
    }

    history.push(from);
  };

  const handleValueUpdate = async (mfaId: number) => {
    const status = 1;
    const emailValue = emailAddress;
    const mobileValue = mobileNumber;
    authService.setPrimaryEmail(emailValue);
    authService.setMobileNumber(mobileValue);
    setErrorMessage("");
    setIsError(false);

    if (!linkedTypes.includes(mfaId)) {
      await updateUser({
        variables: {
          input: {
            mfaId,
            status,
            emailValue,
            mobileValue,
          },
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          if (ALLOWED_ERRORS.includes(e.message)) {
            setErrorMessage(e.message);
            setIsError(true);
          } else {
            setIsError(true);
          }
        })
        .then(async (data) => {
          if (data?.updateUserMfa.sendEmail || data?.updateUserMfa.sendSMS) {
            setSuccessMessage("New code sent");
            setIsSuccess(true);
          }
        });
    }
  };

  const handleSkipForNow = async () => {
    await SkipUserMfa({
      variables: {
        reset: false,
      },
    });
    await VerifyMFAToken({
      variables: {
        input: {
          mfaId: 1,
          token: "test",
        },
      },
    })
      .then((response) => {
        return response && response.data && response.data.verifyMfaToken;
      })
      .then(async (data) => {
        if (data && data.accessToken && data.accessToken.length > 0) {
          const t = jwt.decode(data.accessToken) as MFATypes.MFAToken;
          if (t && t.otpVerified) {
            setNewAccessToken(data.accessToken);
            setCode("");
            setIsSuccess(true);
            setLinkedTypes((oldLinkedTypes) => [...oldLinkedTypes, typeId]);
            setTypeId(0);
            authService.clearToken();
            authService.persistToken(data.accessToken);

            await storeMobileDeviceToken(updateUsersDeviceConfig, cpdProviders);
          }
        }
        history.push("/patients");
      });
  };

  const handleAuthentication = async (otp: string) => {
    try {
      await VerifyMFAToken({
        variables: {
          input: {
            mfaId: typeId,
            token: otp,
          },
        },
      })
        .then((response) => {
          return response && response.data && response.data.verifyMfaToken;
        })
        .then((data) => {
          if (data && data.accessToken && data.accessToken.length > 0) {
            const t = jwt.decode(data.accessToken) as MFATypes.MFAToken;

            if (t && t.otpVerified) {
              setNewAccessToken(data.accessToken);
              setCode("");
              setIsSuccess(true);
              setLinkedTypes((oldLinkedTypes) => [...oldLinkedTypes, typeId]);
              setSuccessMessage("Successfully verified and saved.");
              setTypeId(0);
            } else {
              setCode("");
              setIsError(true);
              setErrorMessage("Your security code has expired or is incorrect. Please try again.");
            }
          } else {
            setCode("");
            setIsError(true);
            setErrorMessage("Your security code has expired or is incorrect. Please try again.");
          }
        });
    } catch (err) {
      // Possible errors
      // - options validation
      // - "Invalid input - it is not base32 encoded string" (if thiry-two is used)
      console.error(err);
      setIsError(true);
    }
  };

  const handleClick = (value: string) => {
    setOpen(true);

    if (authService.isMobility()) {
      const event = new CustomEvent("CopyCode", {
        detail: { code: value },
      });

      window.dispatchEvent(event);
    } else {
      navigator.clipboard.writeText(value);
    }
  };

  const checkSkipBtnAccess = () => {
    const ForceMFA = authService.getHospitalForceEnrol();
    const IPLock = authService.getIpRestricted();
    const Hospital = authService.getHospID() !== "";

    if (isTenantVet()) {
      //All conditions for Vets are here
      if (Hospital && ForceMFA) {
        return false;
      }
      return true;
    }

    if (Hospital && ForceMFA && IPLock) {
      return true;
    }

    if (Hospital && ForceMFA && !IPLock) {
      return false;
    }

    if (Hospital && !ForceMFA && IPLock) {
      return true;
    }

    if (!Hospital && IPLock) {
      return true;
    }
    if (!Hospital && !IPLock) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (mfaProviders.length === 0 && mfaData && mfaToken.hospitalMfaSetup.mfaProviders.length == 0) {
      setMfaProviders(
        mfaData.getMfaProviders.map((p) => {
          return { ...omit(p, ["__typename"]), otptype: "", enabled: true };
        }),
      );
    }

    //checking if display skip button
    if (checkSkipBtnAccess()) {
      setIsShowSkipBtn(true);
    }
  }, [mfaData, mfaProviders, setMfaProviders]);

  const emailMask = (email: string) => {
    const maskedEmail = email.replace(/([^@.])/g, "*").split("");

    let previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous === "." || previous === "@") {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join("");
  };

  const mobileNumberMask = (mobile: string) => {
    return "04** *** " + mobile.substring(mobile.length - 3);
  };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
        <Grid item xs={12}>
          <AuthLayout title="" maxWidth={"690px"}>
            <Box mt={4}>
              <Box mt={4}>
                <Typography variant="subtitle1" className={styles.mfaHeading}>
                  {isShowSkipBtn
                    ? "To improve the security of your patient results, please enrol in multi-factor authentication by linking one or more of the options below. Over the course of this year, Clinical Labs will make multi-factor authentication mandatory for all eResults users."
                    : "Enrol in multi-factor authentication by linking one or more of the options below."}
                </Typography>
              </Box>
              {typeId === 0 ? (
                <>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    {!loadingUpdateUser &&
                      !loadingVerifyMFAToken &&
                      mfaProviders &&
                      typeId === 0 &&
                      mfaProviders.map((provider) => {
                        const type = authTypes.find((el) => el.typeid === provider.id);
                        return (
                          <>
                            <Grid
                              item
                              xs={9}
                              md={6}
                              alignContent="center"
                              alignItems="baseline"
                              className={styles.highlight}
                            >
                              {type && (
                                <Grid container direction="row" alignItems="center">
                                  <Grid item>{type.icon}</Grid>
                                  <Grid item>
                                    <Typography className={styles.verticalSet}>{type.title}</Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={3} md={2} className={styles.alignRight}>
                              {type && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  className={styles.button}
                                  onClick={() => handleTypeChange(type.typeid)}
                                  disabled={
                                    linkedTypes.includes(type.typeid)
                                    // || (type.typeid === 3 && authService.getPrimaryEmail() === "") ||
                                    // (type.typeid === 4 && authService.getMobileNumber() === "")
                                  }
                                >
                                  {linkedTypes.includes(type.typeid) ? type.linkedText : type.buttonText}
                                </Button>
                              )}
                            </Grid>
                            {/* {type?.typeid === 3 && authService.getPrimaryEmail() === "" && (
                              <Grid item xs={12} md={8} className={styles.emailNotice}>
                                <Typography>We do not have an email address associated with your account.</Typography>
                                <Typography>
                                  To enable security verification via email, please do the following:
                                </Typography>
                                {authService.getForceEnrol() ? (
                                  <>
                                    <ol>
                                      <li>
                                        Ask your Group Admin to edit your details and enter an email address for your
                                        account.
                                      </li>
                                      <li>
                                        Log in to eResults again, and you will be able to use security verification via
                                        email.
                                      </li>
                                    </ol>
                                  </>
                                ) : (
                                  <>
                                    <ol>
                                      <li>Select ‘Skip For Now’ and login as normal.</li>
                                      <li>
                                        Go to your Account Settings page, enter your email and select ‘Enrol in MFA at
                                        next logon’.
                                      </li>
                                      <li>
                                        When you login to eResults again, you will be able to use security verification
                                        via email.
                                      </li>
                                    </ol>
                                  </>
                                )}
                              </Grid>
                            )}

                            {type?.typeid === 4 && authService.getMobileNumber() === "" && (
                              <Grid item xs={12} md={8} className={styles.emailNotice}>
                                <Typography>We do not have a mobile number associated with your account.</Typography>
                                <Typography>
                                  To enable security verification via mobile number, please do the following:
                                </Typography>
                                {authService.getForceEnrol() ? (
                                  <>
                                    <ol>
                                      <li>
                                        Ask your Group Admin to edit your details and enter an mobile number for your
                                        account.
                                      </li>
                                      <li>
                                        Log in to eResults again, and you will be able to use security verification via
                                        mobile number.
                                      </li>
                                    </ol>
                                  </>
                                ) : (
                                  <>
                                    <ol>
                                      <li>Select ‘Skip For Now’ and login as normal.</li>
                                      <li>
                                        Go to your Account Settings page, enter your mobile number and select ‘Enrol in
                                        MFA at next logon’.
                                      </li>
                                      <li>
                                        When you login to eResults again, you will be able to use security verification
                                        via mobile number.
                                      </li>
                                    </ol>
                                  </>
                                )}
                              </Grid>
                            )} */}
                          </>
                        );
                      })}
                    {(loadingUpdateUser || loadingVerifyMFAToken) && (
                      <Grid item xs={12} md={12}>
                        <Loading></Loading>
                      </Grid>
                    )}
                    {isSuccess && (
                      <Grid item xs={12} md={12}>
                        <Typography className={styles.successMessage}>{successMessage}</Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* New non-hopital user enrollments will be forced to use MFA except if the user is ip restricted */}
                    {isShowSkipBtn ? (
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={handleSkipForNow}
                      >
                        Skip for now
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      className={styles.button}
                      onClick={handleSaveAndContinue}
                    >
                      {linkedTypes.length > 0 ? "Save and Continue" : "Back to Login"}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    {typeId === 2 && (
                      <>
                        <Grid item xs={12} md={12}>
                          <Typography>
                            Please open your authenticator app and scan the qr code below and then enter the 6 digit
                            security code provided by the app.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} alignItems="center" alignContent="center">
                          <Typography align="center">
                            {
                              <Link href={qr}>
                                <QRCodeSVG value={qr} />
                              </Link>
                            }
                          </Typography>
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={11} className={styles.qr}>
                              {qr.split("=").slice(-1)}
                            </Grid>
                            <Grid item xs={1}>
                              <Button
                                size="small"
                                className={styles.copyButton}
                                onClick={() => {
                                  handleClick(qr.split("=").slice(-1)[0]);
                                }}
                              >
                                <CopyIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {typeId === 3 &&
                      !loadingUpdateUser &&
                      !loadingVerifyMFAToken &&
                      !isError &&
                      authService.getPrimaryEmail() !== "" && (
                        <Grid item xs={12} md={12} alignContent="center" alignItems="center" mb={3}>
                          <Box mt={3}>
                            <Typography>
                              An email with the security code has been sent to{" "}
                              {emailMask(authService.getPrimaryEmail())}. Please wait for the email to arrive.
                            </Typography>
                            <Typography>
                              {" "}
                              Note: Check your junk folder in case the email was marked as spam.{" "}
                            </Typography>{" "}
                          </Box>
                        </Grid>
                      )}
                    {typeId === 4 &&
                      !loadingUpdateUser &&
                      !loadingVerifyMFAToken &&
                      !isError &&
                      authService.getMobileNumber() !== "" && (
                        <Grid item xs={12} md={12} alignContent="center" alignItems="center" mb={3}>
                          <Box mt={3}>
                            <Typography>
                              An SMS with the security code has been sent to{" "}
                              {mobileNumberMask(authService.getMobileNumber())}. Please wait for the SMS to arrive.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    {(typeId === 2 || ((typeId === 3 || typeId === 4) && isSuccess)) && (
                      <Grid item xs={12} md={6} alignContent="center" alignItems="center">
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={styles.highlight + " " + styles.bottomSpace}
                        >
                          <Grid item>{authTypes.find((el) => el.typeid === typeId)?.icon}</Grid>
                          <Grid item>
                            <Typography className={styles.verticalSet}>
                              {authTypes.find((el) => el.typeid === typeId)?.title}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center">
                          <OtpInput
                            key={"code-" + typeId}
                            value={code}
                            onChange={(otp: any) => handleCodeText(otp, digit)}
                            numInputs={digit}
                            separator={<span>-</span>}
                            inputStyle={styles.inputStyle}
                            isInputNum
                            shouldAutoFocus={true}
                          />
                        </Box>
                      </Grid>
                    )}
                    {typeId === 3 && (
                      <>
                        <Grid item xs={12} md={12} alignContent="center" alignItems="center">
                          {!loadingUpdateUser && !loadingVerifyMFAToken && !isError && (
                            <Box mt={3}>
                              <Typography>
                                If you wish to authenticate via a different email, update your email below and hit the
                                button to re-send the new security code.
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} alignContent="center" alignItems="center" mt={3}>
                          <TextField
                            id="email"
                            name="email"
                            fullWidth
                            label="Email"
                            type="email"
                            variant="outlined"
                            onChange={(e) => {
                              if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                                setEmailAddress(e.target.value);
                                setValidEmail(true);
                              } else {
                                setValidEmail(false);
                              }
                            }}
                            defaultValue={emailAddress}
                            error={!validEmail}
                          ></TextField>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              if (validEmail) {
                                handleValueUpdate(typeId);
                              }
                            }}
                            disabled={loadingUpdateUser || loadingVerifyMFAToken}
                            fullWidth
                            style={{ marginTop: "5px" }}
                          >
                            {loadingUpdateUser ? (
                              <Box width={"100%"} textAlign="center">
                                <CircularProgress size={13} />
                              </Box>
                            ) : (
                              <span>Save Email and Re-send Security Code</span>
                            )}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {typeId === 4 && (
                      <>
                        <Grid item xs={12} md={12} alignContent="center" alignItems="center">
                          {!loadingUpdateUser && !loadingVerifyMFAToken && !isError && (
                            <Box mt={3}>
                              <Typography>
                                If you wish to authenticate via a different mobile number, update your mobile number
                                below and hit the button to re-send the new security code.
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} alignContent="center" alignItems="center" mt={3}>
                          <MobileNumberField
                            initState={mobileNumber}
                            inputChange={(value: any) => {
                              setMobileNumber(value.replace(/\s/g, ""));

                              if (/^([0]{1})([4]{1})([0-9]{2}) ([0-9]{3}) ([0-9]{3})/g.test(value)) {
                                setValidMobileNumber(true);
                              } else {
                                setValidMobileNumber(false);
                              }
                            }}
                            error={!validMobileNumber}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              if (validMobileNumber) {
                                handleValueUpdate(typeId);
                              }
                            }}
                            disabled={loadingUpdateUser || loadingVerifyMFAToken}
                            fullWidth
                            style={{ marginTop: "5px" }}
                          >
                            {loadingUpdateUser ? (
                              <Box width={"100%"} textAlign="center">
                                <CircularProgress size={13} />
                              </Box>
                            ) : (
                              <span>Save Mobile Number and Re-send Security Code</span>
                            )}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {isError && (
                      <Grid item xs={12} md={12}>
                        {isError && <Typography className={styles.errorMessage}>{errorMessage}</Typography>}
                      </Grid>
                    )}
                  </Grid>
                  <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      className={styles.button}
                      onClick={() => {
                        handleTypeChange(0);
                        setCode("");
                      }}
                      disabled={loadingVerifyMFAToken || loadingUpdateUser}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      className={styles.button}
                      onClick={() => handleAuthentication(code)}
                      disabled={loadingVerifyMFAToken || loadingUpdateUser}
                    >
                      {loadingVerifyMFAToken ? (
                        <Box width={"100%"} textAlign="center">
                          <CircularProgress size={13} />
                        </Box>
                      ) : (
                        <span>Verify and Save</span>
                      )}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </AuthLayout>
        </Grid>
      </Grid>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Copied to clipboard" />
      {isError && <ErrorPopover errorMessage={errorMessage} />}
    </Fragment>
  );
};

export default withRouter(MFAEnrolment);
