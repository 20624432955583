// import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  GetPrintableReports,
  GetPrintableReportsVariables,
  GetPrintableReports_episodereports_reports as Report,
  GetPrintableReports_patient as Patient,
} from "../PrintableReports/types/GetPrintableReports";
import getPrintableReports from "../PrintableReports/GetPrintableReportQuery";
import authService from "../../services/authService";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import get from "lodash/get";
import styles from "../PrintableReports/PrintableReports.module.css";
import { Document, Page } from "react-pdf";
import { Box, Typography } from "@mui/material";
import aclLogo from "../PrintableReports/acl-logo.jpg";
import vetLogo from "../PrintableReports/gribbles-logo.jpg";
import sspLogo from "../PrintableReports/ssp-logo.jpg";
import { formatDate } from "../../utils/date";
import { getStatusName } from "../../utils/getStatusName";
import Loading from "../Loading/Loading";
import { mergePdfs } from "../PrintableReports/pdfUtils";
import setViewed from "../../utils/setViewed";
import { MarkAsViewed } from "../common/MarkAsViewedMutation/types/MarkAsViewed";
import MarkAsViewedMutation from "../common/MarkAsViewedMutation/MarkAsViewedMutation";
import { updateEpisodes, updateSearchEpisodesCache } from "../../utils/updateApolloCache";
import { useLocation } from "react-router-dom";
import { INTERUM_STATUS } from "../../constants";
import { PatientContext } from "../../context/PatientsContext";
import { isTenantVet } from "../../services/tenantService";
import theme from "../theme";

type PathParams = {
  patientid: string;
  labNumber: string;
  allpatient: string;
};

const queryVariables = (params: PathParams, reportIds: number[]): GetPrintableReportsVariables => ({
  patientId: parseInt(params.patientid, 10),
  providers: authService.getProviders(),
  labnoDigitOnly: params.labNumber,
  includePdf: true,
  allPatients: params.allpatient === "1" ? true : false,
  ids: reportIds,
});

type PdfFileObject = {
  url?: string;
  data?: Uint8Array;
};

interface PreviewProps {
  // open: boolean;
  // fullscreen: boolean;
  previewData: PathParams;
  ids: number[];
  variables: any;
  panel: any;
  // setOpen: (op: boolean) => void;
}

const hasValidData = (data: GetPrintableReports | undefined) =>
  data && data.episodereports && data.episodereports.reports && data.episodereports.reports.length > 0;

const PreviewDialog: React.FC<PreviewProps> = (props) => {
  const [reportsHasPdfs, setReportHasPdfs] = useState(false);
  const location = useLocation();
  const { searchVariables } = useContext(PatientContext);
  // const [pdfLoaded, setPdfLoaded] = useState<boolean>(false);
  const [pdfFileObject, setPdfFileObject] = useState<PdfFileObject>({
    url: "some stuff",
  });
  const [myNumPages, setMyNumPages] = useState(0);
  const [reports, setReports] = useState<Report[]>([]);

  const { data, loading } = useQuery<GetPrintableReports>(getPrintableReports, {
    variables: queryVariables(props.previewData, props.ids ? props.ids.map((a) => Number(a)) : []),
  });

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const [markAsViewed] = useMutation<MarkAsViewed>(MarkAsViewedMutation, {
    ignoreResults: true,
  });

  const client = useApolloClient();

  const value = JSON.stringify({
    reportIds: props.ids,
    useragent: navigator.userAgent,
  });

  useEffect(
    () => {
      if (!loading && data) {
        const onMarkAsViewed = (reportId: string) => {
          if (location.pathname === "/patient-episodes") {
            updateSearchEpisodesCache(client, reportId, searchVariables);
          } else {
            updateEpisodes(client, reportId, props.variables);
          }
        };

        const reports = get(data, "episodereports.reports");

        reports.forEach((report: any) => {
          if (report.pdf) {
            setReportHasPdfs(true);
          }
        });

        const filterReports = reports.filter((report: Report) => props.ids.includes(report.reportid || 0));

        setReports(filterReports);

        setViewed(
          props.previewData.allpatient === "1" ? true : false,
          markAsViewed,
          auditLog,
          onMarkAsViewed,
          props.panel,
        );
      }
    },
    // eslint-disable-next-line
    [client, data, loading, auditLog, value, markAsViewed],
  );

  const patient: Patient = get(data, "patient") || ({} as Patient);

  const getPatientAddress = (): string[] | undefined => {
    const address: string | undefined = get(patient, "fullAddress");
    if (!address) return;

    if (!address.includes(",")) return [address];
    return address.split(",");
  };

  // TEXT and PDF reports
  const patientAddress: string[] | undefined = getPatientAddress();

  // PDF
  useEffect(() => {
    if (!reportsHasPdfs) return;

    //run()
    const getPdfUrl = async () => {
      // Load binary pdfs
      const reportPdfs: string[] = reports.filter((r) => !!r.pdf).map((report) => report.pdf || "");

      const finalPdf: Uint8Array = await mergePdfs(reportPdfs);
      setPdfFileObject({ data: finalPdf });
    };

    getPdfUrl();
  }, [reports, reportsHasPdfs]);

  return (
    <Fragment>
      <Box>
        {loading && <Loading />}
        {!loading && !hasValidData(data) && (
          <Box justifyContent="center" alignContent="center" py={[1]}>
            <Typography>No Report Found!</Typography>
          </Box>
        )}
        {!loading && hasValidData(data) && (
          <Box
            bgcolor={theme.colorSchemes.light.palette.primary.main}
            justifyContent="center"
            align-content="center"
            textAlign="center"
            paddingLeft="10px"
            color="#FFFFFF"
            position="fixed"
            zIndex="100"
            top="0px"
            left="1px"
            width="98.5%"
            borderRadius="2px"
            py={[1]}
          >
            <Typography style={{ fontSize: "1em" }}>
              <strong>Report Update:</strong>{" "}
              {props.panel.dateCreated ? formatDate(props.panel.dateCreated, "DD MMM YYYY HH:mm:ss", false) : ""}
              &nbsp;&nbsp;&nbsp;&nbsp; Status:{" "}
              {data?.episodereports.reports[0].interimStatus
                ? getStatusName(data?.episodereports.reports[0].interimStatus)
                : "Unknown"}
            </Typography>
            {data?.episodereports.reports[0].interimStatus !== INTERUM_STATUS.Pending &&
              data?.episodereports.reports[0].interimStatus !== INTERUM_STATUS.Cancelled && (
                <Typography style={{ fontSize: "0.9em" }}>
                  First Viewed:{" "}
                  {props.panel.firstViewed ? formatDate(props.panel.firstViewed, "DD MMM YYYY HH:mm:ss", false) : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp; Last Viewed:{" "}
                  {props.panel.firstViewed ? formatDate(props.panel.lastViewed, "DD MMM YYYY HH:mm:ss", false) : ""}
                </Typography>
              )}
          </Box>
        )}
        <Box marginTop="50px">
          {reportsHasPdfs && (
            <Document
              // http://jira.pl.pathlabs.com.au/browse/ER8-770
              // https://github.com/wojtekmaj/react-pdf/issues/1539#issuecomment-1652721077
              file={pdfFileObject.data ? { data: pdfFileObject.data.slice(0) } : { url: pdfFileObject.url || "" }}
              onLoadSuccess={({ numPages }) => {
                setMyNumPages(numPages);
              }}
            >
              {Array.from(new Array(myNumPages), (el, index) => (
                <div className={styles.reportpdf} key={`page_${index + 1}`}>
                  <Page
                    pageNumber={index + 1}
                    onRenderSuccess={() => {
                      //Comment to know what is going on here.
                      // setPdfLoaded(index + 1 === myNumPages);
                    }}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    renderForms={false}
                    width={800}
                  />
                </div>
              ))}
            </Document>
          )}
          {reports
            .filter((r) => !r.pdf)
            .map((report: Report, cur: number) => (
              <div key={`${report.reportid}`}>
                {cur > 0 && <div className={styles.report}>&nbsp;</div>}
                <div>&nbsp;</div>
                <div className={styles.subhead2} style={{ borderBottom: "2px solid black" }}>
                  <img src={isTenantVet() ? vetLogo : report.labid === 6 ? sspLogo : aclLogo} alt="" height="60px" />
                </div>

                <div style={{ height: "45px", lineHeight: "3em" }}>
                  <span className={styles.bodyhead} style={{ paddingRight: "10px" }}>
                    {report.labid == 6 ? "Southern Sun Pathology" : "Australian Clinical Labs"}
                  </span>
                  <span className={styles.bodytext}>
                    {get(report, "lab.address")}, {get(report, "lab.suburb")}
                  </span>
                </div>

                {/* PATIENT DETAILS LEFT */}
                <div style={{ display: "table-row" }}>
                  <div style={{ display: "table-cell", width: "50%" }}>
                    {isTenantVet() ? (
                      <>
                        <div style={{ display: "table-row" }}>
                          <div style={{ display: "table-cell", paddingRight: "5px" }}>
                            <b>Animal/Herd:</b>
                          </div>
                          <div style={{ display: "table-cell" }}>
                            {get(patient, "title")} {get(patient, "firstname")}
                          </div>
                        </div>
                        <div style={{ display: "table-row" }}>
                          <div style={{ display: "table-cell", paddingRight: "5px" }}>
                            <b>Owner:</b>
                          </div>
                          <div style={{ display: "table-cell" }}>
                            {get(patient, "title")} {get(patient, "surname")}
                            <br />
                            {patientAddress && (
                              <Fragment>
                                {patientAddress[0].toUpperCase()}
                                <br />
                                {patientAddress[1] && patientAddress[1].toUpperCase()}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div style={{ display: "table-row" }}>
                          <div style={{ display: "table-cell", paddingRight: "5px" }}>
                            <b>Species:</b>
                          </div>
                          <div style={{ display: "table-cell" }}>{get(patient, "species")}</div>
                        </div>
                      </>
                    ) : (
                      <div style={{ display: "table-row" }}>
                        <div style={{ display: "table-cell", paddingRight: "5px" }}>
                          <b>Patient:</b>
                        </div>
                        <div style={{ display: "table-cell" }}>
                          {get(patient, "title")} {get(patient, "fullname")}
                          <br />
                          {patientAddress && (
                            <Fragment>
                              {patientAddress[0].toUpperCase()}
                              <br />
                              {patientAddress[1] && patientAddress[1].toUpperCase()}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    )}

                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>DOB:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {formatDate(get(patient, "dob"), "DD MMM YYYY", true)}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Sex:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>{get(patient, "sex")}</div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Reported:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {formatDate(get(report, "dateCreated"), "DD MMM YYYY HH:mm:ss", false)}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Reference:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>{get(report, "reference")}</div>
                    </div>
                  </div>

                  {/* PATIENT DETAILS RIGHT */}
                  <div style={{ display: "table-cell", width: "50%" }}>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Referred:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {formatDate(get(report, "dateReferred"), "DD MMM YYYY", true)}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Collected:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {formatDate(get(report, "dateCollected"), "DD MMM YYYY HH:mm:ss", true)}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Tested:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {formatDate(get(report, "dateTested"), "DD MMM YYYY HH:mm:ss", true)}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>UR/MR No.:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>{get(report, "urno")}</div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Lab No:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>{get(report, "labnumber")}</div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Status:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>{getStatusName(get(report, "interimStatus") || "")}</div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div style={{ display: "table-cell", paddingRight: "5px" }}>
                        <b>Patient Belongs to User:</b>
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {report.referredProviderNo && (
                          <Fragment>
                            {get(report, "referredFirstname")} {get(report, "referredSurname")} (
                            {get(report, "referredProviderNo")})
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* REPORT TITLE */}
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      lineHeight: "2em",
                      width: "100%",
                      borderTop: "1px solid",
                      borderBottom: "1px solid",
                    }}
                  >
                    <span className={styles.bodyhead} style={{ paddingRight: "10px" }}>
                      <b>Test:</b>
                    </span>
                    <span className={styles.bodytext}>
                      {report.testcode}-{report.testname}
                    </span>
                  </div>
                </div>

                {/* REPORT BODY */}
                <div style={{ width: "100%" }}>
                  <pre className={styles.bodytext1}>
                    <span
                      className={styles.pdfprint}
                      dangerouslySetInnerHTML={{
                        __html: report.report || "Results Pending",
                      }}
                    ></span>
                  </pre>
                </div>

                <div style={{ width: "100%" }}>
                  <span>
                    {report.ccdoctors &&
                      report.ccdoctors.map((item, index) => {
                        const title = item.title ? `${item.title}. ` : "";
                        const provider = item.provider ? ` (${item.provider})` : "";
                        return (
                          <Typography key={index} className={styles.reportText} display={"inline"}>
                            CC : {title} &nbsp;{item.firstname} &nbsp; {item.surname}&nbsp; {provider} <br></br>
                          </Typography>
                        );
                      })}
                  </span>
                </div>
              </div>
            ))}
        </Box>
      </Box>
    </Fragment>
  );
};

export default PreviewDialog;
