import { gql } from "@apollo/client";

export default gql`
  query GetCombinedReport($patientId: Int!, $labnoDigitOnly: String, $ids: [Float!]) {
    combinedReport(patientId: $patientId, labNumber: $labnoDigitOnly, panelIds: $ids) {
      reportId
      reportDateCollected
      reportDateCreated
      reportTestName
      reportLabNumber
      reportLabNumberDigitOnly
      reportUrno
      reportStatus
      status
      reportAbnormalStatus
      report
      lab {
        address
        suburb
        phone
      }
      cumulative
      panelId
      csPanelApprovalStatus
      csPanelApproverId
      csPanelApproverName
      csPanelApproverDatetime
      isReportOlderThanADay
      atomicData {
        name
        value
        measurement
        range
        loinc
        abnormal
        csApproval
        csApproverUserId
        csApproverUsername
        csApproverDatetime
        csStatus
        csUserId
        csDatetime
        csName
        atomicId
        csComment
        csCommentDate
        csCommentUsername
        noActionRequired
        actionRequired
        dCF
        csRepeatComment
        value_type
        commentFlag
      }
      urgentStatus
    }
  }
`;
