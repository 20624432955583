import { gql } from "@apollo/client";

export default gql`
  query getCytologyVerify($id: Float, $providers: [String!]) {
    getCytologyVerify(providers: $providers) {
      verify
    }
    getCytoReport(id: $id, providers: $providers) {
      id
      reportdate
      datecreated
      first_viewed
      last_viewed
      viewcount
      providerno
    }
  }
`;
