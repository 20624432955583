import React, { useRef, useEffect, useState, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { Box, Button, ButtonGroup, Grid, Select, MenuItem, Stack, Chip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import classes from "./CumulativeGraph.module.css";
import Tooltips from "../common/Tooltip/Tooltip";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
  zoomPlugin,
);

const LineGraph: React.FC<any> = (props) => {
  const chartRef = useRef<any>(null);
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();

  const handleZoomIn = () => {
    chartRef.current.zoom({ x: 1.1 });
  };

  const handleZoomOut = () => {
    chartRef.current.zoom({ x: 0.9 });
  };

  const handleResetZoom = () => {
    chartRef.current.resetZoom();
    setSelectedAtomic([]);
    props.onSelectAtomic("");
  };

  useEffect(() => {
    if (props.resetZoom) {
      chartRef.current.resetZoom();
    }
  }, [props.resetZoom]);

  useEffect(() => {
    // see details here:
    // https://github.com/chartjs/chartjs-plugin-zoom/issues/336
    chartRef.current.canvas.style.touchAction = "pan-y";
  }, []);

  const [selectedAtomic, setSelectedAtomic] = useState(props.selectedAtomic);

  const update = (value: any) => {
    setSelectedAtomic(value);
    props.onSelectAtomic(value);
  };

  const selectPanel = (event: any) => {
    const selectedValues = event.target.value;
    update(selectedValues);
  };

  const handleDelete = (nameToDelete: string) => {
    const filteredAtomicNames = props.selectedAtomic.filter((name: any) => name !== nameToDelete);
    update(filteredAtomicNames);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          mt: 1,
          mb: 1,
          bgcolor: "transparent",
          zIndex: 1000,
        }}
      >
        <ButtonGroup size="small">
          <Button
            variant="outlined"
            color="primary"
            className={classes.graphButton}
            onClick={() => {
              props.handleChange(false, props.atomicIndex);
              setSelectedAtomic([]);
            }}
            title="Previous"
            disabled={props.atomicCount <= 1}
            style={{ padding: "8px 5px" }}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.graphButton}
            onClick={() => {
              props.handleChange(true, props.atomicIndex);
              setSelectedAtomic([]);
            }}
            title="Next"
            disabled={props.atomicCount <= 1}
            style={{ padding: "8px 5px" }}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </ButtonGroup>
      </Box>
      <Box mt={-6.2}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          mt: 1,
          mb: 1,
          bgcolor: "transparent",
          zIndex: 1000,
        }}
      >
        {props.availableAtomic && props.availableAtomic.length > 1 && (
          <Select
            multiple
            displayEmpty
            id="paneldept"
            value={selectedAtomic}
            onChange={selectPanel}
            variant="outlined"
            renderValue={() => <em>Add atomic</em>}
            inputProps={{ "aria-label": "Without label" }}
            sx={{ minWidth: !isDesktop || isMobility ? "120px" : "150px" }}
          >
            <MenuItem disabled value="">
              <em>Add atomic</em>
            </MenuItem>
            {props.availableAtomic.map((atomicName: any, index: any) => (
              <MenuItem key={index} value={atomicName}>
                {atomicName}
              </MenuItem>
            ))}
          </Select>
        )}

        <ButtonGroup size="small">
          <Button variant="outlined" className={classes.graphButton} color="primary">
            <Tooltips text="AtomicGraph" buttonColor="#77499D" />
          </Button>
          <Button
            variant="outlined"
            title="Zoom Out"
            className={classes.graphButton}
            color="primary"
            onClick={handleZoomOut}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            variant="outlined"
            title="Zoom In"
            className={classes.graphButton}
            color="primary"
            onClick={handleZoomIn}
          >
            <ZoomInIcon />
          </Button>
          <Button
            variant="outlined"
            title="Reset"
            className={classes.graphButton}
            color="primary"
            onClick={handleResetZoom}
          >
            <RefreshIcon className={classes.refreshIcon} />
          </Button>
        </ButtonGroup>
      </Box>
      <Box mt={window.innerWidth > 550 ? -5.5 : 2}></Box>
      <Line
        id={props.id}
        ref={chartRef}
        width={props.width}
        height={props.height}
        options={props.options}
        data={props.data}
      />
      {props.selectedAtomic.length > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 1,
            mb: 1,
            bgcolor: "transparent",
            zIndex: 1000,
          }}
        >
          <Grid item xs={12} md={3}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                flexWrap: "wrap",
                maxWidth: "800px",
              }}
            >
              {props.selectedAtomic.map((name: any, index: any) => (
                <>
                  <Chip
                    key={name}
                    label={name}
                    onDelete={() => handleDelete(name)}
                    variant="outlined"
                    sx={{ color: "secondary", marginBottom: "8px" }}
                    avatar={<Box sx={{ bgcolor: props.colors[index].borderColor, borderRadius: "50%" }} />}
                  />
                </>
              ))}
            </Stack>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default LineGraph;
