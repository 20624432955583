import { gql } from "@apollo/client";

export default gql`
  query GetCytologyResultsBreakdown($id: Float!, $providers: [String!]!) {
    getCytologyResultsBreakdown(id: $id, providers: $providers) {
      hiGrade {
        heading
        pcnt
        value
        total
      }
      loGrade {
        heading
        pcnt
        value
        total
      }
      organisms {
        heading
        pcnt
        value
        total
      }
      glandular {
        heading
        pcnt
        value
        total
      }
      sti {
        heading
        pcnt
        value
        total
      }
    }
  }
`;
