import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { isTenantVet } from "../services/tenantService";

const primaryColor = {
  purple: "#77499D",
  lightPurple: "#EDE1F7",
  orange: "#FF8000",
  lightOrange: "#FDCD9B",
  green: "#3AAE2A",
  lightGreen: "#E1F1DA",
  lightGrey: "#BFBFBF",
  text: "#21262B",
  textWhite: "#FFFFFFF",
  textGrey: "#4e5962",
  textLightGrey: "#A3ACB3",
  error: "#E1012D",
};

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: primaryColor.purple,
          light: primaryColor.lightPurple,
          dark: isTenantVet() ? primaryColor.green : primaryColor.orange,
        },
        secondary: {
          main: primaryColor.lightPurple,
          light: isTenantVet() ? primaryColor.lightGreen : primaryColor.lightOrange,
          dark: primaryColor.lightGrey,
        },
        grey: {
          "100": primaryColor.textLightGrey,
        },
        text: {
          primary: primaryColor.text,
          secondary: primaryColor.textGrey,
        },
        error: {
          main: primaryColor.error,
        },
        warning: {
          main: isTenantVet() ? primaryColor.green : primaryColor.orange,
        },
      },
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        underlineAlways: "none",
        root: {
          cursor: "pointer",
          textDecoration: "auto",
        },
      },
      defaultProps: {
        fontWeight: 600,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: "#ccc",
        colorSecondary: {
          "&$checked": {
            color: isTenantVet() ? primaryColor.green : primaryColor.orange,
          },
        },
        track: {
          "$checked$colorSecondary + &": {
            opacity: 0.7,
            backgroundColor: "#ffcc99",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "13px !important",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "8.5px 14px !important",
        },
        inputAdornedStart: {
          padding: "16px 14px !important",
        },
        inputMultiline: {
          padding: "0 !important",
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTableCell: {
      defaultProps: {
        padding: "none",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "rgba(0, 0, 0, 0.87)",
          maxWidth: 800,
          backgroundColor: "white",
          border: "1px solid #dadde9",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: "#4CAF50",
          color: "#FFFFFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            textDecoration: "none",
            backgroundColor: "var(--mui-palette-primary-main)",
            color: "var(--mui-palette-primary-contrastText)",
          },
        },

        containedSecondary: {
          backgroundColor: primaryColor.textLightGrey,
          color: "black",
          "&:hover": {
            textDecoration: "none",
            backgroundColor: "#72787D",
            color: "black",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          color: primaryColor.textLightGrey,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: primaryColor.textLightGrey,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          color: primaryColor.textLightGrey,
        },
      },
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
    h3: {
      fontSize: "1.875rem", // 30px
    },
    h4: {
      fontSize: "1.375rem", // 22px
    },
    h6: {
      fontSize: "1.125rem",
    },
    body1: {
      fontSize: "0.93rem", // 15px
    },
    body2: {
      fontSize: "0.8125rem", // 13px
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
  },
});
