import React from "react";
import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function LoadingWithLabel(props: CircularProgressProps & { value?: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        color="primary"
        variant={props.value !== undefined ? "determinate" : "indeterminate"}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="grey.100">
          {props.value !== undefined ? `${Math.round(props.value)}%` : ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default LoadingWithLabel;
