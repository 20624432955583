import React, { Fragment, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";
import { Formik } from "formik";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import DoneIcon from "@mui/icons-material/Done";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./ChangePasswordPage.module.css";
import { ChangePasswordVariables, ChangePassword } from "./types/ChangePassword";
import { ApolloClient, MutationTuple, useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import get from "lodash/get";
import changePasswordMutation from "./ChangePasswordMutation";
import { ApolloError } from "@apollo/client";
import { ChangePasswordFormValues, validatePassword, validationRules } from "./PasswordValidation";
import authService, { SET_AUTH } from "../../services/authService";
import { GraphQLError } from "graphql";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GetOptions } from "../App/types/GetOptions";
import GetOptionsQuery from "../App/GetOptionsQuery";

type ChangePasswordBoxType = {
  onSubmit: (username: string, oldpassword: string, newpassword: string) => Promise<void>;
  error?: ApolloError;
  loading?: boolean;
  location: RouteComponentProps["location"] | undefined;
  history: RouteComponentProps["history"] | undefined;
  success: boolean;
};

export const ChangePasswordBox: React.FC<ChangePasswordBoxType> = (props) => {
  const { error, loading, location, history } = props;
  const username: string = authService.getUsername();
  const hist = useHistory();
  const client = useApolloClient();

  const initialValues: ChangePasswordFormValues = {
    oldpassword: "",
    password: "",
    passwordconfirm: "",
    message: "",
  };

  const [values, setValues] = React.useState({
    oldpassword: "",
    password: "",
    passwordconfirm: "",
    showOldPassword: false,
    showPassword: false,
    showPasswordConfirm: false,
  });

  const submit = (values: ChangePasswordFormValues) => {
    try {
      props.onSubmit(username, values.oldpassword, values.password);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("password didnt change" + e);
    }
  };

  const handleClickShowOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPasswordConfrim = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSuccess = () => {
    if (history !== undefined) {
      const from = get(location, "state.from") || "/patients";
      history.push(from);
    }
  };
  return (
    <AuthLayout title="Change your password" maxWidth={"972px"}>
      <Box mt={4}>
        <Formik initialValues={initialValues} validate={validatePassword} onSubmit={submit}>
          {({ errors, handleChange, handleBlur, handleSubmit, touched }) => {
            const handleCancel = () => {
              if (authService.getExpired()) {
                authService.clearAll();
                client.resetStore();
                // TODO: shouldn't need to explicitly navigate to '/' if we are behind a <PrivateRoute />
                hist.push("/login");
              } else if (get(hist.location, "state.from")) {
                hist.push(get(hist.location, "state.from", ""));
              } else {
                hist.goBack();
              }
            };
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        id="oldpassword"
                        name="oldpassword"
                        label="Old password"
                        fullWidth
                        type={values.showOldPassword ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.oldpassword && touched.oldpassword}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Box>
                    <Box mt={2}>
                      <TextField
                        id="password"
                        name="password"
                        fullWidth
                        label="New Password"
                        type={values.showPassword ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password && touched.password}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Box>
                    <Box>
                      {errors.message && touched.passwordconfirm && (
                        <Typography color="error">{errors.message}</Typography>
                      )}
                    </Box>
                    <Box mt={2}>
                      <TextField
                        id="passwordconfirm"
                        name="passwordconfirm"
                        fullWidth
                        label="Confirm password"
                        type={values.showPasswordConfirm ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.passwordconfirm && touched.passwordconfirm}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfrim}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Box>
                    <Box>
                      {error &&
                        error.graphQLErrors.map(({ message }, i) => (
                          <Typography key={i} color="error">
                            {message}
                          </Typography>
                        ))}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <Link color="primary" onClick={handleCancel} className={styles.backLink}>
                        Cancel
                      </Link>
                      <Button type="submit" color="primary" variant="contained" disabled={loading}>
                        Change password and log in
                      </Button>
                    </Box>
                    {props.success ? (
                      <>
                        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                          <Typography className={styles.valid}>Your password successfully changed</Typography>
                          <Button type="button" color="primary" variant="contained" onClick={handleSuccess}>
                            OK
                          </Button>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Password requirements</Typography>
                    <List className={styles.list} component="nav" aria-label="main mailbox folders">
                      {validationRules.map((v, i) => (
                        <ListItem disableGutters className={styles.listItem} key={i}>
                          <ListItemIcon className={styles.listIcon}>
                            {v.valid === 1 ? (
                              <DoneIcon className={styles.listIconDone} />
                            ) : (
                              <DotIcon className={`${styles.listIconDot} ${v.valid === -1 ? styles.invalid : ""} `} />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: "body1" }}
                            className={`${styles.listText} ${v.valid === -1 ? styles.invalid : ""}`}
                            primary={v.text}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </AuthLayout>
  );
};

const ChangePasswordPage: React.FC<any> = (props) => {
  const { location, history } = props;
  const [success, setSuccess] = useState(false);
  const client: ApolloClient<any> = useApolloClient();
  const [changePassword, { loading, error }] = useMutation<ChangePassword, ChangePasswordVariables>(
    changePasswordMutation,
  );
  const [siteOptions, { loading: optionsLoading }] = useLazyQuery<GetOptions>(GetOptionsQuery);
  if (error) {
    console.error("Error", error);
  }

  const onSubmit =
    (
      location: RouteComponentProps["location"] | undefined,
      history: RouteComponentProps["history"] | undefined,
      changePassword: MutationTuple<ChangePassword, ChangePasswordVariables>[0],
      client: ApolloClient<any>,
    ) =>
    async (username: string, oldpassword: string, newpassword: string) => {
      try {
        const result = await changePassword({
          variables: {
            input: {
              username,
              oldpassword,
              newpassword,
              setCookie: true,
            },
          },
        });

        if (result.data) {
          // console.log(result.data.changePassword.accessToken);
          authService.persistAllValues(result.data.changePassword);

          //always set to non-expired if login via delegate.
          authService.clearExpired();

          client.cache.writeQuery({
            query: SET_AUTH,
            data: { auth: { isAuth: true, __typename: "Auth" } },
          });

          // Get site options
          const options = await siteOptions({});
          if (options.data) {
            authService.setSiteOptions(options.data.getOptions);
          }
        }

        if (authService.isMobility()) {
          localStorage.setItem("x-object", btoa(newpassword));
        }

        authService.clearExpired();

        setSuccess(true);
      } catch (e) {
        if (
          e.graphQLErrors &&
          (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot Change Password - Shared Account") !==
            undefined
        ) {
          authService.setSharedAccount(true);
          window.location.reload();
        }
      }
    };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <ChangePasswordBox
        onSubmit={onSubmit(location, history, changePassword, client)}
        error={error}
        loading={loading || optionsLoading}
        location={location}
        history={history}
        success={success}
      />
    </Fragment>
  );
};

export default ChangePasswordPage;
