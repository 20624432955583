import React, { useContext, useRef } from "react";
import { TextField, Typography, Box, IconButton, Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyQuery } from "@apollo/client";
import { GetPseudonyms, GetPseudonyms_Pseudonyms_pseudonyms } from "./types/GetPseudonyms";
import GetPseudonymsQuery from "./GetPseudonymsQuery";
import styles from "./TestSearch.module.css";
import { useHistory } from "react-router";
import AppContext from "../../context/AppContext";
import { CumulativeContext } from "../../context/CumulativeContext";
import Close from "@mui/icons-material/Close";
import lodash from "lodash";

export type TestSearchType = {
  patientId?: number;
  labnoDigitOnly?: string;
  setReportingPanels?: React.Dispatch<any>;
  allPatients?: boolean;
};

const TestSearch: React.FC<TestSearchType> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<(GetPseudonyms_Pseudonyms_pseudonyms | null)[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<GetPseudonyms_Pseudonyms_pseudonyms | null>(null);
  const isDesktop = useContext(AppContext).isDesktop;
  const setTestName = useContext(AppContext).setTestName;
  const [waitingForCumulativeChange, setWaitingForCumulativeChange] = React.useState(false);

  const { setIsCumulative, setPanelId, setTestname, isCumulative, setShowPanelRep, setAllPanels } =
    useContext(CumulativeContext);

  const history = useHistory();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setOpen(false);
      setOptions([]);
      setShowPanelRep(false);
    }
    setInputValue(event.target.value);
  };

  const handleOptionChange = (event: React.ChangeEvent<any>, value: any) => {
    // eslint-disable-next-line no-console

    let panel = value as GetPseudonyms_Pseudonyms_pseudonyms;
    if (!panel || !panel.report_panelid) {
      panel = options && options.length > 0 && options[0] && options[0].report_panelid !== -1 ? options[0] : value;
    }

    if (panel && panel.report_panelid) {
      setValue(panel);
      if (props.patientId) {
        if (isDesktop) {
          setIsCumulative(true);
          setPanelId(panel.report_panelid);
          setTestname(panel.report_paneln);
          setInputValue(panel.report_paneln);
          setWaitingForCumulativeChange(true);
          setAllPanels(panel.all_panels);
          // eslint-disable-next-line no-console
          setShowPanelRep(false);
        } else {
          // TODO: This allPatients should be changed from true to the proper var
          history.push(
            `/atomic/${props.patientId}/${props.allPatients ? 1 : 0}/${panel.report_panelid}/${props.labnoDigitOnly}`,
          );
        }
        setTestName(panel.report_paneln);
      } else if (props.setReportingPanels) {
        props.setReportingPanels(panel.report_panelid);
      }
    } else {
      setValue(null);
      if (props.patientId) {
        if (isDesktop) {
          setWaitingForCumulativeChange(false);
          setIsCumulative(false);
        }
      }
    }
  };

  const handleClear = () => {
    setIsCumulative(false);
    setPanelId(0);
    setTestname("");
    setInputValue("");
    setOptions([]);
    setOpen(false);
    setValue(null);
    if (props.patientId) {
      if (isDesktop) {
        setWaitingForCumulativeChange(false);
        if (props.setReportingPanels) {
          props.setReportingPanels(0);
        }
      }
    }
  };

  const [getPseudonyms, { data, loading, error }] = useLazyQuery<GetPseudonyms>(GetPseudonymsQuery, {
    variables: { searchTerm: inputValue },
  });
  const autocompleteSearchDebounced = useRef(
    lodash.debounce((q: string) => getPseudonyms({ variables: { searchTerm: q } }), 500),
  );
  const autocompleteSearchThrottled = useRef(
    lodash.throttle((q: string) => getPseudonyms({ variables: { searchTerm: q } }), 500),
  );

  React.useEffect(() => {
    if (!inputValue) {
      setOptions([]);
    } else {
      //getPseudonyms( { variables: { searchTerm: inputValue } })
      if (inputValue.length < 5) {
        autocompleteSearchThrottled.current(inputValue);
      } else {
        autocompleteSearchDebounced.current(inputValue);
      }
    }
  }, [inputValue]);

  React.useEffect(() => {
    if (error && error.graphQLErrors.length > 0) {
      setOptions([
        {
          __typename: "Pseudonyms",
          test_name: "There was an error",
          panel_code: "",
          report_panelid: -1,
          report_paneln: "",
          all_panels: [],
        },
      ]);
    } else if (!inputValue) {
      setOptions([]);
    } else if (data && data.Pseudonyms && data.Pseudonyms.pseudonyms) {
      if (data.Pseudonyms.pseudonyms.length > 0) {
        setOptions(data.Pseudonyms.pseudonyms);
      } else {
        const notFound: GetPseudonyms_Pseudonyms_pseudonyms[] = [
          {
            __typename: "Pseudonyms",
            test_name: "No tests found",
            panel_code: "",
            report_paneln: "",
            report_panelid: -1,
            all_panels: [],
          },
        ];
        setOptions(notFound);
      }
    } else if (loading) {
      setOptions([]);
    }
  }, [inputValue, data, loading, error]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (waitingForCumulativeChange && !isCumulative) {
      setOptions([]);
      setInputValue("");
      setWaitingForCumulativeChange(false);
    }
  }, [isCumulative, waitingForCumulativeChange]);

  return (
    <Autocomplete
      style={{ width: "100%" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option: any) => {
        return option && (option as GetPseudonyms_Pseudonyms_pseudonyms).report_paneln
          ? option.report_paneln
          : options && options.length > 0 && options[0] && options[0].report_panelid !== -1
            ? options[0].report_paneln
            : "";
      }}
      filterOptions={(options) => options}
      options={options}
      loading={loading}
      onChange={handleOptionChange}
      getOptionDisabled={(option) => {
        return (
          (option && option.report_panelid && option.report_panelid === -1) ||
          (error && error.graphQLErrors.length > 0) ||
          false
        );
      }}
      noOptionsText="Start typing to show test list"
      freeSolo
      //disableOpenOnFocus
      disableClearable
      inputValue={inputValue}
      value={value || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Test Search"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            style: { padding: "0px 10px", height: "40px" },
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} style={{ padding: "10px" }} />
                ) : inputValue ? (
                  <IconButton onClick={handleClear}>
                    <Close fontSize="small" />
                  </IconButton>
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option: GetPseudonyms_Pseudonyms_pseudonyms | null | undefined) => {
        return (
          <li {...props} key={option && option.panel_code}>
            <Box>
              <Typography variant={"body2"} color={option && option.report_panelid === -1 ? "error" : "textPrimary"}>
                {option && option.test_name}
              </Typography>
              <Typography variant={"body2"} className={styles.panelText}>
                {option && option.report_paneln}
              </Typography>
            </Box>
          </li>
        );
      }}
    />
  );
};

export default TestSearch;
