import React, { Fragment, useContext } from "react";
import { EpisodeReportsProps } from "../ReportViewer/EpisodeReportsProps";
import ReportViewer from "../ReportViewer/ReportViewer";
import authService from "../../services/authService";
import { useQuery } from "@apollo/client";
import GetEpisodeReports from "../PatientReportsCondense/GetEpisodeReports";
import { GetEpisodeReports as GetEpisodeReportsQueryType } from "../PatientReportsCondense/types/GetEpisodeReports";
import Loading from "../Loading/Loading";
import Scrollbars from "react-custom-scrollbars-2";
import PatientReportHeader from "./PatientReportsHeader";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { Typography, Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Constants from "../../constants";
import { useCacheWithExpiration } from "../../utils/useCacheWithExpiration";
import getCombinedReport from "../CombinedReport/GetCombinedReportQuery";
import { GetCombinedReport } from "../CombinedReport/types/GetCombinedReport";
import { CumulativeContext } from "../../context/CumulativeContext";
import { useEffect } from "react";
import { GetEpisodeReports_episodereports_histoImages } from "../PatientReportsCondense/types/GetEpisodeReports";
import PatientDetails from "../CombinedReport/PatientDetails";
import { cloneDeep } from "lodash";

type PatientReportsFullProps = {
  reportsProps: EpisodeReportsProps;
  initialLabNo: string;
  setInitialLabNo: React.Dispatch<any>;
  allPatients: boolean;
  sortBy?: string | null;
  patient?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  noResult: {
    margin: "50px",
  },
}));

const PatientReportsFull: React.FC<PatientReportsFullProps> = (props) => {
  const { setAtomics } = useContext(CumulativeContext);
  const classes = useStyles();

  const fetchPolicy = useCacheWithExpiration(
    Constants.DEFAULT_CACHE_DURATION,
    `GetEpisodeReports_${props.reportsProps.labnoDigitOnly}`,
  );

  const {
    data: episodeReportsData,
    loading,
    error: episodDataError,
  } = useQuery<GetEpisodeReportsQueryType>(GetEpisodeReports, {
    variables: {
      patientId: props.reportsProps.patientId,
      providers: authService.getProviders(),
      labnoDigitOnly: props.reportsProps.labnoDigitOnly,
      includePdf: false,
      allPatients: props.allPatients,
      sortBy: props.sortBy,
    },
    skip: props.reportsProps.labnoDigitOnly === "UNKNOWN",
    fetchPolicy: fetchPolicy,
  });

  const reportIds = episodeReportsData ? episodeReportsData.episodereports.reports.map((a) => a.reportid) : [];
  const isClinicalTrial = authService.isClinicalTrial();

  const {
    data: combineReportsData,
    loading: loading2,
    error: combineDataError,
  } = useQuery<GetCombinedReport>(getCombinedReport, {
    variables: {
      patientId: props.reportsProps.patientId,
      labnoDigitOnly: props.reportsProps.labnoDigitOnly,
      ids: reportIds,
    },
    skip: !episodeReportsData || !isClinicalTrial || authService.getReportView() == "1", //skip if non-clinical user or is in standard view
    fetchPolicy: fetchPolicy,
  });

  useEffect(() => {
    const atomics = combineReportsData && combineReportsData.combinedReport ? combineReportsData.combinedReport : [];
    const newAtomics = cloneDeep(atomics);

    for (const newAtomic of newAtomics) {
      if (
        !newAtomic?.cumulative &&
        newAtomic?.report !== "" &&
        newAtomic?.atomicData?.length === 1 &&
        newAtomic?.atomicData[0]?.atomicId === null
      ) {
        newAtomic?.atomicData?.forEach((a: any) => {
          a.abnormal = true;
          a.loinc = "REPORT";
          a.name = "REPORT";
        });
      }
    }

    setAtomics(newAtomics);
  }, [combineReportsData]);

  if (loading || loading2) {
    return <Loading />;
  }

  if (episodDataError || combineDataError) {
    return <ErrorPopover />;
  }

  const reports =
    episodeReportsData && episodeReportsData.episodereports ? episodeReportsData.episodereports.reports : undefined;

  const histoImages =
    episodeReportsData && episodeReportsData.episodereports ? episodeReportsData.episodereports.histoImages : undefined;

  const reportView = authService.getReportView();
  const clinicalTrialUserType = isClinicalTrial
    ? authService.getClinicalTrial()
    : Constants.CLINCAL_TRIAL_USER_TYPE.Standard;

  if (reports && reports.length) {
    return (
      <Fragment>
        <Scrollbars id="scrollBars" style={{ transform: "translateZ(0)" }} thumbMinSize={180}>
          <PatientReportHeader
            reports={reports}
            episode={props.reportsProps}
            allPatients={props.allPatients}
            patientId={props.reportsProps.patientId}
            sortBy={props.sortBy}
            patient={props.patient}
            labnoDigitOnly={props.reportsProps.labnoDigitOnly}
          />

          {clinicalTrialUserType === Constants.CLINCAL_TRIAL_USER_TYPE.Standard || reportView === "1" ? (
            <ReportViewer
              patientId={props.reportsProps.patientId}
              labnoDigitOnly={props.reportsProps.labnoDigitOnly}
              reports={reports}
              histoImages={histoImages as GetEpisodeReports_episodereports_histoImages[] | undefined}
              initialLabNo={props.initialLabNo}
              setInitialLabNo={props.setInitialLabNo}
              allPatients={props.allPatients}
              sortBy={props.sortBy}
            />
          ) : (
            <PatientDetails
              reports={reports}
              patientId={props.reportsProps.patientId}
              initialLabNo={props.initialLabNo}
              setInitialLabNo={props.setInitialLabNo}
              allPatients={props.allPatients}
              sortBy={props.sortBy}
              labnoDigitOnly={props.reportsProps.labnoDigitOnly}
            />
          )}
        </Scrollbars>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Scrollbars id="scrollBars">
        <Box mb={[0, 0, 2]} display="flex" justifyContent="center" className={classes.noResult}>
          <Typography variant="h3">
            {props.reportsProps.labnoDigitOnly !== "UNKNOWN" ? "No Reports Found!" : ""}
          </Typography>
        </Box>
      </Scrollbars>
    </Fragment>
  );
};

export default PatientReportsFull;
