import { gql } from "@apollo/client";

export default gql`
  query GetCovidCertificate($labnumber: String!, $providerno: String!) {
    covidtravelcertificate(labnumber: $labnumber, providerno: $providerno) {
      reportpdf
      reportsize
    }
  }
`;
