import { gql } from "@apollo/client";
export default gql`
  query GetPatientExtended($patientId: Int, $allPatients: Boolean) {
    patient(id: $patientId) {
      fullnameTitleCase
      dob
      sex
      street
      city
      state
      postcode
      mobile
      fullAddress
      title
      firstname
      surname
      fullname
      species
    }
    latestReport(id: $patientId, allPatients: $allPatients) {
      id
      ward
      urno
      labnoDigitOnly
    }
  }
`;
