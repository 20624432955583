import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import GeneralHeader from "../GeneralHeader/GeneralHeader";
import { Box, Button, Grid, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ClearIcon from "@mui/icons-material/Clear";
import AppContext from "../../context/AppContext";
import { Formik } from "formik";
import { UserListHeader } from "../HospAdminUserListTable/UserListHeader";
import HospAdminUserListTable from "../HospAdminUserListTable/HospAdminUserListTable";
import authService from "../../services/authService";
import thm from "../theme";
import CreateHospitalUser from "../CreateHospitalUser/CreateHospitalUser";
import HospitalMfaSettings from "../HospitalMfaSettings/HospitalMfaSettings";
import { CLINCAL_TRIAL_USER_TYPE } from "../../constants";
import { Theme } from "@mui/material/styles/createTheme";
import styles from "../HospAdminUserListPage/HospAdminUserListPage.module.css";
export type SearchBarProps = {
  searchtext: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: "46px",
  },
  dontDisplay: {
    display: "none",
  },
  pleaseDisplayClear: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "400px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "257px",
    },
  },
  pleaseDisplayMax: {
    display: "inline-flex",
    width: "56px",
    height: "56px",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    left: "370px",
    fontSize: "10px",
    color: "#a3acb3",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "261px",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    color: "black",
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    display: "flex",
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "56px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  inputRoot: {
    color: "inherit",
    margin: "5px 0px",
  },
  clearButton: {
    cursor: "pointer",
  },

  inputInput: {
    padding: "8.5px 50px!important",
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.common.white,
    "&:placeholder-shown": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&:-ms-input-placeholder": {
      backgroundColor: "#f2f5fa",
      border: "none",
      boxShadow: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
    width: "100%",
    // borderRadius: '4px',
    boxShadow: "0 2px 11px 0 rgba(0,0,0,0.1)",
    border: "solid 1px #4e5962",
    height: "30px",
  },
  inputPatientWidthMax: {
    [theme.breakpoints.up("md")]: {
      width: 320,
    },
    [theme.breakpoints.up("xs")]: {
      width: 170,
    },
    [theme.breakpoints.up("sm")]: {
      width: 240,
    },
  },
  inputPatientWidthMin: {
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: 162,
    },
  },
  error: {
    border: "solid 1px red !important",
  },
  addSpace: {
    marginRight: "10px",
  },
  orangeButton: {
    backgroundColor: thm.colorSchemes.light.palette.primary.dark + " !important",
  },
  purpleButton: {
    backgroundColor: thm.colorSchemes.light.palette.primary.main + " !important",
  },

  resetButtonMob: {
    backgroundColor: "#979797 !important",
    marginLeft: "2px",
    color: "#ffffff",
  },
  clearIcon: {
    opacity: "0",
  },
}));

const HospAdminUserListPage: React.FC = () => {
  const isDesktop = useContext(AppContext).isDesktop;
  const classes = useStyles();
  const history = useHistory();

  const [typing, setTyping] = useState(false);
  const [returned, setReturned] = useState(false);

  const initialValues: SearchBarProps = {
    searchtext: "",
  };

  const [searchText, setSearchText] = useState("");

  if (!authService.isHospitalAdmin()) {
    history.replace("/patients");
  }

  const changeDisplay = () => {
    setTyping(true);
    setReturned(false);
  };

  const hideDisplay = () => {
    setTyping(false);
  };

  const clearSearchNameData = (values: SearchBarProps) => {
    values.searchtext = "";
    submit(values);
  };

  const submit = (values: SearchBarProps) => {
    setSearchText(values.searchtext);
    if (values.searchtext.trim().length > 0) {
      setReturned(true);
      setTyping(false);
    } else {
      setReturned(false);
    }
  };

  const onReset = (values: SearchBarProps) => {
    values.searchtext = "";
    submit(values);
  };

  const fullscreen = useMediaQuery(thm.breakpoints.down("sm"));
  const [openCreateHospitalUser, setOpenCreateHospitalUser] = useState(false);

  const handleClickCreateHospitalUser = () => {
    setOpenCreateHospitalUser(true);
  };

  const handleCreateHospitalUserClose = () => {
    setOpenCreateHospitalUser(false);
  };

  const [openHospitalMfaSettings, setOpenHospitalMfaSettings] = useState(false);

  const handleClickHospitalMfaSettingsOpen = () => {
    setOpenHospitalMfaSettings(true);
  };
  const handleClickStudyConfig = () => {
    const path = `/study`;
    history.push(path);
  };
  const handleHospitalMfaSettingsClose = () => {
    setOpenHospitalMfaSettings(false);
  };

  return (
    <>
      <GeneralHeader></GeneralHeader>
      <Box mx={[1, 3]}>
        <Formik initialValues={initialValues} onSubmit={submit} onReset={onReset} enableReinitialize={true}>
          {({ values, handleSubmit, handleChange, handleReset }) => (
            <Box>
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Box
                    display={isDesktop ? "flex" : ""}
                    width={1}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CreateHospitalUser
                      open={openCreateHospitalUser}
                      fullscreen={fullscreen}
                      onClose={handleCreateHospitalUserClose}
                    />
                    <HospitalMfaSettings
                      open={openHospitalMfaSettings}
                      fullscreen={fullscreen}
                      onClose={handleHospitalMfaSettingsClose}
                    />
                    {/* <Grid item xs={12} md={6} lg={3} > */}
                    <div className={`${styles.searchGroup}`} style={isDesktop ? {} : { display: "inline-block" }}>
                      {
                        <TextField
                          aria-label="user"
                          placeholder="Search User Name"
                          id="searchtext"
                          name="searchtext"
                          className={`${styles.mainInputs} `}
                          onChange={handleChange}
                          onBlur={() => {
                            hideDisplay();
                          }}
                          autoComplete="off"
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              //Enter Key
                              submit(values);
                              e.preventDefault();
                            } else {
                              changeDisplay();
                            }
                          }}
                          value={values.searchtext}
                          InputProps={{
                            className: classes.input,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {typing ? (
                                  <KeyboardReturnIcon
                                  // className={typing ? classes.pleaseDisplayMax : classes.dontDisplay}
                                  />
                                ) : returned ? (
                                  <ClearIcon
                                    className={classes.clearButton}
                                    onClick={() => {
                                      clearSearchNameData(values);
                                    }}
                                  />
                                ) : (
                                  <ClearIcon className={classes.clearIcon} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      }

                      <Button
                        className={`${isDesktop ? styles.resetButton : classes.resetButtonMob}`}
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </div>
                    <div style={isDesktop ? {} : { display: "inline-block" }}>
                      <Box>
                        <Button
                          className={`${classes.addSpace} ${classes.orangeButton}`}
                          variant="contained"
                          color="secondary"
                          onClick={handleClickCreateHospitalUser}
                          style={{
                            padding: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            marginLeft: "10px",
                            marginRight: 0,
                          }}
                        >
                          Add User
                        </Button>
                        {authService.getClinicalTrial() == CLINCAL_TRIAL_USER_TYPE.Approver ? (
                          <Button
                            className={`${classes.addSpace} ${classes.purpleButton}`}
                            variant="contained"
                            color="primary"
                            onClick={handleClickStudyConfig}
                            style={{
                              padding: "5px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              marginLeft: "10px",
                              marginRight: 0,
                            }}
                          >
                            Study Config
                          </Button>
                        ) : (
                          false
                        )}

                        <Button
                          className={`${classes.addSpace} ${classes.purpleButton}`}
                          variant="contained"
                          color="primary"
                          onClick={handleClickHospitalMfaSettingsOpen}
                          style={{
                            padding: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            marginLeft: "10px",
                            marginRight: 0,
                          }}
                        >
                          MFA Settings
                        </Button>
                      </Box>
                    </div>
                  </Box>
                </Grid>
              </form>
            </Box>
          )}
        </Formik>
      </Box>
      <Box style={{ marginBottom: "20px" }}></Box>
      <UserListHeader></UserListHeader>
      <HospAdminUserListTable searchText={searchText} />
    </>
  );
};

export default HospAdminUserListPage;
