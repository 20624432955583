import { gql } from "@apollo/client";
export default gql`
  query GetHistologyData($filename: String!, $size: String!, $patientId: Int, $reportId: Int) {
    histoimage(filename: $filename, size: $size) {
      base64data
      filename
      mimetype
    }
    patient(id: $patientId) {
      dob
      sex
      street
      city
      state
      postcode
      fullname
    }
    patientreportdata(patientId: $patientId, reportId: $reportId) {
      labnumber
      dateCollected
      dateCreated
      dateReferred
      interimStatus
      testnameProperCase
      urno
      ward
      referringDoctor
      suburb
      address
      phone
      referredProviderNo
    }
  }
`;
