import { gql } from "@apollo/client";
export default gql`
  mutation UpdateUserMFA($input: UserMfaInputs!) {
    updateUserMfa(input: $input) {
      totpUrl
      sendEmail
      sendSMS
      status
    }
  }
`;
