import { useEffect, useState } from "react";
import { FetchPolicy } from "@apollo/client";
import ExpiredStorage from "expired-storage";
import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";

export const useCacheWithExpiration = (expiration: Duration, key: string): FetchPolicy => {
  const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>("cache-only");

  useEffect(() => {
    const expiredStorage = new ExpiredStorage(sessionStorage);
    const lastFetch = expiredStorage.getItem(key);
    if (lastFetch === null || dayjs().diff(dayjs(lastFetch)) > expiration.asMilliseconds()) {
      expiredStorage.setItem(key, dayjs().toISOString(), expiration.asSeconds());
      setFetchPolicy("network-only");
    } else {
      setFetchPolicy("cache-first");
    }
    expiredStorage.clearExpired();
  }, [expiration, key]);

  return fetchPolicy;
};
