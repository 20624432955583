import { gql } from "@apollo/client";

export default gql`
  mutation UpdateAbnormalAtomic($input: UpdateAtomicAbnormalArg!) {
    updateAbnormalAtomic(input: $input) {
      atomicId
      abnormal
      reportId
    }
  }
`;
