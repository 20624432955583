import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const formatDate = (date: string | null, formatter: string, doNotConvertToLocal: boolean): string => {
  if (!date) return "";
  if (doNotConvertToLocal) {
    return dayjs(date).utc().format(formatter);
  } else {
    return dayjs(date).format(formatter);
  }
};

export const dateFromNow = (date: string | null, doNotConvertToLocal: boolean): string => {
  if (!date) return "";
  if (doNotConvertToLocal) {
    return dayjs(date).utc().fromNow();
  } else {
    return dayjs(date).fromNow();
  }
};

export const humanDuration = (date: string, currentClock: number): string => {
  const localTime = new Date(currentClock);
  const duration = dayjs.duration(dayjs(localTime).diff(dayjs(date)));
  const durationArray: string[] = [];

  //Get Days
  const days = Math.floor(duration.asDays());
  const daysFormatted = days ? `${days} day(s) ` : "";
  daysFormatted !== "" && durationArray.push(daysFormatted);

  //Get Hours
  const hours = duration.hours();
  const hoursFormatted = `${hours} hour(s) `;
  hoursFormatted !== "" && durationArray.push(hoursFormatted);

  //Get Minutes
  const minutes = duration.minutes();
  const minutesFormatted = `${minutes} minute(s)`;
  minutesFormatted !== "" && durationArray.push(minutesFormatted);

  return durationArray.join(" : ");
};

export const getDiffInHours = (date: string, currentClock: number): number => {
  const localTime = new Date(currentClock);
  const duration = dayjs.duration(dayjs(localTime).diff(dayjs(date)));

  return Math.floor(duration.asHours());
};

export const ausTimeZones = (state: string): string => {
  const timezones = [
    { state: "NSW", tz: "Australia/Sydney", code: "AEST AEDT" },
    { state: "QLD", tz: "Australia/Brisbane", code: "AEST AEDT" },
    { state: "SA", tz: "Australia/Adelaide", code: "ACST ACDT" },
    { state: "TAS", tz: "Australia/Hobart", code: "AEST AEDT" },
    { state: "NT", tz: "Australia/Darwin", code: "ACST ACDT" },
    { state: "VIC", tz: "Australia/Melbourne", code: "AEST AEDT" },
    { state: "WA", tz: "Australia/Perth", code: "AWST AWDT" },
    { state: "ACT", tz: "Australia/Sydney", code: "AEST AEDT" },
  ];

  const currentTz = timezones.filter((obj) => obj.state === state);
  if (currentTz.length === 0) return "Australia/Melbourne";

  return currentTz[0].tz;
};
