import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, Select, MenuItem, Button, Switch, SelectChangeEvent, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatDate } from "../../utils/date";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import styles from "./MainNavigationHeader.module.css";
import { useHistory } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import DialogNewTestRequest from "../NewTestRequest/DialogNewTestRequest";
import EorderDialog from "../EorderDialog/EorderDialog";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import theme from "../theme";
import { EORDER_BLACKLIST, EORDER_FEATURE, getSiteOption, showEorderButton } from "../../utils/siteOptions";
import eOrderLogo from "../../assets/eOrderButton.svg";
import eOrderLogoVet from "../../assets/eOrderButtonVet.svg";
import eOrderLogoMobile from "../../assets/eOrderButtonMobile.svg";
import eOrderLogoVetMobile from "../../assets/eOrderButtonVetMobile.svg";
import { validateProvider } from "../../utils/provider";
import NotificationsMenu from "../NotificationsMenu/NotificationsMenu";
import Tooltips from "../common/Tooltip/Tooltip";
import { isTenantVet } from "../../services/tenantService";
import thm from "../theme";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: thm.colorSchemes.light.palette.primary.dark + " !important",
    color: isTenantVet() ? "#fff !important" : "#21262b !important",
    borderRadius: "20px !important",
    marginLeft: "10px !important",
    marginRight: "10px !important",
    padding: "5px 10px !important",
    fontSize: "1em !important",
    textTransform: "none",
    border: "solid 2px " + thm.colorSchemes.light.palette.primary.main + " !important",
    height: "32px",
    "&:hover": {
      backgroundColor: "grey !important",
      color: "white !important",
    },
  },
  eOrderButton: {
    backgroundColor: "#ffffff !important",
    borderRadius: "20px !important",
    marginLeft: "10px !important",
    marginRight: "10px !important",
    padding: "5px 10px !important",
    fontSize: "1em !important",
    textTransform: "none",
    border: "solid 2px " + thm.colorSchemes.light.palette.primary.main + " !important",
    height: "32px !important",
    "&:hover": {
      backgroundColor: thm.colorSchemes.light.palette.primary.light + " !important",
      color: "white !important",
    },
  },
  eOrderLogo: {
    width: "80px",
    margin: "-5px 0 -4px 0",
  },
  toggle: {
    fontSize: "0.8em",
  },
  text: {
    fontSize: "0.8em",
  },
  eOrderLogoMobile: {
    width: "45px",
    margin: "-5px 0 -4px 0",
  },
  buttonSmall: {
    backgroundColor: isTenantVet() ? "#3aae2a !important" : "#ff8000 !important",
    color: isTenantVet() ? "#fff !important" : "#21262b !important",
    borderRadius: "20px !important",
    padding: "5px !important",
    fontSize: "1em !important",
    textTransform: "none",
    border: "solid 2px #753bbd !important",
    height: "32px",
    minWidth: "auto",
    "&:hover": {
      backgroundColor: "grey !important",
      color: "white !important",
    },
  },
  eOrderButtonSmall: {
    backgroundColor: "#ffffff !important",
    borderRadius: "20px !important",
    marginTop: "-1px",
    marginLeft: "5px !important",
    padding: "5px 5px !important",
    fontSize: "1em !important",
    textTransform: "none",
    border: "solid 2px #753bbd !important",
    height: "32px !important",
    "&:hover": {
      backgroundColor: "#ede1f7 !important",
      color: "white !important",
    },
  },
}));

type MainNavigationHeaderProps = {
  showEorderButton?: boolean;
  subHeader?: string;
  showRefreshButton?: boolean;
  hideProviders?: boolean;
  isCpd?: boolean;
};

const MainNavigationHeader: React.FC<MainNavigationHeaderProps> = (props) => {
  const lastlogin: string = authService.getLastLogin();
  const isMobility = authService.isMobility();
  const isDesktop = useContext(AppContext).isDesktop;
  const history = useHistory();
  const classes = useStyles();
  const { autoRefreshResult, setAutoRefreshResult, setRefreshResult } = useContext(AppContext);
  const [provno, setProvNo] = useState(authService.getProviderSelected() ? authService.getProviderSelected() : "0");
  const [openNewTestRequest, setOpenNewTestRequest] = useState(false);
  const [openEorderDialog, setOpenEorderDialog] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [patient, setPatient] = React.useState<GetPatient_patient | undefined>();
  const [showGenEorderButton, setShowGenEorderButton] = React.useState(false);
  const eOrderFeature = (getSiteOption(EORDER_FEATURE) as string) === "ON";
  const siteOptions = getSiteOption(EORDER_BLACKLIST) as string[];
  const [isValidProvider, setIsValidProvider] = React.useState(false);
  const [cpdProvNo, setCpdProvNo] = useState(
    authService.getProviderSelected() ? authService.getCpdProviderSelected() : "0",
  );
  const whichProviders = props.isCpd ? authService.getCpdProviders() : authService.getProviders();

  const selectProviderNo = (event: SelectChangeEvent<string>) => {
    setProvNo(event.target.value as string);
    authService.setProviderSelected(event.target.value as string);
  };

  const selectCpdProviderNo = (event: SelectChangeEvent<string>) => {
    setCpdProvNo(event.target.value as string);
    authService.setCpdProviderSelected(event.target.value as string);
  };

  useEffect(() => {
    authService.getProviders().map((value) => {
      if (showEorderButton(siteOptions, value) && validateProvider(value as string)) {
        setShowGenEorderButton(true);
        setIsValidProvider(true);
      }
    });
  }, []);

  const onProviderChange = () => {
    history.go(0);
  };

  const handleClickNewTestReq = () => {
    setOpenNewTestRequest(true);
    console.log(ready);
  };

  const handleNewTestRequestClose = () => {
    setOpenNewTestRequest(false);
    setPatient(undefined);
  };

  const handleNewTestRequestContinue = () => {
    setOpenNewTestRequest(false);
    setPatient(undefined);
    setOpenEorderDialog(true);
  };

  const handleEorderDialogClose = () => {
    setOpenEorderDialog(false);
  };

  const handleNewTestRequestSelect = (patient: GetPatient_patient) => {
    setPatient(patient);
    setOpenNewTestRequest(false);
    setOpenEorderDialog(true);
  };

  const LastLoginEl = () => {
    return (
      <Box>
        <Typography
          display="inline"
          variant="body2"
          className={styles.headerSubDetailLabel}
          sx={{ ml: isDesktop ? 4 : 2 }}
        >
          Last Login:
        </Typography>
        <Typography variant="body2" display="inline" className={styles.headerSubDetail}>
          {formatDate(lastlogin, "DD MMM YYYY hh:mm A", false)}
        </Typography>
      </Box>
    );
  };

  const EOrderButton = () => {
    return (
      <>
        <Button
          className={isDesktop ? classes.eOrderButton : classes.eOrderButtonSmall}
          onClick={handleClickNewTestReq}
        >
          <img
            src={
              isTenantVet()
                ? isDesktop
                  ? eOrderLogoVet
                  : eOrderLogoVetMobile
                : isDesktop
                  ? eOrderLogo
                  : eOrderLogoMobile
            }
            alt="eorder logo"
            className={isDesktop ? classes.eOrderLogo : classes.eOrderLogoMobile}
          />
        </Button>
        {openNewTestRequest && (
          <DialogNewTestRequest
            open={openNewTestRequest}
            onClose={handleNewTestRequestClose}
            onContinue={handleNewTestRequestContinue}
            onSelect={handleNewTestRequestSelect}
          />
        )}
        <EorderDialog
          open={openEorderDialog}
          fullscreen={false}
          patient={patient}
          onClose={handleEorderDialogClose}
          setReady={setReady}
        />
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" justifyContent="space-between" flexDirection="row" flexWrap={"wrap"} width={"100%"}>
        {!props.hideProviders ? (
          <Box pr={[0, 2]} ml={2}>
            {whichProviders.length > 1 ? (
              <div style={props.isCpd ? { margin: "5px 0" } : {}}>
                <Typography display="inline" variant="body2" className={styles.headerSubDetailLabel}>
                  Providers:
                </Typography>
                <Select
                  id="providerNo"
                  value={props.isCpd ? cpdProvNo : provno}
                  onChange={props.isCpd ? selectCpdProviderNo : selectProviderNo}
                  name="providerNo"
                  className={`${isDesktop ? styles.selectInput : styles.selectInputMobile}`}
                  size="small"
                >
                  <MenuItem value={"0"} onClick={() => onProviderChange()}>
                    All
                  </MenuItem>
                  {whichProviders.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item} onClick={() => onProviderChange()}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Tooltips text="ProviderInfo" />
                {props.showEorderButton && eOrderFeature && showGenEorderButton && isValidProvider && <EOrderButton />}
              </div>
            ) : (
              <div>
                <Typography display="inline" variant="body2" className={styles.headerSubDetailLabel}>
                  Provider :
                </Typography>
                <Typography variant="body2" display="inline" className={styles.headerSubDetail}>
                  {whichProviders[0]}
                </Typography>
                {props.showEorderButton && eOrderFeature && showGenEorderButton && isValidProvider && <EOrderButton />}
              </div>
            )}
          </Box>
        ) : (
          <Box pr={[0, 2]} ml={2} flexGrow={0.2}></Box>
        )}

        {!isMobility && isDesktop && props.subHeader && (
          <Box pr={[0, 2]} ml={isDesktop ? 2 : 0} display="flex" justifyContent="center" alignItems="center">
            <Typography variant={props.isCpd ? "subtitle2" : "h4"} textTransform="uppercase">
              {props.subHeader}
            </Typography>
          </Box>
        )}
        <Box pr={[0, 2]} ml={isDesktop ? 2 : 0} display="flex" justifyContent="flex-end" alignItems="center">
          {props.showRefreshButton && !isMobility && isDesktop && (
            <span className={classes.text}>
              <Button
                className={classes.button}
                onClick={() => {
                  setRefreshResult(true);
                }}
              >
                Refresh Results
              </Button>
            </span>
          )}
          {props.showRefreshButton && !isMobility && !isDesktop && (
            <span className={classes.text}>
              <Button
                className={classes.buttonSmall}
                size="small"
                onClick={() => {
                  setRefreshResult(true);
                }}
              >
                <RefreshIcon />
              </Button>
            </span>
          )}
          {props.showRefreshButton && !isMobility && isDesktop && (
            <span className={classes.text}>
              Auto refresh results every 15 minutes:
              <Switch
                checked={autoRefreshResult}
                onChange={(_, checked) => {
                  setAutoRefreshResult(checked);
                  localStorage.setItem("AutoRefreshResult", checked.toString());
                }}
                name="autoRefresh"
                color="warning"
                className={styles.toggle}
                size="small"
              />
            </span>
          )}
          {!isMobility && isDesktop && <LastLoginEl />}
          <NotificationsMenu isDesktop={isDesktop} />
        </Box>
      </Box>
      {!(!isMobility && isDesktop) && (
        <Box display="block">
          <LastLoginEl />
        </Box>
      )}
    </ThemeProvider>
  );
};

export default MainNavigationHeader;
