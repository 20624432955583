import { gql } from "@apollo/client";

export default gql`
  query AddAtomicStatus($atomicId: Int!, $csStatus: Int!, $csUserId: Int!, $reportId: Int!) {
    addAtomicStatus(atomicId: $atomicId, csStatus: $csStatus, csUserId: $csUserId, reportId: $reportId) {
      atomicId
      csStatus
      csUserId
      csDatetime
      approval
      approverUserId
      approverDatetime
      comment
      csName
      noActionRequired
      actionRequired
      dCF
    }
  }
`;
