import { gql } from "@apollo/client";

export default gql`
  query GetAddOnTests($state: String!, $hours: Float!) {
    getAddOnTests(state: $state, hours: $hours) {
      testName
      tier1
      tier2
      tier3
    }
  }
`;
