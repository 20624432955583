import { gql } from "@apollo/client";

export default gql`
  query GetOptions {
    getOptions {
      paramname
      value
      type
    }
  }
`;
