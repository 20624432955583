import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  TextField,
  Dialog,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import styles from "./CreateHospitalUser.module.css";
import get from "lodash/get";
import { Formik } from "formik";
import { AddUser, AddUserVariables } from "./types/AddUser";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import AddUserMutation from "./AddUserMutation";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import MobileNumberField from "../common/InputField/MobileNumberField";

export type createHospitalUserInputValues = {
  username: string;
  name: string;
  password: string;
  email: string;
  sessionTimeout: number;
  sharedaccount: boolean;
  enabled: boolean;
  notificationEmails: { id: number; email: string; enabled: boolean }[];
  resultNotification: number;
  mobileNumber: string;
  notificationMobiles: { id: number; mobile: string; enabled: boolean }[];
};

interface CreateHospitalUserPageProps {
  open: boolean;
  fullscreen: boolean;
  onClose: () => void;
}

const CreateHospitalUserDialog: React.FC<CreateHospitalUserPageProps> = (props) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [emailNotifications, setEmailNotifications] = useState<{ id: number; email: string; enabled: boolean }[]>([]);
  const [mobileNotifications, setMobileNotifications] = useState<{ id: number; mobile: string; enabled: boolean }[]>(
    [],
  );
  const [hideme, setHideme] = useState("none");
  const [noEmailAsUsername, setNoEmailAsUsername] = useState(false);
  const [label, setLabel] = useState("Username (Email)");
  const [showPassword, setShowPassword] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [state, setState] = React.useState({
    nameid: "",
    name: "",
    passconfirm: "",
    email: "",
    sessionTimeout: 180,
    sharedaccount: false,
    enabled: enabled,
    notificationEmails: [],
    resultNotification: 1,
    mobileNumber: "",
    notificationMobiles: [],
  });

  const handleClose = () => {
    state.mobileNumber = "";
    state.passconfirm = "";
    setFeedbackMessage("");
    setEmailNotifications([]);
    setMobileNotifications([]);
    props.onClose();
  };

  const handleEmailAsUsernane = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changeState = state;
    if (event.target.checked) {
      setNoEmailAsUsername(true);
      setLabel("Email");
    } else {
      changeState.nameid = changeState.email.toUpperCase();
      setLabel("Username (Email)");
      setNoEmailAsUsername(false);
    }
  };

  const handleChangeSharedAccount = () => {
    state.sharedaccount = !state.sharedaccount ? true : false;
    setState({ ...state });
  };

  const handleChangeEnabled = () => {
    if (enabled) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  };

  const initialValues: createHospitalUserInputValues = {
    username: state.nameid,
    name: state.name,
    password: state.passconfirm,
    email: state.email,
    sessionTimeout: state.sessionTimeout,
    sharedaccount: state.sharedaccount,
    enabled: enabled,
    notificationEmails: emailNotifications,
    resultNotification: state.resultNotification,
    mobileNumber: state.mobileNumber,
    notificationMobiles: mobileNotifications,
  };

  const [createHospitalUser, { loading, error }] = useMutation<AddUser, AddUserVariables>(AddUserMutation);

  if (error) {
    console.error("Error", error);
  }

  const submit = () => {
    // eslint-disable-next-line no-console
    const username = noEmailAsUsername ? state.nameid : state.email;
    const name = state.name;
    const password = state.passconfirm;
    const email = state.email;
    const sessionTimeout = state.sessionTimeout;
    const sharedaccount = state.sharedaccount;
    const notificationEmails = emailNotifications;
    const resultNotification = state.resultNotification;
    const mobileNumber = state.mobileNumber ? state.mobileNumber.replace(/[^0-9]/g, "") : state.mobileNumber;
    const notificationMobiles = mobileNotifications;

    onSubmit(
      username,
      name,
      password,
      email,
      sessionTimeout,
      sharedaccount,
      enabled,
      notificationEmails,
      resultNotification,
      mobileNumber,
      notificationMobiles,
      createHospitalUser,
    );
  };

  const onSubmit = async (
    username: string,
    name: string,
    password: string,
    email: string,
    sessionTimeout: number,
    sharedAccount: boolean,
    enabled: boolean,
    notificationEmails: { id: number; email: string; enabled: boolean }[],
    resultNotification: number,
    mobileNumber: string,
    notificationMobiles: { id: number; mobile: string; enabled: boolean }[],
    createHospitalUser: MutationTuple<AddUser, AddUserVariables>[0],
  ) => {
    try {
      const result = await createHospitalUser({
        variables: {
          input: {
            username,
            name,
            email,
            password,
            sessionTimeout,
            sharedAccount,
            enabled,
            notificationEmails,
            resultNotification,
            mobileNumber,
            notificationMobiles,
          },
        },
      });

      if (get(result, "data.addUser.error")) {
        setFeedbackMessage(get(result, "data.addUser.message", ""));
      } else {
        setFeedbackMessage("User created!");
        setShowPassword(false);
        props.onClose();
        window.location.reload();
      }
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      }
    }
  };

  const handleEmailNotificationText = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    emailnotify[i].email = e.target.value.trim();
    setEmailNotifications(emailnotify);
  };

  const handleDeleteEmailNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const emailnotify = [...emailNotifications.slice(0, i), ...emailNotifications.slice(i + 1)];
    setEmailNotifications(emailnotify);
  };

  const handleAddEmailNotification = (e: any) => {
    e.preventDefault();
    if (hideme === "block") {
      const emailnotify = emailNotifications.concat([{ id: 0, email: "", enabled: true }]);
      setEmailNotifications(emailnotify);
    }

    setHideme("block");
  };

  const handleEmailNotificationChecked = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    const updatedEmailNotify = emailnotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setEmailNotifications(updatedEmailNotify);
  };

  const handleMobileNotificationText = (i: any, value: any) => {
    const mobileNotify = [...mobileNotifications];
    const onlyNums = value.replace(/[^0-9]/g, "");

    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, mobile: onlyNums.trim() } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  const handleDeleteMobileNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const mobileNotify = [...mobileNotifications.slice(0, i), ...mobileNotifications.slice(i + 1)];
    setMobileNotifications(mobileNotify);
  };

  const handleAddMobileNotification = (e: any) => {
    e.preventDefault();
    if (hideme === "block") {
      const mobileNotify = mobileNotifications.concat([{ id: 0, mobile: "", enabled: true }]);
      setMobileNotifications(mobileNotify);
    }

    setHideme("block");
  };

  const handleMobileNotificationChecked = (i: any) => (e: any) => {
    const mobileNotify = [...mobileNotifications];
    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="new-password">
            <DialogTitle>
              <DialogBoxTitle title="Add User" width={200} marginLeft={-3} closeButton handleClose={handleClose} />
            </DialogTitle>

            <DialogContent>
              <Box m={3}>
                <TextField
                  margin="dense"
                  id="email"
                  name="nameid"
                  label={label}
                  type="email"
                  fullWidth
                  variant="outlined"
                  required={!noEmailAsUsername ? true : false}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={(event) => {
                    const { value } = event.target;
                    const changeState = state;
                    if (!noEmailAsUsername) changeState.nameid = value.toUpperCase();
                    changeState.email = value;
                    setState(changeState);
                  }}
                  autoComplete="new-password"
                />
                <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                  Do not use email as username{" "}
                  <Checkbox name="emailAsUsername" color="primary" onChange={handleEmailAsUsernane} />
                </Box>

                {noEmailAsUsername ? (
                  <Box>
                    <TextField
                      margin="dense"
                      id="nameid"
                      name="nameid"
                      label="Username"
                      type="text"
                      fullWidth
                      variant="outlined"
                      required={noEmailAsUsername ? true : false}
                      onChange={(event) => {
                        const { value } = event.target;
                        const changeState = state;

                        changeState.nameid = value.toUpperCase();

                        setState(changeState);
                      }}
                      autoComplete="new-password"
                    />
                  </Box>
                ) : (
                  ""
                )}
                <TextField
                  margin="dense"
                  id="name"
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => {
                    const { value } = event.target;
                    const changeState = state;
                    changeState.name = value;
                    setState(changeState);
                  }}
                  autoComplete="new-password"
                />
                <TextField
                  margin="dense"
                  id="passconfirm"
                  name="passconfirm"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  autoComplete="new-password"
                  variant="outlined"
                  required={false}
                  defaultValue={state.passconfirm}
                  onChange={(event) => {
                    const { value } = event.target;
                    const changeState = state;
                    changeState.passconfirm = value;
                    setState(changeState);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          if (state.passconfirm.trim() === "") {
                            setShowPassword(false);
                          } else {
                            setShowPassword(showPassword ? false : true);
                          }
                        }}
                        onMouseDown={(event: any) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                <MobileNumberField
                  initState={initialValues.mobileNumber}
                  helperText={touched.mobileNumber && errors.mobileNumber}
                  inputChange={(value: any) => {
                    state.mobileNumber = value;
                  }}
                  error={!!errors.mobileNumber && touched.mobileNumber}
                />
                <Box className={styles.notifyEmailHeader}>
                  <Typography>Include additional email address to receive result notification</Typography>
                </Box>
                <Box className={styles.notifyEmailBox}>
                  <Button
                    variant="contained"
                    size="small"
                    className={styles.button}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddEmailNotification}
                  >
                    Add Email
                  </Button>
                  <br />
                  <table className={styles.fullWidthTable}>
                    <tbody>
                      {emailNotifications.map((emailNotification, index) => (
                        <tr key={index}>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <TextField
                              key={"email-" + index}
                              label="Email"
                              placeholder="Input Email"
                              variant="outlined"
                              required={emailNotifications.length > 0 ? true : false}
                              className={styles.specialInput}
                              onChange={handleEmailNotificationText(index)}
                              size="small"
                              value={emailNotification.email}
                              type="email"
                            />
                          </td>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <IconButton
                              color="secondary"
                              aria-label="Delete Email"
                              component="span"
                              size="small"
                              onClick={handleDeleteEmailNotification(index)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </td>
                          <td>
                            <Checkbox
                              key={"enabled-" + index}
                              color="primary"
                              checked={emailNotification.enabled ? emailNotification.enabled : false}
                              onChange={handleEmailNotificationChecked(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <Box className={styles.notifyEmailHeader} mt={1}>
                  <Typography>Include additional mobile number(s) to receive result notification</Typography>
                </Box>
                <Box className={styles.notifyEmailBox}>
                  <Button
                    variant="contained"
                    size="small"
                    className={styles.button}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddMobileNotification}
                  >
                    Add Mobile Number
                  </Button>
                  <br />
                  <table className={styles.fullWidthTable}>
                    <tbody>
                      {mobileNotifications.map((mobileNotification, index) => (
                        <tr key={index}>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <MobileNumberField
                              key={"mobile-" + index}
                              id={"mobile-" + index}
                              error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                              helperText={touched.mobileNumber && errors.mobileNumber}
                              inputChange={(value: any) => {
                                handleMobileNotificationText(index, value);
                              }}
                              initState={mobileNotification.mobile}
                              required={mobileNotifications.length > 0 ? true : false}
                            />
                          </td>
                          <td className={styles.fullWidthTableColumnPadding}>
                            <IconButton
                              color="secondary"
                              aria-label="Delete Mobile"
                              component="span"
                              size="small"
                              onClick={handleDeleteMobileNotification(index)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </td>
                          <td>
                            <Checkbox
                              key={"enabled-" + index}
                              color="primary"
                              checked={mobileNotification.enabled ? mobileNotification.enabled : false}
                              onChange={handleMobileNotificationChecked(index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                  Shared Account <Checkbox name="sharedaccount" color="primary" onChange={handleChangeSharedAccount} />
                </Box>
                <Box display="flex" justifyContent="space-between" align-content="strech">
                  Enabled <Checkbox name="enabled" color="primary" onChange={handleChangeEnabled} />
                </Box>
              </Box>
              <Box ml={1}>
                {error &&
                  error.graphQLErrors.map(({ message }, i) => (
                    <Typography key={i} color="error">
                      {message}
                    </Typography>
                  ))}
              </Box>
              <Box m={3}>
                <Typography className={styles.MenuItem2}>{feedbackMessage}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box ml={3} mb={3} display="flex" justifyContent="space-between" align-content="strech">
                <Button
                  className={styles.spaceRight}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateHospitalUserDialog;
