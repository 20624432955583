import React, { Fragment, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styles from "../LoginPage/LoginPage.module.css";
import { Link as RouterLink } from "react-router-dom";
import { Box, TextField, Link, Button, Typography, CircularProgress } from "@mui/material";
import MainNavigation from "../MainNavigation/MainNavigation";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import { useLazyQuery } from "@apollo/client";
import ForgotPasswordQuery from "./ForgotPasswordQuery";
import { ForgotPasswordQuery as ForgotPasswordToken } from "./types/ForgotPasswordQuery";
import { Formik } from "formik";
import authService from "../../services/authService";

type ForgotPasswordFormValues = {
  email: string;
};

type ForgotPasswordFormErrors = {
  email?: boolean;
};

const ForgotPasswordPage: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;
  const EXPIRES_IN = "1 hour";
  const initialValues: ForgotPasswordFormValues = { email: "" };
  const [email, setEmail] = useState<string>("");

  const [getForgotPasswordQuery, { data, loading, error }] = useLazyQuery<ForgotPasswordToken>(ForgotPasswordQuery, {
    variables: {
      email: "",
      queueEmail: false,
      useragent: "",
      expiresIn: EXPIRES_IN,
    },
  });

  const validate = (values: ForgotPasswordFormValues) => {
    const errors: ForgotPasswordFormErrors = {};
    if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = true;
    }
    return errors;
  };

  useEffect(() => {
    if (data && data.userToken) {
      history.push(`/check-email/${email}`);
    }
    if (error) {
      if (
        error.graphQLErrors &&
        error.graphQLErrors.find((m) => m.message === "Cannot Reset Password - Shared Account") !== undefined
      ) {
        authService.setSharedAccount(true);
      } else {
        authService.clearSharedAccount();
      }
      history.push(`/contact-support`);
    }
    //eslint-disable-next-line
  }, [data, error]);

  const submit = (values: ForgotPasswordFormValues) => {
    setEmail(values.email);
    const useragent = navigator.userAgent;
    getForgotPasswordQuery({
      variables: {
        email: values.email,
        queueEmail: true,
        useragent: useragent,
        expiresIn: EXPIRES_IN,
      },
    });
  };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <AuthLayout
        title="Forgot Password?"
        maxWidth={"490px"}
        footer={
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              By logging in you agree to the{" "}
              <Link
                className={styles.terms}
                color="textSecondary"
                href="https://ehealth.clinicallabs.com.au/terms_conditions.php"
                target="_blank"
                rel="noopener"
              >
                Terms &amp; Conditions
              </Link>
            </Typography>
          </Box>
        }
      >
        <Box pt={3}>
          <Typography variant="subtitle1" color="textSecondary">
            Enter your <b>email address</b> to help us identify you.
          </Typography>
        </Box>
        <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
          {({ errors, handleChange, handleBlur, handleSubmit, touched }) => (
            <form onSubmit={handleSubmit}>
              <Box my={4}>
                <TextField
                  id="email"
                  name="email"
                  fullWidth
                  label="Email"
                  variant="outlined"
                  onChange={handleChange}
                  error={!!errors.email && touched.email}
                  onBlur={handleBlur}
                ></TextField>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Link color="primary" component={RouterLink} to="/login">
                  Return to sign in
                </Link>
                <Button disabled={loading} type="submit" color="primary" variant="contained">
                  {loading ? (
                    <Box width={"100%"} textAlign="center">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    <span>Next</span>
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </AuthLayout>
    </Fragment>
  );
};

export default withRouter(ForgotPasswordPage);
