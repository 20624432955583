import React, { useContext } from "react";
import { Box, Paper, Typography, Grid, Divider } from "@mui/material";
import classes from "./Announcement.module.css";
import AppContext from "../../context/AppContext";
import { getQueryAnnouncement } from "./types/getQueryAnnouncement";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { formatDate } from "../../utils/date";

type AnnouncementProps = {
  data: getQueryAnnouncement;
  showMinimal?: boolean;
};

const Announcement: React.FC<AnnouncementProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;

  if (props.data && props.data.announcement) {
    return (
      <Box className={!props.showMinimal ? (isDesktop ? classes.layouts : classes.layoutsmobile) : ""}>
        <Paper elevation={3} className={isDesktop ? classes.paperlayout : classes.paperlayoutmobile}>
          {!props.showMinimal && (
            <React.Fragment>
              <Box py={1} pl={[2, 4]}>
                <Typography variant="h4" color="primary">
                  <NotificationsIcon className={classes.notiIcon} />
                  <b>Announcements</b>
                </Typography>
              </Box>
              <Divider className={classes.divider} />
            </React.Fragment>
          )}
          {props.data.announcement.announce.map((ep, index) => (
            <Box key={index}>
              <Grid container key={index}>
                <Grid item xs={12} className={classes.annBox}>
                  <Typography className={classes.annTitle}>
                    <b>{ep.subject}</b>
                  </Typography>
                  <Typography className={classes.annDate}>
                    Posted on {formatDate(ep.post_date, "D-MMM-YYYY", true)}
                  </Typography>
                  <span dangerouslySetInnerHTML={{ __html: ep.content }}></span>
                </Grid>
                <Divider className={classes.divider} />
              </Grid>
            </Box>
          ))}
        </Paper>
      </Box>
    );
  } else {
    return <Box></Box>;
  }
};

export default Announcement;
