import React, { useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress, Checkbox } from "@mui/material";
import OtpInput from "react18-input-otp";
import styles from "../MFAEnrolment/MFAEnrolment.module.css";
import authService from "../../services/authService";

import { TRUST_BROWSER_PERIOD, getSiteOption } from "../../utils/siteOptions";

interface MFAInputProps {
  mfaId: number;
  providers?: any;
  otpCode: any;
  loading: any;
  isTrustBrowser: boolean;
  handleAuthentication: (mfaId: number, otp: string, trustBrowser: boolean) => void;
  handleCodeAuthText: (otp: string, digit: number, mfaId: number, trustBrowser: boolean) => void;
}

const MFAInput: React.FC<MFAInputProps> = (props) => {
  const emailMask = (email: string) => {
    const maskedEmail = email.replace(/([^@.])/g, "*").split("");

    let previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous === "." || previous === "@") {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join("");
  };

  const [trustBrowser, setTrustBrowser] = useState(false);

  const mobileNumberMask = (mobile: string) => {
    return "04** *** " + mobile.substring(mobile.length - 3);
  };

  const trustBrowserCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrustBrowser(event.target.checked);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        {props.mfaId === 2 ? (
          <Typography>Enter a security code from your Authenticator app.</Typography>
        ) : (
          <>
            {props.mfaId === 3 ? (
              <>
                <Typography>
                  An email with the security code has been sent to {emailMask(authService.getPrimaryEmail())}.{" "}
                </Typography>
                <Typography>Please wait for the email to arrive.</Typography>
                <Typography> Note: Check your junk folder in case the email was marked as spam. </Typography>{" "}
              </>
            ) : (
              <>
                <Typography>
                  An SMS with the security code has been sent to {mobileNumberMask(authService.getMobileNumber())}.
                </Typography>
                <Typography>Please wait for the SMS to arrive.</Typography>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="center">
          <OtpInput
            isDisabled={props.loading}
            key={props.mfaId}
            value={props.otpCode}
            onChange={(otp: any) => {
              props.handleCodeAuthText(otp, props.providers?.digits || 6, props.mfaId, trustBrowser);
            }}
            numInputs={props.providers?.digits || 6}
            separator={<span>-</span>}
            inputStyle={styles.inputStyle}
            isInputNum
            shouldAutoFocus={true}
          />
        </Box>
      </Grid>
      <Grid>
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={styles.button}
          onClick={() => props.handleAuthentication(props.mfaId, props.otpCode, trustBrowser)}
          disabled={props.otpCode.length !== props.providers?.digits || props.loading}
        >
          {props.loading ? (
            <Box width={"100%"} textAlign="center">
              <CircularProgress size={16} />
            </Box>
          ) : (
            <span>Verify</span>
          )}
        </Button>
      </Grid>
      {props.isTrustBrowser && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Box mt={1} justifyContent="center">
            <Checkbox
              name="trustBrowser"
              color="primary"
              disabled={false}
              onChange={trustBrowserCheckbox}
              checked={trustBrowser}
            />
            <span>Trust this browser for {getSiteOption(TRUST_BROWSER_PERIOD)} days</span>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default MFAInput;
