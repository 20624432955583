import { gql } from "@apollo/client";

export default gql`
  query GetCytologyPatientDemographic($id: Int!, $providers: [String!]!) {
    getCytologyPatientDemographic(id: $id, providers: $providers) {
      doctorCode
      patientAgeGroup
      non1618
      hpv1618
    }
  }
`;
