import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetPrintableCombineCtAtomic,
  GetPrintableCombineCtAtomicVariables,
  GetPrintableCombineCtAtomic_patient as ctAtomicPatient,
  GetPrintableCombineCtAtomic_ctcombineAtomic as ctAtomicData,
} from "../components/PrintableReports/types/GetPrintableCombineCtAtomic";
import getPrintableCtAtomicReport from "../components/PrintableReports/GetPrintableCombineCtAtomicQuery";
import * as Constants from "../constants";
import LoadingWithLabel from "../components/Loading/LoadingWithLabel";
import { SearchEpisodes_episodesSearchResult_episodes } from "../components/PatientEpisodesPage/types/SearchEpisodes";
import { DownloadMenuIcon, PrintMenuIcon } from "../components/PatientReportsFull/PrintMenu/PrintComponents";
import ErrorPopover from "../components/common/ErrorPopover/ErrorPopover";
import axios from "axios";
import authService from "../services/authService";
import {
  UpdatePanelStatusPrinted,
  UpdatePanelStatusPrintedVariables,
} from "../components/PatientReportsFull/types/UpdatePanelStatusPrinted";
import updatePanelStatusPrintedMuation from "../components/PatientReportsFull/updatePanelStatusPrintedMutation";
import { CheckedEpisode } from "../components/PatientEpisodesPage/EpisodeListPage";
import { cloneDeep, get } from "lodash";
import {
  GetPrintableAuditLog,
  GetPrintableAuditLogVariables,
} from "../components/PrintableReports/types/GetPrintableAuditLog";
import getPrintableAuditLogReport from "../components/PrintableReports/GetPrintableAuditLogQuery";
import { GetPrintableAuditLog_getAuditLogs as ctAuditLogs } from "../components/PrintableReports/types/GetPrintableAuditLog";
import { useHistory } from "react-router-dom";
import {
  GenerateDataToken,
  GenerateDataTokenVariables,
} from "../components/PatientEpisodesPage/types/GenerateDataToken";
import GenerateDataTokenMutation from "../components/PatientEpisodesPage/GenerateDataTokenMutation";
import { downloadHelper } from "./downloadHelper";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

type CtReportDataProps = {
  episodes: CheckedEpisode[];
  setEpisodes: React.Dispatch<React.SetStateAction<CheckedEpisode[]>>;
  isLoading: boolean;
  mode: "print" | "download";
};

const CtReports: React.FC<CtReportDataProps> = (props) => {
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState<number | undefined>(0);
  const mobility = authService.isMobility();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const queryCombineCtAtomicVariables = (patientLabNumber: string): GetPrintableCombineCtAtomicVariables => {
    const patientId = parseInt(patientLabNumber.split("~")[0]);
    const labnoDigitOnly = patientLabNumber.split("~")[1];
    const episode = (props.episodes as SearchEpisodes_episodesSearchResult_episodes[]).find(
      (e) => e.patientid === patientId && e.labnoDigitOnly === labnoDigitOnly,
    );
    const panels = episode && episode.panels;
    const ids = (panels && panels.map((p) => p.reportid)) || [];
    return {
      patientId: patientId,
      labnoDigitOnly: labnoDigitOnly,
      ids: ids,
      queryAction: props.mode === "download" ? Constants.AuditAction.DOWNLOAD : Constants.AuditAction.PRINT_COMPRESSED,
    };
  };

  const queryAuditLogVariables = (reportIds: number[], labNoDigitOnly: string): GetPrintableAuditLogVariables => {
    return {
      action: "CLINTRIAL",
      reportIds,
      labNoDigitOnly,
      auditLog: true,
    };
  };

  const [getPrintableCtAtomicReportQuery, { error: cterror, loading: ctloading }] =
    useLazyQuery<GetPrintableCombineCtAtomic>(getPrintableCtAtomicReport, {
      variables: queryCombineCtAtomicVariables(""),
    });

  const [getPrintableAuditLogReportQuery, { error: alerror, loading: alloading }] = useLazyQuery<GetPrintableAuditLog>(
    getPrintableAuditLogReport,
    {
      variables: queryAuditLogVariables([], ""),
    },
  );

  if (cterror || alerror) {
    const errorMessage = Constants.NO_DB_CONNECTION;
    setErrorMessage(errorMessage);
  }

  const [updatePanelStatusPrinted] = useMutation<UpdatePanelStatusPrinted, UpdatePanelStatusPrintedVariables>(
    updatePanelStatusPrintedMuation,
  );

  const updateEpisodesPrinted = async (reportIds: number[]) => {
    const userId = parseInt(authService.getUserId());
    updatePanelStatusPrinted({
      variables: {
        input: {
          reportIds,
          userId,
        },
      },
    }).then(async (result: any) => {
      if (result && result.data) {
        if (result.data.updatePanelStatusPrinted) {
          if (result.data.updatePanelStatusPrinted.length > 0) {
            const newEpisodes = cloneDeep(props.episodes);
            result.data.updatePanelStatusPrinted.forEach((approveData: any) => {
              const panelApprovalStatus = approveData.csPanelApprovalStatus
                ? approveData.csPanelApprovalStatus
                : Constants.APPROVAL_STATUS.Printed;

              if (panelApprovalStatus === Constants.APPROVAL_STATUS.Printed) {
                const episode = newEpisodes.find((e) => {
                  return e.labnoDigitOnly === approveData.reportLabNumberDigitOnly;
                });
                if (episode) {
                  episode.status = "Printed";
                }
              }
            });

            props.setEpisodes(newEpisodes);
          }
        }
      }
    });
  };

  const receiveMessage = (event: any): void => {
    if (event.data && event.data === "eResults2.0 Print Ready") {
      window.removeEventListener("message", receiveMessage, false);
      setIsReportLoading(false);
    }
  };

  const [generateDataToken] = useMutation<GenerateDataToken, GenerateDataTokenVariables>(GenerateDataTokenMutation);

  const reportsHandler = async () => {
    if (props.episodes.filter((e) => e.checked).length) {
      const downloadReportData = new Array<any>();
      setLoadingPercent(0);
      const totalCount = props.episodes.filter((e) => e.checked).length;
      let currentCount = 0;
      let reportIds: number[] = [];
      for (const patientId of props.episodes.filter((e) => e.checked)) {
        const variables = queryCombineCtAtomicVariables(patientId.patientid + "~" + patientId.labnoDigitOnly);
        const ctdata = await getPrintableCtAtomicReportQuery({
          variables: variables,
          fetchPolicy: "network-only",
        });
        const hasValidCtData = (data: GetPrintableCombineCtAtomic | undefined) =>
          data && data.ctcombineAtomic && data.patient;

        if (variables.ids) {
          reportIds = reportIds.concat(variables.ids);
        }

        if (hasValidCtData(ctdata.data) && patientId.labnoDigitOnly && variables.ids) {
          const alvariables = queryAuditLogVariables(variables.ids, patientId.labnoDigitOnly);
          const aldata = await getPrintableAuditLogReportQuery({
            variables: alvariables,
            fetchPolicy: "network-only",
          });

          const ctAtomicResults = get(ctdata.data, "ctcombineAtomic") || ({} as ctAtomicData);
          const ctatomicPatient: ctAtomicPatient = get(ctdata.data, "patient") || ({} as ctAtomicPatient);
          const userHospital = get(ctdata.data, "getUserHospital");

          const ctAuditLog: any = {};
          ctAuditLog.auditlog = true;
          if (aldata.data) {
            const ctauditlogs: ctAuditLogs[] = get(aldata.data, "getAuditLogs" || ({} as ctAuditLogs));
            ctAuditLog.auditlogs = ctauditlogs;
          }

          const downRepData: any = {};
          downRepData.reportDisclaimer = userHospital?.reportDisclaimer ? userHospital.reportDisclaimer : "";
          downRepData.patient = ctatomicPatient;
          downRepData.reports = ctAtomicResults;
          downRepData.auditlogs = ctAuditLog;
          downloadReportData.push(downRepData);

          currentCount++;
          setLoadingPercent((currentCount / totalCount) * 100);
        }
      }

      if (props.mode === "print") {
        setIsReportLoading(true);
        window.addEventListener("message", receiveMessage, false);

        const useDelegation = false;
        let tokenValue: any;
        if (useDelegation) {
          tokenValue = await generateDataToken({
            variables: {
              input: {
                data: JSON.stringify({ reportData: downloadReportData }),
              },
            },
            fetchPolicy: "network-only",
          });
        } else {
          authService.setReportsBlob({ reportData: downloadReportData });
          tokenValue = { data: { generateDataToken: "session-storage" } };
        }

        if (!authService.fetchToken()?.accessToken) {
          history.push("/login", { from: history.location });
        } else {
          if (tokenValue && tokenValue.data) {
            setTimeout(() => {
              //Marked all report id as viewed..
              //   setPrint(true);
              updateEpisodesPrinted(reportIds);

              const iframe: any = document.querySelector("#printFrame");
              iframe.src = `/combine-atomic-delegate-print/patients?token=${tokenValue.data.generateDataToken}&frame=printFrame`;
            }, 200);
          } else {
            setIsReportLoading(false);
          }
        }
      } else if (props.mode === "download") {
        setIsReportLoading(true);
        setLoadingPercent(undefined);
        const url = `${process.env.REACT_APP_ACL_REPORT_URL}/ctreport`;

        axios
          .post<any>(
            url,
            {
              output: "pdf",
              data: {
                tz: dayjs.tz.guess(),
                orders: downloadReportData,
              },
              template: "ctreport-template.html",
            },
            { responseType: "blob" },
          )
          .then((response) => {
            downloadHelper(response.data, `reports-combined.pdf`, true);
            setIsReportLoading(false);
            setErrorMessage("");
            updateEpisodesPrinted(reportIds);
          })
          .catch(() => {
            setIsReportLoading(false);
            setErrorMessage(
              "Your request was not completed. There was a problem with your request. Please wait a few minutes or select fewer reports and try again.  If the problem persists contact us on ehealth.helpdesk@clinicallabs.com.au.",
            );
          });
      }
    }
  };

  return (
    <>
      {props.mode === "print" ? (
        !mobility ? (
          <>
            {isReportLoading || ctloading || alloading ? (
              <LoadingWithLabel size={24} value={loadingPercent} />
            ) : (
              <PrintMenuIcon
                onClick={reportsHandler}
                title={"Print Selected"}
                disabled={props.isLoading || props.episodes.filter((e) => e.checked).length === 0}
                alwaysLargeIcon
              />
            )}
          </>
        ) : null
      ) : isReportLoading || ctloading || alloading ? (
        <LoadingWithLabel size={24} value={loadingPercent} />
      ) : (
        <DownloadMenuIcon
          onClick={reportsHandler}
          title={"Download Selected"}
          disabled={props.isLoading || props.episodes.filter((e) => e.checked).length === 0}
          alwaysLargeIcon
        />
      )}
      {errorMessage && <ErrorPopover errorMessage={errorMessage} />}
    </>
  );
};

export default CtReports;
