import React from "react";
import styles from "./CommonHeader.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import authService from "../../services/authService";
import { Box, Typography } from "@mui/material";

const GoBackButton: React.FC<{ backText: string }> = ({ backText }) => {
  const mobility = authService.isMobility();
  return (
    <Box className={styles.backButton}>
      <Typography variant="body2" sx={{ display: { xs: "none", sm: "block" } }}>
        <ArrowBackIosIcon className={styles.iconDesktop} />
        {backText}
      </Typography>

      <ArrowBackIosIcon
        fontSize="small"
        className={mobility ? styles.iconMobileMobility : styles.iconMobile}
        sx={{ display: { sm: "none", xs: "block" } }}
      />
    </Box>
  );
};

export default GoBackButton;
