import React, { useContext, useRef } from "react";
import StudyQuery from "./StudyQuery";
import { useMutation, useQuery } from "@apollo/client";
import { GetStudy } from "./types/GetStudy";
import { DataGrid, GridCellParams, GridColDef, GridRowId } from "@mui/x-data-grid";
import styles from "./Study.module.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { DeleteStudy, DeleteStudyVariables } from "./types/DeleteStudy";
import { CreateStudy, CreateStudyVariables } from "./types/CreateStudy";
import { UpdateStudy, UpdateStudyVariables } from "./types/UpdateStudy";
import DeleteStudyMutation from "./DeleteStudy";
import CreateStudyMutation from "./CreateStudy";
import UpdateStudyMutation from "./UpdateStudy";
import { Alert } from "@mui/material";
import authService from "../../services/authService";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import AppContext from "../../context/AppContext";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";

const StudyTable: React.FC<any> = () => {
  const provider: string[] = authService.getProviders();
  const [providerMik, setProviderMik] = React.useState<string>("");
  const [selectedId, setSelectedId] = React.useState<number>();
  const [isAbleDelete, SetIsAbleDelete] = React.useState<boolean>(false);
  const [confirmDefualtOpen, SetconfirmDefualtOpen] = React.useState<boolean>(false);
  const [confirmCreateDefualtOpen, SetconfirmCreateDefualtOpen] = React.useState<boolean>(false);
  const [isOverideDefault, setOverideDefault] = React.useState<boolean>(false);
  const [updateMode, setUpdateMode] = React.useState<string>("");
  const [rows, setRows] = React.useState<any>();
  const [reqError, setReqError] = React.useState<string>("");
  const [formError, setFormError] = React.useState<string>("");
  const [updateSelectedRow] = React.useState<any>();
  const [updateSelectedColumn, setUpdateSelectedColumn] = React.useState<any>();
  const { data, refetch } = useQuery<GetStudy>(StudyQuery, {
    variables: { providerNumber: provider },
  });
  const [open, setOpen] = React.useState(false);
  const didMount = React.useRef(false);
  const columns: GridColDef[] = [
    { field: "id", headerName: "id", width: 180 },
    { field: "study_code", headerName: "Study Code", width: 180 },
    { field: "provider_number", headerName: "Provider Number", width: 150 },
    { field: "CRC", headerName: "CRC", flex: 1, editable: true },
    { field: "SCRC", headerName: "SCRC", flex: 1, editable: true },
    { field: "PI", headerName: "PI", flex: 1, editable: true },
  ];

  const handleModalClick = async () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
  };
  const [curObj, setCurObj] = React.useState({ provider_number: "", study_code: "", CRC: "", SCRC: "", PI: "" });
  const [deleteDialogOpen, setDeleteOpen] = React.useState(false);
  const [deleteStudyMutation] = useMutation<DeleteStudy, DeleteStudyVariables>(DeleteStudyMutation);
  const [createStudyMutation] = useMutation<CreateStudy, CreateStudyVariables>(CreateStudyMutation);
  const [updateStudyMutation] = useMutation<UpdateStudy, UpdateStudyVariables>(UpdateStudyMutation);
  const { isDesktop } = useContext(AppContext);
  const hideButton = false;
  const useStyles = makeStyles((theme: Theme) => ({
    gridTable: {
      "& .cellUpdating": {
        backgroundColor: "rgba(224, 183, 60, 0.55)",
        color: "#1a3e72",
        fontWeight: "600",
      },
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
  }));

  const classes = useStyles();

  const closeDeleteDialog = () => {
    setDeleteOpen(false);
  };
  const deleteItem = async () => {
    if (!selectedId) {
      return;
    }
    await deleteStudyMutation({
      variables: {
        input: {
          study_id: Number(selectedId),
        },
      },
    }).then(() => {
      const newrows = rows.filter((r: { id: number }) => r.id != selectedId);
      setRows(newrows);
      closeDeleteDialog();
    });
    return null;
  };

  const updatingCells = useRef<{ rowId: number; cellName: string }[]>([]);

  const handleCellClass = (params: GridCellParams<any>) => {
    if (updatingCells.current.length > 0) {
      if (
        updatingCells.current.filter((item) => !(item.rowId == params.id && item.cellName == params.field)).length <= 0
      ) {
        return "cellUpdating";
      }
    }
    return "";
  };
  const handleCellCommit = async (params: any) => {
    if (params.value == params.formattedValue || !params.value || params.value == "") {
      return;
    }
    if (params.field.trim().toLowerCase() == "default") {
      setUpdateMode("update");
      SetconfirmDefualtOpen(true);
    } else {
      updatingCells.current.push({ rowId: Number(params.id), cellName: params.field });
      const variable: any = {};
      variable["study_id"] = Number(params.row.id);
      variable[params.field] = params.value;
      await updateStudyMutation({
        variables: {
          input: {
            ...variable,
          },
        },
      }).then(async () => {
        updatingCells.current = updatingCells.current.filter(
          (item) => !(item.rowId == Number(params.row.id) && item.cellName == params.field),
        );
      });
    }
  };

  const selectProviderMik = (event: SelectChangeEvent<string>) => {
    setProviderMik(event.target.value as string);
    formik.values.providerMik = event.target.value as string;
    formik.validateForm();
  };

  const getCurRow = (selectedItemId: GridRowId) => {
    if (!selectedItemId) {
      return null;
    }
    const cur = rows.find((obj: { id: GridRowId }) => {
      return obj.id == selectedItemId;
    });
    setCurObj(cur);
    return cur;
  };
  const handleSelected = (selectedItem: any) => {
    setSelectedId(selectedItem[0]);
    const curRow = getCurRow(selectedItem[0]);
    if (!curRow) {
      return;
    }
    if (curRow.study_code.trim().toLowerCase() == "default") {
      SetIsAbleDelete(true);
    } else {
      SetIsAbleDelete(false);
    }
  };
  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const updateEmail = async () => {
    const updateObj: any = {
      study_id: updateSelectedRow.id,
      DefaultOveride: isOverideDefault,
    };
    updateObj[updateSelectedColumn] = updateSelectedRow[updateSelectedColumn];
    await updateStudyMutation({
      variables: {
        input: updateObj,
      },
    }).then(async () => {
      const newRow = await refetch();
      setRows(newRow.data.getStudy);
      handleModalCancel();
    });
  };
  const handleOverideDefualt = async () => {
    setOverideDefault(true);
    SetconfirmDefualtOpen(false);
  };
  const handleIgnoreDefualt = async () => {
    setOverideDefault(false);
    SetconfirmDefualtOpen(false);
    updateEmail();
  };

  const openCreateConfirmBox = () => {
    let err = "";
    if (!formik.values.studyCodeMik) {
      err = "Please enter the Study Code.";
    }
    if (!formik.values.providerMik) {
      err = "Please select the provier number.";
    }
    if (!formik.values.CRCMik && !formik.values.SCRCMik && !formik.values.PIMik) {
      err = "please enter at least one email address.";
    }
    if (err) {
      setFormError(err);
      return;
    }
    if (formik.values.studyCodeMik.trim().toLowerCase() == "default") {
      SetconfirmCreateDefualtOpen(true);
    } else {
      formik.submitForm();
    }
  };
  const closeCreateConfirmBox = () => {
    SetconfirmCreateDefualtOpen(false);
  };
  const handleCreateOverideDefualt = async () => {
    setUpdateMode("create");
    setOverideDefault(true);
    closeCreateConfirmBox();
  };

  const handleCreateIgnoreDefualt = async () => {
    setOverideDefault(false);
    formik.submitForm();
    closeCreateConfirmBox();
  };

  const validate = (values: {
    providerMik: string;
    studyCodeMik: string;
    CRCMik: string;
    SCRCMik: string;
    PIMik: string;
  }) => {
    let error;
    //check duplicate default.
    if (
      rows.filter(
        (r: { provider_number: any; study_code: string }) =>
          r.study_code == values.studyCodeMik && r.provider_number === values.providerMik,
      ).length > 0
    ) {
      setFormError("Study Code is exist!");
    } else if (
      rows.filter(
        (r: { provider_number: any; study_code: string }) =>
          values.studyCodeMik.trim().toLowerCase() == "default" &&
          r.provider_number == values.providerMik &&
          r.provider_number.trim().toLowerCase() == "default",
      ).length > 0
    ) {
      setFormError("Defualt Study Code is exist!");
    } else {
      setFormError("");
    }

    return error;
  };

  const formik = useFormik({
    initialValues: {
      providerMik: "",
      studyCodeMik: "",
      CRCMik: "",
      SCRCMik: "",
      PIMik: "",
    },
    validate,
    onSubmit: async (values: {
      providerMik: string;
      studyCodeMik: string;
      CRCMik: string;
      SCRCMik: string;
      PIMik: string;
    }) => {
      await createStudyMutation({
        variables: {
          input: {
            study_code: values.studyCodeMik,
            provider_number: providerMik,
            CRC: values.CRCMik,
            SCRC: values.SCRCMik,
            PI: values.PIMik,
            DefaultOveride: isOverideDefault,
          },
        },
      })
        .catch(() => {
          setReqError("Please contact Australian Clinical Labs support team for assistance on");
        })
        .then(async () => {
          const newRow = await refetch();
          setRows(newRow.data.getStudy);
          handleModalCancel();
        });
    },
  });

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if (updateMode == "create" && isOverideDefault == true) {
      formik.submitForm();
    }
    if (updateMode == "update") {
      updateEmail();
    }
  }, [isOverideDefault]);

  React.useEffect(() => {
    if (data) {
      setRows(data.getStudy);
    }
  }, [data, refetch]);
  if (!rows) {
    return <></>;
  }

  return (
    <>
      <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
        <Grid item xs={12}>
          <AuthLayout title="" heading="Study List" maxWidth={"100%"}>
            <Box>
              <Box> {reqError ? <Alert severity="error"> {reqError}</Alert> : false}</Box>
              <Box>
                {hideButton && (
                  <div className={classes.buttonGroup}>
                    <Button className={styles.Btn} onClick={handleModalClick}>
                      Add
                    </Button>
                    <Button className={styles.Btn} onClick={handleDelete} disabled={isAbleDelete}>
                      Delete
                    </Button>
                  </div>
                )}
                <Box height={"550PX"} className={classes.gridTable}>
                  <DataGrid
                    disableColumnMenu
                    rows={rows}
                    onCellDoubleClick={(params) => {
                      setUpdateSelectedColumn(params.field);
                    }}
                    onRowSelectionModelChange={handleSelected}
                    disableRowSelectionOnClick
                    processRowUpdate={handleCellCommit}
                    columns={columns}
                    getCellClassName={handleCellClass}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                      },
                    }}
                    columnVisibilityModel={{
                      id: false,
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Dialog keepMounted aria-describedby="alert-dialog-slide-description" open={deleteDialogOpen}>
                  <DialogTitle>
                    <DialogBoxTitle title="Study List" width={260} />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>Are you sure you want to delete this record?</DialogContentText>
                    <Typography>
                      {"Provider Number:"}
                      {curObj["provider_number"]}
                    </Typography>
                    <Typography>
                      {"Study Code:"}
                      {curObj["study_code"]}
                    </Typography>
                    <Typography>
                      {"CRC:"}
                      {curObj["CRC"]}
                    </Typography>
                    <Typography>
                      {"SCRC:"}
                      {curObj["SCRC"]}
                    </Typography>
                    <Typography>
                      {"PI:"}
                      {curObj["PI"]}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button className={styles.Btn} onClick={closeDeleteDialog}>
                      Cancel
                    </Button>
                    <Button className={styles.Btn} onClick={deleteItem}>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
              <Dialog open={confirmDefualtOpen}>
                <DialogTitle>
                  <DialogBoxTitle title="Study List" width={260} />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to replace matching email addresses for studies with this new email address?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className={styles.Btn} onClick={handleIgnoreDefualt}>
                    No
                  </Button>
                  <Button className={styles.Btn} onClick={handleOverideDefualt} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={confirmCreateDefualtOpen}>
                <DialogTitle>
                  <DialogBoxTitle title="Study List" width={260} />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to use this email as a default email for other studies?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className={styles.Btn} onClick={handleCreateIgnoreDefualt}>
                    No
                  </Button>
                  <Button className={styles.Btn} onClick={handleCreateOverideDefualt}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Modal open={open} className={styles.Modal}>
                <form onSubmit={formik.handleSubmit} className={styles.modalStyle}>
                  <Box m={3} display="flex" flexDirection="column">
                    <Box>
                      <Box className={styles.productLabel} bgcolor="primary.main" py={1} mt={1} ml={-2}>
                        <Typography variant="h4">
                          <b>Add Study</b>
                        </Typography>
                      </Box>
                    </Box>
                    {formError ? <Alert severity="error"> {formError}</Alert> : false}

                    <Box py={1} mt={1}>
                      <TextField
                        id="studyCodeMik"
                        variant="outlined"
                        label="Study Code"
                        className={styles.normalInput}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    </Box>
                    <Box py={1} mt={1}>
                      <Select
                        id="providerMik"
                        value={providerMik}
                        onChange={selectProviderMik}
                        variant="outlined"
                        name="providerMik"
                        fullWidth
                      >
                        {provider.map((w) => (
                          <MenuItem key={w} value={w}>
                            {w}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box py={1} mt={1}>
                      <TextField
                        id="CRCMik"
                        placeholder="CRC (Email)"
                        variant="outlined"
                        type="email"
                        onChange={formik.handleChange}
                        className={styles.normalInput}
                        fullWidth
                      />
                    </Box>
                    <Box py={1} mt={1}>
                      <TextField
                        id="SCRCMik"
                        placeholder="SCRC (Email)"
                        variant="outlined"
                        type="email"
                        className={styles.normalInput}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    </Box>
                    <Box py={1} mt={1}>
                      <TextField
                        id="PIMik"
                        placeholder="PI (Email)"
                        variant="outlined"
                        type="email"
                        className={styles.normalInput}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    </Box>
                    <Grid item xs={12} md={12}>
                      <div className={styles.buttonGroup}>
                        <Button
                          className={styles.Btn}
                          onClick={openCreateConfirmBox}
                          disabled={formError ? true : false}
                        >
                          Add
                        </Button>
                        <Button className={styles.Btn} onClick={handleModalCancel}>
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                  </Box>
                </form>
              </Modal>
            </Box>
          </AuthLayout>
        </Grid>
      </Grid>
    </>
  );
};

export default StudyTable;
