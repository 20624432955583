import { WardInput } from "../graphql/types/gql-global-types";
import authService from "../services/authService";

class AdvancedSearch {
  public fullname?: string;
  public dob?: string;
  public urno?: string;
  public ward?: string;
  public reference?: string;
  public labnumber?: string;
  public paneldept?: number | undefined;
  public panel?: string;
  public referredStartDate?: string;
  public referredEndDate?: string;
  public allpatients?: boolean;
  public wardCode?: WardInput;
  public dateCreated?: string;
  public status?: string[];

  constructor(
    fname?: string,
    dob?: string,
    urno?: string,
    ward?: string,
    reference?: string,
    labnumber?: string,
    paneldept?: number,
    panel?: string,
    refstartdate?: string,
    refenddate?: string,
    allpatients?: boolean,
    wardCode?: string,
    dateCreated?: string,
    status?: string[],
  ) {
    const wards = authService.getWards();

    this.fullname = fname;
    this.dob = dob;
    this.urno = urno;
    this.ward = ward;
    this.reference = reference;
    this.labnumber = labnumber;
    this.paneldept = paneldept ? paneldept : undefined;
    this.panel = panel;
    this.referredStartDate = refstartdate;
    this.referredEndDate = refenddate;
    this.allpatients = allpatients;
    this.wardCode = wards.find((w) => w.code === wardCode);
    this.dateCreated = dateCreated;
    this.status = status;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static fromQueryString(params: any): AdvancedSearch {
    const fname: string = params.fullname;
    const dob: string = params.dob;
    const urno: string = params.urno;
    const ward: string = params.ward;
    const reference: string = params.ref;
    const labnumber: string = params.labnumber;
    const paneldept: number = parseInt(params.paneldept);
    const panel: string = params.panel;
    const refstartdate: string = params.refstartdate;
    const refenddate: string = params.refenddate;
    const allpatients: boolean = params.allpatients === "true";
    const wardCode: string = params.wardCode;
    const dateCreated: string = params.dateCreated;
    const status: string = params.status;
    const newStatus: string[] | undefined = status ? status.split(",") : undefined;

    return new AdvancedSearch(
      fname,
      dob,
      urno,
      ward,
      reference,
      labnumber,
      paneldept,
      panel,
      refstartdate,
      refenddate,
      allpatients,
      wardCode,
      dateCreated,
      newStatus,
    );
  }

  public toQueryString(): string {
    let queryString = "";
    let newStatus = null;
    if (this.status) {
      newStatus = this.status.filter(function (el) {
        return el != "";
      });
    }
    if (this.fullname) queryString += `fullname=${this.fullname}`;
    if (this.dob) queryString += (queryString ? "&" : "") + `dob=${this.dob}`;
    if (this.urno) queryString += (queryString ? "&" : "") + `urno=${this.urno}`;
    if (this.ward) queryString += (queryString ? "&" : "") + `ward=${this.ward}`;
    if (this.reference) queryString += (queryString ? "&" : "") + `ref=${this.reference}`;
    if (this.labnumber) queryString += (queryString ? "&" : "") + `labnumber=${this.labnumber}`;
    if (this.paneldept) queryString += (queryString ? "&" : "") + `paneldept=${this.paneldept}`;
    if (this.panel) queryString += (queryString ? "&" : "") + `panel=${this.panel}`;
    if (this.referredStartDate) queryString += (queryString ? "&" : "") + `refstartdate=${this.referredStartDate}`;
    if (this.referredEndDate) queryString += (queryString ? "&" : "") + `refenddate=${this.referredEndDate}`;
    if (this.allpatients) queryString += (queryString ? "&" : "") + `allpatients=${this.allpatients}`;
    if (this.wardCode) queryString += (queryString ? "&" : "") + `wardCode=${this.wardCode.code}`;
    if (this.dateCreated && this.dateCreated != "0")
      queryString += (queryString ? "&" : "") + `dateCreated=${this.dateCreated}`;
    if (newStatus && newStatus.length > 0) queryString += (queryString ? "&" : "") + `status=${newStatus}`;
    return queryString;
  }
}

export default AdvancedSearch;
