//import React from 'react';
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default HtmlTooltip;
