import { gql } from "@apollo/client";
export default gql`
  mutation ApproveEpisode($input: ApproveEpisodeInput!) {
    approveEpisode(input: $input) {
      reportId
      reportTestName
      reportLabNumber
      reportLabNumberDigitOnly
      reportStatus
      csPanelApprovalStatus
      csPanelApproverUserId
      csPanelApproverUsername
      csPanelApprovalDatetime
      atomicData {
        atomicId
        name
        value
        measurement
        csApproval
        csApproverUserId
        csApproverUsername
        csApproverDatetime
        csStatus
      }
    }
  }
`;
