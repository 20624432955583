import { gql } from "@apollo/client";

export default gql`
  query GetPatientDrilldown($patientName: String, $medicare: String, $dob: String) {
    patientDrilldown(patientName: $patientName, medicare: $medicare, dob: $dob) {
      id
      fullname
      dob
      street
      city
      state
      postcode
      medicare
      dvacode
      mobile
      sex
      firstname
      middlename
      surname
      fullnameTitleCase
      fullAddress
      title
      species
    }
  }
`;
