import { Typography, Link } from "@mui/material";
import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
export const CognitoLogoutPage: React.FC<RouteComponentProps> = () => {
  return (
    <Fragment>
      <Typography variant="h3" gutterBottom>
        Logout
      </Typography>
      <Typography gutterBottom>you are now logged off!</Typography>
      <Link href="/login">Return to login page</Link>
    </Fragment>
  );
};
export default withRouter(CognitoLogoutPage);
