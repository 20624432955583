import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GetTokenByCode, GetTokenByCodeVariables } from "./types/GetTokenByCode";
import getTokenBycodeQuery from "./getTokenBycodeQuery";
import authService from "../../services/authService";
import * as jwt from "jsonwebtoken";
import queryString from "query-string";
import { Typography } from "@mui/material";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

export const CognitoLoginPage: React.FC<RouteComponentProps> = (props: any) => {
  const { history, location } = props;
  const [sessionStorageUpdated, setSessionStorageUpdated] = useState(false);
  const qs = queryString.parse(location.search);

  const [getToken, { data: tokenData }] = useLazyQuery<GetTokenByCode, GetTokenByCodeVariables>(getTokenBycodeQuery);
  useEffect(() => {
    if (!tokenData) {
      return;
    }

    const eResultsData: any = jwt.decode(tokenData.getTokenByCode.id_token);
    const eResultsObject = eResultsData ? tokenData.getTokenByCode.loginResult : null;
    authService.persistAllValues(eResultsObject);
    authService.persistCognitoToken(
      tokenData.getTokenByCode.access_token,
      tokenData.getTokenByCode.id_token,
      tokenData.getTokenByCode.refresh_token,
    );
    const from: any = "/patients";

    history.replace(from);
    document.location.reload();
  }, [tokenData]);

  useEffect(() => {
    // Check for session storage updates in each render cycle
    const isSessionStorageUpdated =
      sessionStorage.getItem("cognito-idtoken") !== null &&
      sessionStorage.getItem("cognito-access-token") !== null &&
      sessionStorage.getItem("cognito-refresh-token") !== null;

    if (isSessionStorageUpdated) {
      setSessionStorageUpdated(true);
    }
  });

  // Redirect when sessionStorageUpdated becomes true
  useEffect(() => {
    if (sessionStorageUpdated) {
      history.replace("/patients");
      document.location.reload();
    }
  }, [sessionStorageUpdated, history]);

  useEffect(() => {
    const code = (qs.code || "").toString();
    if (code) {
      getToken({
        variables: {
          input: {
            code: code,
          },
        },
      });
    } else {
      //redirect to hostui. google.com
      window.location.href = `${process.env.REACT_APP_HOSTUI_URL}`;
    }
  }, []);

  return (
    <Fragment>
      <Typography sx={{ alignContent: "center", whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
        {" "}
        {tokenData ? JSON.stringify(jwt.decode(tokenData.getTokenByCode.id_token)) : "loading.."}
      </Typography>
    </Fragment>
  );
};

export default withRouter(CognitoLoginPage);
