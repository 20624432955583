import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import theme from "../theme";
import authService from "../../services/authService";
import MonthlyRollover from "./MonthlyProgressQuery";
import {
  GetDiabeticMonthlyRollover,
  GetDiabeticMonthlyRollover_getDiabeticMonthlyRollover,
} from "./types/GetDiabeticMonthlyRollover";
import { useQuery } from "@apollo/client";
import Loading from "../Loading/Loading";
import { DownloadMenuIcon, PrintMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import styles from "./CPDprograms.module.css";
import { downloadHelper } from "../../utils/downloadHelper";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

const CPDdiabetesTab1: React.FC = () => {
  const chartRef = useRef<any>(null);
  const providers = authService.getCpdProviderValues();

  const [monthlyRollover, setMonthlyRollover] = useState<GetDiabeticMonthlyRollover_getDiabeticMonthlyRollover[]>([]);

  const { data, loading } = useQuery<GetDiabeticMonthlyRollover>(MonthlyRollover, {
    variables: {
      providernos: providers,
    },
  });

  useEffect(() => {
    if (data) {
      setMonthlyRollover(data?.getDiabeticMonthlyRollover);
    }
  }, [data, providers]);

  const graphData = {
    labels: monthlyRollover.map((d) => d.month),
    datasets: [
      {
        label: "You %",
        data: monthlyRollover.map((d) => d.diabeticCount),
        backgroundColor: theme.colorSchemes.light.palette.primary.main,
        color: "white",
      },
      {
        label: "State %",
        data: monthlyRollover.map((d) => d.stateBenchmark),
        backgroundColor: theme.colorSchemes.light.palette.primary.dark,
        color: "white",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom" as const,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltip: {
      filter: (item: any) => {
        if (item.dataset.label.includes("hide")) {
          return false;
        }
        return true;
      },
      enabled: true,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "% of patients with Diabetes who had HbA1C in > 6 month ",
          font: {
            size: 12,
          },
        },
        min: 0,
        max: 100,
      },
    },
  };

  const downloadChart = () => {
    const canvas = document.getElementById("monthly-graph") as HTMLCanvasElement;
    const dataUrl = canvas.toDataURL("image/png");
    downloadHelper(dataUrl, "hba1c-patients-chart.png");
  };

  const printChart = () => {
    window.print();
  };

  if (loading) return <Loading />;

  return (
    <Box>
      <Grid container spacing={10} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={11} lg={12}>
          <Grid container>
            <Grid item xs={12} p={2} display={"flex"} justifyContent={"flex-end"} className={styles.hideForPrinting}>
              <DownloadMenuIcon
                title="Download Chart"
                onClick={downloadChart}
                class={`${styles.spaceRight} ${styles.hideDisplay}`}
              />
              <PrintMenuIcon title="Print" onClick={printChart} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} key="table-diabetic" m={2} ml={8}>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: 2,
                }}
              >
                <Table>
                  <TableHead className={styles["infoBox-large"]}>
                    <TableRow>
                      <TableCell width={"20%"} style={{ borderBottom: "none" }}></TableCell>
                      <TableCell width={"40%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.main}
                          className={styles.infoBoxHeader}
                        >
                          % of patients with Diabetes tested for HbA1c in previous months
                        </Typography>
                      </TableCell>
                      <TableCell width={"40%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.dark}
                          className={styles.infoBoxHeader}
                        >
                          Benchmark State
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyRollover.map((row, i) => (
                      <TableRow key={`row-${i}`}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={`${styles.infoBoxText} ${styles.infoBoxTextPadding} `}
                          >
                            {row.month}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            color={theme.colorSchemes.light.palette.primary.main}
                            className={styles.infoBoxText}
                          >
                            {row.diabeticCount}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            color={theme.colorSchemes.light.palette.primary.dark}
                            className={styles.infoBoxText}
                          >
                            {row.stateBenchmark}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3} style={{ borderBottom: "none" }}>
                        <Typography className={styles.descriptionRef}>
                          {`HbA1c target needs individualisation according to patient circumstances. Generally <7% (53 mmol/mol)`}
                        </Typography>
                        <Typography className={styles.descriptionTiny}>
                          The Royal Australian College of General Practitioners. Management of type 2 diabetes: A
                          handbook for general practice. East Melbourne, Vic: RACGP, 2020.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} key="graph-diabetic" m={2}>
              <Bar
                id={"monthly-graph"}
                ref={chartRef}
                width={"100%"}
                height={"400px"}
                options={options}
                data={graphData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDdiabetesTab1;
