import { gql } from "@apollo/client";

export default gql`
  query GetHba1cPatientsReport(
    $searchPatientName: String
    $overdueOnly: Boolean
    $providers: [String!]
    $page: Float
    $pageSize: Float
  ) {
    getHba1cPatientsReport(
      searchPatientName: $searchPatientName
      overdueOnly: $overdueOnly
      providers: $providers
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      records {
        id
        name
        dob
        lastResult
        lastSent
        lastSubmitted
        recallsExceeded
        submissionAllowed
        testsOverdue {
          test
          lastReport
          isOverdue
        }
        isHba1cOverdue
        lastRecallDates
      }
    }
  }
`;
