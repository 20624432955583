import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PrintableCombineCtAtomicOrders from "./PrintableCombineCtAtomicOrders";
import Loading from "../Loading/Loading";
import { GetDataForToken } from "./types/GetDataForToken";
import GetDataForTokenQuery from "./GetDataForTokenQuery";
import authService from "../../services/authService";

const PrintablePatientsDelegationPage: React.FC<any> = () => {
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  const token = urlSearchParams.get("token");
  const [downloadReportData, setDownloadReportData] = useState<any[]>([]);

  const { data, loading } = useQuery<GetDataForToken>(GetDataForTokenQuery, {
    variables: {
      token,
    },
    skip: token === "session-storage",
  });

  useEffect(() => {
    if (!loading && data) {
      const reportData = JSON.parse(data.getDataForToken.data);
      console.log(reportData);
      setDownloadReportData(reportData.reportData);
    }
  }, [data, loading]);

  if (token === "session-storage") {
    const reportsBlob = authService.getReportsBlob();
    if (reportsBlob.reportData) {
      setDownloadReportData(reportsBlob.reportData);
      authService.clearReportsBlob();
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <PrintableCombineCtAtomicOrders orders={downloadReportData} />
    </>
  );
};

export default PrintablePatientsDelegationPage;
