import React, { useContext, useEffect, useState, useCallback } from "react";
import { Grid, Box, Typography, Button, Switch, useMediaQuery } from "@mui/material";
import styles from "./HospAdminUserListTable.module.css";
import { formatDate } from "../../utils/date";
import AppContext from "../../context/AppContext";
import { UpdateUserVariables, UpdateUser } from "./types/UpdateUser";
import UpdateUserMutation from "./UpdateUserMutation";
import { useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import { cloneDeep } from "lodash";

import theme from "../theme";
import EditHospitalUser from "../EditHospitalUser/EditHospitalUser";
import { ThemeProvider } from "@mui/material/styles";

type HospAdminUserListDataProps = {
  users: any[];
};

const HospAdminUserListData: React.FC<HospAdminUserListDataProps> = (props) => {
  const { users } = props;
  const isDesktop = useContext(AppContext).isDesktop;
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  const [updateUser, { error }] = useMutation<UpdateUser, UpdateUserVariables>(UpdateUserMutation);
  if (error) {
    console.error("Error", error);
  }

  const [state, setState] = React.useState<any[]>([]);

  const saveUserStatus = (index: number, username: string, email: string, enabled: boolean) => {
    try {
      updateUser({
        variables: {
          input: {
            username,
            email,
            enabled,
          },
        },
      }).then(() => {
        const changed = cloneDeep(state);
        changed[index].enabled = enabled;
        setState(changed);
      });
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot Update user") !== undefined
      ) {
        console.error("Error", e);
      }
    }
  };

  const handleEnableChange = (index: number, enabled: boolean) => {
    saveUserStatus(index, state[index].username, state[index].email, enabled);
  };

  useEffect(() => {
    setState(cloneDeep(users));
    // eslint-disable-next-line
  }, [users]);

  const [hospitalUser, setHospitalUser] = React.useState({
    username: "",
    name: "",
    email: "",
    password: "",
    sharedaccount: false,
    enabled: false,
    notificationEmails: "",
    resultNotification: 1,
    notificationMobiles: "",
    cognito: false,
  });

  const handleClickCreateHospitalUser = useCallback(
    (user: any) => () => {
      const newHospitalUser: any = {
        username: user.username,
        name: user.name,
        email: user.email,
        password: user.password,
        sharedaccount: user.sharedaccount,
        enabled: user.enabled,
        mobileNumber: user.mobileNumber,
        notificationEmails: user.notificationEmails,
        resultNotification: user.resultNotification,
        notificationMobiles: user.notificationMobiles,
        cognito: user.cognito,
      };

      // eslint-disable-next-line no-console
      console.log(user.resultNotification);

      setHospitalUser(newHospitalUser);
      setOpenFeedbackDialog(true);
    },
    [],
  );

  const handleFeedbackClose = () => {
    setOpenFeedbackDialog(false);
  };

  return (
    <Box>
      <Box my={2} id="patient-list-table">
        {state &&
          state.map((user, index) => (
            <ThemeProvider key={index} theme={theme}>
              <Box
                mx={isDesktop ? 3 : 0}
                my={0.2}
                px={2}
                py={1}
                className={`patient ${index % 2 ? styles.isUnread : ""} ${styles.tableRow}`}
              >
                <Grid container alignItems="flex-start" key={index}>
                  <Grid container item xs sm md>
                    <Grid item xs={4} sm={4} md={4} lg={2}>
                      <Typography variant="body2" noWrap>
                        {user.username}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={1} md={1} lg={2}>
                      <Typography variant="body2" noWrap style={{ fontSize: "13px" }}>
                        {user.name}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={1} md={2} lg={2}>
                      <Typography variant="body2" noWrap style={{ fontSize: "13px" }}>
                        {" "}
                        {user.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={4} md={4} lg={1}>
                      <Typography variant="body2" style={{ color: "#B3B3B3" }}>
                        {" "}
                        {user.hospitalmanager ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={1} md={1} lg={1}>
                      <Typography variant="body2" style={{ color: "#B3B3B3" }}>
                        {" "}
                        {user.sharedaccount ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={1} md={1} lg={1}>
                      <Typography variant="body2" style={{ color: "#B3B3B3" }}>
                        {" "}
                        {formatDate(user.lastlogin, "DD MMM YYYY", true)}
                        <br />
                        {formatDate(user.lastlogin, "H:m:s", true)}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={1} md={1} lg={1}>
                      <Typography variant="body2" style={{ color: "#B3B3B3" }}>
                        {" "}
                        {user.lastip}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1} md={2} lg={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickCreateHospitalUser(user)}
                        size="small"
                        style={{ padding: "0px", minWidth: "45px" }}
                      >
                        Edit
                      </Button>
                    </Grid>

                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Switch
                        checked={user.enabled}
                        onChange={(_, checked) => {
                          handleEnableChange(index, checked);
                        }}
                        name="userEnable"
                        color="success"
                        className={styles.pushRight}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </ThemeProvider>
          ))}
      </Box>
      <EditHospitalUser
        open={openFeedbackDialog}
        fullscreen={fullscreen}
        onClose={handleFeedbackClose}
        hospitalUser={hospitalUser}
      />
    </Box>
  );
};

export default HospAdminUserListData;
