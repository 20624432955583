import React, { useState, useContext } from "react";
import MainNavigation from "../MainNavigation/MainNavigation";
import PatientListTable from "../PatientListTable/PatientListTable";
import { Box, AppBar } from "@mui/material";
import styles from "./PatientListPage.module.css";
import SearchReportBar from "../SearchReportBar/SearchReportBar";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import AppContext from "../../context/AppContext";
import authService from "../../services/authService";
import advancedSearch from "../../model/advanced-search";
import queryString from "query-string";
import { useLocation } from "react-router";
import { animateScroll } from "react-scroll";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { EpisodeHeader } from "../PatientListTable/EpisodeHeader";

const SEARCHTEXT = "searchtext";
const DEFAULT_WARD_CODE = "defaultWardCode";

export const isOpen = (): boolean => {
  if (authService.isMobility()) {
    return false;
  } else if (!authService.getAdOpen()) {
    if (localStorage.getItem(DEFAULT_WARD_CODE) !== null && localStorage.getItem(DEFAULT_WARD_CODE) !== "") {
      setIsOpen(true);
      return true;
    } else {
      setIsOpen(false);
      return false;
    }
  } else {
    return authService.getAdOpen();
  }
};

export const setIsOpen = (value: boolean): void => {
  authService.setAdOpen(value);
};

export const isSearchText = (): string => localStorage.getItem(SEARCHTEXT) || "";

export const setSearchText = (value: string): void => localStorage.setItem(SEARCHTEXT, value);

const PatientListPage: React.FC = () => {
  //const [isDateDisabled, setDateDisabled] = useState(true);
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const [scrollCount, setScrollCount] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [isError, setIsError] = useState(false);

  const location = useLocation();
  const adSearch: advancedSearch = advancedSearch.fromQueryString(queryString.parse(location.search));

  if (authService.getDefaultWardCode() !== "") {
    setIsOpen(true);
    adSearch.ward = authService.getDefaultWardCode();
  }

  function scrollTop() {
    animateScroll.scrollToTop();
  }

  const [open, setOpen] = useState(isOpen());
  // let isSearchPage = false;
  let isAdvanceSarchPage = false;
  let allP = false;

  if (typeof adSearch.fullname !== "undefined") {
    // isSearchPage = true;
    allP = adSearch.allpatients ? true : false;
  }

  Object.keys(adSearch).forEach((key) => {
    if (
      typeof (adSearch as any)[key] !== "undefined" &&
      key !== "allpatients" &&
      key !== "fullname" &&
      key !== "status"
    ) {
      isAdvanceSarchPage = true;
      setIsOpen(true);
    }
  });

  if (isAdvanceSarchPage) {
    allP = adSearch.allpatients ? true : false;
  }

  const [allPatients, setAllPatient] = useState(allP);

  const isDesktop = useContext(AppContext).isDesktop;
  // const classes = useStyles();
  const mobility = authService.isMobility();
  const offsetHeight = mobility ? 161 : 144; //[offsetHeight, setOffsetHeight] = useState(566);

  // Listen for message from child ChangeEmailPage.tsx
  // Reload page
  // Ref: http://jira.pl.pathlabs.com.au/browse/ER7-63
  const receiveMessage = (event: any): void => {
    if (event.data && event.data === "reloadPage") {
      window.location.reload();
    }
  };

  window.addEventListener("message", receiveMessage, false);

  useScrollPosition(
    ({ prevPos, currPos }: { prevPos: any; currPos: any }) => {
      const isShow = currPos.y > prevPos.y - 20;

      if (isShow !== hideOnScroll) {
        setHideOnScroll(true);
        setScrollCount(currPos.y + 20);
      }
      if (!authService.getAdOpen()) {
        if (currPos.y < 50) {
          setShowUpArrow(true);
        }
        if (currPos.y === 0) {
          setShowUpArrow(false);
        }
      }
      if (authService.getAdOpen()) {
        const adSearchHeight = isDesktop ? 350 : 525;
        if (currPos.y < -adSearchHeight) {
          setShowUpArrow(true);
        }
        if (currPos.y > -adSearchHeight) {
          setShowUpArrow(false);
        }
      }
    },
    [hideOnScroll],
  );

  const handleClickOpen = () => {
    setOpen(true);
    setIsOpen(true);
  };

  return (
    <>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation scrollCount={scrollCount} showEorderButton showRefreshButton />
      </AppBar>
      <div style={{ height: offsetHeight }} />
      {isDesktop && (
        <Box>
          <Box mx={[1, 3]} flexGrow={8} width={"95%"}>
            <SearchReportBar
              allPatients={allPatients}
              setAllPatient={setAllPatient}
              setShowLoadMore={setShowLoadMore}
              hideOnScroll={hideOnScroll}
              open={open}
              setOpen={setOpen}
              advancedClick={handleClickOpen}
              withAdvButton={true}
              isError={isError}
              setIsError={setIsError}
            />
          </Box>
          <EpisodeHeader></EpisodeHeader>
        </Box>
      )}
      {!isDesktop && (
        <Box mx={[1, 3]} pt={2}>
          <SearchReportBar
            allPatients={allPatients}
            setAllPatient={setAllPatient}
            setShowLoadMore={setShowLoadMore}
            hideOnScroll={hideOnScroll}
            open={open}
            setOpen={setOpen}
            withAdvButton={false}
            isError={isError}
            setIsError={setIsError}
          />
        </Box>
      )}
      {showUpArrow ? (
        <div className={styles.popup} onClick={scrollTop}>
          <ArrowUpwardIcon />
        </div>
      ) : (
        ""
      )}
      <PatientListTable setShowLoadMore={setShowLoadMore} showLoadMore={showLoadMore} open={open} />
    </>
  );
};

export default PatientListPage;
