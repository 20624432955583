import React, { useState } from "react";
import { TextField } from "@mui/material";

interface MobileNumberFieldProps {
  initState: string;
  id?: any;
  inputChange?: any;
  error?: any;
  helperText?: any;
  required?: boolean;
}

const MobileNumberField: React.FC<MobileNumberFieldProps> = (props) => {
  const maskedNumber = (mobile: string) => {
    return !mobile ? mobile : mobile.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
  };
  const [inputValue, setInputValue] = useState(maskedNumber(props.initState));
  const handleInputChange = (e: any) => {
    let mobileNumber = e.target.value.replace(/[^0-9]/g, "");

    if (!mobileNumber.startsWith("0")) {
      mobileNumber = mobileNumber.replace(/^.{1}/g, "04");
    }

    if (!mobileNumber.startsWith("04")) {
      mobileNumber = mobileNumber.replace(/^.{2}/g, "04");
    }

    setInputValue(maskedNumber(mobileNumber));
    props.inputChange(maskedNumber(mobileNumber));
  };

  return (
    <TextField
      key={props.id}
      margin="dense"
      id={"mobile" + props.id ? props.id : ""}
      name="mobile"
      label="Mobile Number"
      type="text"
      fullWidth
      variant="outlined"
      inputProps={{
        maxLength: 12,
      }}
      value={inputValue}
      onChange={handleInputChange}
      error={props.error}
      helperText={props.helperText}
      required={props.required}
    />
  );
};

export default MobileNumberField;
