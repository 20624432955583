import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import styles from "./CPDprograms.module.css";
import theme from "../theme";
import { useQuery } from "@apollo/client";
import authService from "../../services/authService";
import {
  getCytologySummary,
  getCytologySummary_getCytologySummary_graph,
  getCytologySummary_getCytologySummary_summary,
} from "./types/getCytologySummary";
import GetCytologySummaryQuery from "./GetCytologySummaryQuery";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import AppContext from "../../context/AppContext";
import { NO_DB_CONNECTION } from "../../constants";
import * as htmlToImage from "html-to-image";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

interface PathParams {
  id: string;
}

export type CpdCytologyTabProps = {
  chart1: string;
  chart2: (value: string) => void;
  isLoading: (value: boolean) => void;
  isActioned: boolean;
};

const CPDcytologyTab0: React.FC<CpdCytologyTabProps> = (props) => {
  const chartRef = useRef<any>(null);
  const providers = authService.getCpdProviderValues();
  const params: PathParams = useParams() as PathParams;
  const setError = useContext(AppContext).setError;
  const domEl = useRef(null);

  const [summary, setSummary] = useState<getCytologySummary_getCytologySummary_summary>();
  const [graph, setGraph] = useState<getCytologySummary_getCytologySummary_graph[]>([]);
  const [graphY, setGraphY] = useState<any[]>([]);
  const [graphN, setGraphN] = useState<any[]>([]);
  const [label, setLabel] = useState<any[]>([]);

  const { data, loading, error } = useQuery<getCytologySummary>(GetCytologySummaryQuery, {
    variables: {
      id: parseInt(params.id),
      providers: providers,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setSummary(data?.getCytologySummary.summary);
      setGraph(data?.getCytologySummary.graph);

      setLabel(Object.keys(data?.getCytologySummary.graph[0]).splice(1));
      setGraphY(Object.values(data?.getCytologySummary.graph[0]).splice(1));
      setGraphN(Object.values(data?.getCytologySummary.graph[1]).splice(1));

      if (props.isActioned) {
        (async () => {
          const dataUrl = await htmlToImage.toPng(domEl.current as unknown as HTMLElement);
          props.chart2(dataUrl);
        })();
      }
      setError("");
    }

    props.isLoading(loading);
  }, [data, loading, props.isActioned]);

  if (error) {
    setError(NO_DB_CONNECTION);
  }

  const graphData = {
    labels: label.map((l) => l.replaceAll("_", " ").toUpperCase()), //remove doctor_name
    datasets: [
      {
        axis: "y",
        label: "You %",
        data: graphY.map((g) => g * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.main,
        color: "white",
      },
      {
        axis: "y",
        label: "National %",
        data: graphN.map((g) => g * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.dark,
        color: "white",
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value: number) {
          const theValue = value !== 0 ? (value === 100 ? value : value.toFixed(1)) : "";
          return theValue + "%";
        },
        color: "#ffffff",
        font: {
          weight: "bold",
        } as const,
      },
      title: {
        display: true,
        text: "Episode Summary",
        font: {
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
      },
    },
    tooltip: {
      filter: (item: any) => {
        if (item.dataset.label.includes("hide")) {
          return false;
        }
        return true;
      },
      enabled: true,
    },
    scales: {
      x: {
        min: 0,
        max: 100,
      },
    },
  };

  if (loading) return <Loading />;

  return (
    <Box mt={[3, 12]} ml={[2, 4]} mr={[2, 4]}>
      <Box className={styles.cytoTabPageHeader}>Dashboard Summary</Box>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} sm={12} md={6} key="table-diabetic">
          <Box className={styles.infoBox} mt={5} ml={2} id="domEl" ref={domEl}>
            <Table style={{ marginBottom: "20px" }}>
              <TableBody>
                <TableRow key={`row-0`}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Total patient episodes referred for cervical screening / diagnostic tests
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.cervical_scrren_diag}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={`row-1`}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Total number of HPV tests
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.total_hpv}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={`row-2`}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Number of cases with cytology performed
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.total_cytology}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={`row-3`}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Number of episodes with endocervical cells present
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.endo_cervical_cells}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={`row-4`}>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Number of unsatisfactory cytology samples
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.unsatisfactory_cytology}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={`row-5`} style={{ visibility: "hidden" }}>
                  <TableCell style={{ border: "none" }}>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.main}
                      className={styles.infoBoxText}
                    >
                      Number of unsatisfactory cytology samples
                    </Typography>
                  </TableCell>
                  <TableCell style={{ border: "none" }}>
                    <Typography
                      variant="subtitle1"
                      color={theme.colorSchemes.light.palette.primary.dark}
                      className={styles.infoBoxText}
                    >
                      {summary?.unsatisfactory_cytology}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} key="graph-summary">
          {graph && <Bar id={props.chart1} ref={chartRef} width={"100%"} options={options} data={graphData} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDcytologyTab0;
