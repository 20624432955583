import { gql } from "@apollo/client";

export default gql`
  query getCytologyAdequacy($id: Float!, $providers: [String!]!) {
    getCytologyAdequacy(id: $id, providers: $providers) {
      graph {
        doctor_code
        unsatisfactory_pcnt
        satisfactory_pcnt
      }
    }
  }
`;
