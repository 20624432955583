import { gql } from "@apollo/client";

export default gql`
  query GetCpdDiabeticPatients($providerNo: [String!]!) {
    getCpdDiabeticPatients(providerNo: $providerNo) {
      newlyDiagnosedDiabeticPatientCount
      newlyDiagnosedKnownDiabeticPatientCount
      diabeticPatientCount
      diabeticPatientCountForState {
        patientCount
        diabeticPatientCount
      }
      knownDiabeticPatientCount
      month12UmaPatientCount
      month24UmaPatientCount
      month12CholPatientCount
      month24CholPatientCount
      percentageTested
      percentageTestedForState
      percentageNotTested
      percentageNotTestedForState
      loginCount
      reportCount
    }
  }
`;
