import React, { Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styles from "../LoginPage/LoginPage.module.css";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import MainNavigation from "../MainNavigation/MainNavigation";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const ForgotSharedAccountPage: React.FC<RouteComponentProps> = () => {
  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <AuthLayout
        title="Contact support"
        maxWidth={"490px"}
        footer={
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              By logging in you agree to the{" "}
              <Link
                className={styles.terms}
                color="textSecondary"
                href="https://ehealth.clinicallabs.com.au/terms_conditions.php"
                target="_blank"
                rel="noopener"
              >
                Terms &amp; Conditions
              </Link>
            </Typography>
          </Box>
        }
      >
        <Box pt={3}>
          <Typography variant="body1" color="textSecondary">
            <b>Unable to Send Reset Password Email Link.</b>
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="subtitle1" color="textSecondary">
            We are unable to send you a Reset Password link as selected user account is marked as a Shared account.
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="body1" color="textSecondary">
            Please contact eResults support team for assistance on
          </Typography>
        </Box>
        <Box my={1}>
          <Link href="tel:1300669961" variant="body1" color="textSecondary">
            <PhoneIcon fontSize="inherit"></PhoneIcon>
            <span style={{ marginLeft: "10px" }}>1300 669 961</span>
          </Link>
        </Box>
        <Box my={1}>
          <Link
            href="mailto:ehealth.helpdesk@clinicallabs.com.au?subject=Password Reset"
            variant="body1"
            color="textSecondary"
            align="center"
          >
            <EmailIcon fontSize="inherit"></EmailIcon>
            <span style={{ marginLeft: "10px" }}>ehealth.helpdesk@clinicallabs.com.au</span>
          </Link>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Link color="primary" component={RouterLink} to="/login">
            Return to sign in
          </Link>
        </Box>
      </AuthLayout>
    </Fragment>
  );
};

export default withRouter(ForgotSharedAccountPage);
