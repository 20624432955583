import React from "react";
import { Typography, Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import theme from "../theme";

interface DialogBoxMFAProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const useStyles = makeStyles(() => ({
  columnA: {
    width: "25%",
  },
  columnB: {
    width: "75%",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    marginBottom: "10px",
  },
  rows: {
    marginBottom: "20px",
  },
  commentBox: {
    maxHeight: "200px",
    overflowY: "scroll",
  },
  dialogBox: {
    width: "380px",
  },
}));

const DialogBoxMFA: React.FC<DialogBoxMFAProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle title="Verification Error" width={200} closeButton handleClose={props.onClose} />
        </DialogTitle>

        <DialogContent>
          <Typography variant="h6" className={classes.header}>
            {props.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBoxMFA;
