import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import authService from "../../services/authService";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import AppContext from "../../context/AppContext";
import thm from "../theme";

const useStyles = makeStyles(() => ({
  notificationsIconActive: {
    color: thm.colorSchemes.light.palette.primary.dark,
  },
  notificationsTitle: {
    fontWeight: "bold",
    fontSize: "0.8125rem",
  },
  announcement: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light,
    borderRadius: "5px",
    padding: "5px",
    width: "250px",
    whiteSpace: "break-spaces",
    cursor: "default",
  },
  announcementMain: {
    marginBottom: "5px",
    backgroundColor: thm.colorSchemes.light.palette.primary.light,
    borderRadius: "5px",
    padding: "3px",
    whiteSpace: "break-spaces",
    color: "black",
    maxWidth: "350px",
  },
  announcementText: {
    fontSize: "0.8rem !important",
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
  announcementSubject: {
    fontSize: "0.7rem !important",
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
  withoutHover: {
    backgroundColor: "transparent !important",
    cursor: "default !important",
  },
}));

export type NotificationInfoMenuProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

const NotificationInfoMenu: React.FC<NotificationInfoMenuProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useContext(AppContext).isDesktop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToChangePassword = () => {
    history.push("/change-password");
  };

  const lastChangePassword = authService.getLastChangePassword();
  const expiryWarning = authService.getPasswordExpiryWarning();
  const windowDays = authService.getPasswordWindowDays();
  const expiredIn = authService.getExpiredIn();

  let enablePage = false;
  let mainMessage = "";

  if (["patients", "patient-episodes"].some((el) => window.location.pathname.includes(el))) {
    enablePage = true;
  }

  if (windowDays != 0 && lastChangePassword > 180) {
    mainMessage = "Your password is older than 180 days. Consider changing it";
  }

  if (windowDays != 0 && expiryWarning > 0 && expiredIn <= expiryWarning) {
    mainMessage = `Your password is reaching its ${windowDays} day expiration. It will expire ${
      expiredIn < 0 || expiredIn === 0 ? "today" : expiredIn === 1 ? "tomorrow" : "in " + expiredIn + " days"
    } . Please change your password`;
  }

  if (!enablePage) {
    return <></>;
  }

  if (mainMessage === "") {
    return <></>;
  } else {
    if (isDesktop) {
      return (
        <>
          {mainMessage !== "" && (
            <Box className={classes.announcementMain} sx={{ display: "inline-flex" }}>
              {" "}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WarningAmberIcon className={classes.notificationsIconActive} />
              </Box>
              <Box ml={1}>
                <Typography className={classes.announcementText}>
                  {mainMessage}{" "}
                  <u style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={goToChangePassword}>
                    here
                  </u>{" "}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      );
    } else {
      return (
        <>
          <Tooltip title="Password Alert">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <WarningAmberIcon className={classes.notificationsIconActive} />
            </IconButton>
          </Tooltip>
          <Menu
            id="notifications-password-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {mainMessage !== "" && (
              <MenuItem
                style={{ pointerEvents: "none" }}
                className={`${classes.notificationsTitle} ${classes.withoutHover}`}
              >
                <Box className={classes.announcement}>
                  <Typography className={classes.announcementText}>
                    {mainMessage}{" "}
                    <u style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={goToChangePassword}>
                      here
                    </u>{" "}
                  </Typography>
                </Box>
              </MenuItem>
            )}
          </Menu>
        </>
      );
    }
  }
};

export default NotificationInfoMenu;
