import React, { useContext } from "react";
import styles from "./AuthLayout.module.css";
import { Box, Paper, Typography } from "@mui/material";
import AppContext from "../../../context/AppContext";
import packageInfo from "../../../../package.json";

export const AuthLayout: React.FC<{
  title: string;
  maxWidth: string;
  footer?: React.ReactNode;
  isAnnouncementAvai?: boolean;
  heading?: string;
}> = ({ title, maxWidth, children, footer, isAnnouncementAvai, heading }) => {
  const isDesktop = useContext(AppContext).isDesktop;

  return (
    <Box
      px={[0, 2]}
      mx={[0, "auto"]}
      className={isDesktop && isAnnouncementAvai ? styles.fullpageA : styles.fullPage}
      mt={3}
      maxWidth={maxWidth}
    >
      <Paper className={styles.fullWidth}>
        <Box py={8} width={"100%"}>
          <Box className={styles.productLabel} bgcolor="primary.main" py={1} pl={[2, 4]}>
            <Typography variant="h4">
              {heading ? (
                heading
              ) : (
                <>
                  <b>eResults</b> Viewer
                </>
              )}
            </Typography>
          </Box>
          <Box px={[2, 4]} mt={3}>
            <Typography variant="h3">{title}</Typography>
            {children}
          </Box>
        </Box>
      </Paper>
      {footer}
      <Typography variant="body2" color="textSecondary" className={styles.version} align={"center"}>
        eResults version {packageInfo.version}
      </Typography>
    </Box>
  );
};

export default AuthLayout;
