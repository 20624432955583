import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import styles from "./PrintMenu.module.css";

type PrintCheckBoxProps = {
  name: string;
  value: boolean;
  onChange: any;
  disabled: boolean;
};

const PrintCheckBox: React.FC<PrintCheckBoxProps> = ({ name, value, onChange, disabled }) => {
  return (
    <FormControlLabel
      key={name}
      className={styles.label}
      control={<Checkbox color="primary" value={value} checked={value} onChange={onChange} disabled={disabled} />}
      label={name}
      labelPlacement="start"
      color="primary"
    />
  );
};

export default PrintCheckBox;
