import { gql } from "@apollo/client";
export default gql`
  query GetPatient($patientId: Int) {
    patient(id: $patientId) {
      fullnameTitleCase
      dob
      sex
      street
      city
      state
      postcode
      mobile
      fullAddress
      title
      firstname
      surname
      fullname
      middlename
      medicare
      dvacode
      species
    }
  }
`;
