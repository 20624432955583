/* eslint-disable no-empty */
import { ApolloClient } from "@apollo/client";
import {
  GetEpisodeReports as GetEpisodeReportsType,
  GetEpisodeReportsVariables,
} from "../components/PatientReportsCondense/types/GetEpisodeReports";
import GetEpisodeReports from "../components/PatientReportsCondense/GetEpisodeReports";
import GetPatientEpisodes from "../components/PatientDetailsPage/GetPatientEpisodes";
import {
  GetPatientEpisodes as GetPatientEpisodesType,
  GetPatientEpisodesVariables,
} from "../components/PatientDetailsPage/types/GetPatientEpisodes";
import { GetEpisodes, GetEpisodesVariables } from "../components/PatientListTable/types/GetEpisodes";
import GetEpisodesQuery from "../components/PatientListTable/GetEpisodesQuery";
import { SearchEpisodes, SearchEpisodesVariables } from "../components/PatientEpisodesPage/types/SearchEpisodes";
import SearchEpisodesQuery from "../components/PatientEpisodesPage/SearchEpisodesQuery";

export const updateEpisodeReportsCache = (
  client: ApolloClient<any>,
  reportId: string,
  variables: GetEpisodeReportsVariables,
): void => {
  try {
    const existingData = client.cache.readQuery<GetEpisodeReportsType>({
      query: GetEpisodeReports,
      variables: variables,
    });

    existingData &&
      client.cache.writeQuery<GetEpisodeReportsType>({
        query: GetEpisodeReports,
        variables: variables,
        data: {
          episodereports: {
            __typename: "EpisodeReportsResult",
            reports: existingData.episodereports.reports.map((report: any) => {
              if (report.reportid === parseInt(reportId)) {
                return { ...report, dateViewed: new Date().toISOString() };
              }
              return report;
            }),
            histoImages: existingData.episodereports.histoImages,
          },
        },
      });
  } catch {}
};

export const updatePatientepisodesCache = (
  client: ApolloClient<any>,
  reportId: string,
  variables: GetPatientEpisodesVariables,
): void => {
  try {
    const existingData = client.cache.readQuery<GetPatientEpisodesType>({
      query: GetPatientEpisodes,
      variables: variables,
    });

    existingData &&
      client.cache.writeQuery<GetPatientEpisodesType>({
        query: GetPatientEpisodes,
        variables: variables,
        data: {
          patientepisodes: {
            __typename: "PatientEpisodesResult",
            episodes: existingData.patientepisodes.episodes.map((episode) => {
              return {
                __typename: "PatientEpisode",
                labnoDigitOnly: episode.labnoDigitOnly,
                panels: episode.panels.map((panel) => {
                  if (panel.reportid === parseInt(reportId)) {
                    return { ...panel, dateViewed: new Date().toISOString() };
                  }
                  return panel;
                }),
              };
            }),
          },
        },
      });
  } catch {}
};

export const updatePatientepisodesAbnormalCache = (
  client: ApolloClient<any>,
  reportId: string,
  variables: GetPatientEpisodesVariables,
): void => {
  try {
    const existingData = client.cache.readQuery<GetPatientEpisodesType>({
      query: GetPatientEpisodes,
      variables: variables,
    });

    existingData &&
      client.cache.writeQuery<GetPatientEpisodesType>({
        query: GetPatientEpisodes,
        variables: variables,
        data: {
          patientepisodes: {
            __typename: "PatientEpisodesResult",
            episodes: existingData.patientepisodes.episodes.map((episode) => {
              return {
                __typename: "PatientEpisode",
                labnoDigitOnly: episode.labnoDigitOnly,
                panels: episode.panels.map((panel) => {
                  if (panel.reportid === parseInt(reportId)) {
                    return { ...panel, hasAbnormalStatus: true };
                  }
                  return panel;
                }),
              };
            }),
          },
        },
      });
  } catch {}
};

export const updateEpisodes = (client: ApolloClient<any>, reportId: string, variables: GetEpisodesVariables): void => {
  try {
    const existingData = client.cache.readQuery<GetEpisodes>({
      query: GetEpisodesQuery,
      variables: variables,
    });

    existingData &&
      client.cache.writeQuery<GetEpisodes>({
        query: GetEpisodesQuery,
        variables: variables,
        data: {
          episodesResult: {
            __typename: "EpisodesResult",
            lastReport: {
              __typename: "Report",
              reportid: existingData.episodesResult.lastReport.reportid,
            },
            episodes: existingData.episodesResult.episodes.map((episode) => {
              return {
                __typename: "Episode",
                labid: episode.labid,
                reportid: episode.reportid,
                patientid: episode.patientid,
                fullnameTitleCase: episode.fullnameTitleCase,
                dob: episode.dob,
                dateCreated: episode.dateCreated,
                allEpisodeTests: episode.allEpisodeTests,
                referrer: episode.referrer,
                isRead:
                  episode.panels?.filter((p) => p.reportid !== parseInt(reportId)).every((p) => p.isRead) || false,
                labnoDigitOnly: episode.labnoDigitOnly,
                reportingProviderNo: episode.reportingProviderNo,
                hasAbnormalStatus: episode.hasAbnormalStatus,
                panels:
                  episode.panels &&
                  episode.panels.map((panel) => {
                    if (panel.reportid === parseInt(reportId)) {
                      return {
                        ...panel,
                        firstViewed: panel.firstViewed === null ? new Date().toISOString() : panel.firstViewed,
                        dateViewed: new Date().toISOString(),
                        lastViewed: new Date().toISOString(),
                        isRead: true,
                      };
                    }
                    return panel;
                  }),
                urgentStatus: episode.urgentStatus,
              };
            }),
          },
        },
      });
  } catch {}
};

export const updateSearchEpisodesCache = (
  client: ApolloClient<any>,
  reportId: string,
  variables: SearchEpisodesVariables,
): void => {
  try {
    const existingData = client.cache.readQuery<SearchEpisodes>({
      query: SearchEpisodesQuery,
      variables: { ...variables },
    });

    existingData &&
      client.cache.writeQuery<SearchEpisodes>({
        query: SearchEpisodesQuery,
        variables: variables,
        data: {
          episodesSearchResult: {
            __typename: "EpisodesSearchResult",
            lastReport: {
              __typename: "Report",
              reportid: existingData.episodesSearchResult.lastReport.reportid,
            },
            episodes: existingData.episodesSearchResult.episodes.map((episode) => {
              return {
                __typename: "Episode",
                labid: episode.labid,
                reportid: episode.reportid,
                patientid: episode.patientid,
                status: episode.status,
                fullnameTitleCase: episode.fullnameTitleCase,
                dob: episode.dob,
                dateCreated: episode.dateCreated,
                allEpisodeTests: episode.allEpisodeTests,
                referrer: episode.referrer,
                isRead:
                  episode.panels?.filter((p) => p.reportid !== parseInt(reportId)).every((p) => p.isRead) || false,
                labnoDigitOnly: episode.labnoDigitOnly,
                reportingProviderNo: episode.reportingProviderNo,
                hasAbnormalStatus: episode.hasAbnormalStatus,
                panels:
                  episode.panels &&
                  episode.panels.map((panel) => {
                    if (panel.reportid === parseInt(reportId)) {
                      return {
                        ...panel,
                        firstViewed: panel.firstViewed === null ? new Date().toISOString() : panel.firstViewed,
                        dateViewed: new Date().toISOString(),
                        lastViewed: new Date().toISOString(),
                        isRead: true,
                      };
                    }
                    return panel;
                  }),
                urgentStatus: episode.urgentStatus,
              };
            }),
          },
        },
      });
  } catch {}
};
