import { gql } from "@apollo/client";
export default gql`
  query GetAtomicComments($atomicId: Int!) {
    getAtomicComments(atomicId: $atomicId) {
      id
      username
      date
      action
      value
      type
      searchKey1
      searchKey2
    }
  }
`;
