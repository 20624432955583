import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StudyTable from "./StudyTable";
import GeneralHeader from "../GeneralHeader/GeneralHeader";

const StudyPage: React.FC = () => {
  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
      height: "100vh",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <GeneralHeader></GeneralHeader>
        <StudyTable />
      </Box>
    </>
  );
};
export default StudyPage;
