import { PDFDocument } from "pdf-lib";

export const mergePdfs = async (pdfsToMerge: string[]): Promise<Uint8Array> => {
  const mergedPdf = await PDFDocument.create();
  for (const pdfBytes of pdfsToMerge) {
    const pdf = await PDFDocument.load(pdfBytes);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  }
  const mergedPdfFile = await mergedPdf.save();
  return mergedPdfFile;
};

export const toObjURL = (bytes: Uint8Array): string => {
  const blob: Blob = new Blob([bytes], { type: "application/pdf" });
  return URL.createObjectURL(blob);
};

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
