import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./CPDprograms.module.css";
import theme from "../theme";
import { useQuery } from "@apollo/client";
import authService from "../../services/authService";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import GetCytologyAdequacyQuery from "./GetCytologyAdequacyQuery";
import { getCytologyAdequacy, getCytologyAdequacy_getCytologyAdequacy_graph } from "./types/getCytologyAdequacy";
import AppContext from "../../context/AppContext";
import { NO_DB_CONNECTION } from "../../constants";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

interface PathParams {
  id: string;
}

export type CpdCytologyTabProps = {
  chart1: string;
  isLoading: (value: boolean) => void;
};

const CPDcytologyTab3: React.FC<CpdCytologyTabProps> = (props) => {
  const chartRef = useRef<any>(null);
  const providers = authService.getCpdProviderValues();
  const params: PathParams = useParams() as PathParams;
  const setError = useContext(AppContext).setError;

  const [graph, setGraph] = useState<getCytologyAdequacy_getCytologyAdequacy_graph[]>([]);
  const [graphY, setGraphY] = useState<any[]>([]);
  const [graphN, setGraphN] = useState<any[]>([]);
  const [label, setLabel] = useState<any[]>([]);

  const { data, loading, error } = useQuery<getCytologyAdequacy>(GetCytologyAdequacyQuery, {
    variables: {
      id: parseInt(params.id),
      providers: providers,
    },
  });

  useEffect(() => {
    if (data) {
      setGraph(data?.getCytologyAdequacy.graph);

      const dataY = Object.values(data?.getCytologyAdequacy.graph[0]).splice(1);
      const dataN = Object.values(data?.getCytologyAdequacy.graph[1]).splice(1);
      if (data?.getCytologyAdequacy.graph && data?.getCytologyAdequacy.graph.length > 0) {
        setLabel(Object.keys(data?.getCytologyAdequacy.graph[0]).splice(1));
        setGraphY(dataY);
        setGraphN(dataN);
      }
      setError("");
    }

    props.isLoading(loading);
  }, [data, loading]);

  if (error) {
    setError(NO_DB_CONNECTION);
  }

  const graphData = {
    labels: label.map((l) => l.replaceAll("_", " ").toUpperCase()), //remove doctor_name
    datasets: [
      {
        axis: "y",
        label: "You %",
        data: graphY.map((g) => g * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.main,
        color: "white",
      },
      {
        axis: "y",
        label: "National %",
        data: graphN.map((g) => g * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.dark,
        color: "white",
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value: number) {
          const theValue = value !== 0 ? (value === 100 ? value : value.toFixed(1)) : "";
          return theValue + "%";
        },
        color: "#ffffff",
        font: {
          weight: "bold",
        } as const,
      },
      title: {
        display: true,
        text: "Sample Adequacy",
        font: {
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
      },
    },
    tooltip: {
      filter: (item: any) => {
        if (item.dataset.label.includes("hide")) {
          return false;
        }
        return true;
      },
      enabled: true,
    },
    scales: {
      x: {
        min: 0,
        max: 100,
      },
    },
  };

  if (loading) return <Loading />;

  return (
    <Box mt={[3, 12]} ml={[2, 4]} mr={[2, 4]}>
      <Box className={styles.cytoTabPageHeader}>Sample Adequacy</Box>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} md={6}>
          {graph && <Bar id={props.chart1} ref={chartRef} width={"100%"} options={options} data={graphData} />}
        </Grid>
        <Grid item xs={12}>
          <Typography>This item represents the adequacy of HPV samples for molecular testing only.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDcytologyTab3;
