/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

type AppContextProps = {
  isDesktop: boolean;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  errorAcknowledged: boolean;
  setErrorAcknowledged: React.Dispatch<React.SetStateAction<boolean>>;
  testName: string;
  setTestName: React.Dispatch<React.SetStateAction<string>>;
  refreshResult: boolean;
  setRefreshResult: React.Dispatch<React.SetStateAction<boolean>>;
  autoRefreshResult: boolean;
  setAutoRefreshResult: React.Dispatch<React.SetStateAction<boolean>>;
  previewMode: boolean;
  setPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
  success: string;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
  successAcknowledged: boolean;
  setSuccessAcknowledged: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppContext = React.createContext<AppContextProps>({
  isDesktop: false,
  error: "",
  setError: () => {},
  errorAcknowledged: false,
  setErrorAcknowledged: () => {},
  testName: "",
  setTestName: () => {},
  refreshResult: false,
  setRefreshResult: () => {},
  autoRefreshResult: false,
  setAutoRefreshResult: () => {},
  previewMode: false,
  setPreviewMode: () => {},
  success: "",
  setSuccess: () => {},
  successAcknowledged: false,
  setSuccessAcknowledged: () => {},
});

export default AppContext;
