import authService from "../services/authService";
import { saveAs } from "file-saver";
import { PDFDocument } from "pdf-lib";

export const downloadHelper = async (blob: string | Blob | any, filename: string, isCt = false): Promise<void> => {
  const mobility = authService.isMobility();
  let pdfFile = blob;

  if (isCt) {
    const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
    const totalPages = pdfDoc.getPageCount();

    for (let i = 0; i < totalPages; i++) {
      const page = pdfDoc.getPages()[i];
      const text = `Page ${i + 1} of ${totalPages}`;

      page.drawText(text, {
        x: 290,
        y: 32,
        size: 6,
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();

    pdfFile = new Blob([modifiedPdfBytes], {
      type: "application/pdf",
    });
  }

  if (mobility) {
    const reader = new FileReader();
    reader.readAsDataURL(pdfFile);
    reader.onloadend = () => {
      const base64data = (reader.result as string).split(",")[1];
      const link = document.createElement("a");
      link.download = filename;
      link.href = "data:attachment/file;base64," + base64data;
      link.click();
    };
  } else {
    saveAs(pdfFile, filename);
  }
};
