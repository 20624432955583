import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import loginMutation from "../LoginPage/loginMutation";
import authService from "../../services/authService";
import { useMutation } from "@apollo/client";
import { Login, LoginVariables } from "../LoginPage/types/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import theme from "../theme";

interface DialogBoxPasswordProps {
  onLoginSuccess: (approve: boolean) => void;
  disabled?: boolean;
  buttonText?: string;
  buttonStyle?: string;
  isApprove: boolean;
  isOffline: boolean;
}

type PasswordDialog = {
  password: string;
};

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  approveButton: {
    backgroundColor: "#5ed840 !important",
    color: "#ffffff !important",
    marginRight: "5px !important",
    padding: "0px 10px !important",
    boxShadow: "none !important",
  },
  clearButton: {
    backgroundColor: "#ffffff !important",
    color: theme.colorSchemes.light.palette.primary.main + " !important",
    marginRight: "5px !important",
    padding: "0px 10px !important",
    textDecoration: "underline !important",
    border: "none !important",
    boxShadow: "none !important",
    align: "right !important",
    "&:hover": {
      backgroundColor: "#ffffff !important",
      color: theme.colorSchemes.light.palette.primary.main + " !important",
      marginRight: "5px !important",
      padding: "0px 10px !important",
      textDecoration: "underline !important",
      border: "none",
      boxShadow: "none",
    },
  },
}));

const DialogBoxPassword: React.FC<DialogBoxPasswordProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [login] = useMutation<Login, LoginVariables>(loginMutation);

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues: PasswordDialog = {
    password: "",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Box display="flex"  justifyContent="flex-end" width={"100%"}> */}
      <Button
        disabled={props.isOffline ? props.isOffline : props.disabled ? props.disabled : false}
        variant="contained"
        // color={props.buttonStyle ? "inherit" : "primary"}
        onClick={handleClickOpen}
        className={
          props.buttonStyle
            ? props.buttonStyle === "approve"
              ? classes.approveButton
              : props.buttonStyle === "clear"
                ? classes.clearButton
                : classes.divider
            : classes.divider
        }
      >
        {props.isApprove ? "Approve" : "Clear"}
      </Button>
      {/* </Box> */}
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: any = {};
          if (!values.password) {
            errors.password = "Please input password";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const useragent = navigator.userAgent;
            const mobility = authService.isMobility();
            const username = authService.getUsername();
            const password = values.password;
            const setCookie = false;

            const result = await login({
              variables: {
                input: {
                  username,
                  password,
                  useragent,
                  mobility,
                  setCookie,
                },
              },
            });
            if (result.data && props.onLoginSuccess) {
              setSubmitting(false);
              await props.onLoginSuccess(props.isApprove);
              handleClose();
            }
          } catch (e) {
            const errors: any = {};
            errors.password = "Invalid credential. Please try again!";
            setErrors(errors);
            setSubmitting(false);
            // don't do anything - the error will be displayed on screen
          }
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Dialog
              open={open}
              onClose={() => {
                values.password = "";
                handleClose();
              }}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                <DialogBoxTitle title="Approval" width={200} closeButton handleClose={handleClose} />
              </DialogTitle>

              <DialogContent>
                <form onSubmit={handleSubmit}>
                  <DialogContentText>Please enter your login password to submit approval.</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                  <Typography color="error">{errors.password}</Typography>
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Box width={"100%"} textAlign="center">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default DialogBoxPassword;
