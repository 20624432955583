import React, { useEffect, useState } from "react";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetCpdDiabeticPatients, GetCpdDiabeticPatients_getCpdDiabeticPatients } from "./types/GetCpdDiabeticPatients";
import GetCpdDiabeticPatientsQuery from "./GetCpdDiabeticPatientsQuery";
import authService from "../../services/authService";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import Loading from "../Loading/Loading";
import { makeStyles } from "@mui/styles";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { DownloadMenuIcon, PrintMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import theme from "../theme";
import styles from "./CPDprograms.module.css";
import { isDesktop } from "react-device-detect";
import { downloadHelper } from "../../utils/downloadHelper";

const useStyles = makeStyles(() => ({
  infoBox: {
    borderRadius: "15px",
    padding: "0 15px 15px 15px",
  },
  infoBox2: {
    borderRadius: "15px",
  },
  infoHeader: {
    backgroundColor: "#f7f7f7",
    marginLeft: "-15px",
    width: "calc(100% + 30px)",
    borderRadius: "15px 15px 0 0",
    padding: "0 15px",
  },
  infoHeader2: {
    backgroundColor: "#f7f7f7",
  },
  infoHeaderText: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontWeight: "bold",
    fontSize: "14px",
    margin: "5px 0",
  },
  infoRow: {
    borderBottom: "1px solid #eaebee",
  },
  infoText: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontSize: "14px",
    margin: "5px 0",
  },
  infoText2: {
    color: "#979797",
    fontSize: "14px",
    margin: "5px 0",
  },
  infoText3: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontSize: "18px",
    fontWeight: "bold",
    margin: "5px 0",
  },
  infoText4: {
    color: theme.colorSchemes.light.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "bold",
    margin: "5px 0",
  },
  infoText5: {
    color: "#808080",
    fontSize: "14px",
    margin: "5px 0",
  },
  infoText6: {
    color: theme.colorSchemes.light.palette.primary.dark,
    fontSize: "14px",
    margin: "5px 0",
  },
  description: {
    marginTop: "10px",
    color: "#bfbfbf",
    fontSize: "14px",
  },
  descriptionTinyWithout1: {
    marginTop: "10px",
    color: "#bfbfbf",
    fontSize: "12px",
    "&::before": {
      fontSize: "8px",
      marginRight: "1px",
      verticalAlign: "top",
    },
  },
  descriptionTiny: {
    marginTop: "10px",
    color: "#bfbfbf",
    fontSize: "12px",
    "&::before": {
      content: '"1"',
      fontSize: "8px",
      marginRight: "1px",
      verticalAlign: "top",
    },
  },
  descriptionRef: {
    marginTop: "10px",
    color: "#bfbfbf",
    fontSize: "14px",
    "&::after": {
      content: '"1"',
      fontSize: "8px",
      marginRight: "1px",
      verticalAlign: "top",
    },
  },
  spaceRight: {
    marginRight: "5px",
    cursor: "pointer",
  },
  list: {
    color: "#bfbfbf",
    listStyleType: "disc",
    fontSize: "14px",
  },
  listItem: {
    display: "list-item",
    padding: 0,
    marginLeft: "20px",
    paddingRight: "20px",
  },
  listItemRef: {
    display: "list-item",
    padding: 0,
    marginLeft: "20px",
    paddingRight: "20px",
    "&::after": {
      content: '"1"',
      fontSize: "10px",
      marginLeft: "1px",
      verticalAlign: "top",
    },
  },
}));

const CPDdiabetesTab0: React.FC = () => {
  const classes = useStyles();
  const [diabeticPatientsData, setDiabeticPatientsData] = useState<GetCpdDiabeticPatients_getCpdDiabeticPatients>();
  const [chartData, setChartData] = useState<any>();
  const testedColor = theme.colorSchemes.light.palette.primary.main;
  const notTestedColor = theme.colorSchemes.light.palette.primary.dark;

  const { data, loading, error } = useQuery<GetCpdDiabeticPatients>(GetCpdDiabeticPatientsQuery, {
    variables: {
      providerNo: authService.getCpdProviderValues(),
    },
    // fetchPolicy: "no-cache",
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right" as const,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => "",
          label: (ctx: any) => {
            const dataset = ctx.chart.data.datasets[ctx.datasetIndex];
            return (dataset.labels[ctx.dataIndex] as string).split("-");
          },
        },
      },
      datalabels: {
        color: "#ffffff",
        formatter: (_v: any, ctx: any) => {
          const index = ctx.dataIndex;
          return `${ctx.dataset.data[index]}%`;
        },
        font: {
          weight: "bold",
          size: 14,
        } as const,
      },
    },
  };

  useEffect(() => {
    if (data && data.getCpdDiabeticPatients) {
      setDiabeticPatientsData(data.getCpdDiabeticPatients);

      const chartData = {
        labels: !isDesktop
          ? [
              ["% Patients that had not", "had HbA1c test in 6 months"],
              ["% Patients that had", "HbA1c test in 6 months"],
            ]
          : ["% Patients that had not had HbA1c test in 6 months", "% Patients that had HbA1c test in 6 months"],
        datasets: [
          {
            label: "Your Patients",
            data: [data.getCpdDiabeticPatients.percentageNotTested, data.getCpdDiabeticPatients.percentageTested],
            labels: [
              " % Your patients have not had - HbA1c test in 6 months",
              " % Your patients had - HbA1c test in 6 months",
            ],
            backgroundColor: [notTestedColor, testedColor],
          },
          {
            label: "State Patients",
            data: [
              data.getCpdDiabeticPatients.percentageNotTestedForState,
              data.getCpdDiabeticPatients.percentageTestedForState,
            ],
            labels: [
              " % State patients have not had - HbA1c test in 6 months",
              " % State patients had - HbA1c test in 6 months",
            ],
            backgroundColor: [notTestedColor, testedColor],
          },
        ],
      };

      setChartData(chartData);
    }
  }, [data]);

  if (error) {
    return <ErrorPopover errorMessage={error.message} />;
  }

  const thousandSeparator = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const downloadChart = () => {
    const canvas = document.getElementById("pieChart") as HTMLCanvasElement;
    const dataUrl = canvas.toDataURL("image/png");
    downloadHelper(dataUrl, "diabetic-patients-chart.png");
  };

  const printChart = () => {
    window.print();
  };

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <Grid container mr={[2, 4]} justifyContent={"flex-start"} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={11} lg={12}>
            <Grid container>
              <Grid item xs={12} p={2} display={"flex"} justifyContent={"flex-end"} className={styles.hideForPrinting}>
                <DownloadMenuIcon
                  title="Download Chart"
                  onClick={downloadChart}
                  class={`${classes.spaceRight} ${styles.hideDisplay}`}
                />
                <PrintMenuIcon title="Print" onClick={printChart} />
              </Grid>
              <Grid item xs={12} sm={12} lg={5} m={2} ml={8}>
                <Box className={classes.infoBox} sx={{ boxShadow: 3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container className={classes.infoHeader}>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoHeaderText}>You</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoHeaderText}>State</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={8}>
                          <Typography className={classes.infoText}>Number of patients with Diabetes*</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {thousandSeparator(diabeticPatientsData ? diabeticPatientsData.diabeticPatientCount : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {thousandSeparator(
                              diabeticPatientsData ? diabeticPatientsData.diabeticPatientCountForState.patientCount : 0,
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={8}>
                          <Typography className={classes.infoText}>
                            % of patients with Diabetes tested for HbA1c in previous 6 months
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {`${diabeticPatientsData?.percentageTested}%`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {`${diabeticPatientsData?.percentageTestedForState}%`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={8}>
                          <Typography className={classes.infoText}>Current number of logins this year</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {thousandSeparator(diabeticPatientsData ? diabeticPatientsData.loginCount : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={8}>
                          <Typography className={classes.infoText}>Current number of HbA1c tests this year</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography className={classes.infoText5}>
                            {thousandSeparator(diabeticPatientsData ? diabeticPatientsData.reportCount : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={12}>
                      <List className={classes.list}>
                        Measure glycated haemoglobin (HbA1c) on an individual basis:
                        <ListItem className={classes.listItem}>
                          three-monthly in newly diagnosed patients, patients undergoing therapeutic changes or those
                          whose HbA1c is outside their individualised target range.
                        </ListItem>
                        <ListItem className={classes.listItemRef}>
                          less frequently, if appropriate, in stable patients who have reached agreed targets.
                        </ListItem>
                      </List>
                      <Typography className={classes.descriptionRef}>
                        Routine investigations are best organised before the review appointment.
                      </Typography>
                      <Typography className={classes.descriptionTiny}>
                        The Royal Australian College of General Practitioners. Management of type 2 diabetes: A handbook
                        for general practice. East Melbourne, Vic: RACGP, 2020.
                      </Typography>
                      <Typography className={classes.descriptionTinyWithout1}>
                        *Patients whose HbA1c percentage is 6.5 and over, or if patient is a known diabetic
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} pt={!isDesktop ? 2 : 0} m={2}>
                {chartData && <Pie id="pieChart" data={chartData} options={options} />}
              </Grid>
              <Grid item xs={12} sm={12} md={12} m={2}>
                <Box className={classes.infoBox2} mt={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container className={classes.infoHeader2}>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                          <Typography className={classes.infoHeaderText}>Urine Microalbumins (UMA)</Typography>
                        </Grid>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                          <Typography className={classes.infoHeaderText}>Cholesterol</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                          <Typography className={classes.infoText2}>
                            Number of HbA1c patients who have not had UMA
                          </Typography>
                        </Grid>
                        <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                          <Typography className={classes.infoText2}>
                            Number of HbA1c patients who have not had cholesterol
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.infoRow}>
                        <Grid item xs={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                          <Typography className={classes.infoText}>{`> 12 months`}</Typography>
                          <Typography className={classes.infoText3}>
                            {thousandSeparator(diabeticPatientsData ? diabeticPatientsData?.month12UmaPatientCount : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                          <Typography className={classes.infoText}>{`> 24 months`}</Typography>
                          <Typography className={classes.infoText3}>
                            {thousandSeparator(diabeticPatientsData ? diabeticPatientsData?.month24UmaPatientCount : 0)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                          <Typography className={classes.infoText6}>{`> 12 months`}</Typography>
                          <Typography className={classes.infoText4}>
                            {thousandSeparator(
                              diabeticPatientsData ? diabeticPatientsData?.month12CholPatientCount : 0,
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                          <Typography className={classes.infoText6}>{`> 24 months`}</Typography>
                          <Typography className={classes.infoText4}>
                            {thousandSeparator(
                              diabeticPatientsData ? diabeticPatientsData?.month24CholPatientCount : 0,
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CPDdiabetesTab0;
