import React, { Fragment, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppBar, Box, Typography, Button, ButtonGroup, IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GetCumulativeAtomicValues,
  GetCumulativeAtomicValues_atomic,
  GetCumulativeAtomicValues_atomic_atomicData,
} from "./types/GetCumulativeAtomicValues";
import GetCumulativeAtomicValuesQuery from "./GetCumulativeAtomicValuesQuery";
import authService from "../../services/authService";
import { CumulativeContext } from "../../context/CumulativeContext";
import Loading from "../Loading/Loading";
import CumulativeTable from "../CumulativeTable/CumulativeTable";
import Scrollbars from "react-custom-scrollbars-2";
import PanelReports from "../PanelReports/PanelReportsPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MarkAsViewedMutation from "../common/MarkAsViewedMutation/MarkAsViewedMutation";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { MarkAsViewed } from "../common/MarkAsViewedMutation/types/MarkAsViewed";
import { updatePatientepisodesCache } from "../../utils/updateApolloCache";
import setViewed from "../../utils/setViewed";
import GetPatientEpisodes from "../PatientDetailsPage/GetPatientEpisodes";
import { GetPatientEpisodes as GetPatientEpisodesQueryType } from "../PatientDetailsPage/types/GetPatientEpisodes";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import thm from "../theme";
import CsvDownloader from "react-csv-downloader";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";
import helpers from "../../utils/helpers";
// import AtomicNamesHeader from "../AtomicNamesHeader/AtomicNamesHeader";
// import AtomicMostRecentColumn from "../AtomicMostRecentColumn/AtomicMostRecentColumn";
// import AtomicRangeColumn from "../AtomicRangeColumn/AtomicRangeColumn";
// import AtomicMeasurementColumn from "../AtomicMeasurementColumn/AtomicMeasurementColumn";
// import AtomicValuesColumn from "../AtomicValuesColumn/AtomicValuesColumn";
// import { isIE } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  headerBar: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light + " !important;",
    height: "150px",
  },
  title: {
    fontWeight: "bold",
  },
  graph: {
    color: thm.colorSchemes.light.palette.primary.dark + " !important",
    height: "25px !important",
    width: "25px !important",
  },
  reportPanel: {
    borderColor: "#e9e9e9",
    borderStyle: "solid",
    borderWidth: "0px 1px 1px 1px",
    //border: 'solid #e9e9e9',
  },
  item: {
    borderTop: "1px solid #e9e9e9",
  },
  headerColumn: {
    minWidth: "180px",
  },
  mostRecentValueColumn: {
    minWidth: "125px",
    borderColor: thm.colorSchemes.light.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "0px 3px 3px",
    //border: '3px solid #753bbd'
  },
  valueColumn: {
    minWidth: "125px",
    display: "flex",
  },
  olderValueColumn: {
    minWidth: "125px",
    flexGrow: 1,
  },
  rangeColumn: {
    minWidth: "120px",
    backgroundColor: "#f5f9fd",
  },
  mostRecentHeader: {
    backgroundColor: thm.colorSchemes.light.palette.primary.main + " !important",
  },
  downloadcsvButton: {
    padding: "3px",
    marginLeft: "8px",
  },
  downloadcsv: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
    height: "35px !important",
    width: "35px !important",
  },
  scrollbar: {
    minHeight: "200px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 1px #fafafa",
      border: "3px 0px solid white",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4e5962",
      borderRadius: "10px",
      border: "3px solid white",
    },
  },
  dummyDivScrollbar: {
    width: "885.5px",
    border: "0px solid red",
    position: "fixed",
    bottom: 0,
    overflowX: "scroll",
  },
  fakeContentForScrolling: {
    minHeight: "1px",
    width: "1000px",
  },
  reportsScrollbar: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 1px #fafafa",
      border: "3px 0px solid white",
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2) !important",
      borderRadius: "10px",
      border: "3px solid white",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#4e5962 !important",
    },
  },
  // ieHeaderBar: {
  //   position: 'fixed',
  //   left: '0 !important',
  //   top: '144px !important',
  //   marginLeft: '400px !important',
  //   /* To adjust for the margin-left */
  //   // width: calc(100% - 400px) !important;
  // },
}));

export type CumulativePanelProps = {
  allPatients: boolean;
  sortBy: string;
  showRemovedPanels: boolean;
  height: number;
  patient?: any;
};

const CumulativePanel: React.FC<CumulativePanelProps> = (props) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { panelId, testname, setShowPanelRep, showPanelRep, currentReportId } = useContext(CumulativeContext);
  const { setShowIndGraph, showIndGraph, setShowCulGraph, showCulGraph } = useContext(CumulativeContext);
  const [downloadChart, setDownloadChart] = useState(false);
  const [isChartDownloading, setIsChartDownloading] = useState(false);
  const [openChartView, setOpenChartView] = useState(false);

  const providers: string[] = authService.getProviders();

  const patientId = Number(id);

  const [downloadCSV, setDownloadCSV] = React.useState<Datas>([]);

  const callBackCumulativeCSVData = React.useCallback((downloadCSV) => {
    setDownloadCSV(downloadCSV);
  }, []);

  const scrollRef = React.createRef<Scrollbars>();
  const mobility = authService.isMobility();

  useEffect(() => {
    if (!isChartDownloading) {
      setDownloadChart(false);
    }
  }, [isChartDownloading]);

  const {
    data: patientEpisodesData,
    loading: patientEpisodesLoading,
    error: patientEpisodesError,
  } = useQuery<GetPatientEpisodesQueryType>(GetPatientEpisodes, {
    variables: {
      patientId: patientId,
      providers: authService.getProviders(),
      allPatients: props.allPatients,
      sortBy: props.sortBy,
      showRemovedPanels: props.showRemovedPanels,
    },
  });

  const { data, loading, error } = useQuery<GetCumulativeAtomicValues>(GetCumulativeAtomicValuesQuery, {
    variables: {
      providers: providers,
      patientId: patientId,
      panelId: panelId,
      allPatients:
        props.allPatients && authService.getAllowAllPatients() && authService.getAllowAllPatientsAcknownledged(),
    },
  });

  const [markAsViewed] = useMutation<MarkAsViewed>(MarkAsViewedMutation, {
    ignoreResults: true,
  });
  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const client = useApolloClient();

  const handleOnArrowLeftClick = () => {
    //let valuesColumnPanel = document.getElementById("valuesColumnPanel");
    //if (valuesColumnPanel) valuesColumnPanel.scrollLeft -= 125;
    scrollRef.current && scrollRef.current.scrollLeft(scrollRef.current.getScrollLeft() - 125);
  };

  const handleOnArrowRightClick = () => {
    //let valuesColumnPanel = document.getElementById("valuesColumnPanel");
    //if (valuesColumnPanel) valuesColumnPanel.scrollLeft += 125;
    scrollRef.current && scrollRef.current.scrollLeft(scrollRef.current.getScrollLeft() + 125);
  };

  if (loading || patientEpisodesLoading) {
    return <Loading />;
  }
  if (error || patientEpisodesError) {
    return <h1>There has been an error.</h1>;
  }

  const openPanelReports = (value: boolean | false) => {
    //setshowPanelReports(value)
    setShowPanelRep(value);
  };

  const openGraphPanel = (value: boolean | false) => {
    setShowCulGraph(value);
    setShowIndGraph(value);
    setOpenChartView(false);
  };

  const handleOpenChartView = () => {
    setOpenChartView(true);
  };

  // const openCumulativePanel = () => {
  //   setshowPanelReports(false)
  // }

  // type RangeMeasurement = {
  //   loinc: string;
  //   name: string;
  //   measurement: string;
  //   range: string;
  // }

  const onMarkAsViewed = (reportId: string) => {
    updatePatientepisodesCache(client, reportId, {
      patientId: patientId,
      providers: authService.getProviders(),
      allPatients: props.allPatients,
      sortBy: props.sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  let oldestValueAtomicDate = "";

  if (data && data.atomicNames && data.atomicNames.length > 0 && data.atomic && data.atomic.length > 0) {
    let validAtomiccount = 0;

    data.atomic.forEach((atomic: any) => {
      if (atomic.reportTestName != testname) {
        //skip this iteration
        return;
      }
      const loinc = atomic.atomicData;
      if (loinc && loinc?.length > 1) {
        loinc.forEach((microAtomic: any) => {
          if (helpers.isNumber(microAtomic.value) && microAtomic.value_type != "FT") {
            validAtomiccount++;
          }
        });
      }
    });
    //let result = data.atomic
    const latestResult: GetCumulativeAtomicValues_atomic | null =
      data.atomic && data.atomic[0] ? data.atomic[0] : ({} as GetCumulativeAtomicValues_atomic);
    // let latestAtomic = (data.atomic[0] && data.atomic[0].atomicData) ? data.atomic[0].atomicData : []

    // construct atomic names header
    // let atomicNameList: string[] = [];
    // atomicNameList = data.atomicNames.map((value) => (value ? value.name : ""));
    let atomicLoinCInOrders: string[] = data.atomicNames.map((value) =>
      value ? [value.loinc, value.name].join("|") : "",
    );

    let atomicCommentInOrders: string[] = [];

    const allRangesMap: Map<string, GetCumulativeAtomicValues_atomic_atomicData> = new Map();
    const selectedReportId: string[] = [];
    if (data.atomic) {
      data.atomic.forEach((a) => {
        if (a && a.atomicData) {
          a.atomicData.forEach((ad) => {
            if (ad) {
              const k = [ad.loinc, ad.name].join("|");
              const val = allRangesMap.get(k);
              if (val) {
                if (!val.range || !val.measurement) {
                  val.range = ad.range;
                  val.measurement = ad.measurement;
                }
              } else {
                allRangesMap.set(k, { ...ad });
              }
            }
          });
          selectedReportId.push(a.reportId.toString());
        }
      });

      const selectedPanel: any[] = [];
      if (patientEpisodesData && patientEpisodesData.patientepisodes && patientEpisodesData.patientepisodes.episodes) {
        patientEpisodesData.patientepisodes.episodes.forEach((episode: any) => {
          episode.panels.forEach((panel: any) => {
            if (selectedReportId.indexOf(panel.reportid.toString()) !== -1) {
              selectedPanel.push(panel);
            }
          });
        });

        selectedPanel.forEach((panel) => {
          setViewed(props.allPatients, markAsViewed, auditLog, onMarkAsViewed, panel);
        });
      }
    }
    const latestResultWithCompleteRanges = { ...latestResult };
    if (latestResultWithCompleteRanges) {
      latestResultWithCompleteRanges.atomicData = Array.from(allRangesMap.values());
    }

    // atomic range
    // let atomicRangeList: string[] = []
    // atomicRangeList = latestAtomic.map(value => (value) ? value.range : '')

    // atomic measurement
    // let atomicMeasurementList: string[] = []
    // atomicMeasurementList = latestAtomic.map(value => (value) ? value.measurement : '')

    // atomic older values
    // let nonLatestResults: (GetCumulativeAtomicValues_atomic | null)[] = [];
    // if (data && data.atomic && data.atomic.length > 1) {
    //   nonLatestResults = data.atomic.slice(1, data.atomic.length);
    // }

    if (latestResult.atomicData) {
      const extraCodes = latestResult.atomicData.filter((a: any) => {
        if (a.value_type === "FT") {
          return false;
        } else {
          return a ? !atomicLoinCInOrders.includes([a.loinc, a.name].join("|")) : true;
        }
      });

      if (extraCodes.length > 0) {
        // atomicNameList = atomicNameList.concat(
        //   extraCodes.map((a: any) => (a ? a.name : ""))
        // );
        atomicLoinCInOrders = atomicLoinCInOrders.concat(
          extraCodes.map((a: any) => (a ? [a.loinc, a.name].join("|") : "")),
        );
      }

      const extraComments = latestResult.atomicData.filter((a: any) =>
        a && a.value_type === "FT" && a.name.toLowerCase().includes("comment") ? true : false,
      );
      if (extraComments.length > 0) {
        atomicCommentInOrders = atomicCommentInOrders.concat(
          extraComments.map((a: any) => (a ? [a.loinc, a.name].join("|") : "")),
        );
      }
    }

    const oldestValueAtomic: GetCumulativeAtomicValues_atomic | null =
      data.atomic && data.atomic[data.atomic.length - 1]
        ? data.atomic[data.atomic.length - 1]
        : ({} as GetCumulativeAtomicValues_atomic);
    oldestValueAtomicDate = oldestValueAtomic ? oldestValueAtomic.reportDateCollected : "";

    return (
      <Fragment>
        <AppBar elevation={0} position="sticky" className={classes.headerBar}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems="center" height={"100%"} mx={4} my={2}>
            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} height={"100%"}>
              <Typography className={classes.title} variant="subtitle2" color="textPrimary">
                CUMULATIVE VIEW
              </Typography>
              <Typography className={classes.title} variant="h3" color="primary">
                {testname}
              </Typography>
            </Box>
            <Box display={"flex"}>
              {!showIndGraph && !showCulGraph && !showPanelRep ? (
                <ButtonGroup>
                  <Button variant="outlined" color="primary" onClick={handleOnArrowLeftClick}>
                    <KeyboardArrowLeftIcon />
                  </Button>
                  <Button variant="outlined" color="primary" onClick={handleOnArrowRightClick}>
                    <KeyboardArrowRightIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                ""
              )}
              <Box ml={2}>
                {showPanelRep ? (
                  <Button variant="contained" color="primary" onClick={() => openPanelReports(false)}>
                    Cumulative View {"  "}
                    <StackedBarChartIcon style={{ margin: "0 0 -6px 0" }} />
                  </Button>
                ) : (
                  <>
                    {!showIndGraph && !showCulGraph && (
                      <Button variant="contained" color="primary" onClick={() => openPanelReports(true)}>
                        Older Reports
                      </Button>
                    )}
                    {!showIndGraph && !showCulGraph && data.atomic.length > 1 && validAtomiccount > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenChartView}
                        style={{ marginLeft: "15px" }}
                      >
                        Chart View {"  "}
                        <StackedBarChartIcon style={{ margin: "0 -5px 0 5px" }} />
                      </Button>
                    )}
                    {(showIndGraph || showCulGraph) && (
                      <>
                        <Button variant="contained" color="primary" onClick={() => openGraphPanel(false)}>
                          Table View
                          <PivotTableChartIcon style={{ margin: "0 0 0 5px" }} />
                        </Button>
                        <IconButton
                          className={classes.downloadcsvButton}
                          title="Download Chart"
                          disabled={isChartDownloading}
                          onClick={() => setDownloadChart(true)}
                        >
                          {isChartDownloading && downloadChart ? (
                            <CircularProgress size={25} />
                          ) : (
                            <SaveAltIcon className={classes.downloadcsv} fontSize="small" />
                          )}
                        </IconButton>
                      </>
                    )}
                  </>
                )}
              </Box>
              <Box>
                {!showPanelRep && !mobility && !showCulGraph && !showIndGraph && (
                  <CsvDownloader
                    filename={`cumulative-report-${testname}`}
                    separator=","
                    wrapColumnChar=""
                    datas={downloadCSV}
                    columns={downloadCSV && downloadCSV[0] && Object.keys(downloadCSV[0])}
                  >
                    <IconButton className={classes.downloadcsvButton}>
                      <SaveAltIcon className={classes.downloadcsv} fontSize="small" />
                    </IconButton>
                  </CsvDownloader>
                )}
              </Box>
            </Box>
          </Box>
        </AppBar>

        {showPanelRep ? (
          // <Scrollbars id="PrScrollBar">
          <Box className={!mobility ? classes.reportsScrollbar : ""}>
            <PanelReports
              panelId={panelId}
              patientId={patientId}
              date={oldestValueAtomicDate}
              providers={providers}
              allPatients={props.allPatients}
            />
          </Box>
        ) : (
          <Box height={props.height - 150}>
            <Scrollbars ref={scrollRef}>
              <CumulativeTable
                totaldata={data}
                data={data.atomic}
                loincOrders={atomicLoinCInOrders}
                commentOrders={atomicCommentInOrders}
                allPatients={props.allPatients}
                callBackCumulativeCSVData={callBackCumulativeCSVData}
                latestResultWithCompleteRanges={latestResultWithCompleteRanges}
                providers={providers}
                panelId={panelId}
                patientId={patientId}
                reportId={currentReportId}
                patient={props.patient}
                episodeData={patientEpisodesData?.patientepisodes.episodes}
                downloadChart={downloadChart}
                setIsChartDownloading={setIsChartDownloading}
                openChartView={openChartView}
              />
            </Scrollbars>
          </Box>
        )}
      </Fragment>
    );
  } else {
    //setshowPanelReports(true)
    return (
      <Fragment>
        <Scrollbars>
          <AppBar elevation={0} position="sticky" className={classes.headerBar}>
            <Box
              ml={3}
              display={"flex"}
              justifyContent={"space-around"}
              flexDirection={"column"}
              height={"100%"}
              mt={2}
              mb={2}
            >
              <Typography className={classes.title} variant="subtitle2" color="textPrimary">
                CUMULATIVE VIEW
              </Typography>
              <Typography className={classes.title} variant="h3" color="primary">
                {testname}
              </Typography>
            </Box>
          </AppBar>
          <Box>
            <PanelReports
              panelId={panelId}
              patientId={patientId}
              date={oldestValueAtomicDate}
              providers={providers}
              allPatients={props.allPatients}
            />
          </Box>
        </Scrollbars>
      </Fragment>
    );
  }
};

export default CumulativePanel;
