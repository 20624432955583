import React from "react";

const PrintFrame: React.FC<any> = () => {
  return (
    <iframe
      scrolling={"no"}
      style={{
        position: "absolute",
        width: "1px",
        height: "1px",
        border: "0px",
        overflow: "hidden",
      }}
      hidden
      title="printFrame"
      id="printFrame"
      name="printFrame"
    ></iframe>
  );
};

export default PrintFrame;
