import { gql } from "@apollo/client";

export default gql`
  query GetDiabeticHba1cResults($providerNo: [String!]!) {
    getDiabeticHba1cResults(providernos: $providerNo) {
      range
      userCount
      userPercent
      statePercent
    }
  }
`;
