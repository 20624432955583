import React from "react";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { getQueryAnnouncement } from "../Announcement/types/getQueryAnnouncement";
import Announcement from "../Announcement/Announcement";

interface DialogBoxAnnouncementsProps {
  open: boolean;
  onClose: () => void;
  data: getQueryAnnouncement;
  title: string;
}

const DialogBoxAnnouncements: React.FC<DialogBoxAnnouncementsProps> = (props) => {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={"md"} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <DialogBoxTitle title={props.title} width={200} closeButton handleClose={props.onClose} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Announcement data={props.data} showMinimal></Announcement>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBoxAnnouncements;
