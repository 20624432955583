import { gql } from "@apollo/client";

export default gql`
  query GetPanelReports($panelId: Int, $patientId: Int, $date: String, $providers: [String!], $allPatients: Boolean) {
    panelreports(
      panelId: $panelId
      patientId: $patientId
      date: $date
      providers: $providers
      allPatients: $allPatients
    ) {
      reports {
        reportid
        labnumber
        dateCollected
        dateCreated
        dateReferred
        dateTested
        interimStatus
        reportingProviderNo
        referredProviderNo
        referredFirstname
        referredSurname
        testcode
        testname
        report
        urno
        pdf
        lab {
          address
          suburb
        }
        reference
      }
    }
  }
`;
