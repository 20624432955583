import { gql } from "@apollo/client";

export default gql`
  query GetFoodPrintReport($labnumber: String!) {
    foodprintreport(labnumber: $labnumber) {
      reportpdf
      reportsize
    }
  }
`;
