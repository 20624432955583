import React from "react";
import { GetCombinedReport_combinedReport } from "./types/GetCombinedReport";
import styles from "./CombinedReport.module.css";
import { Box } from "@mui/material";

type ResultsReportTextProps = {
  episode: GetCombinedReport_combinedReport;
};

const ResultsReportText: React.FC<ResultsReportTextProps> = (props) => {
  const { episode } = props;

  return (
    <Box>
      <pre
        style={{ overflowX: "auto", padding: "15px", border: "1px dashed #afa8a8", margin: "0", borderTop: "none" }}
        className={styles.reportText}
        dangerouslySetInnerHTML={{
          __html: episode.report || "Results Pending",
        }}
      ></pre>
    </Box>
  );
};

export default ResultsReportText;
