import React, { useContext, useState } from "react";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import styles from "./PrintMenu.module.css";
import { Popover, CircularProgress, Button } from "@mui/material";
import AppContext from "../../../context/AppContext";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import eOrderLogo from "../../../assets/eOrderButton.svg";
import eOrderLogoVet from "../../../assets/eOrderButtonVet.svg";
import { isTenantVet } from "../../../services/tenantService";
import theme from "../../theme";

type PrintMenuIconProps = {
  onClick: any;
  title?: string;
  disabled?: boolean;
  largeIcon?: boolean;
  alwaysLargeIcon?: boolean;
  smscEnabled?: boolean;
  eorderFormReady?: boolean;
  addOnTestEnabled?: boolean;
  class?: string;
};

export const PrintMenuIcon: React.FC<PrintMenuIconProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;

  return (
    <PrintOutlinedIcon
      titleAccess={props.title ? props.title : "Print"}
      color={isDesktop ? (props.disabled ? "disabled" : "primary") : props.disabled ? "disabled" : "primary"}
      fontSize={props.alwaysLargeIcon ? "large" : isDesktop ? "large" : "small"}
      fontWeight={300}
      className={props.class ? props.class : styles.print}
      onClick={!props.disabled ? props.onClick : null}
    />
  );
};

export const DownloadMenuIcon: React.FC<PrintMenuIconProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;
  return (
    <SaveAltIcon
      titleAccess={props.title ? props.title : "Download"}
      color={isDesktop ? (props.disabled ? "disabled" : "primary") : props.disabled ? "disabled" : "primary"}
      fontSize={props.alwaysLargeIcon ? "large" : isDesktop ? "large" : "small"}
      fontWeight={300}
      className={props.class ? props.class : styles.print}
      onClick={!props.disabled ? props.onClick : null}
    />
  );
};

export const SendMenuIcon: React.FC<PrintMenuIconProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;
  return (
    <SendAndArchiveIcon
      titleAccess={props.title ? props.title : "Send reports to my Patient Management Software via SMSC"}
      color={props.disabled ? "disabled" : "primary"}
      fontSize={props.alwaysLargeIcon ? "large" : isDesktop ? "large" : "small"}
      fontWeight={300}
      className={
        isDesktop
          ? props.disabled
            ? styles.send
            : styles.sendEnabled
          : props.disabled
            ? styles.send
            : styles.sendInherit
      }
      onClick={!props.disabled ? props.onClick : null}
    />
  );
};

export const PlusMenuIcon: React.FC<PrintMenuIconProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;

  const smallIcon: React.CSSProperties = {
    color: "white",
    fontSize: "30px",
    marginTop: "-1px",
  };

  const largeIcon: React.CSSProperties = {
    color: theme.colorSchemes.light.palette.primary.main,
    fontSize: "35px",
    cursor: "pointer",
  };

  const largeIconSpacing: React.CSSProperties = {
    color: theme.colorSchemes.light.palette.primary.main,
    fontSize: "35px",
    cursor: "pointer",
    marginRight: "24px",
  };

  return (
    <AddCircleIcon
      titleAccess={props.title ? props.title : "Add-on Test"}
      style={props.largeIcon ? (props.smscEnabled ? largeIconSpacing : largeIcon) : smallIcon}
      fontSize={isDesktop ? "inherit" : "small"}
      onClick={!props.disabled ? props.onClick : null}
    />
  );
};

export const EorderMenuIcon: React.FC<PrintMenuIconProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;

  const largeIconSpacing: React.CSSProperties = {
    color: theme.colorSchemes.light.palette.primary.main,
    fontSize: "35px",
    cursor: "pointer",
    marginRight: props.smscEnabled || props.addOnTestEnabled ? "24px" : "0px",
  };

  return props.eorderFormReady ? (
    <CoronavirusIcon
      titleAccess={props.title ? props.title : "Order COVID-19 & Respiratory Test"}
      style={largeIconSpacing}
      fontSize={isDesktop ? "inherit" : "small"}
      onClick={!props.disabled ? props.onClick : null}
    />
  ) : (
    <CircularProgress size={35} style={{ marginRight: "24px" }} />
  );
};

export const NewEorderMenuButton: React.FC<PrintMenuIconProps> = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const isDesktop = useContext(AppContext).isDesktop;

  const largeIconSpacing: React.CSSProperties = {
    background: isHovering ? theme.colorSchemes.light.palette.primary.light : "#ffffff",
    borderRadius: "20px",
    border: "solid 2px " + theme.colorSchemes.light.palette.primary.main,
    margin: props.smscEnabled || props.addOnTestEnabled ? (isDesktop ? "0px" : "-24px 24px 0 0") : "0px",
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return props.eorderFormReady ? (
    <Button
      size="medium"
      style={largeIconSpacing}
      onClick={!props.disabled ? props.onClick : null}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={isTenantVet() ? eOrderLogoVet : eOrderLogo} alt="eorder logo" style={{ width: "80px" }} />
    </Button>
  ) : (
    <CircularProgress size={35} style={{ marginRight: "24px" }} />
  );
};

type PrintPopoverProps = {
  anchorEl: any;
  handleClose: any;
  children: any;
};
export const PrintPopover: React.FC<PrintPopoverProps> = ({ anchorEl, handleClose, children }) => (
  <Popover
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  >
    {children}
  </Popover>
);
