import React, { Fragment, useContext, useState, SyntheticEvent, useEffect } from "react";
import { Typography, Box, Card, CardContent, Checkbox } from "@mui/material";
import styles from "./PatientEpisodes.module.css";
import AppContext from "../../context/AppContext";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import {
  GetPatientEpisodes as GetPatientEpisodesQueryType,
  GetPatientEpisodes_patientepisodes,
} from "../PatientDetailsPage/types/GetPatientEpisodes";
import PatientEpisodeHeader from "../PatientEpisodeHeader/PatientEpisodeHeader";
import * as H from "history";
import Loading from "../Loading/Loading";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import authService from "../../services/authService";
import GetPatientEpisodes from "../PatientDetailsPage/GetPatientEpisodes";
import get from "lodash/get";
import { CumulativeContext } from "../../context/CumulativeContext";
import { List, ListRowProps, Index, AutoSizer } from "react-virtualized";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { updatePatientepisodesCache } from "../../utils/updateApolloCache";
import { MarkAsViewed } from "../common/MarkAsViewedMutation/types/MarkAsViewed";
import MarkAsViewedMutation from "../common/MarkAsViewedMutation/MarkAsViewedMutation";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import setViewed from "../../utils/setViewed";
import * as Constants from "../../constants";
import { useCacheWithExpiration } from "../../utils/useCacheWithExpiration";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import { INTERUM_STATUS } from "../../constants";
import { useLocation } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { isTenantVet } from "../../services/tenantService";
import theme from "../theme";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";

type PatientEpisodesProps = {
  patientId: number;
  history: H.History;
  labnoDigitOnly: string;
  setLabnoDigitOnly: React.Dispatch<React.SetStateAction<string>>;
  setUrno: React.Dispatch<React.SetStateAction<string>>;
  setWard: React.Dispatch<React.SetStateAction<string>>;
  setReferringDocotr: React.Dispatch<React.SetStateAction<string>>;
  setInitialLabNo: React.Dispatch<React.SetStateAction<string>>;
  allPatients: boolean;
  sortBy: string;
  setChangeSelected: React.Dispatch<React.SetStateAction<any>>;
  reportIdSelected: any[];
  setCheckEpisode: (reportId: string) => void;
  allReportIds: any[];
  setAllReportIds: (allReportIds: string[]) => void;
  allReportFetch: boolean;
  setAllReportFetch: (allReportFetch: boolean) => void;
  showRemovedPanels: boolean;
  rescroll: boolean;
  setRescroll: (rescroll: boolean) => void;
  panel: number;
  setPanel: React.Dispatch<React.SetStateAction<number>>;
  isDesktop: boolean;
  print: boolean;
  setPrint: (print: boolean) => void;
  patient: GetPatient_patient | undefined;
};

type PatientEpisodeCardsProps = {
  patientEpisodes: GetPatientEpisodes_patientepisodes;
  pageProps: PatientEpisodesProps;
  selectedPanel: number;
  setSelectedPanel: React.Dispatch<React.SetStateAction<number>>;
  reportIdSelected: any[];
  setCheckEpisode: (reportId: string) => void;
  showRemovedPanels: boolean;
};

type TestNameProps = {
  testName: string;
  abnormal: boolean | null;
  unread: boolean | null;
  pending: boolean | null;
  deleted: boolean | null;
  reportid: number;
  checkEpisode: boolean | false;
  setCheckEpisode: (reportId: string) => void;
  interimStatus: string | null;
  onPanelClick: (e: React.SyntheticEvent) => void;
  setPanel: () => void;
  urgentStatus: boolean | null;
};

const TestName: React.FC<TestNameProps> = (props) => (
  <Box className={styles.testNameOuterBox}>
    <Box display={"inline"}>
      {props.interimStatus === INTERUM_STATUS.Final ||
      props.interimStatus === INTERUM_STATUS.FinalCorrected ||
      props.interimStatus === INTERUM_STATUS.Preliminary ? (
        <Checkbox
          checked={props.checkEpisode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.setPanel();
            props.setCheckEpisode(event.target.name);
            event.stopPropagation();
          }}
          name={props.reportid.toString()}
          color="primary"
          className={styles.episodeCheckbox}
        />
      ) : (
        ""
      )}
      {props.abnormal && (
        <HtmlTooltip
          lang={"en"}
          title={
            <Typography display={"inline"} className={`${styles.testNameTextAbnormal}`} variant={"inherit"}>
              Abnormal Report
            </Typography>
          }
        >
          <Box width={"15px"} display="inline-block">
            <Typography
              display={"inline"}
              className={`${styles.testNameText} ${styles.testNameTextAbnormal}`}
              variant={"inherit"}
            >
              &#x2731;
            </Typography>
          </Box>
        </HtmlTooltip>
      )}

      <Typography
        display={"inline"}
        className={`${styles.testNameText} ${
          props.pending || props.deleted
            ? styles.testNameTextPending
            : props.abnormal
              ? styles.testNameTextAbnormal
              : styles.testNameText
        }`}
        variant={"body1"}
        style={{ textDecoration: props.deleted ? "line-through" : "none" }}
        onClick={(e) => {
          props.onPanelClick(e);
        }}
      >
        {props.testName}
      </Typography>
      {props.urgentStatus && (
        <>
          &nbsp;
          <HtmlTooltip
            lang={"en"}
            title={
              <Typography display={"inline"} className={`${styles.testNameTextAbnormal}`} variant={"inherit"}>
                Urgent Report
              </Typography>
            }
          >
            <PriorityHighIcon
              htmlColor={theme.colorSchemes.light.palette.error.main}
              fontSize="small"
              className={styles.urgentEpisode}
            />
          </HtmlTooltip>
        </>
      )}
    </Box>
    <HtmlTooltip
      lang="en"
      title={
        props.deleted
          ? "This is a deleted report"
          : props.pending
            ? "This is a pending report"
            : props.unread
              ? "This is a new report"
              : "This report has been viewed"
      }
    >
      <Box
        className={`${styles.testNameCircle} ${
          props.pending || props.deleted
            ? styles.testNameCirclePending
            : props.unread
              ? isTenantVet()
                ? styles.testNameCircleUnreadVet
                : styles.testNameCircleUnread
              : styles.testNameCircleRead
        }`}
      />
    </HtmlTooltip>
  </Box>
);

const PatientEpisodeCardsWindow: React.FC<PatientEpisodeCardsProps> = (props) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const [hoveredPos, setHoveredPos] = React.useState(0);
  const { setIsCumulative, setShowPanelRep } = useContext(CumulativeContext);
  const listRef = React.createRef<List>();
  const location = useLocation();

  useEffect(() => {
    if (listRef && listRef.current && props.selectedPanel >= 0 && props.pageProps.rescroll) {
      listRef.current.scrollToRow(props.selectedPanel);
    } else {
      if (!props.pageProps.rescroll && props.pageProps.panel >= 0) {
        listRef.current && listRef.current.scrollToRow(props.pageProps.panel);
      }
    }

    // eslint-disable-next-line
  }, [props.selectedPanel]);

  const EpisodeCard: React.FC<ListRowProps> = ({ index, style }) => {
    const episode = props.patientEpisodes.episodes[index];
    const reported = episode.panels.filter(
      (p: any) =>
        p.interimStatus === INTERUM_STATUS.Final ||
        p.interimStatus === INTERUM_STATUS.FinalCorrected ||
        p.interimStatus === INTERUM_STATUS.Preliminary,
    );

    const pending = episode.panels.filter(
      (p: any) =>
        p.interimStatus !== INTERUM_STATUS.Final &&
        p.interimStatus !== INTERUM_STATUS.FinalCorrected &&
        p.interimStatus !== INTERUM_STATUS.Preliminary &&
        p.interimStatus !== INTERUM_STATUS.Cancelled,
    );
    const deleted = episode.panels.filter((p) => p.interimStatus === INTERUM_STATUS.Cancelled);
    const handleExpandClick = (event: SyntheticEvent, pos: number, labnumber: string) => {
      if (!isDesktop) {
        props.pageProps.history.push(
          `/report/${props.pageProps.patientId}/${props.pageProps.allPatients ? "1" : "0"}/labnumber/${
            episode.labnoDigitOnly
          }/initial/${labnumber}/${props.pageProps.sortBy}`,
          location.state,
        );
      } else {
        props.pageProps.setLabnoDigitOnly(episode.labnoDigitOnly || "UNKNOWN");
        props.pageProps.setInitialLabNo(labnumber);
        setIsCumulative(false);
        setShowPanelRep(false);
        props.setSelectedPanel(index);
        props.pageProps.setPanel(index);
      }
      props.pageProps.setRescroll(true);
      event.stopPropagation();
    };
    const handleHover = (pos: number) => {
      setHoveredPos(pos);
    };
    const hovered = index === hoveredPos;
    const active = episode.labnoDigitOnly === props.pageProps.labnoDigitOnly;

    return (
      <div key={index} style={style}>
        <Card
          className={`${styles.episodeCard} ${active ? styles.episodeCardActive : ""}`}
          onMouseOver={() => {
            handleHover(index);
          }}
        >
          <CardContent className={styles.episodeCardContent}>
            <PatientEpisodeHeader
              active={active}
              episode={episode}
              mostRecent={index === 0}
              sortBy={props.pageProps.sortBy}
              showRemovedPanels={props.showRemovedPanels}
              onHeaderClick={(e) => {
                handleExpandClick(
                  e,
                  index,
                  reported.length > 0
                    ? reported[0].labnumber
                    : pending.length > 0
                      ? pending[0].labnumber
                      : deleted.length > 0
                        ? deleted[0].labnumber
                        : "",
                );
              }}
              isDesktop={props.pageProps.isDesktop}
              patient={props.pageProps.patient}
            />
            <Box position={"relative"} p={"16px"}>
              {!active && !hovered && (
                <Box
                  className={styles.episodeCardContentGradient}
                  height={`${
                    reported.length * 32 +
                    pending.length * 32 +
                    deleted.length * 32 +
                    32 +
                    (reported.length > 0 && pending.length > 0 && deleted.length > 0
                      ? 51
                      : (reported.length > 0 && pending.length > 0) ||
                          (reported.length > 0 && deleted.length > 0) ||
                          (deleted.length > 0 && pending.length > 0)
                        ? 34
                        : 0)
                  }px`}
                />
              )}
              {reported.map((panel, panelkey) => (
                <Box key={panelkey}>
                  <TestName
                    testName={panel.testnameProperCase}
                    abnormal={panel.hasAbnormalStatus}
                    unread={panel.dateViewed === null}
                    pending={false}
                    deleted={false}
                    reportid={panel.reportid}
                    interimStatus={panel.interimStatus}
                    checkEpisode={props.reportIdSelected.includes(panel.reportid.toString()) ? true : false}
                    setCheckEpisode={(reportId) => {
                      props.setCheckEpisode(reportId);
                    }}
                    onPanelClick={(e) => {
                      handleExpandClick(e, index, panel.labnumber);
                    }}
                    setPanel={() => {
                      props.pageProps.setPanel(index);
                    }}
                    urgentStatus={panel.urgentStatus}
                  />
                </Box>
              ))}
              {pending.length > 0 && (
                <Fragment>
                  {reported.length > 0 && <hr className={styles.lineBetween} />}
                  {pending.map((panel, panelkey) => (
                    <Box key={panelkey}>
                      <TestName
                        testName={panel.testnameProperCase}
                        abnormal={false}
                        unread={false}
                        pending={true}
                        deleted={false}
                        reportid={panel.reportid}
                        interimStatus={panel.interimStatus}
                        checkEpisode={props.reportIdSelected.includes(panel.reportid.toString()) ? true : false}
                        setCheckEpisode={(reportId) => {
                          props.setCheckEpisode(reportId);
                        }}
                        onPanelClick={(e) => {
                          handleExpandClick(e, index, panel.labnumber);
                        }}
                        setPanel={() => {
                          props.pageProps.setPanel(index);
                        }}
                        urgentStatus={panel.urgentStatus}
                      />
                    </Box>
                  ))}
                </Fragment>
              )}
              {deleted.length > 0 && (
                <Fragment>
                  {(reported.length > 0 || pending.length > 0) && <hr className={styles.lineBetween} />}
                  {deleted.map((panel, panelkey) => (
                    <Box key={panelkey}>
                      <TestName
                        testName={panel.testnameProperCase}
                        abnormal={false}
                        unread={false}
                        pending={false}
                        deleted={true}
                        reportid={panel.reportid}
                        interimStatus={panel.interimStatus}
                        checkEpisode={props.reportIdSelected.includes(panel.reportid.toString()) ? true : false}
                        setCheckEpisode={(reportId) => {
                          props.setCheckEpisode(reportId);
                        }}
                        onPanelClick={(e: any) => {
                          return handleExpandClick(e, index, panel.labnumber);
                        }}
                        setPanel={() => {
                          props.pageProps.setPanel(index);
                        }}
                        urgentStatus={panel.urgentStatus}
                      />
                    </Box>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </div>
    );
  };

  const getItemSize = (params: Index) => {
    const header_height = 100;
    const header_height_with_pending = 96;
    const header_height_with_pending_deleted = 121;
    const header_height0 = 0;
    const panel_height_report = 28;
    const panel_height_pending = 22;
    const panel_height_deleted = 22;
    const margins = 32;
    const marginbetween = 14;
    const episode = props.patientEpisodes.episodes[params.index];

    const divider_height = 34;
    const reported = episode.panels.filter(
      (p) =>
        p.interimStatus === INTERUM_STATUS.Final ||
        p.interimStatus === INTERUM_STATUS.FinalCorrected ||
        p.interimStatus === INTERUM_STATUS.Preliminary,
    );
    const pending = episode.panels.filter(
      (p) =>
        p.interimStatus !== INTERUM_STATUS.Final &&
        p.interimStatus !== INTERUM_STATUS.FinalCorrected &&
        p.interimStatus !== INTERUM_STATUS.Preliminary &&
        p.interimStatus !== INTERUM_STATUS.Cancelled,
    );
    const deleted = episode.panels.filter((p) => p.interimStatus === INTERUM_STATUS.Cancelled);
    const both_exist =
      (reported.length > 0 && pending.length > 0) ||
      (reported.length > 0 && deleted.length > 0) ||
      (deleted.length > 0 && pending.length > 0);
    const all_exist = reported.length > 0 && pending.length > 0 && deleted.length > 0;

    const details_height =
      reported.length * panel_height_report +
      pending.length * panel_height_pending +
      deleted.length * panel_height_deleted +
      margins;

    const h =
      (all_exist ? header_height_with_pending_deleted : both_exist ? header_height_with_pending : header_height) +
      (params.index === 0 ? header_height0 : 0) +
      details_height +
      marginbetween +
      (all_exist ? divider_height * 2 : both_exist ? divider_height : 0);

    return h;
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className={isDesktop ? "scrolling" : ""}
          rowCount={props.patientEpisodes.episodes.length}
          rowRenderer={EpisodeCard}
          height={isDesktop ? height - 100 : height}
          width={width}
          rowHeight={getItemSize}
          overscanRowCount={4}
          scrollToAlignment={"start"}
          ref={listRef}
        />
      )}
    </AutoSizer>
  );
};

const PatientEpisodes: React.FC<PatientEpisodesProps> = (props) => {
  const [selectedPanel, setSelectedPanel] = useState(-1);
  const [reload, setReload] = useState(false);
  const { setLabId } = useContext(PatientDetailsContext);
  let sortby = props.sortBy;

  const [markAsViewed] = useMutation<MarkAsViewed>(MarkAsViewedMutation, {
    ignoreResults: true,
  });
  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const fetchPolicy = useCacheWithExpiration(Constants.DEFAULT_CACHE_DURATION, `GetPatientEpisodes_${props.patientId}`);

  if (sortby === "0") sortby = "dateCreated";
  const {
    data: patientEpisodesData,
    loading: patientEpisodesLoading,
    error: fetchError,
  } = useQuery<GetPatientEpisodesQueryType>(GetPatientEpisodes, {
    variables: {
      patientId: props.patientId,
      providers: authService.getProviders(),
      allPatients: props.allPatients,
      sortBy: sortby,
      showRemovedPanels: props.showRemovedPanels,
    },
    fetchPolicy: fetchPolicy,
  });

  const client = useApolloClient();

  const onMarkAsViewed = (reportId: string) => {
    updatePatientepisodesCache(client, reportId, {
      patientId: props.patientId,
      providers: authService.getProviders(),
      allPatients: props.allPatients,
      sortBy: props.sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  useEffect(() => {
    if (patientEpisodesLoading) {
      setReload(true);
    } else {
      if (
        patientEpisodesData &&
        patientEpisodesData.patientepisodes &&
        patientEpisodesData.patientepisodes.episodes &&
        props.labnoDigitOnly !== "UNKNOWN"
      ) {
        if (
          reload &&
          patientEpisodesData &&
          patientEpisodesData.patientepisodes &&
          patientEpisodesData.patientepisodes.episodes
        ) {
          setReload(false);
          //props.setLabnoDigitOnly(patientEpisodesData.patientepisodes.episodes[0].labnoDigitOnly || 'UNKONWN');
        } else {
          setSelectedPanel(
            patientEpisodesData.patientepisodes.episodes.findIndex((e) => e.labnoDigitOnly === props.labnoDigitOnly),
          );
        }
        setLabId(
          patientEpisodesData.patientepisodes.episodes && patientEpisodesData.patientepisodes.episodes.length > 0
            ? patientEpisodesData.patientepisodes.episodes[0].panels[0].labid
            : 0,
        );
      }
    }

    if (patientEpisodesData && patientEpisodesData.patientepisodes && patientEpisodesData.patientepisodes.episodes) {
      if (props.allReportIds.length <= 0 && !props.allReportFetch) {
        //Need to run once!!

        const addDefaultSelected: any = [];

        patientEpisodesData.patientepisodes.episodes.forEach((episode: any) => {
          episode.panels.forEach((panel: any) => {
            if (
              panel.interimStatus === INTERUM_STATUS.Final ||
              panel.interimStatus === INTERUM_STATUS.FinalCorrected ||
              panel.interimStatus === INTERUM_STATUS.Preliminary
            ) {
              addDefaultSelected.push(panel.reportid.toString());
            }
          });
        });
        // eslint-disable-next-line no-console
        props.setAllReportFetch(true);
        props.setAllReportIds(addDefaultSelected);
      }
    }

    if (props.labnoDigitOnly) {
      const episode = patientEpisodesData?.patientepisodes.episodes.filter((obj) => {
        if (props.labnoDigitOnly === obj.labnoDigitOnly) return obj;
      });
      if (episode) {
        props.setUrno(get(episode, "[0].panels[0].urno", ""));
        props.setWard(get(episode, "[0].panels[0].ward", ""));
        props.setReferringDocotr(get(episode, "[0].panels[0].referringDoctor", ""));
      }
    }

    if (props.print) {
      //Have all the report ID Selected, time to mark as viewed
      const selectedPanels: any[] = [];
      if (patientEpisodesData && patientEpisodesData.patientepisodes && patientEpisodesData.patientepisodes.episodes) {
        const getAllSelectedPanels = new Promise<void>((resolve) => {
          props.reportIdSelected.forEach((reportId, index, array) => {
            patientEpisodesData.patientepisodes.episodes.forEach((episode: any) => {
              episode.panels.forEach((panel: any) => {
                if (panel.reportid.toString() === reportId) {
                  selectedPanels.push(panel);
                }
              });
            });
            if (index === array.length - 1) resolve();
          });
        });

        getAllSelectedPanels.then(() => {
          selectedPanels.forEach((panel) => {
            setViewed(props.allPatients, markAsViewed, auditLog, onMarkAsViewed, panel);
          });
        });

        const patientEpisodes: GetPatientEpisodes_patientepisodes = patientEpisodesData.patientepisodes;

        props.setUrno(get(patientEpisodes, "episodes[0].panels[0].urno", ""));

        if (props.labnoDigitOnly === "UNKNOWN") {
          props.setLabnoDigitOnly(get(patientEpisodes, "episodes[0].labnoDigitOnly", ""));
        }

        props.setWard(get(patientEpisodes, "episodes[0].panels[0].ward", ""));

        props.setReferringDocotr(get(patientEpisodes, "episodes[0].panels[0].referringDoctor", ""));
      }

      props.setPrint(false);
    }
    // eslint-disable-next-line
  }, [props.labnoDigitOnly, setSelectedPanel, patientEpisodesData, patientEpisodesLoading, props.print]);

  if (patientEpisodesLoading) {
    return (
      <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        <Loading />
      </Box>
    );
  }

  if (fetchError) {
    return <ErrorPopover />;
  }

  if (!patientEpisodesData) {
    return <h1>There was an error</h1>;
  }

  return (
    <PatientEpisodeCardsWindow
      pageProps={props}
      patientEpisodes={patientEpisodesData.patientepisodes}
      selectedPanel={selectedPanel}
      setSelectedPanel={setSelectedPanel}
      reportIdSelected={props.reportIdSelected}
      setCheckEpisode={(reportId) => {
        props.setCheckEpisode(reportId);
      }}
      showRemovedPanels={props.showRemovedPanels}
    />
  );
};

export default PatientEpisodes;
