import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import styles from "./HospitalMfaSettings.module.css";
import get from "lodash/get";
import { Formik } from "formik";
import { UpdateHospitalMfa, UpdateHospitalMfaVariables } from "./types/UpdateHospitalMfa";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import UpdateHospitalMfaMutation from "./UpdateHospitalMfaMutation";
import authService from "../../services/authService";
import { GetHospitalMfa, GetHospitalMfa_getHospitalMfa_mfaProviders } from "./types/GetHospitalMfa";
import GetHospitalMfaQuery from "./GetHospitalMfaQuery";
import { useQuery } from "@apollo/client";
import Loading from "../Loading/Loading";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";

export type updateHospitalMfaInputValues = {
  hospid: string;
  mfaEnforced: boolean;
  isTrustBrowserEnabled: boolean;
  mfaProviders: number[];
};

interface UpdareHospitalMfaPageProps {
  open: boolean;
  fullscreen: boolean;
  onClose: () => void;
}

const HospitalMfaSettingsDialog: React.FC<UpdareHospitalMfaPageProps> = (props) => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [mfaEnforced, setMfaEnforced] = useState(false);
  const [isTrustBrowserEnabled, setIsTrustBrowserEnabled] = useState(false);
  const [mfaProviders, setMfaProviders] = useState<GetHospitalMfa_getHospitalMfa_mfaProviders[]>([]);
  const [mfaProvidersOld, setMfaProvidersOld] = useState<GetHospitalMfa_getHospitalMfa_mfaProviders[]>([]);
  const [warnAdd, setWarnAdd] = useState(false);
  const [warnRemove, setWarnRemove] = useState(false);
  const [warnAddText, setWarnAddText] = useState("");
  const [warnRemoveText, setWarnRemoveText] = useState("");
  const [addTick, setAddTick] = useState(false);
  const [removeTick, setRemoveTick] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [state, setState] = React.useState({
    hospid: authService.getHospID(),
    mfaEnforced: mfaEnforced,
    isTrustBrowserEnabled: isTrustBrowserEnabled,
    mfaProviders: [0],
  });

  // const handleTrustBrowserCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newState: any = {
  //     hospid: state.hospid,
  //     mfaEnforced: state.mfaEnforced,
  //     isTrustBrowserEnabled: event.target.checked,
  //     mfaProviders: state.mfaProviders,
  //   };

  //   setIsTrustBrowserEnabled(event.target.checked);
  //   setState(newState);
  // };

  // const handleChangeMfaEnforced = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newState: any = {
  //     hospid: state.hospid,
  //     mfaEnforced: event.target.checked,
  //     isTrustBrowserEnabled: state.isTrustBrowserEnabled,
  //     mfaProviders: state.mfaProviders,
  //   };

  //   setMfaEnforced(event.target.checked);
  //   setState(newState);
  // };

  const handleChangeMfaProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    const provider = event.target.value as unknown as number;
    const newMfaProviders: GetHospitalMfa_getHospitalMfa_mfaProviders[] = [];

    /* eslint eqeqeq: 0 */
    mfaProviders.forEach(function (pro) {
      const newPro: GetHospitalMfa_getHospitalMfa_mfaProviders = {
        __typename: "MfaList",
        id: pro.id,
        name: pro.name,
        enabled: pro.id == provider ? event.target.checked : pro.enabled,
      };

      newMfaProviders.push(newPro);
    });

    setMfaProviders(newMfaProviders);

    const changedProviders = newMfaProviders.filter(
      (b) => !mfaProvidersOld.some((a) => a.id === b.id && a.enabled === b.enabled),
    );

    const removedProviders = changedProviders
      .filter((mfa) => !mfa.enabled)
      .map((mfa) => {
        return mfa.name;
      });

    const addedProviders = changedProviders
      .filter((mfa) => mfa.enabled)
      .map((mfa) => {
        return mfa.name;
      });

    if (removedProviders.length > 0) {
      setWarnRemoveText(
        `Users who have previously been enrolled to ${removedProviders.join(
          ", ",
        )} only will be required to re-enrol using other Authentication Methods.`,
      );
      setWarnRemove(true);
    } else {
      setWarnRemoveText("");
      setWarnRemove(false);
    }

    if (addedProviders.length > 0) {
      setWarnAddText(
        `To enable ${addedProviders.join(
          ", ",
        )}  for already enrolled users their MFA Authentication Methods need to be manually reset.`,
      );
      setWarnAdd(true);
    } else {
      setWarnAddText("");
      setWarnAdd(false);
    }
  };

  const initialValues: updateHospitalMfaInputValues = {
    hospid: state.hospid,
    mfaEnforced: state.mfaEnforced,
    isTrustBrowserEnabled: state.isTrustBrowserEnabled,
    mfaProviders: state.mfaProviders,
  };

  const {
    data,
    loading: loadingMfa,
    error: errorMfa,
    refetch,
  } = useQuery<GetHospitalMfa>(GetHospitalMfaQuery, {
    variables: {
      hospid: authService.getHospID(),
    },
    fetchPolicy: "network-only",
  });

  if (errorMfa) {
    console.error("Error", errorMfa);
  }

  useEffect(() => {
    if (data && data.getHospitalMfa) {
      setMfaEnforced(data.getHospitalMfa.mfaEnforced || false);
      setIsTrustBrowserEnabled(data.getHospitalMfa.trustBrowserEnabled || false);
      setMfaProviders(data.getHospitalMfa.mfaProviders || []);
      setMfaProvidersOld(data.getHospitalMfa.mfaProviders || []);

      const providers = ((data && data.getHospitalMfa && data.getHospitalMfa.mfaProviders) || [])
        .filter((provider) => {
          return provider.enabled;
        })
        .map((provider) => provider.id);

      const newState: any = {
        hospid: state.hospid,
        mfaEnforced: data.getHospitalMfa.mfaEnforced,
        isTrustBrowserEnabled: data.getHospitalMfa.trustBrowserEnabled,
        mfaProviders: providers,
        hidePanelEnabled: data.getHospitalMfa.hidePanelEnabled,
        autoSearchEnabled: data.getHospitalMfa.autoSearchEnabled,
      };

      setState(newState);

      if (reload) {
        refetch();
        setReload(false);
        setFeedbackMessage("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reload, refetch]);

  const [updateHospitalMfa, { loading, error }] = useMutation<UpdateHospitalMfa, UpdateHospitalMfaVariables>(
    UpdateHospitalMfaMutation,
  );

  if (error) {
    console.error("Error", error);
  }

  const submit = () => {
    setFeedbackMessage("");

    const changedProviders = mfaProviders.filter(
      (b) => !mfaProvidersOld.some((a) => a.id === b.id && a.enabled === b.enabled),
    );

    const providers = mfaProviders
      .filter((provider) => {
        return provider.enabled;
      })
      .map((provider) => provider.id);

    if (state.mfaEnforced && providers.length < 1) {
      setFeedbackMessage("Please select at least one authentication method");
    } else if (changedProviders.length === 0) {
      setFeedbackMessage("No changes made to settings");
    } else if ((warnRemove && !removeTick) || (warnAdd && !addTick)) {
      setFeedbackMessage("Please acknowledge the warning(s) for this change");
    } else {
      setMfaProvidersOld(mfaProviders);
      onSubmit(state.hospid, state.mfaEnforced, state.isTrustBrowserEnabled, providers, updateHospitalMfa);
    }
  };

  const onSubmit = async (
    hospid: string,
    mfaEnforced: boolean,
    trustBrowser: boolean,
    mfaProviders: number[],
    updateHospitalMfa: MutationTuple<UpdateHospitalMfa, UpdateHospitalMfaVariables>[0],
  ) => {
    try {
      setUpdateLoading(true);

      const result = await updateHospitalMfa({
        variables: {
          input: {
            hospid,
            mfaEnforced,
            trustBrowser,
            mfaProviders,
          },
        },
      });

      if (get(result, "data.updateHospitalMfa")) {
        setFeedbackMessage(get(result, "data.updateHospitalMfa", ""));
        setUpdateLoading(false);

        setTimeout(function () {
          setFeedbackMessage("");
          setWarnAdd(false);
          setWarnAddText("");
          setAddTick(false);
          setWarnRemove(false);
          setWarnRemoveText("");
          setRemoveTick(false);
          props.onClose();
        }, 1000);
      }
    } catch (e) {
      setUpdateLoading(false);

      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      }
    }
  };

  const handleClose = () => {
    setReload(true);
    setFeedbackMessage("");
    setWarnAdd(false);
    setWarnAddText("");
    setAddTick(false);
    setWarnRemove(false);
    setWarnRemoveText("");
    setRemoveTick(false);
    props.onClose();
  };

  return loadingMfa ? (
    <Loading />
  ) : (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} autoComplete="new-password">
            <DialogTitle>
              <DialogBoxTitle
                title="Multi-Factor Authentication Settings"
                width={400}
                closeButton
                handleClose={handleClose}
              />
            </DialogTitle>

            <DialogContent>
              <Box m={3}>
                {/* <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                  Enforce MFA for Group Users?{" "}
                  <Checkbox
                    name="mfaEnforced"
                    color="primary"
                    checked={mfaEnforced}
                    onChange={handleChangeMfaEnforced}
                  />
                </Box> */}
                {/* Temporary Hide Enable Trust Browser checkbox */}
                {/* <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                  Enable Trust Browser{" "}
                  <Checkbox
                    name="isTrustBrowserEnabled"
                    color="primary"
                    checked={isTrustBrowserEnabled}
                    onChange={handleTrustBrowserCheckbox}
                  />
                </Box> */}
                <Typography className={styles.menuTitle2}>
                  This setting applies for your entire Group. Changing them may result in resetting MFA parameters for
                  all users in your Group. Users may require re-enrolling to MFA if Authentication Methods are changed.
                </Typography>
                <Typography className={styles.menuTitle}>Select One-Time Password Authentication Method(s):</Typography>
                {mfaProviders.map((provider, index) => (
                  <Box mt={1} display="flex" justifyContent="space-between" align-content="strech" key={index}>
                    {provider.name}
                    {provider.name === "Authenticator" && " app"}
                    <Checkbox
                      name="mfaProvider"
                      color="primary"
                      checked={provider.enabled}
                      value={provider.id}
                      onChange={handleChangeMfaProvider}
                    />
                  </Box>
                ))}
                {(warnRemove || warnAdd) && (
                  <Typography className={styles.menuTitle3}>WARNING (please acknowledge):</Typography>
                )}
                {warnRemove && (
                  <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                    <Checkbox
                      name="removeTick"
                      color="primary"
                      checked={removeTick}
                      onChange={(e) => setRemoveTick(e.target.checked)}
                    />
                    {warnRemoveText}
                  </Box>
                )}
                {warnAdd && (
                  <Box mt={1} display="flex" justifyContent="space-between" align-content="strech">
                    <Checkbox
                      name="addTick"
                      color="primary"
                      checked={addTick}
                      onChange={(e) => setAddTick(e.target.checked)}
                    />
                    {warnAddText}
                  </Box>
                )}
              </Box>
              <Box ml={1}>
                {error &&
                  error.graphQLErrors.map(({ message }, i) => (
                    <Typography key={i} color="error">
                      {message}
                    </Typography>
                  ))}
              </Box>
              <Box m={3}>
                <Typography className={styles.MenuItem2}>{feedbackMessage}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box ml={3} mb={3} display="flex" justifyContent="space-between" align-content="strech">
                <Button
                  className={styles.spaceRight}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  {updateLoading ? (
                    <Loading boxStyle={{ margin: "0" }} iconStyle={{ color: "grey", height: "20px", width: "20px" }} />
                  ) : (
                    `Save`
                  )}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default HospitalMfaSettingsDialog;
