import { gql } from "@apollo/client";
export default gql`
  query GetUserEmailMobile($userid: Float!) {
    getUserEmail(userid: $userid) {
      data {
        id
        email
        enabled
      }
    }
    getUserMobile(userid: $userid) {
      data {
        id
        mobile
        enabled
      }
    }
    getSmsDelivery(userid: $userid) {
      data {
        id
        onlyBusinessHours
        includeWeekends
      }
    }
    getUserDevice(userid: $userid) {
      data {
        id
        deviceid
        friendlyname
        platform
        enabled
      }
    }
  }
`;
