import React, { useEffect, useState, useContext } from "react";
import {
  GetPrintableReports_episodereports_reports as Report,
  GetPrintableReports_patient as Patient,
} from "./types/GetPrintableReports";
import styles from "./PrintableReports.module.css";
import { mergePdfs } from "./pdfUtils";
import { Document, Page, pdfjs } from "react-pdf";
import authService from "../../services/authService";
import useAxios from "axios-hooks";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import AppContext from "../../context/AppContext";
import { isTenantVet } from "../../services/tenantService";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PrintableReportProps = {
  reports: Report[];
  patient: Patient;
  frame: string;
};

type PdfFileObject = {
  url?: string;
  data?: Uint8Array;
};

const PrintableReports: React.FC<PrintableReportProps> = ({ reports, patient, frame }) => {
  const reportsHasPdfs = reports.some((report) => report.pdf);
  const reportsHasTexts = reports.some((report) => !report.pdf);
  const [pdfLoaded, setPdfLoaded] = useState<boolean>(false);
  const [pdfFileObject, setPdfFileObject] = useState<PdfFileObject>({
    url: "some stuff",
  });
  const [myNumPages, setMyNumPages] = useState(0);
  const mobility = authService.isMobility();
  const setError = useContext(AppContext).setError;

  // eslint-disable-next-line
  const [{ data, loading, error }] = useAxios({
    url: `${process.env.REACT_APP_ACL_REPORT_URL}/report`,
    method: "POST",
    data: {
      output: "html",
      data: {
        tz: dayjs.tz.guess(),
        patient: patient,
        reports: reports.filter((r) => !r.pdf),
      },
      template: isTenantVet() ? "gribbles-template.html" : "report-template.html",
    },
  });

  const getPdfUrl = async (reports: Report[]) => {
    // Load binary pdfs
    const reportPdfs: string[] = reports.filter((r) => !!r.pdf).map((report) => report.pdf || "");

    const finalPdf: Uint8Array = await mergePdfs(reportPdfs);
    setPdfFileObject({ data: finalPdf });
  };

  // TEXT
  useEffect(() => {
    if (reportsHasPdfs) return;

    const iframe: any = window.parent.document.getElementById(frame);

    if (
      ((data && data.data && !reportsHasPdfs && reportsHasTexts) ||
        (pdfLoaded && !reportsHasTexts && reportsHasPdfs) ||
        (data && data.data && reportsHasTexts && pdfLoaded && reportsHasPdfs)) &&
      iframe &&
      iframe.contentWindow
    ) {
      setTimeout(() => {
        window.parent.postMessage("eResults2.0 Print Ready", "*");
        iframe.scrolling = "no";
        iframe.contentWindow.focus();
        if (document.queryCommandSupported("print")) {
          if (!mobility) {
            iframe.contentWindow.document.execCommand("print", false, null);
          } else {
            window.open(iframe.contentWindow.location.href);
          }
        } else {
          iframe.contentWindow.print();
        }
      }, 200);
    }
  }, [reportsHasPdfs, data, pdfLoaded, reportsHasTexts, frame, mobility]);

  // PDF
  useEffect(() => {
    const iframe: any = window.parent.document.getElementById("printFrame");

    if (reports && reports.length > 0 && iframe && iframe.contentWindow) {
      iframe.contentWindow.document.title =
        reports.length === 1
          ? `Report ${reports[0].labnumber} for ${patient.surname}, ${patient.firstname}`
          : `Report for ${patient.surname}, ${patient.firstname}`;
    }

    if (!reportsHasPdfs) return;

    //run()
    getPdfUrl(reports);
  }, [reports, reportsHasPdfs, patient]);

  // TEXT and PDF reports

  useEffect(() => {
    if (pdfFileObject.data && pdfLoaded) {
      const iframe: any = window.parent.document.getElementById("printFrame");
      iframe.contentWindow.print();
      window.parent.postMessage("eResults2.0 Print Ready", "*");
      setPdfFileObject({
        url: "some stuff",
      });
      setPdfLoaded(false);
    }
  }, [pdfFileObject, pdfLoaded]);

  if (loading) return <Loading />;

  if (error) {
    setError(`There has been an error. Please refresh the page to try again ${error.message}`);
    return <ErrorPopover />;
  } else {
    return (
      <div className={styles.page}>
        {reportsHasPdfs && (
          <Document
            file={pdfFileObject.data ? { data: pdfFileObject.data.slice(0) } : { url: pdfFileObject.url || "" }}
            onLoadSuccess={({ numPages }) => {
              setMyNumPages(numPages);
            }}
          >
            {Array.from(new Array(myNumPages), (el, index) => (
              <div className={styles.reportpdf} key={`page_${index + 1}`}>
                <Page
                  pageNumber={index + 1}
                  onRenderSuccess={() => {
                    setPdfLoaded(index + 1 === myNumPages);
                  }}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  renderForms={false}
                  width={800}
                  scale={0.9}
                />
              </div>
            ))}
          </Document>
        )}
        {reportsHasTexts && data && data.data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div>}
      </div>
    );
  }
};

export default PrintableReports;
