import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GetSingleAtomicValues } from "../CumulativePanel/types/GetSingleAtomicValues";
import GetSingleAtomicValuesQuery from "../CumulativePanel/GetSingleAtomicValuesQuery";
import classes from "./CumulativeGraph.module.css";
import Loading from "../Loading/Loading";
import LineGraph from "./LineGraph";
import axios from "axios";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";
import theme from "../theme";
import { downloadHelper } from "../../utils/downloadHelper";

export interface RefGraphProps {
  measurement: any;
  data: any;
  atomicIndex: number;
  atomicName: string;
  providers: string[];
  patientId: number;
  panelId: number;
  isMobile?: string | null;
  loincs: any;
  patient?: any;
  episodeData?: any;
  reportId: number;
  downloadChart?: boolean;
  allPatients?: boolean;
  setIsChartDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  allPanels: string[];
}

const ReferenceGraph: React.FC<RefGraphProps> = (props) => {
  const atomicCount = props.loincs.length as number;
  const [atomicName, setAtomicName] = React.useState(props.atomicName);
  const [atomicIndex, setAtomicIndex] = React.useState(props.atomicIndex);
  const [targetMeasurement, setTargetMeasurement] = React.useState(
    props.measurement && props.measurement[0] && props.measurement[0].measurement,
  );
  const [error, setError] = React.useState("");
  const { labnoDigitOnly } = useContext(PatientDetailsContext);
  const [resetZoom, setResetZoom] = useState(false);
  const [selectedAtomicFromGraph, setSelectedAtomicFromGraph] = useState([atomicName.split("|")[1]]);

  const { data: graphData, loading } = useQuery<GetSingleAtomicValues>(GetSingleAtomicValuesQuery, {
    variables: {
      providers: props.providers,
      patientId: props.patientId,
      panelId: props.panelId,
      atomicName: atomicName,
      allPatients: props.allPatients,
    },
  });

  const result = graphData?.singleAtomicData?.map((item: any) => ({
    reportId: item.reportId,
    name: item.atomicData[0].name,
    value: item.atomicData[0].value,
    value_type: item.atomicData[0].value_type,
    measurement: item.atomicData[0].measurement.replace(/"/g, ""),
    range: item.atomicData[0].range,
    abnormal: item.atomicData[0].abnormal,
    reportDateCollected: item.reportDateCollected,
  }));

  result?.sort((a: any, b: any) => {
    const dateA = new Date(a.reportDateCollected).valueOf();
    const dateB = new Date(b.reportDateCollected).valueOf();
    return dateA - dateB; // sort in asc order
  });

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    return `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}`;
  }

  const rangeStart = result
    ? result.map((item: any) => (item.range.split(" - ")[0] === "" ? null : item.range.split(" - ")[0]))
    : [];
  const rangeEnd = result
    ? result.map((item: any) => (item.range.split(" - ")[1] === "" ? null : item.range.split(" - ")[1]))
    : [];
  const value = result ? result.map((item: any) => parseFloat(item.value)) : [];
  const date = result ? result.map((item: any) => formatDate(item.reportDateCollected.split("T")[0])) : [];
  const abnormal = result ? result.map((item: any) => (item.abnormal ? "red" : "green")) : [];

  const reportDates = result ? result.map((report) => report.reportDateCollected.split("T")[0]) : [];
  const start: any[] = [];
  const end: any[] = [];
  if (!rangeEnd[0] || !rangeStart[0]) {
    rangeStart.forEach((item) => {
      if (item && typeof item === "string") {
        const parts = item.split(" ");
        if (parts[0] === "<") {
          start.push("0");
          end.push(parts[1]);
        }
        if (parts[0] === ">") {
          end.push(parts[1]);
          start.push(Math.max(...value));
        }
      }
    });
  } else {
    rangeStart.forEach((item) => {
      start.push(item);
    });

    rangeEnd.forEach((item) => {
      end.push(item);
    });
  }

  // const hsvToRgb = (h: any, s: any, v: any) => {
  //   let r, g, b;
  //   const i = Math.floor(h * 6);
  //   const f = h * 6 - i;
  //   const p = v * (1 - s);
  //   const q = v * (1 - f * s);
  //   const t = v * (1 - (1 - f) * s);

  //   switch (i % 6) {
  //     case 0:
  //       (r = v), (g = t), (b = p);
  //       break;
  //     case 1:
  //       (r = q), (g = v), (b = p);
  //       break;
  //     case 2:
  //       (r = p), (g = v), (b = t);
  //       break;
  //     case 3:
  //       (r = p), (g = q), (b = v);
  //       break;
  //     case 4:
  //       (r = t), (g = p), (b = v);
  //       break;
  //     case 5:
  //       (r = v), (g = p), (b = q);
  //       break;
  //   }

  //   return {
  //     r: Math.round(r * 255),
  //     g: Math.round(g * 255),
  //     b: Math.round(b * 255),
  //   };
  // };

  // const generateDistinctColors = (count: any, seed: any) => {
  //   const colors = [];
  //   const goldenRatio = 0.618033988749895;
  //   let hue = seed || Math.random();

  //   for (let i = 0; i < count; i++) {
  //     hue += goldenRatio;
  //     hue %= 1;
  //     const color = hsvToRgb(hue, 0.5, 0.95);
  //     colors.push(`rgba(${color.r}, ${color.g}, ${color.b}, 1)`);
  //   }

  //   return colors;
  // };

  // const numberOfColors = 20;
  // const seed = 0.5;

  // const distinctColors = generateDistinctColors(numberOfColors, seed);

  // eResults Colors http://jira.pl.pathlabs.com.au/browse/ER8-611
  const distinctColors = [
    "#77499D",
    "#FF8000",
    "#E1012D",
    "#9d001f",
    "#b25900",
    "#0288d1",
    "#03a9f4",
    "#01579b",
    "#2e7d32",
    "#4caf50",
    "#1b5e20",
    "#5c1422",
    "#014361",
    "#1e4620",
    "#663d14",
    "#fdcd9b",
    "#21262B",
    "#4e5962",
    "#cbb9d9",
    "#f39eaf",
  ];

  const mergeAtomicData = (data: any, colors: any) => {
    const mergedData: any = [];
    const sortedReports = [...data.atomic].sort((a: any, b: any) => {
      const dateA = new Date(a.reportDateCollected).valueOf();
      const dateB = new Date(b.reportDateCollected).valueOf();
      return dateA - dateB;
    });

    sortedReports.forEach((report: any, index: any) => {
      report.atomicData.forEach((atomic: any) => {
        const existingEntry = mergedData.find((item: any) => item.name === atomic.name);
        if (existingEntry) {
          existingEntry.value.push(parseFloat(atomic.value));
          existingEntry.rangeStart.push(parseFloat(atomic.range.split(" - ")[0]));
          existingEntry.rangeEnd.push(parseFloat(atomic.range.split(" - ")[1]));
          existingEntry.abnormal.push(atomic.abnormal);
          existingEntry.reportDateCollected.push(new Date(report.reportDateCollected).toISOString().split("T")[0]);
          existingEntry.color = colors[index];
        } else {
          mergedData.push({
            name: atomic.name,
            value: [parseFloat(atomic.value)],
            value_type: atomic.value_type,
            measurement: atomic.measurement,
            rangeStart: [parseFloat(atomic.range.split(" - ")[0])],
            rangeEnd: [parseFloat(atomic.range.split(" - ")[1])],
            abnormal: [atomic.abnormal],
            reportDateCollected: [new Date(report.reportDateCollected).toISOString().split("T")[0]],
            color: colors[index],
          });
        }
      });
    });

    mergedData.forEach((entry: any, index: any) => {
      entry.color = distinctColors[index % distinctColors.length];
    });

    return mergedData;
  };

  const transformedData = mergeAtomicData(props.data, distinctColors);
  const filteredNames = transformedData
    .filter((item: any) => item.measurement === targetMeasurement)
    .map((item: any) => item.name);
  const filteredData = targetMeasurement
    ? transformedData.filter(
        (record: any) => record.measurement === targetMeasurement && selectedAtomicFromGraph.includes(record.name),
      )
    : transformedData;

  type AbnormalMapFunction = (abnormal: any) => string[];
  const formatData = (input: any, dataKey: string, labelSuffix: string, colorMap: AbnormalMapFunction) => {
    const formattedData: any = [];

    for (let i = 0; i < input.length; i++) {
      const name = input[i].name;
      const value = input[i][dataKey];

      const color = colorMap(input[i].abnormal);

      const formattedObject = {
        label: name + labelSuffix,
        borderColor: labelSuffix === "-start" || labelSuffix === "-end" ? "rgba(119, 73, 157, 0.10)" : input[i].color,
        backgroundColor: labelSuffix === "-start" || labelSuffix === "-end" ? "rgba(119, 73, 157, 0)" : color,
        data: value,
        fill: false,
        pointRadius: labelSuffix === "-start" || labelSuffix === "-end" ? 0 : 4,
        tension: 0,
        index: i,
      };

      formattedData.push(formattedObject);
    }

    return formattedData;
  };

  const startRange = (input: any[]) => {
    const colorMap: AbnormalMapFunction = (abnormal) => {
      return abnormal.map(() => "rgba(0, 0, 0, 0)");
    };

    return formatData(input, "rangeStart", "-start", colorMap);
  };

  const endRange = (input: any[]) => {
    const colorMap: AbnormalMapFunction = (abnormal) => {
      return abnormal.map(() => "rgba(0, 0, 0, 0)");
    };

    return formatData(input, "rangeEnd", "-end", colorMap);
  };

  const valueData = (input: any[]) => {
    const colorMap: AbnormalMapFunction = (abnormal) => {
      return abnormal.map((item: any) => (!item ? "green" : "red"));
    };

    return formatData(input, "value", "", colorMap);
  };

  const data =
    selectedAtomicFromGraph.length > 1
      ? {
          labels: [...date],
          datasets: [...startRange(filteredData), ...endRange(filteredData), ...valueData(filteredData)],
        }
      : {
          labels: [null, ...date, null],
          datasets: [
            {
              label: "range-start",
              data: [null, ...start, null],
              backgroundColor: theme.colorSchemes.light.palette.primary.main + "10",
              fill: 1,
              pointRadius: 0,
              tension: 0,
            },
            {
              label: "range-end",
              data: [null, ...end, null],
              backgroundColor: theme.colorSchemes.light.palette.primary.main + "10",
              fill: false,
              pointRadius: 0,
              tension: 0,
            },
            {
              label: atomicName.split("|")[1],
              data: [null, ...value, null],
              borderColor: theme.colorSchemes.light.palette.primary.main,
              backgroundColor: [null, ...abnormal, null],
              tension: 0,
            },
          ],
        };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Collection Date",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        ticks: {
          autoSkip: false,
          display: true,
          font: {
            weight: function (context: any) {
              return context.index === date.length - 1 ? "bold" : "italic";
            },
          },
          color: function (context: any) {
            return context.index === date.length - 1 ? theme.colorSchemes.light.palette.primary.main : "";
          },
        },
      },
      y: {
        title: {
          display: true,
          text: result ? result.map((item) => item.measurement)[0] : "",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: function (item: any) {
          if (
            selectedAtomicFromGraph.length > 1 ||
            item.dataset.label.includes("hide") ||
            item.dataset.label.includes("start") ||
            item.dataset.label.includes("end")
          ) {
            return false;
          }

          return true;
        },
        align: "end",
        offset: 4,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        filter: (item: any) => {
          if (item.dataset.label.includes("hide")) {
            return false;
          }
          return true;
        },
        enabled: true,
      },
      title: {
        display: true,
        text: result
          ? [
              selectedAtomicFromGraph.length > 1 ? "Combined Chart" : result[0].name,
              "( " + formatDate(reportDates[0]) + " - " + formatDate(reportDates[reportDates.length - 1]) + " )",
            ]
          : "",
        font: {
          size: 14,
        },
        padding: {
          bottom: 40,
        },
      },
      filler: {
        propagate: false,
      },
    },
    elements: {
      point: {
        hoverRadius: 6,
      },
    },
    interaction: {
      mode: "nearest",
    },
    onHover: (e: any, activeEls: any, chart: any) => {
      if (selectedAtomicFromGraph.length > 1) {
        if (activeEls.length === 0) {
          chart.data.datasets.forEach((dataset: any) => {
            if (dataset.label.includes("start") || dataset.label.includes("end")) {
              dataset.backgroundColor = "rgba(119, 73, 157, 0)";
              dataset.borderColor = "rgba(119, 73, 157, 0.10)";
              dataset.fill = false;
              dataset.pointRadius = 0;
            } else {
              // dataset.borderColor = "rgba(119, 73, 157, 0.6)";
              dataset.fill = false;
              dataset.pointRadius = 4;
            }
          });
          chart.update();
          return;
        }

        const hoveredEl = chart.getElementsAtEventForMode(
          e,
          "point",
          {
            intersect: false,
            axis: "x",
          },
          true,
        )[0];

        const data = chart.data.datasets.find((dataset: any, i: any) => {
          let currentlabel = "";

          if (dataset.backgroundColor?.length) {
            if (hoveredEl.datasetIndex === i || dataset.backgroundColor.length === 9) {
              currentlabel = dataset.label;
              if (dataset.label.includes(currentlabel)) {
                return true;
              }
            }
            return false;
          }
        });

        let indexStart = -1;

        chart.data.datasets.forEach((dataset: any, i: any) => {
          if (dataset && dataset.label && dataset.label.includes(data.label)) {
            if (dataset.label.includes("start")) {
              indexStart = i;
              dataset.backgroundColor = theme.colorSchemes.light.palette.primary.main + "50";
            }
            if (dataset.label.includes("end")) {
              dataset.fill = indexStart;
              dataset.backgroundColor = theme.colorSchemes.light.palette.primary.main + "50";
            }

            if (!dataset.label.includes("start") && !dataset.label.includes("end")) {
              // dataset.borderColor = "rgba(119, 73, 157, 1)";
              dataset.pointRadius = 4;
            }
          }

          if (dataset.backgroundColor?.length) {
            if (hoveredEl.datasetIndex === i || dataset.backgroundColor.length === 9) {
              if (dataset.label.includes("start") || dataset.label.includes("end")) {
                dataset.pointRadius = 0;
                return;
              }
              // dataset.borderColor = "rgba(119, 73, 157, 1)";
              dataset.pointRadius = 4;
            }
          }
        });

        chart.update();
      }
    },
  };

  const setAtomicValues = (index: number) => {
    const { loinc, measurement } = props.loincs[index];
    setTargetMeasurement(measurement);
    setAtomicIndex(index);
    setAtomicName(loinc);
    setSelectedAtomicFromGraph([loinc.split("|")[1]]);
  };

  const handleSelectAtomicFromGraph = (selectedValues: any) => {
    if (selectedValues.length === 1) {
      const selectedValue = selectedValues[0];
      const index = props.loincs.findIndex((entry: any) => entry.loinc.includes(selectedValue));

      if (index !== -1) {
        setAtomicValues(index);
      }
    } else {
      setSelectedAtomicFromGraph(selectedValues);
    }
  };

  const handleChange = (direction: boolean) => {
    const total = props.loincs.length;
    const atoIdx = (atomicIndex + (direction ? 1 : -1) + total) % total;

    setAtomicValues(atoIdx);
  };

  useEffect(() => {
    props.setIsChartDownloading(loading);

    if (props.downloadChart) {
      props.setIsChartDownloading(true);
      setResetZoom(true);

      const labnumber = props.episodeData.find((val: any) => val && val.labnoDigitOnly === labnoDigitOnly);
      const panel = labnumber.panels.find((val: any) =>
        props.reportId === 0
          ? val && val.labnumber.includes(props.allPanels[0])
          : val && val.reportid === props.reportId,
      );
      const canvas = document.getElementById("atomicChart") as HTMLCanvasElement;
      const dataUrl = canvas.toDataURL("image/png");
      const url = `${process.env.REACT_APP_ACL_REPORT_URL}/report`;

      const downData: any = {
        patient: {
          fullname: props.patient.fullname,
          dob: props.patient.dob,
          sex: props.patient.sex,
          street: props.patient.street,
          city: props.patient.city,
          state: props.patient.state,
          postcode: props.patient.postcode,
          phone: props.patient.mobile,
          image: dataUrl,
        },
        reports: [
          {
            dateCreated: panel.dateCreated,
            dateReferred: panel.dateReferred,
            dateCollected: panel.dateCollected,
            referredProviderNo: panel.reportingProviderNo,
            referringDoctor: panel.referringDoctor,
            urno: panel.urno,
            testname: panel.testnameProperCase,
            labid: panel.labid,
            lab: {
              address: panel.address,
              suburb: panel.suburb,
            },
          },
        ],
      };

      axios
        .post<any>(
          url,
          {
            template: "atomic-chart-template.html",
            output: "pdf",
            data: downData,
          },
          { responseType: "blob" },
        )
        .then((response) => {
          downloadHelper(response.data, `download-chart-${props.reportId}.pdf`);
          setError("");
          props.setIsChartDownloading(false);
        })
        .catch((e) => {
          setError(e.message ? e.message : e);
          props.setIsChartDownloading(false);
        });
    }

    if (resetZoom) {
      setResetZoom(false);
    }
  }, [props.downloadChart, loading]);

  if (error) {
    console.log(error);
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <div className={classes.chartContent}>
          <LineGraph
            availableAtomic={filteredNames}
            onSelectAtomic={handleSelectAtomicFromGraph}
            selectedAtomic={selectedAtomicFromGraph}
            colors={valueData(filteredData)}
            width={!props.isMobile ? 100 : undefined}
            height={!props.isMobile ? 50 : undefined}
            options={options}
            data={data}
            id="atomicChart"
            resetZoom={resetZoom}
            handleChange={handleChange}
            atomicIndex={atomicIndex}
            atomicCount={atomicCount}
          />
        </div>
      )}
    </div>
  );
};

export default ReferenceGraph;
