import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { useQuery } from "@apollo/client";
import { GetAtomicComments } from "./types/GetAtomicComments";
import getAtomicCommentsQuery from "./getAtomicCommentsQuery";
import { GetPanelComments } from "./types/GetPanelComments";
import getPanelCommentsQuery from "./GetPanelCommentsQuery";
import { formatDate } from "../../utils/date";
import authService from "../../services/authService";
import { CommentDialog } from "./CommentDialog";
import { GetCombinedReport_combinedReport_atomicData } from "../CombinedReport/types/GetCombinedReport";
import { CLINCAL_TRIAL_USER_TYPE } from "../../constants";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { CumulativeContext } from "../../context/CumulativeContext";
import InnerHTML from "dangerously-set-html-content";
import theme from "../theme";

interface DialogBoxCommentProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (dlg: CommentDialog) => void;
  atomic?: GetCombinedReport_combinedReport_atomicData | null;
  reportId?: number;
  submitText?: string;
  random?: number;
  isOffline?: boolean;
  setCommentsPresent?: React.Dispatch<React.SetStateAction<boolean>>;
  setCommentsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(() => ({
  columnA: {
    width: "25%",
  },
  columnB: {
    width: "75%",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    marginBottom: "10px",
  },
  rows: {
    marginBottom: "20px",
  },
  commentBox: {
    maxHeight: "200px",
    overflowY: "scroll",
  },
}));

const DialogBoxComment: React.FC<DialogBoxCommentProps> = (props) => {
  const classes = useStyles();
  const [comments, setComments] = useState<any>();
  const { atomic } = props;
  const dcfWorkflowEnabled = authService.getDcfWorkflowEnabled();
  const actionWorkflowEnabled = authService.getActionWorkflowEnabled();
  const { showCommentsExtra } = useContext(CumulativeContext);
  let formik: any;
  let initialValues: CommentDialog = {
    comment: "",
    noActionRequired: atomic && atomic.noActionRequired ? atomic.noActionRequired : false,
    actionRequired: atomic && atomic.actionRequired ? atomic.actionRequired : false,
    dCF: atomic && atomic.dCF ? atomic.dCF : false,
  };

  const loadAtomicComments = props.atomic && props.atomic.atomicId ? true : false;
  const loadPanelComments =
    (!props.atomic || !props.atomic.atomicId || props.atomic.atomicId === 0) && props.reportId ? true : false;

  const {
    data: atomicCommentData,
    loading: atomicCommentLoading,
    error: atomicCommentError,
  } = useQuery<GetAtomicComments>(getAtomicCommentsQuery, {
    variables: {
      atomicId: props.atomic && props.atomic.atomicId ? props.atomic.atomicId : 0,
      random: props.random,
    },
    fetchPolicy: "no-cache",
    skip: !loadAtomicComments,
  });

  const {
    data: panelCommentData,
    loading: panelCommentLoading,
    error: panelCommentError,
  } = useQuery<GetPanelComments>(getPanelCommentsQuery, {
    variables: {
      panelId: props.reportId,
      random: props.random,
    },
    fetchPolicy: "no-cache",
    skip: !loadPanelComments,
  });

  useEffect(() => {
    if (atomicCommentData) {
      setComments(atomicCommentData.getAtomicComments);
      if (props.setCommentsPresent) {
        props.setCommentsPresent(
          atomicCommentData.getAtomicComments && atomicCommentData.getAtomicComments.length > 0 ? true : false,
        );
      }
    } else if (panelCommentData) {
      setComments(panelCommentData.getPanelComments);
      if (props.setCommentsPresent) {
        props.setCommentsPresent(
          panelCommentData.getPanelComments && panelCommentData.getPanelComments.length > 0 ? true : false,
        );
      }
    }
  }, [atomicCommentData, panelCommentData]);

  useEffect(() => {
    if (props.setCommentsLoading) {
      props.setCommentsLoading(atomicCommentLoading);
    }
  }, [atomicCommentLoading]);

  useEffect(() => {
    if (props.setCommentsLoading) {
      props.setCommentsLoading(panelCommentLoading);
    }
  }, [panelCommentLoading]);

  useEffect(() => {
    if (atomic) {
      formik.resetForm();

      initialValues = {
        comment: "",
        noActionRequired: atomic && atomic.noActionRequired ? atomic.noActionRequired : false,
        actionRequired: atomic && atomic.actionRequired ? atomic.actionRequired : false,
        dCF: atomic && atomic.dCF ? atomic.dCF : false,
      };
    }
  }, [props.atomic]);

  const actionCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, values: any) => {
    values[event.target.name] = event.target.checked;
    if (event.target.name == "actionRequired" && event.target.checked) {
      values["noActionRequired"] = false;
    }
    if (event.target.name == "noActionRequired" && event.target.checked) {
      values["actionRequired"] = false;
    }
  };

  return (
    <>
      <Formik
        innerRef={(p) => (formik = p)}
        enableReinitialize
        initialValues={initialValues}
        validate={(values) => {
          const errors: any = {};
          if (!values.comment && showCommentsExtra == true) {
            errors.comment = "Please input comment";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          props.onSubmit(values);
          values.comment = "";
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
          return (
            <Dialog
              open={props.open}
              onClose={props.onClose}
              maxWidth={"sm"}
              fullWidth
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                <DialogBoxTitle title="Comments" width={200} closeButton handleClose={props.onClose} />
              </DialogTitle>

              <DialogContent>
                {authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver && !props.isOffline && (
                  <>
                    <form onSubmit={handleSubmit}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="comment"
                        label="Comment"
                        name="comment"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        multiline
                        minRows={4}
                        value={values.comment}
                      />
                      <Typography color="error">{errors.comment}</Typography>

                      {showCommentsExtra && (
                        <Box>
                          {dcfWorkflowEnabled && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="dCF"
                                  id="dCF"
                                  checked={values.dCF}
                                  value={values.dCF}
                                  onChange={handleChange}
                                />
                              }
                              label="DCF"
                            />
                          )}
                          {actionWorkflowEnabled && (
                            <fieldset>
                              <legend>Action:</legend>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.actionRequired}
                                      name="actionRequired"
                                      id="actionRequired"
                                      value={values.actionRequired}
                                      onChange={(e) => {
                                        actionCheckedChange(e, values);
                                        handleChange(e);
                                      }}
                                    />
                                  }
                                  label="Action Required"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.noActionRequired}
                                      name="noActionRequired"
                                      id="noActionRequired"
                                      value={values.noActionRequired}
                                      onChange={(e) => {
                                        actionCheckedChange(e, values);
                                        handleChange(e);
                                      }}
                                    />
                                  }
                                  label="No Action Required"
                                />
                              </FormGroup>
                            </fieldset>
                          )}
                        </Box>
                      )}
                    </form>
                  </>
                )}
                {showCommentsExtra && (loadAtomicComments || loadPanelComments) && (
                  <>
                    <hr />
                    <Typography variant="h6" className={classes.header}>
                      Previous Comments
                    </Typography>
                    <Box className={classes.commentBox} display="flex" flexDirection="column">
                      {!atomicCommentLoading &&
                        !panelCommentLoading &&
                        !atomicCommentError &&
                        !panelCommentError &&
                        comments &&
                        comments.map((audit: any) => {
                          const val: any = JSON.parse(audit.value);
                          return (
                            <Box display="flex" flexDirection="row" className={classes.rows} key={audit.id}>
                              <Typography variant="caption" className={classes.columnA}>
                                <b>{audit.username}</b>
                                <br />
                                {formatDate(audit.date, "DD/MMM/YYYY HH:mm", false)}
                              </Typography>
                              <Typography variant="caption" className={classes.columnB}>
                                <br />
                                {val.dCF ? "DCF - " : ""}
                                {val.actionRequired ? "Action Required - " : ""}
                                {val.noActionRequired ? "No Action Required - " : ""}
                                <InnerHTML html={val.comment.replace(/(?:\r\n|\r|\n)/g, "<br />")} />
                              </Typography>
                            </Box>
                          );
                        })}
                      {(atomicCommentLoading || panelCommentLoading) && <CircularProgress size={24} />}
                      {atomicCommentError && <Typography>Error retrieving comment history.</Typography>}
                    </Box>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.onClose} variant="contained" color="secondary">
                  Cancel
                </Button>
                {authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver && !props.isOffline && (
                  <Button type="submit" variant="contained" color="primary" onClick={() => handleSubmit()}>
                    {isSubmitting ? (
                      <Box width={"100%"} textAlign="center">
                        <CircularProgress size={16} />
                      </Box>
                    ) : props.submitText ? (
                      props.submitText
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default DialogBoxComment;
