import React from "react";
import { Box, CircularProgress } from "@mui/material";

type LoadingProps = {
  mt?: number;
  textAlign?: any;
  boxStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
};
const Loading: React.FC<LoadingProps> = (props) => (
  <Box my={props.mt !== undefined ? props.mt : 4} textAlign={props.textAlign || "center"} style={props.boxStyle || {}}>
    <CircularProgress color="primary" style={props.iconStyle || {}} />
  </Box>
);

export default Loading;
