import { gql } from "@apollo/client";
export default gql`
  query GetSingleAtomicValues(
    $providers: [String!]
    $patientId: Int!
    $panelId: Int
    $atomicName: String
    $allPatients: Boolean
  ) {
    singleAtomicData(
      providers: $providers
      patientId: $patientId
      panelId: $panelId
      atomicName: $atomicName
      allPatients: $allPatients
    ) {
      reportId
      reportDateCollected
      atomicData {
        name
        value
        value_type
        measurement
        range
        abnormal
      }
    }
  }
`;
