import React, { useContext } from "react";
import { GetCombinedReport_combinedReport } from "./types/GetCombinedReport";
import styles from "./CombinedReport.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography, Box, AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import { withStyles } from "@mui/styles";
import AppContext from "../../context/AppContext";
import { formatDate } from "../../utils/date";
import ResultsTable from "./ResultsTable";
import ResultsReportText from "./ResultsReportText";
import ResultsHeader from "./ResultsHeader";
import { INTERUM_STATUS } from "../../constants";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import theme from "../theme";

type ResultsCardProps = {
  episode: GetCombinedReport_combinedReport;
  Key: number;
  refs: any;
  patientId: number;
  allPatients: boolean;
  sortBy?: string | null;
};

const ResultsCard: React.FC<ResultsCardProps> = (props) => {
  const { Key, episode, patientId, allPatients, sortBy } = props;
  const isDesktop = useContext(AppContext).isDesktop;
  const AccordionSummaryCustom = withStyles({
    content: {
      marginTop: "0",
      marginBottom: "0",
      "&$expanded": {
        marginTop: "0",
        marginBottom: "0",
      },
    },
    expanded: {},
  })(AccordionSummary);

  return (
    <Box key={Key} className={styles.resultCard} id={episode.reportLabNumber} mb={[0, 0, 2]}>
      <Accordion
        key={episode.reportLabNumber}
        ref={episode.reportLabNumber && props.refs[episode.reportLabNumber]}
        elevation={0}
        square
        className={
          isDesktop
            ? Key === 0
              ? styles.expansionPanelMt
              : styles.expansionPanelMain
            : styles.expansionPanelMainMobile
        }
        defaultExpanded={isDesktop ? true : false}
      >
        <AccordionSummaryCustom
          expandIcon={<ExpandMoreIcon className={isDesktop ? styles.expandIcon : styles.expandIconMb} />}
          className={isDesktop ? styles.expansionPanelDt : styles.expansionPanelMb}
          id={episode.reportLabNumber ? episode.reportLabNumber : ""}
          aria-controls={episode.reportTestName ? episode.reportTestName : ""}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            position={"relative"}
            p={"16px"}
            className={isDesktop ? styles.resultCardheader : styles.resultCardheaderMb}
          >
            <Typography display={"inline"} className={styles.reportTestName}>
              {episode.reportTestName}
              {episode.urgentStatus && (
                <>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <HtmlTooltip
                    lang={"en"}
                    title={
                      <Typography display={"inline"} className={`${styles.testNameTextAbnormal}`} variant={"inherit"}>
                        Urgent Report
                      </Typography>
                    }
                  >
                    <PriorityHighIcon
                      htmlColor={theme.colorSchemes.light.palette.error.main}
                      fontSize="small"
                      className={styles.urgentPanel}
                    />
                  </HtmlTooltip>
                </>
              )}
            </Typography>
            <Typography display={"inline"}>
              {isDesktop ? "" : formatDate(episode.reportDateCollected, "DD MMM YYYY", true)}
            </Typography>
          </Box>
        </AccordionSummaryCustom>
        <AccordionDetails style={{ display: "block", padding: 0 }}>
          <ResultsHeader episode={episode} />
          {episode.reportStatus !== INTERUM_STATUS.Pending &&
            (episode.cumulative ? (
              <ResultsTable
                episode={episode}
                isTextReport={false}
                allPatients={allPatients}
                patientId={patientId}
                sortBy={sortBy}
              />
            ) : (
              <>
                <ResultsTable
                  episode={episode}
                  isTextReport={true}
                  allPatients={allPatients}
                  patientId={patientId}
                  sortBy={sortBy}
                />
                <ResultsReportText episode={episode} />
              </>
            ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ResultsCard;
