import { gql } from "@apollo/client";

export default gql`
  query GetHospitalMfa($hospid: String!) {
    getHospitalMfa(hospid: $hospid) {
      mfaEnforced
      trustBrowserEnabled
      mfaProviders {
        id
        name
        enabled
      }
      hidePanelEnabled
      autoSearchEnabled
    }
  }
`;
