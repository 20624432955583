import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Typography,
  Table,
  TableCell,
  Link,
  Box,
  TableHead,
  TableBody,
  TableRow,
  ClickAwayListener,
  Paper,
  Popper,
  Fade,
  IconButton,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  GetCumulativeAtomicValues_atomic,
  GetCumulativeAtomicValues_atomic_atomicData,
} from "../CumulativePanel/types/GetCumulativeAtomicValues";
import { formatDate } from "../../utils/date";
import { CumulativeContext } from "../../context/CumulativeContext";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";
import AppContext from "../../context/AppContext";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import { zipObject } from "lodash";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDialog from "../Preview/Comments";
import { Theme } from "@mui/material/styles/createTheme";
import ReferenceGraph from "../CumulativeGraph/ReferenceGraph";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import thm from "../theme";

interface StyleProps {
  ranges: "full" | "ranges" | "cumulative";
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  item: {
    borderTop: "1px solid #e9e9e9",
  },
  olderValueColumn: {
    minWidth: "125px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "75px",
    },
    flexGrow: 1,
  },
  dateHeaderContainer: {
    display: "flex",
    padding: "0px",
    alignItems: "center",
    justifyContent: "flex-end",
    alignContent: "center",
    backgroundColor: "#fff",
    minWidth: ({ ranges }) => (ranges !== "cumulative" ? 253 : 130),
    position: "sticky",
    zIndex: 200,
  },
  dateHeaderText: {
    backgroundColor: "#FFEEDE",
    textAlign: "right",
    color: thm.colorSchemes.light.palette.primary.main,
    borderRadius: "30px 0 0 30px",
    padding: "14px 16px",
    minWidth: ({ ranges }) => (ranges !== "cumulative" ? 210 : 120),
  },
  graphHeader: {
    backgroundColor: "#FFEEDE",
    textAlign: "right",
    color: "red",
    borderRadius: "30px 0 0 30px",
    padding: "14px 16px",
    minWidth: ({ ranges }) => (ranges !== "cumulative" ? 210 : 120),
  },
  dateHeader: {
    backgroundColor: "#FFEEDE",
    position: "sticky",
  },
  dateHeaderNamesOnly: {
    backgroundColor: "#FFEEDE",
    position: "sticky",
  },
  columnWithRanges: {
    minWidth: ({ ranges }) => (ranges !== "cumulative" ? 380 : 140),
    zIndex: "1100 !important",
  },
  itemNameText: {
    width: ({ ranges }) => (ranges !== "cumulative" ? 222 : 122),
    paddingRight: 8,
    overflowWrap: "anywhere",
  },
  tooltip: {
    padding: theme.spacing(1),
  },
  sticky: {
    zIndex: "1100 !important",
    position: "sticky",
    top: 150,
    [theme.breakpoints.down("xs")]: {
      top: 177,
    },
  },
  chartButton: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
    height: "25px !important",
    width: "25px !important",
  },
  graph: {
    color: thm.colorSchemes.light.palette.primary.dark + " !important",
    height: "25px !important",
    width: "25px !important",
  },
  mostRecentHeader: {
    backgroundColor: "#FFEEDE",
    borderTop: "0px solid " + thm.colorSchemes.light.palette.primary.main,
    borderLeft: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    borderRight: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    zIndex: "1100 !important",
    left: ({ ranges }) => (ranges !== "cumulative" ? 388 : 148),
    position: "sticky",
  },
  mostRecentPlaceholder: {
    backgroundColor: thm.colorSchemes.light.palette.primary.main + " !important",
  },
  mostRecentValueColumn: {
    borderTop: "1px solid #e9e9e9",
    borderLeft: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    borderRight: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    left: ({ ranges }) => (ranges !== "cumulative" ? 388 : 148),
    position: "sticky",
    backgroundColor: "white",
  },
  mostRecentValueColumnLast: {
    borderTop: "1px solid #e9e9e9",
    borderLeft: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    borderRight: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    borderBottom: "3px solid " + thm.colorSchemes.light.palette.primary.main,
    left: ({ ranges }) => (ranges !== "cumulative" ? 388 : 148),
    position: "sticky",
    backgroundColor: "white",
  },
  valuesColumn: {
    textAlign: "center !important" as any,
  },
  ieStickyHeader: {
    top: "294px",
    position: "fixed",
  },
  ieItemStart: {
    marginTop: "200px",
  },
  dateLink: {
    height: "50px",
    display: "inline-flex",
    alignItems: "center",
    textAlign: "center",
    textDecoration: "none",
  },
  columnFiller: {
    width: "100%",
  },
  comment: {
    cursor: "pointer",
  },
}));

const StyledTable = styled((props) => <Table {...props} />)({
  paddingTop: 0,
});

const StyledTableCell = styled((props) => <TableCell style={props.style} {...props} />)({
  height: 50,
  minWidth: "125px",
  justifyContent: "center",
  padding: "0px",
  textAlign: "center",
});

const StyledFixedLeftTableCell = styled((props) => <TableCell style={props.style} {...props} />)({
  height: 50,
  left: 0,
  position: "sticky",
  justifyContent: "center",
  padding: "0px",
  textAlign: "center",
  backgroundColor: "white",
});

const StyledTableHeaderItem = styled((props) => <TableCell style={props.style} {...props} />)({
  height: 70,
  minWidth: "125px",
  justifyContent: "center",
  padding: 0,
  textAlign: "center",
});

const StyledPlaceholder = styled((props) => <Box {...props} />)({
  height: 20,
  width: "100%",
  backgroundColor: thm.colorSchemes.light.palette.primary.light,
  textAlign: "center",
  justifyContent: "center",
  color: "white",
  position: "sticky",
});

const StyledTableHeaderFixedLeftItem = styled((props) => <TableCell style={props.style} {...props} />)({
  height: 50,
  left: 0,
  justifyContent: "center",
  padding: 0,
  textAlign: "center",
  zIndex: 1100,
  backgroundColor: "white",
});

const ItemText = styled((props) => <Typography {...props} />)({
  textAlign: "center",
});

const ItemNameText = styled((props) => <Typography {...props} />)({
  textAlign: "left",
  fontWeight: "bold",
  height: "50px",
  display: "inline-flex",
  alignItems: "center",
});

const ItemUnitsText = styled((props) => <Typography {...props} />)({
  textAlign: "center",
  width: "150px",
  backgroundColor: "#f5f9fd",
  color: "#4e5962",
  height: "50px",
  display: "inline-flex",
  alignItems: "center",
  paddingLeft: "4px",
  justifyContent: "center",
});

const StyledTableFillerHeaderItem = styled((props) => <TableCell style={props.style} {...props} />)({
  height: 70,
  width: "100%",
  justifyContent: "center",
  padding: 0,
  textAlign: "center",
});

type CumulativeTableProps = {
  totaldata: any;
  data: (GetCumulativeAtomicValues_atomic | null)[] | null;
  loincOrders: string[];
  commentOrders: string[];
  allPatients: boolean;
  callBackCumulativeCSVData: (callBackCumulativeCSVData: any) => void;
  latestResultWithCompleteRanges: GetCumulativeAtomicValues_atomic | null;
  displayType?: null | "ranges" | "cumulative";
  providers: string[];
  panelId: any;
  patientId: any;
  hideMobilePanel?: any;
  patient?: any;
  episodeData?: any;
  reportId?: any;
  downloadChart?: boolean;
  setIsChartDownloading?: React.Dispatch<React.SetStateAction<boolean>>;
  openChartView: boolean;
};

const CumulativeTable: React.FC<CumulativeTableProps> = (props: CumulativeTableProps) => {
  const [openCommentDialog, setOpenCommentDialog] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);

  const [atomicName, setAtomicName] = useState("");
  const [atomicIndex, setAtomicIndex] = useState(0);
  const [ranges, setRanges] = useState<"full" | "ranges" | "cumulative">("full");
  const isDesktop = useContext(AppContext).isDesktop;
  const location = useLocation();

  useEffect(() => {
    setRanges(!props.displayType ? "full" : props.displayType);
  }, [props, props.displayType]);

  const classes = useStyles({
    ranges: ranges,
  });

  const patientId: number = props.data && props.data.length > 0 && props.data[0] ? props.data[0].reportPatientId : 0;
  const { setIsCumulative } = useContext(CumulativeContext);
  const { setInitialLabNo, setLabnoDigitOnly } = useContext(PatientDetailsContext);
  const { setShowIndGraph, showIndGraph, setShowCulGraph, showCulGraph, allPanels } = useContext(CumulativeContext);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [openCommentDialog, setOpenCommentDialog, anchorEl]);

  const handleOnClick = (labnumber: string, labnumberDigitOnly: string) => {
    setIsCumulative(false);
    setInitialLabNo(labnumber);
    setLabnoDigitOnly(labnumberDigitOnly);
  };

  const getTransformData = () => {
    const result = props.loincOrders.map((loinc) => {
      const latestLoincRange =
        props.latestResultWithCompleteRanges &&
        props.latestResultWithCompleteRanges.atomicData &&
        props.latestResultWithCompleteRanges.atomicData.find((val) => val && [val.loinc, val.name].join("|") === loinc);

      return {
        loinc: loinc,
        showChart: false,
        atomics:
          props.data &&
          props.data.map((value) => {
            const atomicData = value && value.atomicData ? value.atomicData : [];
            const obj: GetCumulativeAtomicValues_atomic_atomicData | null | undefined = atomicData.find(
              (val) => val && [val.loinc, val.name].join("|") === loinc,
            );

            return {
              value: obj ? obj.value : "-",
              abnormal: obj && obj.abnormal,
              value_type: obj && obj.value_type,
            };
          }),
        range: latestLoincRange ? latestLoincRange.range : "-",
        measurement: latestLoincRange ? latestLoincRange.measurement : "",
      };
    });

    result.map((res) => {
      const validValues = res.atomics?.filter((obj) => !isNaN(Number(obj.value))).length;

      if (Number(validValues) > 1) res.showChart = true;

      return res;
    });

    return result;
  };

  const getTransformComments = () => {
    return props.commentOrders.map((loinc) => {
      const latestLoincRange =
        props.latestResultWithCompleteRanges &&
        props.latestResultWithCompleteRanges.atomicData &&
        props.latestResultWithCompleteRanges.atomicData.find((val) => val && [val.loinc, val.name].join("|") === loinc);
      return {
        loinc: loinc,
        atomics:
          props.data &&
          props.data.map((value) => {
            const atomicData = value && value.atomicData ? value.atomicData : [];
            const obj: GetCumulativeAtomicValues_atomic_atomicData | null | undefined = atomicData.find(
              (val) => val && [val.loinc, val.name].join("|") === loinc,
            );

            return {
              value: obj ? obj.value : "-",
              abnormal: obj && obj.abnormal,
              value_type: obj && obj.value_type,
            };
          }),
        range: latestLoincRange ? latestLoincRange.range : "-",
        measurement: latestLoincRange ? latestLoincRange.measurement : "",
      };
    });
  };

  useEffect(() => {
    const downloadData = getTransformData();

    const cumulativeCSVData = downloadData.map((loinc) => {
      if (loinc && loinc.atomics) {
        const atomic = loinc.atomics.map((ele) => {
          return [ele.value];
        });
        const headers = props.data
          ? props.data.map((value) => {
              return value
                ? `${formatDate(value.reportDateCollected, "D MMM YYYY", true)} (${value.reportLabNumberDigitOnly})`
                : "";
            })
          : [];
        const mergedAtomicValues = zipObject(headers, atomic);
        return {
          atomics: loinc.loinc.split("|")[1],
          ...mergedAtomicValues,
          range: loinc.range ? loinc.range : "",
          unit: loinc.measurement ? loinc.measurement : "",
        };
      } else {
        return { ...loinc };
      }
    });
    props.callBackCumulativeCSVData(cumulativeCSVData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderData = useMemo(() => {
    const columns = props.data
      ? props.data.map((value) => {
          return {
            labnumberDigitOnly: value ? value.reportLabNumberDigitOnly : "",
            labnumber: value ? value.reportLabNumber : "",
            reportDate: value ? value.reportDateCollected : "",
          };
        })
      : [];

    return (
      <TableRow>
        <StyledTableHeaderFixedLeftItem
          className={`${classes.dateHeader} ${classes.columnWithRanges}`}
          key="namesHeader"
        >
          <Box display={"flex"} flexDirection={"column"} alignContent={"space-between"}>
            <StyledPlaceholder />
            <Box className={classes.dateHeaderContainer}>
              {/* Disabled for now */}
              {/* <Button size="small" variant="contained" color="primary" onClick={() => openGraphPanel(true)}>
                Graph View
                <StackedBarChartIcon className={classes.graph} fontSize="small" />
              </Button> */}
              <IconButton onClick={() => openGraphPanel(false)}></IconButton>
              <Box className={classes.dateHeaderText}>
                <Typography className={`bold`}>Date Collected</Typography>
              </Box>
            </Box>
          </Box>
        </StyledTableHeaderFixedLeftItem>

        {columns.map(
          (column, index) =>
            ((ranges === "ranges" && index === 0) || ranges !== "ranges") && (
              <StyledTableHeaderItem
                className={`${classes.dateHeader} ${index === 0 ? classes.mostRecentHeader : ""}`}
                key={index}
              >
                <HtmlTooltip
                  title={
                    <Box display="flex" flexDirection="column" className={classes.tooltip}>
                      {/* <Typography display={'inline'} variant={'inherit'} color='primary'>Last Updated: {formatDate(reportDateCreated,'D MMM YYYY', true)}</Typography> */}
                      <Typography display={"inline"} variant={"inherit"}>
                        Lab No. {column.labnumberDigitOnly}
                      </Typography>
                    </Box>
                  }
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignContent={"space-between"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <StyledPlaceholder className={`${index === 0 ? classes.mostRecentPlaceholder : ""}`}>
                      {index === 0 && <Typography variant="body2">Latest</Typography>}
                    </StyledPlaceholder>
                    {isDesktop ? (
                      <Link
                        className={classes.dateLink}
                        component={RouterLink}
                        onClick={() => handleOnClick(column.labnumber, column.labnumberDigitOnly)}
                        to={{
                          pathname: column.labnumber.includes("/")
                            ? `/patient/${patientId}/${props.allPatients}/labnumber/${column.labnumber}`
                            : `/patient/${patientId}/${props.allPatients}/labnumber/${column.labnumberDigitOnly}/${column.labnumber}`,
                          state: location.state,
                        }}
                      >
                        {formatDate(column.reportDate, "D MMM YYYY", true)}
                      </Link>
                    ) : (
                      <Link
                        className={classes.dateLink}
                        component={RouterLink}
                        onClick={() => handleOnClick(column.labnumber, column.labnumberDigitOnly)}
                        to={{
                          pathname: column.labnumber.includes("/")
                            ? `/report/${patientId}/${props.allPatients}/labnumber/${column.labnumber}`
                            : `/report/${patientId}/${props.allPatients}/labnumber/${column.labnumberDigitOnly}/initial/${column.labnumber}`,
                          state: location.state,
                        }}
                      >
                        {formatDate(column.reportDate, "D/MM/YY", true)}
                      </Link>
                    )}
                  </Box>
                </HtmlTooltip>
              </StyledTableHeaderItem>
            ),
        )}
        <StyledTableFillerHeaderItem className={`${classes.dateHeader} ${classes.columnFiller}`} key={"filler"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignContent={"space-between"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <StyledPlaceholder />
            <Box className={classes.dateLink}></Box>
          </Box>
        </StyledTableFillerHeaderItem>
      </TableRow>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, classes, ranges]);

  const AtomicData = useMemo(() => {
    const downloadData = getTransformData();
    const openGraphPanel = (value: boolean | false, loinc: string, index: number) => {
      setAtomicName(loinc);
      setAtomicIndex(index);
      setShowIndGraph(value);
    };

    if (props.openChartView) {
      setAtomicName(getTransformData().filter((obj) => obj.showChart)[0].loinc);
      setAtomicIndex(0);
      setShowIndGraph(true);
    }

    const countDetectedValues = (loincObj: any) => {
      let count = 0;

      loincObj.atomics.forEach((atomic: any) => {
        if (/[0-9]/.test(atomic.value)) {
          count++;
        }
      });
      return count;
    };

    return downloadData.map((loinc, index: number, isMobile) => {
      return (
        <TableRow key={index}>
          <StyledFixedLeftTableCell key="loinc">
            <Box display={"flex"} flexDirection={"row"} alignContent={"space-between"} marginLeft={"4px"}>
              <ItemNameText className={classes.itemNameText}>{loinc.loinc.split("|")[1]} </ItemNameText>
              {loinc.atomics && loinc.atomics?.length > 1 && countDetectedValues(loinc) > 0 && loinc.showChart && (
                <ItemText
                  marginLeft={"-41px"}
                  paddingTop={"5px"}
                  paddingLeft={ranges === "cumulative" && isMobile ? "25px" : ""}
                >
                  <IconButton onClick={() => openGraphPanel(true, loinc.loinc, index)}>
                    <StackedBarChartIcon className={classes.chartButton} fontSize="small" />
                  </IconButton>
                </ItemText>
              )}
              {ranges !== "cumulative" && <ItemUnitsText>{`${loinc.range} ${loinc.measurement}`}</ItemUnitsText>}
            </Box>
          </StyledFixedLeftTableCell>

          {loinc &&
            loinc.atomics &&
            loinc.atomics.map(
              (obj, atomicIndex) =>
                ((ranges === "ranges" && atomicIndex === 0) || ranges !== "ranges") &&
                obj.value_type !== "FT" && (
                  <StyledTableCell
                    key={atomicIndex}
                    className={`${
                      atomicIndex > 0
                        ? classes.item
                        : index === downloadData.length - 1
                          ? props.commentOrders.length > 0
                            ? classes.mostRecentValueColumn
                            : classes.mostRecentValueColumnLast
                          : classes.mostRecentValueColumn
                    } valuesColumn`}
                  >
                    <ItemText color={obj && obj.abnormal ? "error" : "inherit"}>{obj ? obj.value : "-"}</ItemText>
                  </StyledTableCell>
                ),
            )}
        </TableRow>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, classes, ranges]);

  const handleTooltipClose = (index: number) => {
    if (openCommentDialog === index) {
      setOpenCommentDialog(-1);
    }
  };

  const handleTooltipOpen = (event: any, index: number) => {
    setOpenCommentDialog(index);
    setAnchorEl(event.currentTarget);
  };

  const CommentData = useMemo(() => {
    const downloadComment = getTransformComments();
    const mainComment: any[] = [];

    if (downloadComment) {
      //Only take the Comment section.
      downloadComment
        .filter((comment) => comment.loinc.toLowerCase().includes("comment"))
        .forEach((comment, commentIdx) => {
          comment.atomics?.forEach((atomic, atomicIdx) => {
            if (!mainComment[atomicIdx]) {
              mainComment[atomicIdx] = [];
            }
            mainComment[atomicIdx][commentIdx] = { title: comment.loinc, value: atomic.value };
          });
        });
    }

    return (
      <>
        {showIndGraph ? (
          <></>
        ) : (
          <TableRow key={10000}>
            <StyledFixedLeftTableCell key="loinc">
              <Box display={"flex"} flexDirection={"row"} alignContent={"space-between"} marginLeft={"4px"}>
                <ItemNameText className={classes.itemNameText}>Comments</ItemNameText>
                {ranges !== "cumulative" && <ItemUnitsText></ItemUnitsText>}
              </Box>
            </StyledFixedLeftTableCell>

            {mainComment &&
              mainComment.map((cmt, index) => {
                return isDesktop ? (
                  <StyledTableCell
                    key={index}
                    className={`${index > 0 ? classes.item : classes.mostRecentValueColumnLast} ${
                      classes.valuesColumn
                    }`}
                  >
                    <HtmlTooltip title={<CommentsDialog commentsData={cmt} />} disableInteractive={false}>
                      <CommentIcon className={classes.comment} />
                    </HtmlTooltip>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    key={index}
                    className={`${index > 0 ? classes.item : classes.mostRecentValueColumnLast} ${
                      classes.valuesColumn
                    }`}
                  >
                    <CommentIcon
                      className={classes.comment}
                      onClick={(e) => {
                        handleTooltipOpen(e, index);
                      }}
                    />
                    <div>
                      <Popper
                        id={`popper-${index}`}
                        open={openCommentDialog === index}
                        placement="bottom-end"
                        anchorEl={anchorEl}
                        transition
                      >
                        {({ TransitionProps }) => (
                          <ClickAwayListener key={`click-${index}`} onClickAway={() => handleTooltipClose(index)}>
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper //className={classes.SliderBox}
                              >
                                <CommentsDialog key={`comment-${index}`} commentsData={cmt} />
                              </Paper>
                            </Fade>
                          </ClickAwayListener>
                        )}
                      </Popper>
                    </div>
                  </StyledTableCell>
                );
              })}
          </TableRow>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, classes, ranges, openCommentDialog, setOpenCommentDialog]);

  const openGraphPanel = (value: boolean | false) => {
    setShowCulGraph(value);
    setShowIndGraph(value);
  };

  return (
    <>
      {showCulGraph ? (
        <></>
      ) : showIndGraph ? (
        <>
          <ReferenceGraph
            measurement={getTransformData().filter((obj) => {
              if (obj.loinc == atomicName) {
                return obj.measurement;
              }
              return null;
            })}
            data={props.totaldata}
            providers={props.providers}
            patientId={props.patientId}
            panelId={props.panelId}
            atomicName={atomicName}
            isMobile={props.displayType}
            loincs={getTransformData().filter((obj) => obj.showChart)}
            atomicIndex={atomicIndex}
            patient={props.patient}
            episodeData={props.episodeData}
            reportId={props.reportId}
            downloadChart={props.downloadChart}
            allPatients={props.allPatients}
            setIsChartDownloading={props.setIsChartDownloading as React.Dispatch<React.SetStateAction<boolean>>}
            allPanels={allPanels}
          />
        </>
      ) : (
        <StyledTable
          //dense={false}
          className={classes.olderValueColumn}
          stickyHeader
        >
          <TableHead>{HeaderData}</TableHead>
          {props.commentOrders.length > 0 ? (
            <TableBody>
              {AtomicData}
              {CommentData}
            </TableBody>
          ) : (
            <TableBody>{AtomicData}</TableBody>
          )}
        </StyledTable>
      )}
    </>
  );
};

export default CumulativeTable;
