import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./EpisodeListTable.module.css";
import AppContext from "../../context/AppContext";
import { PatientContext } from "../../context/PatientsContext";
import authService from "../../services/authService";

type NoResultsMessageProps = {
  isOpen: boolean;
};

export const NoResultsMessage: React.FC<NoResultsMessageProps> = (props: NoResultsMessageProps) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const { advancedSearch, searchNow } = useContext(PatientContext);

  if (advancedSearch.fullname && !props.isOpen) {
    return (
      <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
        <Typography variant="h4">
          No patients found for <strong>{advancedSearch.fullname}</strong>
        </Typography>
        <Typography>
          Tip: Try searching by firstname and surname using a comma, e.g. Smith, Robert Jones or using the advanced
          search
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
        <Typography variant="h4">
          {!authService.getAutoSearch() && !searchNow
            ? "Please search for a patient."
            : "No results found matching your search criteria"}
        </Typography>
      </Box>
    );
  }
};

export default NoResultsMessage;
