import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PatientLab } from "../../graphql/types/gql-global-types";
import GetPrintableMultiCombineCtAtomicAuditLogQuery from "./GetPrintableMultiCombineCtAtomicAuditLogQuery";
import { GetPrintableMultiCombineCtAtomicAuditLog } from "./types/GetPrintableMultiCombineCtAtomicAuditLog";
import PrintableCombineCtAtomicOrders from "./PrintableCombineCtAtomicOrders";
import Loading from "../Loading/Loading";
import { AuditAction } from "../../constants";

const PrintablePatientsPage: React.FC<any> = () => {
  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  // const downloadMode = urlSearchParams.get("downloadMode") ? Number(urlSearchParams.get("downloadMode")) : 0;
  const patientIds = urlSearchParams.get("patientIds");
  const reportIds = urlSearchParams.get("reportIds");
  const [downloadReportData, setDownloadReportData] = useState<any[]>([]);

  const patientsLabNos = patientIds?.split(",") || [];

  const reportIdNos = reportIds?.split(",").map(function (item) {
    return parseInt(item);
  });

  // const totalCount = patientsLabNos.length + 1;
  // let currentCount = 0;
  const patientLabs: PatientLab[] = [];
  for (const p of patientsLabNos) {
    const patIds = p.split("~");
    patientLabs.push({
      patientId: parseInt(patIds[0]),
      labnumber: patIds[1],
      reportIds: reportIdNos,
    });
  }

  const { data, loading } = useQuery<GetPrintableMultiCombineCtAtomicAuditLog>(
    GetPrintableMultiCombineCtAtomicAuditLogQuery,
    {
      variables: {
        patientLabs: patientLabs,
        action: "CLINTRIAL",
        auditLog: true,
        queryAction: AuditAction.PRINT_COMPRESSED,
      },
    },
  );

  useEffect(() => {
    if (!loading && data) {
      console.log(data);
      const ordersData = [];
      for (const ct of data.ctcombineAtomicPatientAuditLogs) {
        const downRepData: any = {};
        downRepData.patient = ct.patient;
        if (ct.reports) {
          downRepData.reports = ct.reports;
        }
        if (ct.auditlogs) {
          downRepData.auditlogs = ct.auditlogs;
        }
        ordersData.push(downRepData);
      }
      console.log(ordersData);
      setDownloadReportData(ordersData);
    }
  }, [data, loading]);

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <PrintableCombineCtAtomicOrders orders={downloadReportData} />
    </>
  );
};

export default PrintablePatientsPage;
