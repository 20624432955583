import { gql } from "@apollo/client";

export default gql`
  query getCytologySummary($id: Float!, $providers: [String!]!) {
    getCytologySummary(id: $id, providers: $providers) {
      summary {
        cervical_scrren_diag
        total_hpv
        total_cytology
        endo_cervical_cells
        unsatisfactory_cytology
      }
      graph {
        doctor_name
        hpv_routine
        hpv_and_reflex_lbc
        hpv_follow_up
        co_test
        self_collect
      }
    }
  }
`;
