import React, { useState } from "react";
import styles from "./CombinedReport.module.css";
import authService from "../../services/authService";
import { Box, Button, Typography } from "@mui/material";
import { CLINCAL_TRIAL_USER_TYPE, CLINCAL_SIGNIFICANCE_STATUS } from "../../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export interface IAppProps {
  atomic: any;
  onAtomicSignificanceButtonClick: any;
  isOffline?: boolean;
}

const AtomicSignificanceButtons: React.FC<IAppProps> = (props: IAppProps) => {
  const { atomic } = props;
  const { allowCS, allowNCS, allowIndeterminate } = authService.getRestrictedClinicalStatuses();

  const isDisabled: boolean =
    authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Viewer ||
    (atomic.csApproval !== null ? atomic.csApproval : false);

  const calcButtonVisibility = (status: number): boolean => {
    return (
      (atomic.csStatus === null && authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver) ||
      atomic.csStatus === status
    );
  };

  const [showCSButton, setShowCSButton] = useState<boolean>(
    calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.ClinicalSignificant),
  );
  const [showNCSButton, setShowNCSButton] = useState<boolean>(
    calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.NotClinicalSignificant),
  );
  const [showIndeterminateButton, setShowIndeterminateButton] = useState<boolean>(
    calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.Indeterminate),
  );

  React.useEffect(() => {
    setShowCSButton(calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.ClinicalSignificant));
    setShowNCSButton(calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.NotClinicalSignificant));
    setShowIndeterminateButton(calcButtonVisibility(CLINCAL_SIGNIFICANCE_STATUS.Indeterminate));
  }, [atomic]);

  const csClickHandler = () => {
    if (atomic.csStatus && !showNCSButton && !showIndeterminateButton) {
      setShowNCSButton(true && allowNCS);
      setShowIndeterminateButton(true && allowIndeterminate);
    } else {
      props.onAtomicSignificanceButtonClick({ atomic, status: CLINCAL_SIGNIFICANCE_STATUS.ClinicalSignificant });
      setShowNCSButton(false);
      setShowIndeterminateButton(false);
    }
  };

  const ncsClickHandler = () => {
    if (atomic.csStatus && !showCSButton && !showIndeterminateButton) {
      setShowCSButton(true && allowCS);
      setShowIndeterminateButton(true && allowIndeterminate);
    } else {
      props.onAtomicSignificanceButtonClick({ atomic, status: CLINCAL_SIGNIFICANCE_STATUS.NotClinicalSignificant });
      setShowCSButton(false);
      setShowIndeterminateButton(false);
    }
  };

  const indeterminateClickHandler = () => {
    if (atomic.csStatus && !showCSButton && !showNCSButton) {
      setShowCSButton(true && allowCS);
      setShowNCSButton(true && allowNCS);
    } else {
      props.onAtomicSignificanceButtonClick({ atomic, status: CLINCAL_SIGNIFICANCE_STATUS.Indeterminate });
      setShowCSButton(false);
      setShowNCSButton(false);
    }
  };

  const csButton = allowCS && showCSButton && !props.isOffline && (
    <Button className={styles.csButton} onClick={() => csClickHandler()} disabled={isDisabled}>
      CS
    </Button>
  );

  const ncsButton = allowNCS && showNCSButton && !props.isOffline && (
    <Button className={styles.ncsButton} onClick={() => ncsClickHandler()} disabled={isDisabled}>
      NCS
    </Button>
  );

  const indeterminateButton = allowIndeterminate && showIndeterminateButton && !props.isOffline && (
    <Button className={styles.indeterminateButton} onClick={() => indeterminateClickHandler()} disabled={isDisabled}>
      ?CS
    </Button>
  );

  const reviewText = (
    <Typography className={styles.approverText}>
      {atomic.csStatus === null && <>Not reviewed</>}
      {atomic.csStatus !== null && (
        <>
          {atomic.csName}
          <br />
          {dayjs.utc(atomic.csDatetime).local().format("DD/MMM/YYYY HH:mm")}
        </>
      )}
    </Typography>
  );

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
        <Box display="flex" flexDirection="row" alignItems="center">
          {csButton}
          {ncsButton}
          {indeterminateButton}
        </Box>

        {authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Viewer && (
          <Box display="flex" flexDirection="column" alignItems="center">
            {reviewText}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AtomicSignificanceButtons;
