import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { useQuery } from "@apollo/client";
import { GetFoodPrintReport } from "./types/GetFoodPrintReport";
import GetFoodPrintReportQuery from "./GetFoodPrintReportQuery";
import { b64toBlob } from "./pdfUtils";
import { downloadHelper } from "../../utils/downloadHelper";

type FoodPrintReportProps = {
  labnumber: string;
};

const FoodPrintReport: React.FC<any> = () => {
  const { labnumber }: FoodPrintReportProps = useParams() as FoodPrintReportProps;

  const {
    data,
    loading,
    error: fetchError,
  } = useQuery<GetFoodPrintReport>(GetFoodPrintReportQuery, {
    variables: { labnumber: labnumber },
  });

  // PDF
  useEffect(() => {
    if (data && data.foodprintreport && data.foodprintreport.reportpdf) {
      const blobData = b64toBlob(data.foodprintreport.reportpdf, "application/pdf");
      downloadHelper(blobData, `foodprint-report-${labnumber}.pdf`);
      window.parent.postMessage("eResults2.0 Download Complete", "*");
    }
  }, [labnumber, data]);

  if (loading) {
    return <Loading />;
  }

  if (fetchError) {
    return <ErrorPopover />;
  }

  return <div></div>;
};

export default FoodPrintReport;
