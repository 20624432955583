import React, { Fragment, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Loading from "../Loading/Loading";
import MainNavigation from "../MainNavigation/MainNavigation";
import { Link } from "@mui/material";
import useWindowSize from "react-use/lib/useWindowSize";
import * as Constants from "../../constants";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UserGuidePage: React.FC = () => {
  const [loadedNumPages, setLoadedNumPages] = useState(0);
  const [failedLoad, setFailedLoad] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  function onDocumentLoadSuccess(a: { numPages: number }) {
    setLoadedNumPages(a.numPages);
  }

  const { width, height } = useWindowSize();

  const file = useMemo(
    () => ({
      url: Constants.getUserGuide(),
      httpHeaders: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "Content-Length,Content-Type,Accept-Ranges",
      },
    }),
    [],
  );

  return (
    <Fragment>
      <MainNavigation hideTopMenu showBackButton hideSeparator />
      <div
        style={{
          height: height - 103,
          width: width,
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {failedLoad ? (
          <Link href={Constants.getUserGuide()} target="blank">
            Click to download User Guide
          </Link>
        ) : (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => {
              setFailedLoad(true);
            }}
            loading={<Loading />}
          >
            {Array.from(new Array(loadedNumPages), (el, index) => (
              <div key={`page_${index + 1}`} onClick={() => setZoomed(!zoomed)}>
                <Page
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  renderForms={false}
                  width={zoomed ? window.innerWidth * 2 : window.innerWidth}
                  loading={""}
                />
              </div>
            ))}
          </Document>
        )}
      </div>
    </Fragment>
  );
};

export default UserGuidePage;
