import React, { useContext } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./HospAdminUserListTable.module.css";
import AppContext from "../../context/AppContext";
import thm from "../theme";

const useStyles = makeStyles(() => ({
  purpleShade: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light,
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

export const UserListHeader: React.FC = () => {
  const isDesktop = useContext(AppContext).isDesktop;
  const classes = useStyles();

  return (
    <Box mx={isDesktop ? 1 : 0} my={0.2} px={isDesktop ? 2 : 0} py={1}>
      <Grid container className={classes.purpleShade}>
        <Grid container item xs sm md>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography className={isDesktop ? styles.headerText : styles.headerTextMob}>Username</Typography>
          </Grid>
          <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs sm={2} md={2} lg={2}>
            <Typography className={styles.headerText}>Name</Typography>
          </Grid>
          <Grid sx={{ display: { xs: "none", lg: "block" } }} item xs="auto"></Grid>
          <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs sm md lg={2}>
            <Typography className={styles.headerText}>Email</Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={1}>
            <Typography className={isDesktop ? styles.headerTextNpPad : styles.headerTextMob}>
              Group Administrator
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={2} md={1} lg={1}>
            <Typography className={styles.headerTextNpPad}>Shared Account</Typography>
          </Grid>
          <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={2} md={1} lg={1}>
            <Typography className={styles.headerTextNp}>Last Login</Typography>
          </Grid>
          <Grid item sx={{ display: { xs: "none", lg: "block" } }} xs={1} sm={2} md={1} lg={1}>
            <Typography className={styles.headerTextNp}>Last IP</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1}>
            <Typography className={styles.headerTextNpPad}>Action</Typography>
          </Grid>
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <Typography className={isDesktop ? styles.headerText : styles.headerTextMpMob}>Enable</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
