import { gql } from "@apollo/client";
export default gql`
  mutation LoginDelegate($input: LoginDelegateInput!) {
    loginDelegate(input: $input) {
      accessToken
      username
      expired
      providers
      allowAllPatients
      showDeletedResults
      defaultWardCode
      sharedaccount
      hospid
      wards {
        id
        hospid
        code
        name
      }
      lastlogin
      hospitalAdmin
      state
      resultNotification
      notificationEmail
      email
      clinicalTrial
      userId
      smscEnabled
      name
      restrictedClinicalStatuses
      dcfWorkflowEnabled
      actionWorkflowEnabled
      mobileNumber
      repeatAtomicComment
      hidePanel
      autoSearch
    }
  }
`;
