import React from "react";
import { StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "../gqlClient";
import App from "./App";
import { StyledEngineProvider, Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import theme from "./theme";
import Ribbon from "./common/Ribbon/Ribbon";

const Main: React.FC = () => (
  //injectFirst https://mui.com/material-ui/guides/interoperability/
  <ApolloProvider client={client}>
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme}>
        <StrictMode>
          <Ribbon />
          <App />
        </StrictMode>
      </CssVarsProvider>
    </StyledEngineProvider>
  </ApolloProvider>
);

export default Main;
