import { gql } from "@apollo/client";

export default gql`
  query GetStates {
    getStates {
      id
      state
    }
  }
`;
