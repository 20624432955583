import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import theme from "../theme";
import authService from "../../services/authService";
import { useQuery } from "@apollo/client";
import Loading from "../Loading/Loading";
import {
  GetDiabeticHba1cResults,
  GetDiabeticHba1cResults_getDiabeticHba1cResults,
} from "./types/GetDiabeticHba1cResults";
import GetDiabeticHba1cResultsQuery from "./GetDiabeticHba1cResultsQuery";
import { DownloadMenuIcon, PrintMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import styles from "./CPDprograms.module.css";
import { downloadHelper } from "../../utils/downloadHelper";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

const CPDdiabetesTab2: React.FC = () => {
  const chartRef = useRef<any>(null);
  const providers = authService.getCpdProviderValues();

  const [diabetesHbaqc, SetDiabetesHbaqc] = useState<GetDiabeticHba1cResults_getDiabeticHba1cResults[]>([]);

  const { data, loading } = useQuery<GetDiabeticHba1cResults>(GetDiabeticHba1cResultsQuery, {
    variables: {
      providerNo: providers,
    },
  });

  useEffect(() => {
    if (data) {
      SetDiabetesHbaqc(data?.getDiabeticHba1cResults);
    }
  }, [data, providers]);

  const graphData = {
    labels: diabetesHbaqc.map((d) => `${d.range}.0 - ${d.range}.9`),
    datasets: [
      {
        axis: "y",
        label: "You %",
        data: diabetesHbaqc.map((d) => d.userPercent),
        backgroundColor: theme.colorSchemes.light.palette.primary.main,
        color: "white",
      },
      {
        axis: "y",
        label: "State %",
        data: diabetesHbaqc.map((d) => d.statePercent),
        backgroundColor: theme.colorSchemes.light.palette.primary.dark,
        color: "white",
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom" as const,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltip: {
      filter: (item: any) => {
        if (item.dataset.label.includes("hide")) {
          return false;
        }
        return true;
      },
      enabled: true,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Number of patients ",
          font: {
            size: 12,
          },
        },
        min: 0,
        max: 100,
      },
      y: {
        title: {
          display: true,
          text: "DCCT% result range ",
          font: {
            size: 12,
          },
        },
        min: 0,
        max: 100,
      },
    },
  };

  const downloadChart = () => {
    const canvas = document.getElementById("monthly-graph") as HTMLCanvasElement;
    const dataUrl = canvas.toDataURL("image/png");
    downloadHelper(dataUrl, "hba1c-results-chart.png");
  };

  const printChart = () => {
    window.print();
  };

  if (loading) return <Loading />;

  return (
    <Box>
      <Grid container spacing={10} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={11} lg={12}>
          <Grid container>
            <Grid item xs={12} p={2} display={"flex"} justifyContent={"flex-end"} className={styles.hideForPrinting}>
              <DownloadMenuIcon
                title="Download Chart"
                onClick={downloadChart}
                class={`${styles.spaceRight} ${styles.hideDisplay}`}
              />
              <PrintMenuIcon title="Print" onClick={printChart} />
            </Grid>
            <Grid item xs={12} sm={12} lg={5} key="table-diabetic" m={2} ml={8}>
              <Box boxShadow={3} className={`${styles.infoBox} ${styles.singleRow}`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={"30%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.main}
                          className={styles.infoBoxHeader}
                        >
                          DCCT% result range
                        </Typography>
                      </TableCell>
                      <TableCell width={"23%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.main}
                          className={styles.infoBoxHeader}
                        >
                          Number of patients
                        </Typography>
                      </TableCell>
                      <TableCell width={"23%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.main}
                          className={styles.infoBoxHeader}
                        >
                          % of total
                        </Typography>
                      </TableCell>
                      <TableCell width={"23%"} style={{ borderBottom: "none" }}>
                        <Typography
                          variant="body1"
                          color={theme.colorSchemes.light.palette.primary.dark}
                          className={styles.infoBoxHeader}
                        >
                          State average
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {diabetesHbaqc.map((row, i) => (
                      <TableRow key={`row-${i}`}>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={styles.infoBoxText}
                          >{`${row.range}.0 - ${row.range}.9`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            color={theme.colorSchemes.light.palette.primary.main}
                            className={styles.infoBoxText}
                          >
                            {row.userCount}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            color={theme.colorSchemes.light.palette.primary.main}
                            className={styles.infoBoxText}
                          >
                            {row.userPercent}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            color={theme.colorSchemes.light.palette.primary.dark}
                            className={styles.infoBoxText}
                          >
                            {row.statePercent}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4} style={{ borderBottom: "none" }}>
                        <List className={styles.list}>
                          Measure glycated haemoglobin (HbA1c) on an individual basis:
                          <ListItem className={styles.listItem}>
                            three-monthly in newly diagnosed patients, patients undergoing therapeutic changes or those
                            whose HbA1c is outside their individualised target range.
                          </ListItem>
                          <ListItem className={styles.listItemRef}>
                            less frequently, if appropriate, in stable patients who have reached agreed targets.
                          </ListItem>
                        </List>
                        <Typography className={styles.descriptionRef} style={{ marginLeft: 0, marginRight: 0 }}>
                          Routine investigations are best organised before the review appointment.
                        </Typography>
                        <Typography
                          className={styles.descriptionTiny}
                          style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
                        >
                          The Royal Australian College of General Practitioners. Management of type 2 diabetes: A
                          handbook for general practice. East Melbourne, Vic: RACGP, 2020.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} key="graph-diabetic" m={2}>
              <Bar
                id={"monthly-graph"}
                ref={chartRef}
                width={"100%"}
                height={"500px"}
                options={options}
                data={graphData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDdiabetesTab2;
