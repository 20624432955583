import { gql } from "@apollo/client";

export default gql`
  query GetHospitalUser($hospid: String!, $start: Float!, $pageSize: Float!, $search: String!) {
    hospitalUsers(hospid: $hospid, start: $start, pageSize: $pageSize, search: $search) {
      users {
        username
        name
        email
        enabled
        sharedaccount
        hospid
        lastlogin
        lastip
        hospitalmanager
        state
        password
        mobileNumber
        notificationEmails {
          id
          email
          enabled
        }
        resultNotification
        notificationMobiles {
          id
          mobile
          enabled
        }
        cognito
        cognitoPool
        cognitoSub
      }
    }
  }
`;
