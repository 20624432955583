import { gql } from "@apollo/client";

export default gql`
  query getCytologyReport(
    $pageSize: Float
    $page: Float
    $reportStartDate: String
    $reportEndDate: String
    $providers: [String!]
    $sort: [String!]
  ) {
    getCytologyReport(
      pageSize: $pageSize
      page: $page
      reportStartDate: $reportStartDate
      reportEndDate: $reportEndDate
      providers: $providers
      sort: $sort
    ) {
      rows {
        id
        reportdate
        datecreated
        first_viewed
        last_viewed
        viewcount
        providerno
      }
      rowCount
    }
  }
`;
