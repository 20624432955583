import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import getPrintableCombineCtAtomicAuditLog from "./GetPrintableCombineCtAtomicAuditLogQuery";
import { useParams, useLocation } from "react-router";
import get from "lodash/get";
import queryString from "query-string";
import PrintableCombineCtAtomic from "./PrintableCombineCtAtomic";
import {
  GetPrintableCombineCtAtomicAuditLog_patient as Patient,
  GetPrintableCombineCtAtomicAuditLog,
} from "./types/GetPrintableCombineCtAtomicAuditLog";
import { AuditAction } from "../../constants";

type PathParams = {
  patientId: string;
  labnoDigitOnly: string;
  allPatients: string;
};

const getReportIds = (search: any): number[] =>
  search.ids
    ? search.ids.split(",").map((item: string) => {
        return parseInt(item, 10);
      })
    : null;

const getDownloadMode = (search: any): number => (search.downloadMode ? Number(search.downloadMode) : 0);

const auditLogs = (search: any): boolean =>
  search.auditLogs ? (Number(search.auditLogs) === 1 ? true : false) : false;

const hasValidData = (data: GetPrintableCombineCtAtomicAuditLog | undefined) =>
  data && data.ctcombineAtomic && data.getAuditLogs;

const PrintableCombineCtAtomicPage: React.FC<any> = () => {
  const params: PathParams = useParams() as PathParams;
  const location = useLocation();
  const reportIds = getReportIds(queryString.parse(location.search));
  const downloadMode = getDownloadMode(queryString.parse(location.search));
  const [GetPrintableCombineCtAtomicAuditLogQuery, { loading, data }] =
    useLazyQuery<GetPrintableCombineCtAtomicAuditLog>(getPrintableCombineCtAtomicAuditLog);

  useEffect(() => {
    insertPrintLog();
  }, []);

  const insertPrintLog = async () => {
    GetPrintableCombineCtAtomicAuditLogQuery({
      variables: {
        patientId: parseInt(params.patientId, 10),
        labnoDigitOnly: params.labnoDigitOnly,
        ids: reportIds,
        action: "CLINTRIAL",
        auditLog: auditLogs(queryString.parse(location.search)),
        queryAction: AuditAction.PRINT_COMPRESSED,
      },
    });
    window.parent.postMessage("PRINT_DATA_LOADED", "*");
  };

  if (loading || !hasValidData(data)) return null;

  const patient: Patient = get(data, "patient") || ({} as Patient);
  const rptAtomics = data && data.ctcombineAtomic ? data.ctcombineAtomic : [];
  const auditlogs = data && data.getAuditLogs ? data.getAuditLogs : [];

  return (
    <PrintableCombineCtAtomic
      atomics={rptAtomics}
      patient={patient}
      downloadMode={downloadMode}
      auditLog={auditLogs(queryString.parse(location.search))}
      auditLogs={auditlogs}
    />
  );
};

export default PrintableCombineCtAtomicPage;
