import React, { useContext, useEffect } from "react";
import { AppBar, Box, Tab, TabProps, Tabs, Theme, useMediaQuery, useTheme } from "@mui/material";
import CpdStyledHeader from "./CpdStyledHeader";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./CPDprograms.module.css";
import logostyles from "../MainNavigation/MainNavigation.module.css";
// import authService from "../../services/authService";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CPDdiabetesTab0 from "./CPDdiabetesTab0";
import CPDdiabetesTab1 from "./CPDdiabetesTab1";
import CPDdiabetesTab2 from "./CPDdiabetesTab2";
import CPDdiabetesTab3 from "./CPDdiabetesTab3";
import { omit } from "lodash";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { useMutation } from "@apollo/client";
import imageEresults from "../../assets/eResults Logo - White.png";
import imageEresultsG from "../../assets/eResults Logo - White-green.svg";
import imageAcl from "../../assets/clinicallabs logo.png";
import imageVet from "../../assets/vet-logo.png";
import { isTenantVet } from "../../services/tenantService";
import AppContext from "../../context/AppContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
}));

export type CPDdiabetesProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

interface PathParams {
  tabpage?: string;
}

type LinkTabProps = TabProps<Link> & {
  isactive: boolean;
  label?: string;
  href?: string;
  to?: string;
};

interface DiabeticTab {
  index: number;
  label: string;
}

const CPDdiabetesPage: React.FC<CPDdiabetesProps> = () => {
  function LinkTab(props: LinkTabProps) {
    const linkprops = omit(props, ["isactive"]);
    return (
      <Tab
        component={Link}
        className={`${!isDesktop ? styles.tabHeaderMobile : styles.tabHeader} ${
          props.isactive ? styles.tabHeaderSelected : styles.tabHeaderInactive
        }`}
        {...linkprops}
      />
    );
  }

  const { isDesktop } = useContext(AppContext);
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("sm"));
  const params: PathParams = useParams() as PathParams;
  const [tabPage, setTabPage] = React.useState(0);
  // const mobility = authService.isMobility();
  // const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const action = "CPD_DIABETES_VIEW";
  const diabeticTabs: DiabeticTab[] = [
    {
      index: 0,
      label: "Program Dashboard",
    },
    {
      index: 1,
      label: "Monthly Progress Rolling 12 months",
    },
    {
      index: 2,
      label: "HbA1c Results",
    },
    {
      index: 3,
      label: "Review Patients with Diabetes",
    },
  ];

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  useEffect(() => {
    const tabPage = Number(params.tabpage) || 0;
    setTabPage(tabPage);
    const value = JSON.stringify(diabeticTabs.find((d) => d.index === tabPage));

    auditLog({
      variables: {
        audit: {
          value,
          action,
        },
      },
    });
  }, [params]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      setTabPage(newValue);
    }
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.mainBody} margin={"0 auto"}>
      <Box>
        <AppBar
          elevation={0}
          className={`${styles.headerBar} ${styles.hideForPrinting}`}
          style={{ position: "sticky" }} //Testing functionality (Probably didn't work on IE11.)
        >
          <MainNavigation
            subHeader="Diabetes Clinical Evaluation Program"
            showBackButton
            mainHeader={CpdStyledHeader()}
            backText="HOME"
            backPath="/cpd-programs"
            hideSeparator
            isCpd
            displayACL={isDesktop ? true : false}
          />
        </AppBar>
      </Box>
      {/* <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} /> */}
      <Box visibility="visible" className={styles.tabContainer}>
        <img
          src={isTenantVet() ? imageEresultsG : imageEresults}
          alt="acl logo"
          className={`${styles.displayForPrintingOnly} ${matches ? logostyles.logo_135px : logostyles.logo_100px}`}
        />

        <Tabs
          value={tabPage}
          onChange={handleChange}
          className={styles.tabPanel}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force nav tabs example"
        >
          {diabeticTabs.map((d) => (
            <LinkTab key={d.index} isactive={tabPage === d.index} label={d.label} to={`/cpd-diabetes/${d.index}`} />
          ))}
        </Tabs>
        <img
          src={isTenantVet() ? imageVet : imageAcl}
          alt="acl logo"
          className={`${styles.displayForPrintingOnly} ${
            isTenantVet() ? logostyles.logo_156px : logostyles.logo_185px
          }`}
        />
      </Box>
      <Box className={classes.root} style={{ overflow: "auto" }}>
        {tabPage === 0 && <CPDdiabetesTab0 />}
        {tabPage === 1 && <CPDdiabetesTab1 />}
        {tabPage === 2 && <CPDdiabetesTab2 />}
        {tabPage === 3 && <CPDdiabetesTab3 />}
      </Box>
    </Box>
  );
};
export default CPDdiabetesPage;
