import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box, TextField, Button, Grid, Typography, Checkbox, Link } from "@mui/material";
import { Formik } from "formik";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import MainNavigation from "../MainNavigation/MainNavigation";
import { MutationTuple, useApolloClient, useMutation } from "@apollo/client";
import get from "lodash/get";
import changeEmailMutation from "./ChangeEmailMutation";
import { ApolloError } from "@apollo/client";
import { ChangeEmailFormValues } from "./EmailValidation";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import styles from "./ChangeEmailPage.module.css";
import { GraphQLError } from "graphql";
import { has } from "lodash";
import { ChangeEmail, ChangeEmailVariables } from "./types/ChangeEmail";
import { SkipUserMfa, SkipUserMfaVariables } from "../MFAEnrolment/types/SkipUserMfa";
import SkipUserMfaMutation from "../MFAEnrolment/SkipUserMfa";
import MobileNumberField from "../common/InputField/MobileNumberField";
import Tooltips from "../common/Tooltip/Tooltip";
import * as yup from "yup";

type ChangeEmailBoxType = {
  onSubmit: (
    newEmail: string,
    newMobileNumber: string,
    autoRefreshResult: boolean,
    previewMode: boolean,
    openNewTab: boolean,
    resetMfa: boolean,
    hidePanel: boolean,
    autoSearch: boolean,
  ) => Promise<void>;
  error?: ApolloError;
  loading?: boolean;
  submitSuccess: boolean;
};

export const ChangeEmailBox: React.FC<ChangeEmailBoxType> = (props) => {
  const { error, loading, submitSuccess } = props;
  const history = useHistory();
  const hist = useHistory();
  const initialValues: ChangeEmailFormValues = {
    newEmail: "",
    message: "",
    resultNotification: 1,
    notificationEmail: "",
    mobile: "",
  };

  const isDesktop = useContext(AppContext).isDesktop;

  const isEnforced = authService.getHomepageEnforced();

  const [primaryEmail, setPrimaryEmail] = useState(authService.getPrimaryEmail());
  const [mobileNumber, setMobileNumber] = useState(authService.getMobileNumber());

  const { autoRefreshResult, previewMode } = useContext(AppContext);
  const isMobility = authService.isMobility();
  const [toggleAutoRefreshResult, setToggleAutoRefreshResult] = useState(autoRefreshResult);
  const [togglePreviewMode, setTogglePreviewMode] = useState(previewMode);
  const [toggleOpenNewTabMode, setToggleOpenNewTabMode] = useState(authService.getOpenNewTab());
  const [toggleHidePanel, setToggleHidePanel] = useState(authService.getHidePanel());
  const [toggleAutoSearch, setToggleAutoSearch] = useState(authService.getAutoSearch());
  const [resetMfa, setResetMfa] = useState(false);
  const client = useApolloClient();

  const formValidationSchema = yup.object().shape(
    {
      newEmail: yup.string().email("Enter a valid email"),
      mobile: yup
        .string()
        .test("Mobile Test", "Required format is 04XX XXX XXX", (value: any) =>
          value && value.length ? /^([0]{1})([4]{1})([0-9]{2}) ([0-9]{3}) ([0-9]{3})/g.test(value) : true,
        ),
    },
    [["newEmail", "mobile"]],
  );

  useEffect(() => {
    if (!authService.isAuthenticated) {
      history.push(`/contact-support`);
    }
  }, [history]);

  const [SkipUserMfa] = useMutation<SkipUserMfa, SkipUserMfaVariables>(SkipUserMfaMutation);

  const resetUserMFA = async () => {
    await SkipUserMfa({
      variables: {
        reset: true,
      },
    });
  };
  const submit = () => {
    if (resetMfa == true) {
      resetUserMFA();
    }
    props.onSubmit(
      primaryEmail,
      mobileNumber,
      toggleAutoRefreshResult,
      togglePreviewMode,
      toggleOpenNewTabMode,
      resetMfa,
      toggleHidePanel,
      toggleAutoSearch,
    );
  };

  const handleBack = () => {
    if (authService.getExpired()) {
      authService.clearAll();
      client.resetStore();
      // TODO: shouldn't need to explicitly navigate to '/' if we are behind a <PrivateRoute />
      hist.push("/login");
    } else if (get(hist.location, "state.from")) {
      hist.push(get(hist.location, "state.from", ""));
    } else {
      hist.goBack();
    }
  };

  const handleDeleteAccountClick = () => {
    history.push("/delete-account");
  };

  return (
    <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
      <Grid item xs={12}>
        <AuthLayout title="Account Settings" maxWidth={"690px"}>
          <Box mt={4}>
            <Box textAlign={"right"} mb={1} mt={-8}>
              <Link color="primary" onClick={handleBack} className={styles.backLink}>
                Back
              </Link>
            </Box>

            <Formik
              validationSchema={formValidationSchema}
              enableReinitialize={false}
              initialValues={initialValues}
              onSubmit={submit}
            >
              {({ setFieldValue, errors, handleBlur, handleSubmit, touched }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {!authService.getSharedAccount() && (
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          Change your primary email
                        </Typography>
                      </Box>
                    )}
                    {!authService.getSharedAccount() && (
                      <span>
                        <Box>
                          {error &&
                            error.graphQLErrors.map(({ message }: any, i: number) => (
                              <Typography key={i} color="error">
                                {message}
                              </Typography>
                            ))}
                        </Box>
                        <Box mt={4}>
                          <TextField
                            id="newEmail"
                            name="newEmail"
                            fullWidth
                            label="Primary Email"
                            type="email"
                            error={!!errors.newEmail && touched.newEmail}
                            variant="outlined"
                            onChange={(value: any) => {
                              const pemail = value.target.value.trim();
                              setFieldValue("newEmail", pemail);
                              setPrimaryEmail(pemail);
                            }}
                            onBlur={handleBlur}
                            value={primaryEmail}
                            required={true}
                            helperText={touched.newEmail && errors.newEmail}
                          ></TextField>
                        </Box>

                        <Box style={{ marginTop: "20px" }}>
                          <hr />
                        </Box>
                      </span>
                    )}
                    <span>
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          Change your mobile number
                        </Typography>
                      </Box>

                      <Box mt={4}>
                        <MobileNumberField
                          initState={mobileNumber}
                          helperText={touched.mobile && errors.mobile}
                          inputChange={(value: any) => {
                            setFieldValue("mobile", value);
                            setMobileNumber(value.replace(/\s/g, ""));
                          }}
                          error={!!errors.mobile && touched.mobile}
                        />
                      </Box>
                      {(!isMobility && isDesktop) || !isEnforced ? (
                        <>
                          <Box style={{ marginTop: "20px" }}>
                            <hr />
                          </Box>
                          <Box>
                            <Typography color="primary" className={styles.subHeading}>
                              Home page settings
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                    <span>
                      {!isMobility && isDesktop && (
                        <>
                          <Box>
                            <Checkbox
                              checked={toggleAutoRefreshResult}
                              name="autoRefresh"
                              color="primary"
                              onChange={(_, checked) => {
                                setToggleAutoRefreshResult(checked);
                              }}
                            />
                            Auto Refresh Results every 15 minutes
                          </Box>
                          <Box>
                            <Checkbox
                              checked={togglePreviewMode}
                              name="previewMode"
                              color="primary"
                              onChange={(_, checked) => {
                                setTogglePreviewMode(checked);
                              }}
                            />
                            Enable Preview Mode <Tooltips text="PreviewMode" />
                          </Box>
                          <Box>
                            <Checkbox
                              checked={toggleOpenNewTabMode}
                              name="previewMode"
                              color="primary"
                              onChange={(_, checked) => {
                                setToggleOpenNewTabMode(checked);
                              }}
                            />
                            Automatically Open Results in New Tab (Requires Logout) <Tooltips text="NewTab" />
                          </Box>
                        </>
                      )}
                      {!isEnforced && (
                        <>
                          <Box>
                            <Checkbox
                              checked={toggleHidePanel}
                              name="hidePanel"
                              color="primary"
                              onChange={(_, checked) => {
                                setToggleHidePanel(checked);
                              }}
                            />
                            Hide Panel Name in Results
                          </Box>
                          <Box>
                            <Checkbox
                              checked={toggleAutoSearch}
                              name="autoSearch"
                              color="primary"
                              onChange={(_, checked) => {
                                setToggleAutoSearch(checked);
                              }}
                            />
                            Display Patients on Home Screen
                          </Box>
                        </>
                      )}
                    </span>
                    {authService.getForceAuth() == false && authService.getOnlySkipMFAenrolled() == true && (
                      <>
                        <Box>
                          <Typography color="primary" className={styles.subHeading}>
                            Multi Factor Authentication <Tooltips text="EnrollMFA" />
                          </Typography>
                        </Box>
                        <Box>
                          <Checkbox
                            name="resetMfa"
                            color="primary"
                            onChange={(_, checked) => {
                              setResetMfa(checked);
                            }}
                          />
                          Enrol in MFA at next logon (Requires Logout to Re-enrol)
                        </Box>
                      </>
                    )}

                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      {!authService.getDelegatedUser() && (
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={loading}
                          onClick={handleDeleteAccountClick}
                          className={styles.deleteAccountButton}
                        >
                          Delete Account
                        </Button>
                      )}
                      {submitSuccess && <Box className={styles.successMessage}>Successfully saved your changes.</Box>}
                      <Button type="submit" color="primary" variant="contained" disabled={loading}>
                        Save
                      </Button>
                    </Box>
                    {/* </Grid>
                    </Grid> */}
                  </form>
                );
              }}
            </Formik>
          </Box>
        </AuthLayout>
      </Grid>
    </Grid>
  );
};

const ChangeEmailPage: React.FC<any> = (props) => {
  const { location, history } = props;
  const [success, setSuccess] = useState(false);
  const [changeEmail, { loading, error }] = useMutation<ChangeEmail, ChangeEmailVariables>(changeEmailMutation);
  const { setAutoRefreshResult, setPreviewMode } = useContext(AppContext);

  if (error) {
    console.error("Error", error);
  }

  const onSubmit =
    (
      location: RouteComponentProps["location"] | undefined,
      history: RouteComponentProps["history"] | undefined,
      changeEmail: MutationTuple<ChangeEmail, ChangeEmailVariables>[0],
    ) =>
    async (
      newEmail: string,
      newMobileNumber: string,
      autoRefreshResult: boolean,
      previewMode: boolean,
      openNewTab: boolean,
      resetMfa: boolean,
      hidePanel: boolean,
      autoSearch: boolean,
    ) => {
      try {
        const currentEmail = localStorage.getItem("primary-email");
        const currentMobileNumber = localStorage.getItem("mobile-number");
        const currentHidePanel = authService.getHidePanel();
        const currentAutoSearch = authService.getAutoSearch();
        setSuccess(false);

        if (
          currentEmail !== newEmail ||
          currentMobileNumber !== newMobileNumber ||
          currentHidePanel !== hidePanel ||
          currentAutoSearch !== autoSearch
        ) {
          const result = await changeEmail({
            variables: {
              input: {
                newEmail,
                resetMfa,
                newMobileNumber,
                hidePanel,
                autoSearch,
              },
            },
          });

          authService.setMobileNumber(get(result, "data.changeEmail.mobileNumber", ""));

          if (get(result, "data.changeEmail.email")) {
            authService.setPrimaryEmail(get(result, "data.changeEmail.email", ""));
            // eslint-disable-next-line no-console
            setSuccess(true);
          }

          if (has(result, "data.changeEmail.notificationEmails")) {
            authService.setNotificationEmail(get(result, "data.changeEmail.notificationEmails", "") as string);
            // eslint-disable-next-line no-console
            setSuccess(true);
          }

          if (get(result, "data.changeEmail.resultNotification")) {
            authService.setResultNotification(get(result, "data.changeEmail.resultNotification", "") as string);
          }
        }

        localStorage.setItem("AutoRefreshResult", autoRefreshResult.toString());
        localStorage.setItem("PreviewMode", previewMode.toString());
        localStorage.setItem("OpenNewTab", openNewTab.toString());
        localStorage.setItem("hidePanel", hidePanel.toString());
        localStorage.setItem("autoSearch", autoSearch.toString());
        setAutoRefreshResult(autoRefreshResult);
        setPreviewMode(previewMode);
        setSuccess(true);
        // setOpenNewTab(openNewTab);

        // On save settings, post message (notify) PatientListPage.tsx
        // Ref: http://jira.pl.pathlabs.com.au/browse/ER7-63
        window.opener.postMessage("reloadPage", "*");

        // if (history !== undefined) {
        //   const from = get(location, "state.from") || "/patients";
        //   history.push(from);
        // }
      } catch (e) {
        if (
          e.graphQLErrors &&
          (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot Change Email - Shared Account") !==
            undefined
        ) {
          authService.setSharedAccount(true);
          // window.location.reload();

          // allow saving of account settings for shared account
          localStorage.setItem("AutoRefreshResult", autoRefreshResult.toString());
          localStorage.setItem("PreviewMode", previewMode.toString());
          localStorage.setItem("OpenNewTab", openNewTab.toString());
          setAutoRefreshResult(autoRefreshResult);
          setPreviewMode(previewMode);
          // setOpenNewTab(openNewTab)
          setSuccess(true);

          // On save settings, post message (notify) PatientListPage.tsx
          // Ref: http://jira.pl.pathlabs.com.au/browse/ER7-63
          if (window.opener && !window.opener.closed) {
            window.opener.postMessage("reloadPage", "*");
          }
        }

        if (
          e.graphQLErrors &&
          (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Email already exists") !== undefined
        ) {
          setSuccess(false);
        }

        setTimeout(function () {
          setSuccess(false);
        }, 3000);
      }
    };
  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <ChangeEmailBox
        onSubmit={onSubmit(location, history, changeEmail)}
        error={error}
        loading={loading}
        submitSuccess={success}
      />
    </Fragment>
  );
};

export default ChangeEmailPage;
