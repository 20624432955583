import { gql } from "@apollo/client";
export default gql`
  query GetTokenByCode($input: AuthCodeInput!) {
    getTokenByCode(input: $input) {
      access_token
      id_token
      refresh_token
      token_type
      loginResult {
        actionWorkflowEnabled
        allowAllPatients
        clinicalTrial
        dcfWorkflowEnabled
        defaultWardCode
        email
        expired
        hospid
        hospitalAdmin
        lastlogin
        id
        mobileNumber
        name
        notificationEmail
        providers
        repeatAtomicComment
        restrictedClinicalStatuses
        resultNotification
        sharedaccount
        showDeletedResults
        smscEnabled
        state
        userId
        username
        hidePanel
        autoSearch
        wards {
          code
          hospid
          id
          name
        }
      }
    }
  }
`;
