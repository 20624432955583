import { gql } from "@apollo/client";

export default gql`
  query TrustBrowser {
    trustBrowser {
      message
      data {
        token
        period
      }
    }
  }
`;
