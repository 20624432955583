import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, AppBar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MainNavigation from "../MainNavigation/MainNavigation";
import SearchReportBar from "../SearchReportBar/SearchReportBar";
import EpisodeListTable from "./EpisodeListTable";
import { setIsOpen } from "../PatientListPage/PatientListPage";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { animateScroll } from "react-scroll";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import PatientEpisodesListHeader from "./PatientEpisodesListHeader";
import { PatientContext } from "../../context/PatientsContext";
import PrintFrame from "../ReportViewer/PrintFrame";
import AdvancedSearch from "../../model/advanced-search";
import { useQuery } from "@apollo/client";
import {
  SearchEpisodes,
  SearchEpisodesVariables,
  SearchEpisodes_episodesSearchResult_episodes,
} from "./types/SearchEpisodes";
import SearchEpisodesQuery from "./SearchEpisodesQuery";
import { PullDownContent, PullToRefresh, RefreshContent, ReleaseContent } from "react-js-pull-to-refresh";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Loading from "../Loading/Loading";
import NoResultsMessage from "./NoResultsMessage";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import CtReports from "../../utils/getDataForCtReport";
import styles from "./EpisodeListTable.module.css";

export type CheckedEpisode = SearchEpisodes_episodesSearchResult_episodes & { checked: boolean };

const EpisodeListPage: React.FC<any> = () => {
  const location = useLocation();
  const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));
  const { isDesktop } = useContext(AppContext);
  const { providers, setProviders, setSearchVariables, searchNow } = useContext(PatientContext);
  let isAdvanceSarchPage = false;
  let allP = false;
  const [episodes, setEpisodes] = useState(new Array<CheckedEpisode>());
  const [scrollCount, setScrollCount] = useState(0);
  const [allPatients, setAllPatient] = useState(allP);
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showUpArrow, setShowUpArrow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const connectionRef = useRef<AbortController[]>([]);
  const isNewSearchObj = useRef<any>("");
  const { autoRefreshResult, refreshResult, setRefreshResult } = useContext(AppContext);

  const useStyles = makeStyles(() => ({
    headerBar: {
      position: "fixed",
      background: "white !important",
    },
  }));
  const classes = useStyles();
  const mobility = authService.isMobility();

  if (typeof advancedSearch.fullname !== "undefined") {
    // isSearchPage = true;
    allP = advancedSearch.allpatients ? true : false;
  }
  Object.keys(advancedSearch).forEach((key) => {
    if (typeof (advancedSearch as any)[key] !== "undefined" && key !== "allpatients" && key !== "fullname") {
      isAdvanceSarchPage = true;
      setIsOpen(true);
    }
  });
  if (isAdvanceSarchPage) {
    allP = advancedSearch.allpatients ? true : false;
  }

  const enableSearch = () => {
    if (authService.getAutoSearch()) {
      return true;
    } else {
      if (searchNow) {
        return true;
      }
    }
    return false;
  };

  const [isOpen, setOpen] = useState(isAdvanceSarchPage);
  //initialise search parameters
  useEffect(() => {
    if (authService.getProviderSelected() !== "0") {
      const tempProviders = [];
      tempProviders.push(authService.getProviderSelected());
      setProviders(tempProviders);
    } else {
      setProviders(authService.getProviders());
    }
    if (
      advancedSearch.allpatients &&
      authService.getAllowAllPatients() &&
      authService.getAllowAllPatientsAcknownledged()
    ) {
      setAllPatient(true);
    }
  }, []);

  if (authService.getDefaultWardCode() !== "" && !advancedSearch.ward) {
    advancedSearch.ward = authService.getDefaultWardCode();
  }

  if (advancedSearch.allpatients) {
    advancedSearch.ward = undefined;
  }

  const searchVariables: SearchEpisodesVariables = {
    providers: providers,
    limit: 50,
    lastReportId: 0,
    adSearch: advancedSearch,
  };

  const { data, loading, fetchMore, refetch } = useQuery<SearchEpisodes>(SearchEpisodesQuery, {
    variables: searchVariables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !providers || providers.length === 0 || !enableSearch(),
    notifyOnNetworkStatusChange: false,
    context: {
      fetchOptions: {
        get signal() {
          if (JSON.stringify(isNewSearchObj.current) != JSON.stringify(searchVariables)) {
            isNewSearchObj.current = searchVariables;
            connectionRef.current.push(new AbortController());
            if (connectionRef.current.length > 0) {
              for (let i = 0; i < connectionRef.current.length - 1; i++) {
                connectionRef.current[i].abort();
                connectionRef.current.splice(i, 1);
              }
            }
            return connectionRef.current[connectionRef.current.length - 1].signal;
          }
        },
      },
    },
  });
  useEffect(() => {
    setSearchVariables(searchVariables);
  }, [data]);

  const loadMore = (): any => {
    fetchMore({
      variables: {
        lastReportId: episodes.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult.episodesSearchResult.episodes.length == 0) {
          setShowLoadMore(false);
        } else {
          setShowLoadMore(true);
        }
        setIsLoading(false);

        const merged = Object.assign({}, prev, {
          episodesSearchResult: {
            __typename: "EpisodesResult",
            episodes: [...prev.episodesSearchResult.episodes, ...fetchMoreResult.episodesSearchResult.episodes],
            lastReport: fetchMoreResult.episodesSearchResult.lastReport,
          },
        });
        if (fetchMoreResult.episodesSearchResult.episodes) {
          // console.log(
          //   episodes.concat(
          //     fetchMoreResult.episodesSearchResult.episodes.map((e) => {
          //       return { ...e, checked: false };
          //     }),
          //   ),
          // );
          setEpisodes(
            episodes.concat(
              fetchMoreResult.episodesSearchResult.episodes.map((e) => {
                return { ...e, checked: false };
              }),
            ),
          );
        }
        return merged;
      },
    });
  };

  // set episodes when data changes
  useEffect(() => {
    setEpisodes(
      data?.episodesSearchResult?.episodes
        ? data.episodesSearchResult.episodes.map((e) => {
            return { ...e, checked: false };
          })
        : [],
    );
  }, [refetch, data]);

  // sets loading state and auto refresh of episodes
  useEffect(() => {
    if (refreshResult) {
      setIsLoading(true);

      (async () => {
        const result = await refetch();

        if (result.data.episodesSearchResult.episodes.length > 0) {
          setRefreshResult(false);
        }
      })();
    } else {
      setIsLoading(loading);
    }

    // refetch latest data every 15 minutes
    if (autoRefreshResult && !authService.isMobility()) {
      const interval = setInterval(() => {
        setIsLoading(true);

        (async () => {
          const result = await refetch();

          if (result.data.episodesSearchResult.episodes.length > 0) {
            setRefreshResult(false);
            setIsLoading(false);
          }
        })();
      }, 900000);

      return () => clearInterval(interval);
    }
  }, [refetch, loading, refreshResult, autoRefreshResult]);

  useScrollPosition(
    ({ prevPos, currPos }: { prevPos: any; currPos: any }) => {
      const isShow = currPos.y > prevPos.y - 20;

      if (isShow !== hideOnScroll) {
        setHideOnScroll(true);
        setScrollCount(currPos.y + 20);
      }
      if (!authService.getAdOpen()) {
        if (currPos.y < 50) {
          setShowUpArrow(true);
        }
        if (currPos.y === 0) {
          setShowUpArrow(false);
        }
      }
      if (authService.getAdOpen()) {
        const adSearchHeight = isDesktop ? 350 : 525;
        if (currPos.y < -adSearchHeight) {
          setShowUpArrow(true);
        }
        if (currPos.y > -adSearchHeight) {
          setShowUpArrow(false);
        }
      }
    },
    [hideOnScroll],
  );

  const handleClickOpen = () => {
    setOpen(true);
    setIsOpen(true);
  };

  function scrollTop() {
    animateScroll.scrollToTop();
  }

  return (
    <>
      <AppBar elevation={0} className={classes.headerBar}>
        <MainNavigation scrollCount={scrollCount} showEorderButton />

        {/* {isDesktop && <EpisodeHeader></EpisodeHeader>} */}
      </AppBar>
      <Box
        width={isDesktop ? "auto" : "fit-content"}
        marginRight={isDesktop ? 1 : 0}
        marginLeft={isDesktop ? 3 : 0}
        pt={2}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={isOpen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isOpen ? "flex-end" : "center"}
          flexWrap={isOpen ? "wrap" : "nowrap"}
          marginTop={"144px"}
          mx={0}
        >
          {!isDesktop ? (
            <SearchReportBar
              allPatients={allPatients}
              setAllPatient={setAllPatient}
              setShowLoadMore={setShowLoadMore}
              hideOnScroll={hideOnScroll}
              open={isOpen}
              setOpen={setOpen}
              advancedClick={handleClickOpen}
              withAdvButton={true}
              isError={isError}
              setIsError={setIsError}
            />
          ) : (
            <Box mx={isDesktop ? 0 : 1}>
              <SearchReportBar
                allPatients={allPatients}
                setAllPatient={setAllPatient}
                setShowLoadMore={setShowLoadMore}
                hideOnScroll={hideOnScroll}
                open={isOpen}
                setOpen={setOpen}
                advancedClick={handleClickOpen}
                withAdvButton={true}
                isError={isError}
                setIsError={setIsError}
              />
            </Box>
          )}

          <Box
            mb={0}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={mobility || isOpen ? "flex-end" : "space-between"}
            minWidth={!isDesktop ? "75px" : "80px"}
            className={isDesktop ? styles.iconsBox : styles.iconsBoxMobile}
            style={{ marginTop: authService.getAdOpen() && !isDesktop ? "65px" : "0px" }}
          >
            <CtReports episodes={episodes} setEpisodes={setEpisodes} isLoading={isLoading} mode={"download"} />
            <CtReports episodes={episodes} setEpisodes={setEpisodes} isLoading={isLoading} mode={"print"} />
          </Box>
        </Box>
        {isDesktop && <PatientEpisodesListHeader />}

        {showUpArrow ? (
          <div className={styles.popup} onClick={scrollTop}>
            <ArrowUpwardIcon />
          </div>
        ) : (
          ""
        )}
        {isLoading && <Loading />}

        {episodes.length === 0 && !isLoading && <NoResultsMessage isOpen={isOpen} />}

        {authService.isMobility() ? (
          <PullToRefresh
            pullDownContent={<PullDownContent label="Pull down to refresh patient list..." />}
            releaseContent={<ReleaseContent label="Release to refresh patient list..." />}
            refreshContent={<RefreshContent />}
            pullDownThreshold={200}
            onRefresh={() => {
              refetch();
              return Promise.resolve();
            }}
            triggerHeight={"auto"}
            backgroundColor="white"
            startInvisible={true}
          >
            <div style={{ overflow: "scroll" }}>
              <div style={{ textAlign: "center" }}>
                <ArrowDropDownIcon />
              </div>
              <EpisodeListTable
                loadMore={loadMore}
                episodes={episodes}
                showLoadMore={showLoadMore}
                isOpen={isOpen}
                setEpisodes={setEpisodes}
              />
            </div>
          </PullToRefresh>
        ) : (
          <EpisodeListTable
            loadMore={loadMore}
            episodes={episodes}
            showLoadMore={showLoadMore}
            isOpen={isOpen}
            setEpisodes={setEpisodes}
          />
        )}
      </Box>
      {isError && <ErrorPopover />}
      <PrintFrame />
    </>
  );
};

export default EpisodeListPage;
