import React, { useContext, useState } from "react";
import {
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import AppContext from "../../context/AppContext";
import authService from "../../services/authService";
import { formatDate } from "../../utils/date";
import styles from "./NewTestRequest.module.css";
import { GetPatientDrilldown } from "./types/GetPatientDrilldown";
import { useLazyQuery } from "@apollo/client";
import GetPatientDrilldownQuery from "./GetPatientDrilldownQuery";
import Loading from "../Loading/Loading";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as yup from "yup";
import { validateMedicare } from "../../utils/medicare";
import thm from "../theme";
import clsx from "clsx";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { isTenantVet } from "../../services/tenantService";
import dayjs, { Dayjs } from "dayjs";

export type newTestRequestValues = {
  medicare: string;
  patientName: string;
};

interface DialogNewTestRequestProps {
  open?: boolean;
  fullscreen?: boolean;
  sendState?: string;
  onClose: () => void;
  onContinue: () => void;
  onSelect: (patient: GetPatient_patient) => void;
}

const DialogNewTestRequest: React.FC<DialogNewTestRequestProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();
  const newdobformat: string[] | "" = "";
  const [search, setSearch] = useState(false);
  const [dob, setDob] = useState<string | number | Dayjs | Date | null | undefined>(
    newdobformat !== "" ? new Date(newdobformat[0] + "-" + newdobformat[2] + "-" + newdobformat[1]) : null,
  );

  const handleClose = () => {
    props.onClose();
  };

  const handleContinue = () => {
    props.onContinue();
  };

  const handleSelect = (patient: GetPatient_patient) => {
    props.onSelect(patient);
  };

  const initialValues: newTestRequestValues = {
    patientName: "",
    medicare: "",
  };

  const [getPatientDrilldown, { data, loading: patientLoading, error: patientError }] =
    useLazyQuery<GetPatientDrilldown>(GetPatientDrilldownQuery, {
      variables: { patientName: "", medicare: "", dob: "" },
    });

  const handleDobChange = (date: Date | null) => {
    setDob(date);
  };

  const submit = async (values: newTestRequestValues) => {
    setSearch(true);
    setOpen(false);
    document.getElementById("patientList")?.focus();
    await getPatientDrilldown({
      variables: {
        patientName: values.patientName,
        medicare: values.medicare,
        dob: dob ? dayjs(new Date(dob as string | number).getTime()).format("DD/MM/YYYY") : "",
      },
    });
  };

  const validateValues = (values: newTestRequestValues) => {
    if (values.medicare) {
      if (!values.medicare.match(/(\d{10})\/(\d{1})/) && !values.medicare.match(/(\d{10})/)) {
        return { medicare: "Please enter full Medicare card with or without IRN" };
      }
    }
    if (!values.medicare && !values.patientName) {
      return { patientName: "Please enter Patient Name or Medicare card", medicare: " " };
    }
    return {};
  };

  const patients = data && data.patientDrilldown ? data.patientDrilldown : [];

  const formValidationSchema = yup.object().shape(
    {
      patientName: yup.string().when("patientName", (val: any) => {
        if (val?.length > 0) {
          return yup.string();
        } else {
          return yup.string().notRequired();
        }
      }),
      medicare: yup
        .string()
        .test("Medicare Test", "Invalid Medicare Number", (value: any) =>
          value && value?.length > 0 ? validateMedicare(value as string) : true,
        ),
    },
    [
      ["patientName", "patientName"],
      ["medicare", "medicare"],
    ],
  );

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={true}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"md"}
      fullWidth={true}
      className={!isDesktop || isMobility ? styles.sizeMobility : styles.sizeDesktop}
      classes={{
        // paper: clsx(
        //   ((!isDesktop || isMobility) && styles.customMobileDialog),
        //   isDesktop && styles.customDesktopDialog,
        // ),
        paper: clsx({
          [styles.customMobileDialog]: !isDesktop || isMobility,
          [styles.customDesktopDialog]: isDesktop && !isMobility,
        }),
      }}
    >
      <DialogTitle id="scroll-dialog-title" style={{ margin: "4px" }}>
        <Formik
          validationSchema={formValidationSchema}
          enableReinitialize={false}
          initialValues={initialValues}
          onSubmit={submit}
          validate={validateValues}
        >
          {({ setFieldValue, values, handleChange, handleBlur, touched, errors, handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              <DialogBoxTitle
                title="New eOrder"
                supplementaryTitle=" - Search for Patient"
                marginTop={"0px"}
                closeButton
                handleClose={handleClose}
              />
              <Box
                sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}
                display="flex"
                flexDirection="row"
              >
                <Typography className={styles.menuTitle}></Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={!isDesktop || isMobility ? "column" : "row"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={
                    isDesktop
                      ? { width: "33%", display: "flex", flexDirection: "row", alignItems: "center" }
                      : { display: "flex", flexDirection: "row", alignItems: "center" }
                  }
                  pr={1}
                >
                  <TextField
                    margin="dense"
                    id="patientName"
                    name="patientName"
                    label="Patient Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.patientName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.patientName && Boolean(errors.patientName)}
                    helperText={touched.patientName && errors.patientName}
                    className={styles.DateInput}
                    InputProps={{
                      className: styles.dateField,
                    }}
                    size="small"
                  />{" "}
                  <Box sx={{ marginLeft: 1, display: { md: "none", lg: "none", sm: "block" } }} onClick={handleClick}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Box>
                </Box>
                {!isDesktop || isMobility ? (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <>
                      <Box sx={isDesktop ? { width: "33%" } : { width: "95%" }} pr={1}>
                        <TextField
                          margin="dense"
                          id="medicare"
                          name="medicare"
                          label="Medicare"
                          type="text"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            maxLength: 12,
                          }}
                          value={values.medicare}
                          onChange={(val) => {
                            const onlyNums = val.target.value.replace(/[^0-9]/g, "");
                            if (onlyNums.length === 11) {
                              const maskedNums = onlyNums.replace(/(\d{10})(\d{1})/, "$1/$2");
                              setFieldValue("medicare", maskedNums);
                            } else {
                              setFieldValue("medicare", onlyNums);
                            }
                          }}
                          onBlur={handleBlur}
                          error={touched.medicare && Boolean(errors.medicare)}
                          helperText={touched.medicare && errors.medicare}
                          className={styles.DateInput}
                          InputProps={{
                            className: styles.dateField,
                          }}
                          size="small"
                        />
                      </Box>
                      <Box sx={isDesktop ? { width: "33%" } : { width: "95%" }} pr={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="DD/MM/YYYY"
                            label="Date of Birth"
                            value={dob as Dayjs | null | undefined}
                            onChange={(newValue: Dayjs | null) => {
                              handleDobChange(newValue as Date | null);
                            }}
                            slotProps={{
                              textField: {
                                placeholder: "DD/MM/YYYY",
                                size: "small",
                                className: clsx(styles.DateInput, !isDesktop || (isMobility && styles.fullFillElement)),
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  </Collapse>
                ) : (
                  <>
                    <Box sx={isDesktop ? { width: "33%" } : {}} pr={1}>
                      <TextField
                        margin="dense"
                        id="medicare"
                        name="medicare"
                        label="Medicare"
                        type="text"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          maxLength: 12,
                        }}
                        value={values.medicare}
                        onChange={(val) => {
                          const onlyNums = val.target.value.replace(/[^0-9]/g, "");
                          if (onlyNums.length === 11) {
                            const maskedNums = onlyNums.replace(/(\d{10})(\d{1})/, "$1/$2");
                            setFieldValue("medicare", maskedNums);
                          } else {
                            setFieldValue("medicare", onlyNums);
                          }
                        }}
                        onBlur={handleBlur}
                        error={touched.medicare && Boolean(errors.medicare)}
                        helperText={touched.medicare && errors.medicare}
                        className={styles.DateInput}
                        InputProps={{
                          className: styles.dateField,
                        }}
                        size="small"
                      />
                    </Box>
                    <Box sx={isDesktop ? { width: "33%" } : {}} pr={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          label="Date of Birth"
                          value={dob as Dayjs | null | undefined}
                          onChange={(newValue: Dayjs | null) => {
                            handleDobChange(newValue as Date | null);
                          }}
                          slotProps={{
                            textField: { placeholder: "DD/MM/YYYY", size: "small", className: styles.DateInput },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}

                <Box sx={{ height: "33.3333333333%", margin: "7px 0 0 0" }}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={patientLoading}
                    className={`${styles.searchButton}`}
                  >
                    {patientLoading ? (
                      <Loading
                        boxStyle={{ margin: "0px 15px -6px 15px", paddingTop: "6px" }}
                        iconStyle={{ color: "grey", height: "20px", width: "20px" }}
                      />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
        {search && patients.length === 10 && (
          <Box>
            <Typography className={styles.disclaimer3}>
              The results were limited to the 10 most recently created patients. Please refine your search if the
              required patient is not found.
            </Typography>
          </Box>
        )}
        <Box className={styles.tableHeader}>
          <Box display="flex" flexDirection="row">
            <Grid container alignItems="flex-start">
              <Grid item xs={3} sm={3} md={5} lg={2}>
                <Typography variant="body2">
                  {" "}
                  {!isDesktop || isMobility ? "Name" : isTenantVet() ? "Owner, Animal/Herd" : "Surname, Given name(s)"}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={2} lg={1}>
                <Typography variant="body2" align="left">
                  Birth date
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={2}>
                <Typography variant="body2" align="center">
                  Gender
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2}>
                <Typography variant="body2">Medicare</Typography>
              </Grid>
              <Grid sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }} item xs sm md lg={4}>
                <Typography variant="body2">Contact</Typography>
              </Grid>
              <Grid sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }} item xs sm md lg={1}>
                <Typography variant="body2" align="center" style={{ marginLeft: "-2px" }}>
                  Action
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent tabIndex={0} id="patientList">
        <Box mt={1}>
          {patientLoading ? (
            <Loading
              boxStyle={{ margin: "15px 0 0 0" }}
              iconStyle={{ color: thm.colorSchemes.light.palette.primary.main, height: "40px", width: "40px" }}
            />
          ) : (
            <>
              {patients.length > 0 &&
                patients.map((pat: any, index: number) => (
                  <>
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      className={styles.tableBox}
                      onClick={() => {
                        // only allow selection on mobile
                        if (isDesktop) {
                          return;
                        }
                        const patient: GetPatient_patient = {
                          __typename: "Patient",
                          firstname: pat.firstname,
                          middlename: pat.middlename,
                          surname: pat.surname,
                          dob: pat.dob,
                          sex: pat.sex,
                          street: pat.street,
                          city: pat.city,
                          postcode: pat.postcode,
                          mobile: pat.mobile,
                          medicare: pat.medicare,
                          dvacode: pat.dvacode,
                          state: pat.state,
                          fullname: pat.fullname,
                          fullnameTitleCase: pat.fullnameTitleCase,
                          fullAddress: pat.fullAddress,
                          title: pat.title,
                          species: pat.species || "",
                        };
                        handleSelect(patient);
                      }}
                    >
                      <Grid container alignItems="flex-start" className={styles.grid}>
                        <Grid item xs={3} sm={3} md={5} lg={2}>
                          <Typography variant="body2" style={{ textTransform: "capitalize" }}>
                            <b>{pat.fullname.toLowerCase()}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={2} lg={1}>
                          <Typography variant="body2">{formatDate(pat.dob, "DD MMM YYYY", true)}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={1} lg={2}>
                          <Typography variant="body2" align="center">
                            {pat.sex === "F"
                              ? !isDesktop || isMobility
                                ? "F"
                                : "Female"
                              : !isDesktop || isMobility
                                ? "M"
                                : "Male"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                          <Typography variant="body2">{pat.medicare}</Typography>
                        </Grid>
                        <Grid
                          sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
                          item
                          xs
                          sm
                          md
                          lg={4}
                          style={{ maxWidth: "32.333333%" }}
                        >
                          <Typography variant="body2" style={{ textTransform: "capitalize", wordWrap: "break-word" }}>
                            {pat.street.toLowerCase()}, {pat.city.toLowerCase()}, {pat.postcode}{" "}
                            {pat.state.toUpperCase()} <br />
                            {pat.mobile}
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
                          item
                          xs
                          sm
                          md
                          lg={1}
                          alignItems="flex-end"
                          style={{ paddingLeft: "4px" }}
                        >
                          <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            color="primary"
                            className={styles.selectButton}
                            onClick={() => {
                              const patient: GetPatient_patient = {
                                __typename: "Patient",
                                firstname: pat.firstname,
                                middlename: pat.middlename,
                                surname: pat.surname,
                                dob: pat.dob,
                                sex: pat.sex,
                                street: pat.street,
                                city: pat.city,
                                postcode: pat.postcode,
                                mobile: pat.mobile,
                                medicare: pat.medicare,
                                dvacode: pat.dvacode,
                                state: pat.state,
                                fullname: pat.fullname,
                                fullnameTitleCase: pat.fullnameTitleCase,
                                fullAddress: pat.fullAddress,
                                title: pat.title,
                                species: pat.species || "",
                              };
                              handleSelect(patient);
                            }}
                          >
                            <span style={{ margin: "2px 9px 2px 9px" }}>{"Select"}</span>
                          </Button>
                        </Grid>
                        <Box sx={{ display: { sm: "block", md: "block", lg: "none" } }} mt={1}>
                          <Typography variant="body2" style={{ textTransform: "capitalize", wordWrap: "break-word" }}>
                            {pat.street.toLowerCase()}, {pat.city.toLowerCase()}, {pat.postcode}{" "}
                            {pat.state.toUpperCase()}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  </>
                ))}

              <Box>
                <Typography className={styles.disclaimer}>
                  {search &&
                    (patients.length === 0 || patientError) &&
                    "Sorry, we didn't find that patient in our database."}
                  {!search && "Please search for a patient."}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <>
          <Typography className={styles.disclaimer2}>
            {search && patients.length > 0
              ? "If patient details are not correct, please press 'Continue' to manually enter your patient."
              : "Please press 'Continue' to manually enter your patient."}
          </Typography>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={patientLoading}
            className={`${styles.searchButton} ${styles.continueButton}`}
            onClick={handleContinue}
          >
            {patientLoading ? (
              <Loading
                boxStyle={{ margin: "0px 15px -6px 15px", paddingTop: "6px" }}
                iconStyle={{ color: "grey", height: "20px", width: "20px" }}
              />
            ) : (
              "Continue"
            )}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNewTestRequest;
