import React from "react";
import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";

type CommentData = {
  title: string;
  value: string;
};

interface CommentsProps {
  commentsData: CommentData[];
}

const StyledTable = styled((props: any) => <Table {...props} />)({
  paddingTop: 0,
});

// const StyledTableHeaderCell = styled((props) => (
//   <TableCell style={props.style} {...props} />
// ))({
//   height: 50,
//   minWidth: "125px",
//   justifyContent: "center",
//   padding: "0px",
//   textAlign: "center",
// });

const StyledTableCell = styled((props: any) => <TableCell style={props.style} {...props} />)({
  height: 50,
  minWidth: "125px",
  padding: "0px",
});

const CommentsDialog: React.FC<CommentsProps> = (props) => {
  return (
    <StyledTable
      //dense={false}
      // className={classes.olderValueColumn}
      stickyHeader
    >
      {/* <TableHead>
        <TableRow>
          <StyledTableHeaderCell>Title</StyledTableHeaderCell>
          <StyledTableHeaderCell>Value</StyledTableHeaderCell>
        </TableRow>  
      </TableHead> */}
      <TableBody>
        {props.commentsData &&
          props.commentsData.map((comment, i, row) => {
            return (
              <TableRow key={i}>
                {/* <StyledTableCell>{comment.title.split("|")[1]}</StyledTableCell> */}
                <StyledTableCell style={{ borderBottom: i + 1 === row.length ? 0 : "1px solid rgba(224,224,224,1)" }}>
                  <span
                    // className={styles.pdfprint}
                    dangerouslySetInnerHTML={{
                      __html: comment.value || "-",
                    }}
                  ></span>
                </StyledTableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </StyledTable>
  );
};

export default CommentsDialog;
