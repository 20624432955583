import { gql } from "@apollo/client";

export default gql`
  query GetPrintableMultiCombineCtAtomicAuditLog(
    $patientLabs: [PatientLab!]!
    $action: String!
    $auditLog: Boolean!
    $queryAction: String!
  ) {
    ctcombineAtomicPatientAuditLogs(
      patientLabs: $patientLabs
      action: $action
      auditLog: $auditLog
      queryAction: $queryAction
    ) {
      patient {
        fullnameTitleCase
        dob
        sex
        mobile
        title
        firstname
        surname
        fullAddress
        street
        city
        state
        postcode
        fullname
        homePhone
      }
      reports {
        reportId
        reportDateCollected
        reportDateCreated
        reportTestName
        reportLabNumberDigitOnly
        reportUrno
        labid
        lab {
          address
          suburb
          phone
        }
        csPanelApproverUserId
        csPanelApproverUsername
        csPanelApprovalDatetime
        atomicData {
          name
          value
          measurement
          range
          loinc
          abnormal
          csApproval
          csApproverUserId
          csApproverUsername
          csApproverDatetime
          csStatus
          atomicId
          report
          csComment
          csCommentDate
          csCommentUsername
          csComments {
            id
            date
            username
            comment
            dCF
            actionRequired
            noActionRequired
          }
        }
      }
      auditlogs {
        auditlog
        auditlogs {
          id
          username
          date
          action
          value
          type
          searchKey1
          searchKey2
        }
      }
    }
  }
`;
