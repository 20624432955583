import React, { useEffect, useState } from "react";
import get from "lodash/get";
import {
  GetPrintableCombineAtomic_patient as Patient,
  GetPrintableCombineAtomic_combineAtomic_atomicData,
  GetPrintableCombineAtomic_combineAtomic,
} from "./types/GetPrintableCombineAtomic";
import styles from "./PrintableReports.module.css";
import { formatDate } from "../../utils/date";
import logo from "./acl-logo.jpg";
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import dayjs from "dayjs";

type PrintableCombineAtomicProps = {
  atomics: (GetPrintableCombineAtomic_combineAtomic | null)[];
  patient: Patient;
  downloadMode: number;
  isMultiPatientReport: boolean;
  isLastPatient?: boolean;
};

const PrintableCombineAtomic: React.FC<PrintableCombineAtomicProps> = ({
  atomics,
  patient,
  downloadMode,
  isMultiPatientReport,
  isLastPatient = false,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const latestReport = atomics.reduce((p, c) => {
    const p_reportid = get(p, "reportId");
    const c_reportid = get(c, "reportId");
    if (p_reportid && c_reportid) {
      return p_reportid > c_reportid ? p : c;
    } else {
      return null;
    }
  });

  // TEXT
  useEffect(() => {
    const iframe: any = window.parent.document.getElementById("printFrame");
    if (iframe && iframe.contentWindow && imageLoaded && downloadMode === 0) {
      if (downloadMode === 0) {
        setImageLoaded(false);
        setTimeout(() => {
          if (!isMultiPatientReport) {
            window.parent.postMessage("eResults2.0 Print Ready", "*");
          }
          iframe.scrolling = "no";
          iframe.contentWindow.focus();
          iframe.contentWindow.document.title = `Report ${get(latestReport, "reportLabNumberDigitOnly")} for ${
            patient.fullname
          }`;
          if (document.queryCommandSupported("print")) {
            if (!isMultiPatientReport || (isMultiPatientReport && isLastPatient)) {
              iframe.contentWindow.document.execCommand("print", false, null);
            }
          } else {
            if (!isMultiPatientReport || (isMultiPatientReport && isLastPatient)) {
              iframe.contentWindow.print();
            }
          }
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoaded]);

  if (atomics?.length === 0) {
    return null;
  }

  const getPatientAddress = (): string[] => {
    const street: string | undefined = get(patient, "street");
    const city: string | undefined = get(patient, "city");
    const postcode: string | undefined = get(patient, "postcode");

    if (street) {
      return [street, (city || "") + " " + (postcode || "")];
    } else {
      const address: string | undefined = get(patient, "fullAddress");
      if (!address) return [];

      if (!address.includes(",")) return [address];
      return address.split(",");
    }
  };

  // TEXT and PDF reports

  return (
    <div id="print-latest" className={styles.page}>
      <div className={styles.subhead3}>
        <img src={logo} onLoad={() => setImageLoaded(true)} alt="" height="60px" />
      </div>
      <h2>Combined Panels Report</h2>
      <Box mb={3}>
        <TableContainer className={styles.headertable}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow className={styles.verticaltop}>
                <TableCell className={styles.headerbox} width="40%">
                  <Typography className={styles.bodytext3}>Patient:</Typography>
                  <Typography className={styles.subhead3}>{patient.fullname}</Typography>
                  {getPatientAddress().map((a, i) => (
                    <Typography className={styles.bodytext3} key={i}>
                      {a}
                    </Typography>
                  ))}
                  <Typography className={styles.bodytext3}>
                    DOB: {formatDate(get(patient, "dob"), "DD/MMM/YYYY", true)}
                  </Typography>
                  <Typography className={styles.bodytext3}>Sex: {patient.sex}</Typography>
                  <Typography className={styles.bodytext3}>Ref#: {latestReport && latestReport.reportUrno}</Typography>
                  <Typography className={styles.bodytext3}>
                    Printed Date: {formatDate(dayjs().toString(), "DD/MMM/YYYY", false)}
                  </Typography>
                </TableCell>
                <TableCell className={styles.headerbox} width="32%">
                  <Typography className={styles.subhead3}>Request Details:</Typography>
                  <Box>
                    <Typography className={styles.subhead3} display={"inline"}>
                      LAB Ref:
                    </Typography>
                    <Typography className={styles.bodytext3} display={"inline"} style={{ marginLeft: "4px" }}>
                      {get(latestReport, "reportLabNumberDigitOnly")}
                    </Typography>
                  </Box>
                  <Typography className={styles.bodytext3}>
                    Referred: {formatDate(get(latestReport, "reportDateCreated") || null, "DD/MMM/YYYY", false)}
                  </Typography>
                  <Typography className={styles.bodytext3}>
                    Collected: {formatDate(get(latestReport, "reportDateCollected") || null, "DD/MMM/YYYY HH:mm", true)}
                  </Typography>
                </TableCell>
                <TableCell className={styles.headerbox}>
                  <Typography className={styles.subhead3}>Australian Clinical Labs</Typography>
                  <Typography className={styles.bodytext3}>
                    {latestReport && latestReport.lab ? get(latestReport.lab, "address") : ""}
                  </Typography>
                  <Typography className={styles.bodytext3}>
                    {latestReport && latestReport.lab ? get(latestReport.lab, "suburb") : ""}
                  </Typography>
                  <Typography className={styles.bodytext3}>
                    {latestReport && latestReport.lab ? get(latestReport.lab, "phone") : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {atomics
        ? atomics.map((combineAtomic: GetPrintableCombineAtomic_combineAtomic | null) => {
            if (combineAtomic) {
              return (
                <Box mt={3} key={combineAtomic.reportId}>
                  <div className={styles.tablecontain}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={styles.headertitle}>
                              <Typography className={styles.subhead3}>{combineAtomic.reportTestName}</Typography>
                            </TableCell>
                            <TableCell className={styles.headertitle} align="right">
                              <Typography className={styles.subhead3}>
                                Reported:{" "}
                                {formatDate(get(combineAtomic, "reportDateCreated"), "DD/MMM/YYYY HH:mm", false)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" className={styles.headertbl} width={"30%"}>
                              Item
                            </TableCell>
                            <TableCell align="center" className={styles.headertbl} width={"20%"}>
                              Result
                            </TableCell>
                            <TableCell align="center" className={styles.headertbl} width={"20%"}>
                              Unit
                            </TableCell>
                            <TableCell align="center" className={styles.headertbl} width={"30%"}>
                              Reference Range
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {combineAtomic.atomicData
                            ? combineAtomic.atomicData.map(
                                (atomic: GetPrintableCombineAtomic_combineAtomic_atomicData | null) => {
                                  if (atomic)
                                    return (
                                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                      <TableRow className={styles.striped} key={atomic.loinc}>
                                        <TableCell component="th" scope="row" className={styles.tablebox}>
                                          {atomic.name}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={atomic.abnormal ? styles.tableboxAbnormal : styles.tablebox}
                                        >
                                          {atomic.value}
                                          {atomic.abnormal ? " *" : ""}
                                        </TableCell>
                                        <TableCell align="center" className={styles.tablebox}>
                                          {atomic.measurement}
                                        </TableCell>
                                        <TableCell align="center" className={styles.tablebox}>
                                          {atomic.range}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  else return <></>;
                                },
                              )
                            : ""}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Box>
              );
            } else {
              return "";
            }
          })
        : ""}
      <Box mt={2}>
        <p>
          Flags: <br />
          * = Value outside reference range <br />E = Value meets exclusion criteria <br />I = Investigator Discretion
        </p>
        <br />
        <p>
          NOTE: This is print optimised report format. This format excludes all comments and may not report all panels
          on this patient episode. Please refer standard lab report for full details.
        </p>
        <br />
        <p>Printed Date: {formatDate(dayjs().toString(), "DD/MMM/YYYY", false)}</p>
        <br />
        <p>INVESTIGATOR (sign)______________________________________ Date: ________________</p>
      </Box>

      <div className={styles.reportFooter}>
        <span className={styles.leftFooter}>
          {get(latestReport, "reportLabNumberDigitOnly")} : {patient.fullname}
        </span>
        <span className={styles.rightFooter}>Printed Date: {formatDate(dayjs().toString(), "DD/MMM/YYYY", false)}</span>
      </div>
    </div>
  );
};

export default PrintableCombineAtomic;
