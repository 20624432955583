import React, { useContext } from "react";
import { Link, Typography, Box, Grid, AppBar } from "@mui/material";
import { capitalize } from "lodash";
import styles from "./CommonHeader.module.css";
import AppContext from "../../context/AppContext";
import TopMenu from "../TopMenu/TopMenu";
import authService from "../../services/authService";
import { isTenantVet } from "../../services/tenantService";
import thm from "../theme";
import GoBackButton from "./GoBackButton";

type CommonHeaderProps = {
  title: string | null;
  title2?: string | null;
  onLinkClick: () => void;
  infoBar: any;
};

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, title2, onLinkClick, infoBar }) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const mobility = authService.isMobility();

  return (
    <AppBar elevation={0} position="sticky" className={styles.headerContainer}>
      <div className={mobility ? styles.navigation__feature_mobility : styles.navigation__feature}></div>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          align-content="strech"
          width={1}
          className={mobility ? styles.welcomeBgGradMobility : styles.welcomeBgGrad}
        >
          <Box flexGrow={1} mt={1} ml={3} pt={[0, 1]} pb={1.1}>
            <Link onClick={onLinkClick}>
              <Box style={{ color: "white" }}>
                <GoBackButton backText="PATIENT LIST" />
                {!isTenantVet() && (
                  <Typography
                    variant={isTenantVet() ? "h6" : isDesktop ? "h3" : "h5"}
                    style={{
                      marginTop: isDesktop ? "7px" : "13px",
                      marginLeft: isDesktop ? "50px" : "40px",
                      fontSize: isDesktop ? "1.5rem" : "1.2rem",
                    }}
                    sx={{ display: { sm: "none", xs: "block" } }}
                  >
                    {(title || "").split(" ").map(capitalize).join(" ")}
                  </Typography>
                )}
                {title2 ? (
                  <Typography
                    variant={isTenantVet() ? "h6" : isDesktop ? "h3" : "h5"}
                    style={{
                      marginLeft: isDesktop ? "50px" : "40px",
                      fontSize: isDesktop ? "1.5rem" : "1.2rem",
                      marginTop: isTenantVet() ? "12px" : "0px",
                    }}
                    sx={{ display: { sm: "none", xs: "block" } }}
                    display="block"
                  >
                    {(title2 || "").split(" ").map(capitalize).join(" ")}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Link>
            <Box>
              {!isTenantVet() && (
                <Typography
                  variant={isDesktop ? "h3" : "h5"}
                  style={{ marginTop: "7px" }}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {(title || "").split(" ").map(capitalize).join(" ")}
                </Typography>
              )}

              {title2 ? (
                <Typography
                  variant={isTenantVet() ? "h3" : isDesktop ? "h3" : "h5"}
                  style={{
                    marginTop: isTenantVet() ? "11.5px" : "0px",
                    marginBottom: isTenantVet() ? "11.5px" : "0px",
                  }}
                  sx={{ display: { xs: "none", sm: "block" } }}
                  display="block"
                >
                  {(title2 || "").split(" ").map(capitalize).join(" ")}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box ml={6} mt={3} mr={2}>
            <TopMenu />
          </Box>
        </Box>
      </Box>
      <Box
        bgcolor={thm.colorSchemes.light.palette.primary.main}
        display="flex"
        alignItems="center"
        mt={isTenantVet() ? 0 : [0, 2]}
        py={[1]}
        pl={[8, 3]}
      >
        <Grid container alignItems="stretch">
          {infoBar}
        </Grid>
      </Box>
    </AppBar>
  );
};

export default CommonHeader;
