import React, { useContext } from "react";
import { AppBar, Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { ValueWithLabel } from "../ValueWithLabel/ValueWithLabel";
import {
  GetEpisodeReports_episodereports_reports as Report,
  GetEpisodeReportsVariables as Episode,
} from "../PatientReportsCondense/types/GetEpisodeReports";
import styles from "./PatientReportsFull.module.css";
import PrintMenu from "./PrintMenu/PrintMenu";
import { formatDate } from "../../utils/date";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import { useState } from "react";
import { useEffect } from "react";
import { PrintPopover } from "./PrintMenu/PrintComponents";
import PrintCheckBox from "./PrintMenu/PrintCheckbox";
import DialogBoxPassword from "../DialogBox/DialogBoxPassword";
import { useMutation } from "@apollo/client";
import { ApproveEpisode, ApproveEpisodeVariables, ApproveEpisode_approveEpisode } from "./types/ApproveEpisode";
import approveEpisodeMutation from "./approveEpisodeMutation";
import { CumulativeContext } from "../../context/CumulativeContext";
import _ from "lodash";
import * as Constants from "../../constants";
import { APPROVAL_STATUS } from "../../constants";
import { ClearEpisode, ClearEpisode_clearEpisode } from "./types/ClearEpisode";
import clearEpisodeMutation from "./clearEpisodeMutation";
import { CLINCAL_TRIAL_USER_TYPE, INTERUM_STATUS, NO_DB_CONNECTION } from "../../constants";
import { DispatchRepeatStudyEmail } from "./types/DispatchRepeatStudyEmail";
import DispatchRepeatStudyEmailMutation from "./DispatchRepeatStudyEmailMutation";
import LoadingDialog from "../CombinedReport/LoadingDialog";
import { DispatchRepeatAtomicEmail } from "../CombinedReport/types/DispatchRepeatAtomicEmail";
import DispatchRepeatAtomicEmailMutation from "../CombinedReport/DispatchRepeatAtomicEmailMutation";
import Badge from "@mui/material/Badge";
import DialogBoxComment from "../DialogBox/DialogBoxComment";
import { CommentDialog } from "../DialogBox/CommentDialog";

type PatientReportHeaderProps = {
  reports: Report[];
  episode: Episode;
  allPatients: boolean;
  patientId: number;
  sortBy?: string | null;
  patient?: any;
  labnoDigitOnly: string;
};

const PatientReportHeader: React.FC<PatientReportHeaderProps> = ({
  reports,
  episode,
  allPatients,
  patientId,
  sortBy,
  patient,
  labnoDigitOnly,
}) => {
  const { setSuccess, setError } = useContext(AppContext);
  const { atomics, setAtomics, repeatAtomics, setRepeatAtomics } = useContext(CumulativeContext);
  const isDesktop = useContext(AppContext).isDesktop;
  const [isstdView, setIsStdView] = useState(false);
  const [anchorElApprove, setAnchorElApprove] = React.useState<HTMLElement | null>(null);
  const [reportsToApprove, setReportsToApprove] = React.useState<any>({});
  const [isApprove, setIsApprove] = useState(true);
  const [isRequest, setIsRequest] = useState(false);
  const [requestDisable, setRequestDisable] = useState<number[]>([]);
  const [approveDisable, setApproveDisable] = useState<number[]>([]);
  const [clearDisable, setClearDisable] = useState<number[]>([]);
  const [reportApprovedStatusText, setReportApprovedStatusText] = useState("");
  const [approveEpisode, { loading: approveEpisodeLoading }] = useMutation<ApproveEpisode, ApproveEpisodeVariables>(
    approveEpisodeMutation,
  );
  const [isOffline, setIsOffline] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [random, setRandom] = useState<number>(0);
  const [commentsLoading, setCommentsLoading] = useState(false);

  const [clearEpisode, { loading: clearEpisodeLoading }] = useMutation<ClearEpisode, ApproveEpisodeVariables>(
    clearEpisodeMutation,
  );

  const [dispatchRepeatStudyEmail, { loading: requestEpisodeLoading }] = useMutation<DispatchRepeatStudyEmail>(
    DispatchRepeatStudyEmailMutation,
  );

  const [dispatchRepeatAtomicEmail, { loading: requestAtomicLoading }] = useMutation<DispatchRepeatAtomicEmail>(
    DispatchRepeatAtomicEmailMutation,
  );

  const approveInterumStatuses = [
    INTERUM_STATUS.Preliminary.toString(),
    INTERUM_STATUS.Final.toString(),
    INTERUM_STATUS.FinalCorrected.toString(),
  ];

  useEffect(() => {
    //disalbe browser scrollbar as we use compponet scrolling
    document.body.style.overflow = "hidden";
    if (authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Standard) {
      //Always set it to standard view as this user don't have access to clinical trial
      setIsStdView(true);
    } else {
      if (authService.getReportView().toString() === "1") {
        setIsStdView(true);
      } else {
        setIsStdView(false);
      }
    }
    return () => {
      //re-enable browser scrollbar on unmount
      document.body.style.overflow = "auto";
    };
  }, []);

  const reportApproveArray = (): string[] =>
    Object.keys(reportsToApprove)
      .map((key: any) => !!reportsToApprove[key] && key)
      .filter((key: any) => !!key);

  const allApproveReportsSelected = (): boolean => {
    let result = false;
    if (isRequest) {
      result =
        reportApproveArray().length > 0 && reportApproveArray().length === reports.length - requestDisable.length;
    } else {
      if (isApprove) {
        result =
          reportApproveArray().length > 0 && reportApproveArray().length === reports.length - approveDisable.length;
      } else {
        result =
          reportApproveArray().length > 0 && reportApproveArray().length === reports.length - clearDisable.length;
      }
    }
    return result;
    // reportApproveArray().length > 0;
  };

  const selectAllEnabled = () => {
    if (isRequest) {
      return reports.length > requestDisable.length;
    } else {
      if (isApprove) {
        return reports.length > approveDisable.length;
      } else {
        return reports.length > clearDisable.length;
      }
    }
  };

  const onSelectReportChange = (reportid: string): void => {
    setReportsToApprove({
      ...reportsToApprove,
      [reportid]: !reportsToApprove[reportid],
    });
  };

  const onRequestRepeatClick = (event: any) => {
    setIsRequest(true);
    setIsApprove(false);
    setAnchorElApprove(event.target);
  };

  const onRequestsSelectedClick = () => {
    setRandom(random + 1);
    setIsCommentsOpen(true);
  };

  const onApproveClick = (event: any) => {
    setIsRequest(false);
    setIsApprove(true);
    setAnchorElApprove(event.target);
  };

  const onClearClick = (event: any) => {
    setIsRequest(false);
    setIsApprove(false);
    setAnchorElApprove(event.target);
  };

  const handleCloseApprove = () => {
    setReportsToApprove({});
    setAnchorElApprove(null);
  };

  const handleClose = () => {
    setReportsToApprove({});
    setAnchorElApprove(null);
    setIsCommentsOpen(false);
  };

  const onLoginSuccess = (approve: boolean) => {
    if (approve) {
      doApproveEpisode();
    } else {
      cancelEpisode();
    }
  };

  const onSelectAllApproveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (isRequest) {
      const allReports: any = {};
      reports
        .filter((f) => {
          return approveInterumStatuses.includes(f.interimStatus + "");
        })
        .forEach((report: any) => {
          if (!requestDisable.includes(report.reportid)) {
            allReports[report.reportid] = event.currentTarget.checked;
          }
        });
      setReportsToApprove(allReports);
    } else {
      if (isApprove) {
        const allReports: any = {};
        reports
          .filter((f) => {
            return approveInterumStatuses.includes(f.interimStatus + "");
          })
          .forEach((report: any) => {
            if (!approveDisable.includes(report.reportid)) {
              allReports[report.reportid] = event.currentTarget.checked;
            }
          });
        setReportsToApprove(allReports);
      } else {
        const allReports: any = {};
        reports
          .filter((f) => {
            return approveInterumStatuses.includes(f.interimStatus + "");
          })
          .forEach((report: any) => {
            if (!clearDisable.includes(report.reportid)) {
              allReports[report.reportid] = event.currentTarget.checked;
            }
          });
        setReportsToApprove(allReports);
      }
    }
  };

  const doApproveEpisode = async () => {
    //Do Mutation to approve episode
    if (isApprove) {
      try {
        const reportIds = Object.keys(reportsToApprove)
          .map((key: any) => !!reportsToApprove[key] && key)
          .filter((key: any) => !!key)
          .map((x) => +x);
        const userId = parseInt(authService.getUserId());

        await approveEpisode({
          variables: {
            input: {
              reportIds,
              userId,
            },
          },
        }).then(async (approveEpisodeData: any) => {
          if (approveEpisodeData && approveEpisodeData.data) {
            if (approveEpisodeData.data.approveEpisode) {
              if (approveEpisodeData.data.approveEpisode.length > 0) {
                const newAtomics = _.cloneDeep(atomics);
                (approveEpisodeData.data.approveEpisode as ApproveEpisode_approveEpisode[]).forEach((approveData) => {
                  const panelApprovalStatus = approveData.csPanelApprovalStatus
                    ? approveData.csPanelApprovalStatus
                    : APPROVAL_STATUS.New;
                  const panelApprovalDatetime = approveData.csPanelApprovalDatetime
                    ? approveData.csPanelApprovalDatetime
                    : null;
                  const panelApprovalUserId = approveData.csPanelApproverUserId
                    ? approveData.csPanelApproverUserId
                    : null;
                  const panelApprovalUsername = approveData.csPanelApproverUsername
                    ? approveData.csPanelApproverUsername
                    : null;

                  newAtomics.forEach((newAtomic) => {
                    if (newAtomic) {
                      if (newAtomic.reportId === approveData.reportId) {
                        newAtomic.csPanelApprovalStatus = panelApprovalStatus;
                        newAtomic.csPanelApproverDatetime = panelApprovalDatetime || null;
                        newAtomic.csPanelApproverId = panelApprovalUserId || null;
                        newAtomic.csPanelApproverName = panelApprovalUsername || null;
                      }
                    }
                  });

                  if (approveData.atomicData) {
                    approveData.atomicData.forEach((atomicD) => {
                      if (atomicD) {
                        const atomicId = atomicD.atomicId ? atomicD.atomicId : null;
                        const approval = atomicD.csApproval !== null ? atomicD.csApproval : null;
                        const approverUserId = atomicD.csApproverUserId ? atomicD.csApproverUserId : null;
                        const approverUsername = atomicD.csApproverUsername ? atomicD.csApproverUsername : null;
                        const approverDatetime = atomicD.csApproverDatetime ? atomicD.csApproverDatetime : null;

                        newAtomics.forEach((newAtomic) => {
                          if (newAtomic && newAtomic.atomicData) {
                            newAtomic.atomicData.forEach((atomic) => {
                              if (atomic && atomic.atomicId === atomicId && atomic.csStatus !== undefined) {
                                atomic.csApproval = Boolean(approval);
                                atomic.csApproverUserId = approverUserId;
                                atomic.csApproverUsername = approverUsername;
                                atomic.csApproverDatetime = approverDatetime;
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
                newAtomics.forEach((newAtomic) => {
                  if (newAtomic) {
                    // this will trigger status recalculation
                    newAtomic.status = "";
                  }
                });
                setAtomics(newAtomics);
                if (repeatAtomics.length > 0) repeatAtomicsHandler();
              }
            }
          }
        });
        handleCloseApprove();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  };

  const doRequestEpisode = async (dlg: CommentDialog) => {
    //Do Mutation to approve episode
    try {
      setIsCommentsOpen(false);
      setCommentsLoading(true);
      const reportIds = Object.keys(reportsToApprove)
        .map((key: any) => !!reportsToApprove[key] && key)
        .filter((key: any) => !!key)
        .map((x) => +x);

      const panels: any[] = [];
      reports
        .filter((f) => f.reportid && reportIds.includes(f.reportid))
        .forEach((r) => {
          panels.push({
            reportId: r.reportid,
            panelCode: r.testcode,
            panelName: r.testname,
            labNumber: r.labnumber,
          });
        });

      dispatchRepeatStudyEmail({
        variables: {
          input: {
            provider: reports[0].providerNo,
            patientId: patientId,
            labNo: labnoDigitOnly,
            panels: panels,
            urNo: reports[0].urno,
            comment: dlg.comment,
          },
        },
      })
        .then(async (response) => {
          if (
            response.data &&
            response.data.dispatchRepeatStudyEmail &&
            response.data.dispatchRepeatStudyEmail.message
          ) {
            if (response.data.dispatchRepeatStudyEmail.message.includes("pushed")) {
              setSuccess("Repeat notification email sent.");
            } else {
              setError(response.data.dispatchRepeatStudyEmail.message);
            }
          } else {
            setError("Transaction Failed.");
          }
        })
        .catch(() => {
          setError(NO_DB_CONNECTION);
        })
        .finally(() => {
          setCommentsLoading(false);
          handleCloseApprove();
        });
    } catch (e) {
      setCommentsLoading(false);
      setError(NO_DB_CONNECTION);
    }
  };

  const cancelEpisode = async () => {
    //Do Mutation to approve episode
    try {
      const reportIds = Object.keys(reportsToApprove)
        .map((key: any) => !!reportsToApprove[key] && key)
        .filter((key: any) => !!key)
        .map((x) => +x);

      const userId = parseInt(authService.getUserId());
      await clearEpisode({
        variables: {
          input: {
            reportIds,
            userId,
          },
        },
      }).then(async (approveEpisodeData: any) => {
        setIsApprove(false);
        if (approveEpisodeData?.data?.clearEpisode?.length > 0) {
          const newAtomics = _.cloneDeep(atomics);

          (approveEpisodeData.data.clearEpisode as ClearEpisode_clearEpisode[]).forEach((clearData) => {
            const panelApprovalStatus = clearData.csPanelApprovalStatus
              ? clearData.csPanelApprovalStatus
              : APPROVAL_STATUS.New;
            const panelApprovalDatetime = clearData.csPanelApprovalDatetime ? clearData.csPanelApprovalDatetime : null;
            const panelApprovalUserId = clearData.csPanelApproverUserId ? clearData.csPanelApproverUserId : null;
            const panelApprovalUsername = clearData.csPanelApproverUsername ? clearData.csPanelApproverUsername : null;

            newAtomics.forEach((newAtomic) => {
              if (newAtomic) {
                if (newAtomic.reportId === clearData.reportId) {
                  if (newAtomic.csPanelApprovalStatus !== undefined) {
                    newAtomic.csPanelApprovalStatus = panelApprovalStatus;
                  }
                  if (newAtomic.csPanelApproverDatetime !== undefined) {
                    newAtomic.csPanelApproverDatetime = panelApprovalDatetime || null;
                  }
                  if (newAtomic.csPanelApproverId !== undefined) {
                    newAtomic.csPanelApproverId = panelApprovalUserId || null;
                  }
                  if (newAtomic.csPanelApproverName !== undefined) {
                    newAtomic.csPanelApproverName = panelApprovalUsername || null;
                  }
                  if (newAtomic.status !== undefined) {
                    newAtomic.status = "Ready for review" || null;
                  }
                }
              }
            });

            if (clearData.atomicData) {
              clearData.atomicData.map((atomicD) => {
                if (atomicD) {
                  const atomicId = atomicD.atomicId ? atomicD.atomicId : null;
                  const approval = atomicD.csApproval !== null ? atomicD.csApproval : null;
                  const approverUserId = atomicD.csApproverUserId ? atomicD.csApproverUserId : null;
                  const approverUsername = atomicD.csApproverUsername ? atomicD.csApproverUsername : null;
                  const approverDatetime = atomicD.csApproverDatetime ? atomicD.csApproverDatetime : null;

                  newAtomics.forEach((newAtomic) => {
                    if (newAtomic && newAtomic.atomicData) {
                      newAtomic.atomicData.forEach((atomic) => {
                        if (atomic && atomic.atomicId === atomicId && atomic.csStatus !== undefined) {
                          atomic.csApproval = Boolean(approval);
                          atomic.csApproverUserId = approverUserId;
                          atomic.csApproverUsername = approverUsername;
                          atomic.csApproverDatetime = approverDatetime;
                        }
                      });
                    }
                  });
                }
              });
            }
          });
          newAtomics.forEach((newAtomic) => {
            if (newAtomic) {
              // this will trigger status recalculation
              newAtomic.status = "";
            }
          });
          setAtomics(newAtomics);
        }
      });

      handleCloseApprove();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    const reportsToDisableApprove = new Array<number>();
    const reportsToDisableClear = new Array<number>();
    const reportsToDisableRequest = new Array<number>();
    reports.forEach((report) => {
      checkApproveDisable(report, reportsToDisableApprove);
      checkClearDisable(report, reportsToDisableClear);
      checkRequestDisable(report, reportsToDisableRequest);
    });
    setApproveDisable(reportsToDisableApprove);
    setClearDisable(reportsToDisableClear);
    setRequestDisable(reportsToDisableRequest);
    if (atomics && atomics.length) {
      const reportStatusText = checkApprovalStatus();
      setReportApprovedStatusText(reportStatusText);
    }
  }, [reports, atomics]);

  const checkApprovalStatus = () => {
    let statusText = "Ready for review";
    let reportStatus = "";
    let statusPendingCount = 0;
    let statusApproveCount = 0;
    let statusPrintedCount = 0;
    let isAnyReportolderThanADay = false;
    atomics.forEach((report) => {
      if (report) {
        if (report.isReportOlderThanADay) {
          isAnyReportolderThanADay = true;
        }
        if (report.status) {
          reportStatus = report.status;
        } else {
          let unapprovedAtomics = false;
          if (report.atomicData) {
            unapprovedAtomics = report.atomicData.some((a) => a && a.csApproval === false);
          }
          switch (report.csPanelApprovalStatus || 0) {
            case APPROVAL_STATUS.Approved:
              if (!unapprovedAtomics) {
                statusApproveCount++;
              }
              break;
            case APPROVAL_STATUS.Printed:
              if (!unapprovedAtomics) {
                statusPrintedCount++;
              }
              break;
          }
          if (report.reportStatus === "I") {
            statusPendingCount++;
          }
        }
      }
    });

    if (reportStatus) {
      if (reportStatus.toLowerCase() === "offline") {
        setIsOffline(true);
      }
      statusText = reportStatus;
    } else if (
      statusApproveCount + statusPrintedCount > 0 &&
      statusApproveCount + statusPrintedCount < atomics.length &&
      statusPendingCount > 0
    ) {
      statusText = isAnyReportolderThanADay ? "Partially Reviewed" : "Pending";
    } else if (statusPrintedCount === atomics.length) {
      statusText = "Printed";
    } else if (statusApproveCount + statusPrintedCount === atomics.length) {
      statusText = "Reviewed / Approved";
    } else if (statusPendingCount > 0) {
      statusText = isAnyReportolderThanADay ? "Ready for partial review" : "Pending";
    } else {
      statusText = "Ready for review";
    }
    return statusText;
  };

  const checkApproveDisable = (report: Report, reportsToDisableApprove: Array<number>) => {
    const atomic = atomics.find((a) => a && a.reportId === report.reportid);
    let atomicAbnormal = false;
    if (!report.hasAbnormalStatus && atomic) {
      const cntAbnormal = atomic.atomicData?.filter((ad) => ad?.abnormal);
      if (cntAbnormal && cntAbnormal?.length > 0) {
        atomicAbnormal = true;
      }
    }
    if (
      (report.hasAbnormalStatus || atomicAbnormal) &&
      approveInterumStatuses.includes(report.interimStatus + "") &&
      atomic
    ) {
      if (
        (atomic?.cumulative || (!atomic?.cumulative && atomic?.report !== "")) &&
        (atomic?.csPanelApprovalStatus === null || atomic?.csPanelApprovalStatus === APPROVAL_STATUS.New)
      ) {
        const atomicD = atomics.find((a) => a && a.reportId === report.reportid)?.atomicData;

        if (atomicD) {
          const faultData = atomicD.filter((ad) => ad && ad.abnormal && ad.csStatus === null).length;
          if (faultData > 0) {
            if (report && report.reportid) {
              reportsToDisableApprove.push(report.reportid);
            }
            return true;
          } else {
            if (report && report.reportid) {
              if (approveDisable.includes(report.reportid)) {
                approveDisable.splice(approveDisable.findIndex((t) => t === report.reportid));
              }
            }
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (report && report.reportid) {
          reportsToDisableApprove.push(report.reportid);
        }
        return true;
      }
    } else if (approveInterumStatuses.includes(report.interimStatus + "")) {
      const atomic = atomics.find((a) => a && a.reportId === report.reportid);
      if (
        !atomic ||
        (atomic &&
          report &&
          report.reportid &&
          [APPROVAL_STATUS.Approved, APPROVAL_STATUS.Printed].includes(atomic.csPanelApprovalStatus || 0))
      ) {
        reportsToDisableApprove.push(report?.reportid ?? 0);
        return true;
      }
      return false;
    }
    reportsToDisableApprove.push(report?.reportid ?? 0);

    return true;
  };

  const checkRequestDisable = (report: Report, reportsToDisableRequest: Array<number>) => {
    if (
      report &&
      report.interimStatus === "I" &&
      report.reportid &&
      !reportsToDisableRequest.includes(report.reportid)
    ) {
      reportsToDisableRequest.push(report.reportid);
      return true;
    }
    return false;
  };

  const checkClearDisable = (report: Report, reportsToDisableClear: Array<number>) => {
    if (
      report.hasAbnormalStatus &&
      approveInterumStatuses.includes(report.interimStatus + "") &&
      atomics &&
      atomics.find((a) => a && a.reportId === report.reportid)
    ) {
      const atomic = atomics.find((a) => a && a.reportId === report.reportid);

      if (
        atomic?.cumulative &&
        (atomic.csPanelApprovalStatus === null || atomic.csPanelApprovalStatus === APPROVAL_STATUS.New)
      ) {
        if (report && report.reportid && !reportsToDisableClear.includes(report.reportid)) {
          reportsToDisableClear.push(report.reportid);
        }
        return true;
      } else {
        if (report && report.reportid && !clearDisable.includes(report.reportid)) {
          if (reportsToDisableClear.includes(report.reportid)) {
            reportsToDisableClear = reportsToDisableClear.splice(
              reportsToDisableClear.findIndex((t) => t === report.reportid),
            );
          }
        }
        return false;
      }
    } else if (approveInterumStatuses.includes(report.interimStatus + "")) {
      const atomic = atomics.find((a) => a && a.reportId === report.reportid);
      if (!atomic || [APPROVAL_STATUS.Approved, APPROVAL_STATUS.Printed].includes(atomic.csPanelApprovalStatus || 0))
        return false;
    }
    if (report && report.reportid && !reportsToDisableClear.includes(report.reportid)) {
      reportsToDisableClear.push(report.reportid);
    }
    return true;
  };

  const repeatAtomicsHandler = () => {
    if (repeatAtomics.length > 0) {
      dispatchRepeatAtomicEmail({
        variables: {
          input: {
            atomicId: repeatAtomics.map((ra) => {
              return { atomicId: ra.atomicId, comment: ra.csRepeatComment };
            }),
          },
        },
      }).then(async (response) => {
        if (
          response.data &&
          response.data.dispatchRepeatAtomicEmail &&
          response.data.dispatchRepeatAtomicEmail.message
        ) {
          if (response.data.dispatchRepeatAtomicEmail.message.includes("pushed")) {
            setRepeatAtomics([]);
            setSuccess("Repeat notification email sent.");
          } else {
            if (response.data.dispatchRepeatAtomicEmail.message) {
              setError(response.data.dispatchRepeatAtomicEmail.message);
            } else {
              setError(NO_DB_CONNECTION);
            }
          }
        } else {
          setError("Transaction Failed.");
        }
      });
    }
  };

  return (
    <AppBar
      elevation={0}
      className={`${styles.headerBar} ${!isDesktop ? styles.headerBarMobility : styles.headerBarNormal}`}
    >
      <Box display="flex" justifyContent="space-between" mx={5} my={2} height={"100%"}>
        <Box display={"flex"} justifyContent={"space-around"} flexDirection={"column"}>
          <ValueWithLabel
            width={320}
            label="Ordered:"
            value={formatDate(reports[0].dateReferred, "DD MMM YYYY", true)}
          />
          <ValueWithLabel
            width={320}
            label="Last Updated:"
            value={formatDate(reports[0].dateCreated, "DD MMM YYYY hh:mm A", false)}
          />
          {/* <ValueWithLabel width={320} label="Status:" value={atomics && atomics[0] ? atomics[0].status : "New"} /> */}
          {!isstdView ? (
            <ValueWithLabel
              width={320}
              label="Status:"
              value={reportApprovedStatusText ? reportApprovedStatusText : "New"}
            />
          ) : null}
        </Box>
        <Box display={"flex"} justifyContent={"space-around"} flexDirection={"row"}>
          {authService.getReportView() === "2" &&
            authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver &&
            !isOffline && (
              <Box maxWidth={"90%"} display={"flex"} flexDirection={"row"}>
                <Box mt={2.5} mr={2.5}>
                  {requestAtomicLoading ? (
                    <CircularProgress size={35} />
                  ) : (
                    <Badge badgeContent={repeatAtomics.length} color="secondary">
                      <Button
                        size="medium"
                        disabled={repeatAtomics.length === 0}
                        className={repeatAtomics.length > 0 ? styles.repeatAtomicButton : styles.disabledButton}
                        title={
                          repeatAtomics.length > 0
                            ? `${repeatAtomics.length} Atomic(s) in basket:\n- ${repeatAtomics
                                .map((ra) => {
                                  return ra.reportTestName + " (" + ra.name + ")";
                                })
                                .join("\n- ")}\n\nClick to submit Repeat Request`
                            : "Add Atomic(s) to basket"
                        }
                        onClick={repeatAtomics.length > 0 ? repeatAtomicsHandler : undefined}
                      >
                        <Typography className={styles.buttonText}>Repeat Atomic(s)</Typography>
                      </Button>
                    </Badge>
                  )}
                </Box>
                <Button size="medium" className={styles.repeatButton} onClick={onRequestRepeatClick}>
                  <Typography className={styles.buttonText}>Repeat Panel(s)</Typography>
                </Button>
                <Button
                  size="medium"
                  className={
                    reports
                      .map((report: Report) => approveDisable.includes(report?.reportid ?? 0))
                      .filter((e: any) => e == false).length == 0
                      ? styles.disableClearApproveBtn
                      : styles.approveButton
                  }
                  onClick={onApproveClick}
                  disabled={
                    reports
                      .map((report: Report) => approveDisable.includes(report?.reportid ?? 0))
                      .filter((e: any) => e == false).length == 0
                  }
                >
                  <Typography className={styles.buttonText}>Approve</Typography>
                </Button>
                <Button
                  size="medium"
                  className={
                    reports
                      .map((report: Report) => clearDisable.includes(report?.reportid ?? 0))
                      .filter((e: any) => e == false).length == 0
                      ? styles.disableClearApproveBtn
                      : styles.clearButton
                  }
                  onClick={onClearClick}
                  disabled={
                    reports
                      .map((report: Report) => clearDisable.includes(report?.reportid ?? 0))
                      .filter((e: any) => e == false).length == 0
                  }
                >
                  Clear
                </Button>
                <PrintPopover anchorEl={anchorElApprove} handleClose={handleCloseApprove}>
                  <Box m={2}>
                    <Typography variant="h4" color="primary" className={styles.menuTitle}>
                      {isRequest ? "Request Repeat" : isApprove ? "Approve" : "Clear"}
                    </Typography>

                    <Box display="flex" flexDirection="column">
                      <PrintCheckBox
                        name="Full report (All panels)"
                        value={allApproveReportsSelected()}
                        onChange={(e: any) => onSelectAllApproveChange(e)}
                        disabled={!selectAllEnabled()}
                      />
                      <Divider className={styles.divider} />
                      {reports.map((report: Report) => (
                        <PrintCheckBox
                          key={`${report.testname}`}
                          name={`${report.testname}`}
                          value={!!reportsToApprove[`${report.reportid}`]}
                          onChange={() => onSelectReportChange(`${report.reportid}`)}
                          disabled={
                            isRequest
                              ? requestDisable.includes(report?.reportid ?? 0)
                              : isApprove
                                ? approveDisable.includes(report?.reportid ?? 0)
                                : clearDisable.includes(report?.reportid ?? 0)
                          }
                        />
                      ))}

                      <Box mt={2} display="flex" justifyContent="flex-end">
                        {isRequest ? (
                          <Button
                            type="button"
                            disabled={
                              Object.keys(reportsToApprove)
                                .map((key: any) => !!reportsToApprove[key] && key)
                                .filter((key: any) => !!key).length === 0
                            }
                            variant="contained"
                            onClick={() => onRequestsSelectedClick()}
                          >
                            {requestEpisodeLoading || commentsLoading ? (
                              <Box width={"100%"} textAlign="center">
                                <CircularProgress size={16} />
                              </Box>
                            ) : (
                              "Request Repeat"
                            )}
                          </Button>
                        ) : (
                          <DialogBoxPassword
                            onLoginSuccess={(status: boolean) => {
                              onLoginSuccess(status);
                            }}
                            disabled={
                              Object.keys(reportsToApprove)
                                .map((key: any) => !!reportsToApprove[key] && key)
                                .filter((key: any) => !!key).length === 0
                            }
                            isApprove={isApprove}
                            isOffline={false}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </PrintPopover>
              </Box>
            )}

          <Box display={"flex"} mr={3}>
            <PrintMenu
              reports={reports}
              episode={episode}
              allPatients={allPatients}
              deliveryMode={Constants.DELIVERY_MODE_EORDER}
              patientId={patientId}
              sortBy={sortBy}
              printCombined={!isstdView}
              smscEnabled={authService.isSmscEnabled()}
              patient={patient}
              reportingProviderNo={reports[0].reportingProviderNo as string}
            />
          </Box>

          <Box display={"flex"} mr={3}>
            <PrintMenu
              reports={reports}
              episode={episode}
              allPatients={allPatients}
              deliveryMode={Constants.DELIVERY_MODE_SEND}
              patientId={patientId}
              sortBy={sortBy}
              printCombined={!isstdView}
              smscEnabled={authService.isSmscEnabled()}
              patient={patient}
              reportingProviderNo={reports[0].reportingProviderNo as string}
            />
          </Box>

          <Box display={"flex"} mr={3}>
            <PrintMenu
              reports={reports}
              episode={episode}
              allPatients={allPatients}
              deliveryMode={Constants.DELIVERY_MODE_ADDON}
              patientId={patientId}
              sortBy={sortBy}
              printCombined={!isstdView}
              patient={patient}
              isAddon
              reportingProviderNo={reports[0].reportingProviderNo as string}
            />
          </Box>

          <Box display={"flex"} mr={3}>
            <PrintMenu
              reports={reports}
              episode={episode}
              ctReport={isstdView ? [] : undefined}
              allPatients={allPatients}
              deliveryMode={Constants.DELIVERY_MODE_DOWNLOAD}
              patientId={patientId}
              sortBy={sortBy}
              printCombined={!isstdView}
              patient={patient}
              reportingProviderNo={reports[0].reportingProviderNo as string}
            />
          </Box>

          {isDesktop && (
            <Box display={"flex"}>
              <PrintMenu
                reports={reports}
                episode={episode}
                allPatients={allPatients}
                deliveryMode={Constants.DELIVERY_MODE_PRINT}
                patientId={patientId}
                sortBy={sortBy}
                printCombined={!isstdView}
                patient={patient}
                reportingProviderNo={reports[0].reportingProviderNo as string}
              />
            </Box>
          )}
        </Box>
      </Box>
      {(approveEpisodeLoading || clearEpisodeLoading) && (
        <LoadingDialog
          setOpen={approveEpisodeLoading || clearEpisodeLoading}
          message="Applying changes to atomic, please wait..."
        />
      )}
      <DialogBoxComment
        open={isCommentsOpen}
        onClose={handleClose}
        onSubmit={(dlg: CommentDialog) => {
          doRequestEpisode(dlg);
        }}
        atomic={null}
        random={random}
        isOffline={isOffline}
      />
    </AppBar>
  );
};

export default PatientReportHeader;
