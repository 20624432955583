import React, { Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import styles from "../LoginPage/LoginPage.module.css";
import { Link as RouterLink, useParams } from "react-router-dom";

import { Box, Link, Typography } from "@mui/material";

import MainNavigation from "../MainNavigation/MainNavigation";
import AuthLayout from "../common/AuthLayout/AuthLayout";

type CheckEmailPathProps = {
  email: string;
};

const CheckEmailPage: React.FC<RouteComponentProps> = () => {
  const { email }: CheckEmailPathProps = useParams() as CheckEmailPathProps;
  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <AuthLayout
        title="Check your email"
        maxWidth={"490px"}
        footer={
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              By logging in you agree to the{" "}
              <Link
                className={styles.terms}
                color="textSecondary"
                href="https://ehealth.clinicallabs.com.au/terms_conditions.php"
                target="_blank"
                rel="noopener"
              >
                Terms &amp; Conditions
              </Link>
            </Typography>
          </Box>
        }
      >
        <Box pt={3}>
          <Typography variant="body1" color="textSecondary">
            An email has been sent to <b>{email}</b>
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="subtitle1" color="textSecondary">
            Didn’t recieve the email?
          </Typography>
        </Box>
        <Box my={4}>
          <Typography variant="body1" color="textSecondary">
            Check spam or bulk folders for a message coming from clinical labs
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Link color="primary" component={RouterLink} to="/login">
            Return to sign in
          </Link>
        </Box>
      </AuthLayout>
    </Fragment>
  );
};

export default withRouter(CheckEmailPage);
