import { gql } from "@apollo/client";

export default gql`
  mutation ApproveAtomic($input: ApproveAtomicArg!) {
    approveAtomic(input: $input) {
      atomicId
      csStatus
      csUserId
      csDatetime
      approval
      approverUserId
      approverName
      approverDatetime
      comment
      csName
      noActionRequired
      actionRequired
      dCF
    }
  }
`;
