import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import BlockIcon from "@mui/icons-material/Block";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AppContext from "../../context/AppContext";
import theme from "../theme";
import styles from "./CPDprograms.module.css";
import tabstyles from "./CPDdiabetesPage3.module.css";
import { titleCase } from "title-case/dist";
import { formatDate } from "../../utils/date";
import getHba1cPatientsReport from "./GetHba1cPatientsReportQuery";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetHba1cPatientsReportVariables,
  GetHba1cPatientsReport_getHba1cPatientsReport_records_testsOverdue,
  GetHba1cPatientsReport,
  GetHba1cPatientsReport_getHba1cPatientsReport_records,
} from "./types/GetHba1cPatientsReport";
import { getCpdProviderValues } from "../../services/authService";
import Loading from "../Loading/Loading";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { RemoveDiabeticPatient, RemoveDiabeticPatientVariables } from "./types/RemoveDiabeticPatient";
import RemoveDiabeticPatientMutation from "./RemoveDiabeticPatientMutation";
import * as Constants from "../../constants";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { SendToDiabeticPatients, SendToDiabeticPatientsVariables } from "./types/SendToDiabeticPatients";
import SendToDiabeticPatientsMutation from "./SendToDiabeticPatientsMutation";
import PatientDetailsTooltip from "../PatientDetailsTooltip/PatientDetailsTooltip";
import Tooltips from "../common/Tooltip/Tooltip";
import { PrintMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export type CPDdiabetesForm = {
  searchtext: string;
  overdueOnly: boolean;
};

type Hba1cDiabeticPatient = GetHba1cPatientsReport_getHba1cPatientsReport_records & {
  recall: boolean;
  requestForm: boolean;
};

type PatientRowProps = {
  patient: Hba1cDiabeticPatient;
  index: number;
  setChecked: (index: number, isRecall: boolean) => void;
  patientRemoved: (index: number) => void;
};

const properDob = (dateString: string | null) => {
  return formatDate(dateString, "DD MMM YYYY", false);
};

const properPanels = (tests: GetHba1cPatientsReport_getHba1cPatientsReport_records_testsOverdue[] | null) => {
  return tests?.map((item, index) => {
    return (
      item.isOverdue && (
        <Chip
          key={index}
          label={
            <Typography variant="caption" color={"inherit"}>
              {item.test}
            </Typography>
          }
          size="small"
          className={styles.chipRead}
        />
      )
    );
  });
};

const formatDateWithInterval = (dateString: string | null, asUtc: boolean): string => {
  const dt = asUtc ? dayjs(dateString || "").utc() : dayjs(dateString || "");
  return dt >= dayjs().startOf("day")
    ? dayjs(dt.format("YYYY-MM-DD HH:mm:ss")).fromNow() || "-"
    : dt >= dayjs().subtract(1, "days").startOf("day") && dt < dayjs().startOf("day")
      ? "Yesterday"
      : dt.year() !== dayjs().year()
        ? formatDate(dateString || "", "DD MMM  YYYY", false)
        : formatDate(dateString || "", "DD MMM", false);
};

const PatientRow: React.FC<PatientRowProps> = ({ patient, index, setChecked, patientRemoved }) => {
  const [recallCheck, setRecallCheck] = useState(patient.recall);
  const [requestFormCheck, setRequestFormCheck] = useState(patient.requestForm);
  const { setError, isDesktop } = useContext(AppContext);
  const [confirmRemovePatient, setConfirmRemovePatient] = useState(false);

  const [removeDiabeticPatient, { loading, data, error }] = useMutation<
    RemoveDiabeticPatient,
    RemoveDiabeticPatientVariables
  >(RemoveDiabeticPatientMutation);

  const removePatient = () => {
    setConfirmRemovePatient(true);
  };

  const handleRemovePatientOk = () => {
    setConfirmRemovePatient(false);
    removeDiabeticPatient({
      variables: {
        patientId: patient.id,
      },
    });
  };

  useEffect(() => {
    if (data && data.removeDiabeticPatient === "ok") {
      setError("");
      patientRemoved(index);
    }
    if (error) {
      setError(Constants.NO_DB_CONNECTION);
    }
  }, [data, error]);

  type ActionCheckboxProps = {
    checked: boolean;
    onChecked: () => void;
    title?: string;
  };

  const recallChecked = () => {
    setRecallCheck(!recallCheck);
    setChecked(index, true);
  };

  const requestFormChecked = () => {
    setRequestFormCheck(!requestFormCheck);
    setChecked(index, false);
  };

  const isCheckboxDisabled = () => {
    return patient.recallsExceeded || !patient.submissionAllowed;
  };

  const obfuscateName = (name: string) => {
    const correctName = titleCase(name.toLowerCase());
    return correctName.replace(
      /\b(\w{1,2})(\w{2,}?)(\w?)\b/g,
      (_, first, middle, last) => `${first}${"*".repeat(middle.length)}${last}`,
    );
  };

  const getCheckboxDisabledTooltip = () => {
    if (patient.recallsExceeded) {
      return "Previously recalled patients (3 or more recall letters sent since last HbA1c test)";
    } else if (!patient.isHba1cOverdue) {
      return "Patient is not overdue for HbA1c test";
    } else if (!patient.submissionAllowed) {
      if (dayjs(patient.lastSubmitted).diff(dayjs(), "days") < 14) {
        if (patient.lastSent && dayjs(patient.lastSubmitted) < dayjs(patient.lastSent)) {
          return `Last Request submitted: ${formatDateWithInterval(
            patient.lastSubmitted,
            false,
          )}. \nNext available submission is after ${dayjs(patient.lastSubmitted)
            .add(15, "days")
            .format("DD MMM YYYY")}.`;
        } else {
          return `Mail out request is pending. \nRequest submitted: ${formatDateWithInterval(
            patient.lastSubmitted,
            false,
          )}. \nNext available submission is after ${dayjs(patient.lastSubmitted)
            .add(15, "days")
            .format("DD MMM YYYY")}.`;
        }
      }
    }
    return "";
  };

  const ActionCheckbox = (props: ActionCheckboxProps) => {
    return (
      <>
        {isCheckboxDisabled() ? (
          <HtmlTooltip
            title={
              <Typography display={"inline"} variant={"inherit"} style={{ whiteSpace: "pre-line" }}>
                {getCheckboxDisabledTooltip()}
              </Typography>
            }
          >
            <BlockIcon sx={{ color: "red", padding: "9px" }} />
          </HtmlTooltip>
        ) : (
          <Checkbox checked={props.checked} name="recall" color="primary" onClick={props.onChecked} />
        )}
        {!isDesktop && props.title && (
          <Typography variant="body2" sx={{ margin: "auto" }}>
            {props.title}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <TableRow key={index} className={`${!isDesktop ? styles.displayForPrintingOnly : ""}`}>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_remove}`}>
          <Button
            variant="contained"
            color="primary"
            className={styles.removeButton}
            size="small"
            disabled={loading}
            onClick={removePatient}
          >
            {loading ? <CircularProgress size={16} /> : isDesktop ? "Remove" : <DeleteForeverIcon />}
          </Button>
          <Dialog open={confirmRemovePatient} onClose={() => setConfirmRemovePatient(false)}>
            <DialogTitle>
              <DialogBoxTitle title="Please confirm" width={260} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Only remove patients that are no longer in your care or deceased. This action will permanently delete
                this patient from your diabetes list. Are you sure you want to remove this patient?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={styles.Btn} onClick={() => setConfirmRemovePatient(false)}>
                Cancel
              </Button>
              <Button className={styles.Btn} onClick={handleRemovePatientOk} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_name}`}>
          {patient.isHba1cOverdue && <Tooltips buttonColor="red" text="This patient is overdue for recall" />}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_name}`}>
          <HtmlTooltip
            classes={{ tooltip: styles.patientDetailTooltip }}
            title={<PatientDetailsTooltip patientId={patient.id} allPatients={false}></PatientDetailsTooltip>}
          >
            <div>
              <div className={styles.hideForPrinting}>{titleCase(patient.name.toLowerCase())}</div>
              <div className={styles.displayForPrintingOnly}>{obfuscateName(patient.name)}</div>
            </div>
          </HtmlTooltip>
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_dob}`}>{properDob(patient.dob)}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_lastresult}`}>
          {formatDateWithInterval(patient.lastResult, false)}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_testsoverdue}`}>
          {properPanels(patient.testsOverdue)}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_recall}`}>
          <ActionCheckbox checked={recallCheck} onChecked={recallChecked} />
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_requestform}`}>
          <ActionCheckbox checked={requestFormCheck} onChecked={requestFormChecked} />
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_lastsent}`}>
          {patient.lastRecallDates && patient.lastRecallDates.length > 1 ? (
            <HtmlTooltip
              classes={{ tooltip: styles.patientDetailTooltip }}
              title={
                <>
                  <Typography variant="body1">Previous Recall dates:</Typography>
                  {patient.lastRecallDates.slice(1).map((dt, i) => {
                    return (
                      <Typography key={i} variant="body2" display="block">
                        {formatDateWithInterval(dt, false)}
                      </Typography>
                    );
                  })}
                </>
              }
            >
              <div>{formatDateWithInterval(patient.lastSubmitted, false)}</div>
            </HtmlTooltip>
          ) : (
            formatDateWithInterval(patient.lastSubmitted, false)
          )}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_lastsent1}`}>
          {patient.lastRecallDates &&
            patient.lastRecallDates[0] &&
            formatDateWithInterval(patient.lastRecallDates[0], false)}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_lastsent2}`}>
          {patient.lastRecallDates &&
            patient.lastRecallDates[1] &&
            formatDateWithInterval(patient.lastRecallDates[1], false)}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${tabstyles.tableCell_lastsent3}`}>
          {patient.lastRecallDates &&
            patient.lastRecallDates[2] &&
            formatDateWithInterval(patient.lastRecallDates[2], false)}
        </TableCell>
      </TableRow>
      <TableRow key={`dup-${index}`} className={`${!isDesktop ? styles.hideForPrinting : styles.hideDisplay}`}>
        <Card sx={{ marginBottom: "20px" }}>
          <CardContent className={styles.mobileCard}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                backgroundColor: theme.colorSchemes.light.palette.primary.light,
              }}
            >
              <Box>
                <Typography variant="h6">{titleCase(patient.name.toLowerCase())}</Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body2">DOB</Typography>
                  <Typography variant="body2">{properDob(patient.dob)}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1px",
                padding: "10px",
                backgroundColor: theme.colorSchemes.light.palette.primary.light,
              }}
            >
              <Typography variant="body2">Last HbA1c Request</Typography>
              <Typography variant="body2">{formatDateWithInterval(patient.lastResult, false)}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1px",
                padding: "10px",
                backgroundColor: theme.colorSchemes.light.palette.primary.light,
              }}
            >
              <Typography variant="body2">Test(s) Overdue</Typography>
              <Typography variant="body2">{properPanels(patient.testsOverdue)}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                marginTop: "1px",
                backgroundColor: theme.colorSchemes.light.palette.primary.light,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body2">Last recall sent</Typography>
                <Typography variant="body2">
                  {patient.lastRecallDates &&
                    patient.lastRecallDates[2] &&
                    formatDateWithInterval(patient.lastRecallDates[2], false)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <ActionCheckbox checked={recallCheck} onChecked={recallChecked} title="Send Recall Letter" />
                <ActionCheckbox checked={requestFormCheck} onChecked={requestFormChecked} title="Send Request Form" />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </TableRow>
    </>
  );
};

const CPDdiabetesTab3: React.FC = () => {
  const { setError, isDesktop } = useContext(AppContext);
  const [typing, setTyping] = useState(false);
  const [returned, setReturned] = useState(false);
  const [clearVisible, setClearVisible] = useState(false);
  const [allRecall, setAllRecall] = useState(false);
  const [allRequestForms, setAllRequestForms] = useState(false);
  const [providers] = useState(getCpdProviderValues());
  const [patients, setPatients] = useState<Hba1cDiabeticPatient[]>();
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [start, setStart] = useState(1);
  const [searchPatientName, setSearchPatientName] = useState("");
  const [overdueOnly, setOverdueOnly] = useState(true);
  const [sendPatientsEnabled, setSendPatientsEnabled] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [usePaginator] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const { data, loading, fetchMore, error } = useQuery<GetHba1cPatientsReport, GetHba1cPatientsReportVariables>(
    getHba1cPatientsReport,
    {
      variables: {
        providers: providers,
        pageSize: pageSize,
        page: start,
        searchPatientName: searchPatientName,
        overdueOnly: overdueOnly,
      },
      fetchPolicy: "network-only",
      skip: !providers || providers.length === 0 || pageSize < 1,
      // context: {
      //   fetchOptions: {
      //     get signal() {
      //       if (JSON.stringify(isNewSearchObj.current) != JSON.stringify(searchVariables)) {
      //         isNewSearchObj.current = searchVariables;
      //         connectionRef.current.push(new AbortController());
      //         if (connectionRef.current.length > 0) {
      //           for (let i = 0; i < connectionRef.current.length - 1; i++) {
      //             connectionRef.current[i].abort();
      //             connectionRef.current.splice(i, 1);
      //           }
      //         }
      //         return connectionRef.current[connectionRef.current.length - 1].signal;
      //       }
      //     },
      //   },
      // },
    },
  );

  const [sendToDiabeticPatients, { loading: loadingSend, data: dataSend, error: errorSend }] = useMutation<
    SendToDiabeticPatients,
    SendToDiabeticPatientsVariables
  >(SendToDiabeticPatientsMutation);

  useEffect(() => {
    if (data && data.getHba1cPatientsReport) {
      setError("");
      if (!usePaginator && patients && patients.length > 0) {
        const newpatients = patients.concat(
          data.getHba1cPatientsReport.records.map((e) => {
            return { ...e, recall: false, requestForm: false };
          }),
        );
        setPatients(newpatients);
        checkAllRecall(newpatients);
        checkAllRequestForm(newpatients);
        checkSendPatients(newpatients);
      } else {
        setPatients(
          data.getHba1cPatientsReport.records.map((e) => {
            return { ...e, recall: false, requestForm: false };
          }),
        );
      }
      if (data.getHba1cPatientsReport.records.length === pageSize) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }
      setTotalCount(data.getHba1cPatientsReport.totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (
      patients &&
      dataSend &&
      dataSend.sendToDiabeticPatients &&
      dataSend.sendToDiabeticPatients.results &&
      dataSend.sendToDiabeticPatients.results.length
    ) {
      const newpatients = [...patients];
      dataSend.sendToDiabeticPatients.results.forEach((res) => {
        const pat = newpatients.find((pat) => {
          return pat.id === res.id;
        });

        if (pat) {
          pat.recall = false;
          pat.requestForm = false;
          pat.lastSubmitted = res.dateSent;
          pat.submissionAllowed = false;
          if (pat.lastRecallDates) {
            pat.lastRecallDates = [...pat.lastRecallDates.reverse()];
            pat.lastRecallDates.push(res.dateSent);
            pat.lastRecallDates = pat.lastRecallDates.slice(-3).reverse();
            pat.recallsExceeded = pat.lastRecallDates.length >= 3;
          } else {
            pat.lastRecallDates = [res.dateSent];
          }
        }
      });
      setPatients(newpatients);
      checkAllRecall(newpatients);
      checkAllRequestForm(newpatients);
      checkSendPatients(newpatients);
    }
  }, [dataSend]);

  const loadMoreData = async () => {
    const nextPage = start + 1;
    setStart(nextPage);
    setError("");

    try {
      await fetchMore({
        variables: {
          start: nextPage,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const checkAllRecall = (testpatients: Hba1cDiabeticPatient[] | undefined) => {
    setAllRecall(testpatients?.every((patient) => patient.recall) || false);
  };

  const checkAllRequestForm = (testpatients: Hba1cDiabeticPatient[] | undefined) => {
    setAllRequestForms(testpatients?.every((patient) => patient.requestForm) || false);
  };

  const checkSendPatients = (testpatients: Hba1cDiabeticPatient[] | undefined) => {
    setSendPatientsEnabled(testpatients?.some((patient) => patient.recall || patient.requestForm) || false);
  };

  const setChecked = (index: number, isRecall: boolean) => {
    if (patients) {
      const newpatients = patients;
      if (isRecall) {
        newpatients[index].recall = !newpatients[index].recall;
      } else {
        newpatients[index].requestForm = !newpatients[index].requestForm;
      }
      setPatients(newpatients);
      if (isRecall) {
        checkAllRecall(newpatients);
      } else {
        checkAllRequestForm(newpatients);
      }
      checkSendPatients(newpatients);
    }
    return true;
  };

  const patientRemoved = (index: number) => {
    if (patients) {
      const newpatients = [...patients];
      newpatients.splice(index, 1);
      setPatients(newpatients);
      checkAllRecall(newpatients);
      checkAllRequestForm(newpatients);
      checkSendPatients(newpatients);
    }
  };

  const recallAllChecked = () => {
    setAllRecall(!allRecall);
    if (patients) {
      const newpatients = [...patients];
      newpatients.forEach((patient) => (patient.recall = !allRecall));
      setPatients(newpatients);
      checkSendPatients(newpatients);
    }
  };

  const referralFormAllChecked = () => {
    setAllRequestForms(!allRequestForms);
    if (patients) {
      const newpatients = [...patients];
      newpatients.forEach((patient) => (patient.requestForm = !allRequestForms));
      setPatients(newpatients);
      checkSendPatients(newpatients);
    }
  };

  type HeaderWithCheckboxProps = {
    checkboxLabel: string;
    checked: boolean;
    onCheckbox: () => void;
    sampleFormUrl: string;
    sampleFormLinkLabel: string;
  };
  const HeaderWithSampleButton = (props: HeaderWithCheckboxProps) => {
    return (
      <div className={styles.hideForPrinting}>
        <Box mb={1}>
          <Link component={"a"} className={styles.exampleButton} href={props.sampleFormUrl} target="_blank">
            <DescriptionOutlinedIcon fontSize="small" /> &nbsp; {props.sampleFormLinkLabel}
          </Link>
        </Box>
      </div>
    );
  };
  const HeaderWithCheckbox = (props: HeaderWithCheckboxProps) => {
    return (
      <div className={styles.hideForPrinting}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.checked}
              name="allRecall"
              color="primary"
              onClick={props.onCheckbox}
              disabled={loading || patients?.length === 0}
            />
          }
          label={props.checkboxLabel}
          sx={{
            marginLeft: "0px",
            "& .MuiTypography-root": {
              font: "inherit",
              lineHeight: "inherit",
            },
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      id: "remove",
      label: "Remove inactive / deceased patients",
      style: tabstyles.tableCell_remove,
    },
    {
      id: "overdue",
      label: "",
      style: tabstyles.tableCell_icon,
    },
    {
      id: "name",
      label: "Name",
      style: tabstyles.tableCell_name,
    },
    {
      id: "dob",
      label: "DOB",
      style: tabstyles.tableCell_dob,
    },
    {
      id: "lastresult",
      label: "Last HbA1c Request",
      style: tabstyles.tableCell_lastresult,
    },
    {
      id: "testsOverdue",
      label: (
        <>
          {"Additional Test(s) Due"} <Tooltips text="Displayed test(s) conducted over 12 months ago" />
        </>
      ),
      style: tabstyles.tableCell_testsoverdue,
    },
    {
      id: "recall",
      labelTop: (
        <HeaderWithSampleButton
          checkboxLabel="Send Recall Letter"
          checked={allRecall}
          onCheckbox={recallAllChecked}
          sampleFormLinkLabel="Example recall letter"
          sampleFormUrl={Constants.DIABETES_RECALL_LETTER_EXAMPLE}
        />
      ),
      label: (
        <HeaderWithCheckbox
          checkboxLabel="Send Recall Letter"
          checked={allRecall}
          onCheckbox={recallAllChecked}
          sampleFormLinkLabel="Example recall letter"
          sampleFormUrl={Constants.DIABETES_RECALL_LETTER_EXAMPLE}
        />
      ),
      style: tabstyles.tableCell_recall,
    },
    {
      id: "requestForm",
      labelTop: (
        <HeaderWithSampleButton
          checkboxLabel="Send Request Form"
          checked={allRequestForms}
          onCheckbox={referralFormAllChecked}
          sampleFormLinkLabel="Example referral form"
          sampleFormUrl={Constants.DIABETES_REFERRAL_FORM_EXAMPLE}
        />
      ),
      label: (
        <HeaderWithCheckbox
          checkboxLabel="Send Request Form"
          checked={allRequestForms}
          onCheckbox={referralFormAllChecked}
          sampleFormLinkLabel="Example referral form"
          sampleFormUrl={Constants.DIABETES_REFERRAL_FORM_EXAMPLE}
        />
      ),
      style: tabstyles.tableCell_requestform,
    },
    {
      id: "lastSent",
      label: "Last Recall Sent",
      style: tabstyles.tableCell_lastsent,
    },
    {
      id: "lastSent1",
      label: "Recall 1 Sent",
      style: tabstyles.tableCell_lastsent1,
    },
    {
      id: "lastSent2",
      label: "Recall 2 Sent",
      style: tabstyles.tableCell_lastsent2,
    },
    {
      id: "lastSent3",
      label: "Recall 3 Sent",
      style: tabstyles.tableCell_lastsent3,
    },
  ];

  const rowsPerPage = [
    {
      value: 50,
      label: "50",
    },
    {
      value: 100,
      label: "100",
    },
    {
      value: 200,
      label: "200",
    },
    {
      value: 99999,
      label: "All",
    },
  ];

  const changeDisplay = (values: CPDdiabetesForm) => {
    setClearVisible((values.searchtext && values.searchtext.length > 0) || values.overdueOnly);
    setTyping(true);
    setReturned(false);
  };

  const hideDisplay = () => {
    setTyping(false);
  };

  const initialValues: CPDdiabetesForm = {
    searchtext: searchPatientName,
    overdueOnly: overdueOnly,
  };

  const submit = (values: CPDdiabetesForm) => {
    setPatients([]);
    setStart(1);
    setSearchPatientName(values.searchtext);
    setOverdueOnly(values.overdueOnly);
    setAllRecall(false);
    setAllRequestForms(false);
    setSendPatientsEnabled(false);
    checkClearVisible(values);
  };

  const onReset = () => {
    setPatients([]);
    setStart(1);
    setSearchPatientName("");
    setOverdueOnly(true);
    setClearVisible(false);
    setAllRecall(false);
    setAllRequestForms(false);
    setSendPatientsEnabled(false);
  };

  const checkClearVisible = (values: CPDdiabetesForm) => {
    setClearVisible((values.searchtext && values.searchtext.length > 0) || values.overdueOnly);
  };

  const sendToPatients = () => {
    if (patients) {
      sendToDiabeticPatients({
        variables: {
          patientRequests: patients
            ?.filter((p) => (p.recall || p.requestForm) && !p.recallsExceeded && p.submissionAllowed)
            .map((p) => {
              return {
                id: p.id,
                recall: p.recall,
                requestForm: p.requestForm,
                overdues: p.testsOverdue?.filter((o) => o.isOverdue).map((m) => m.test),
              };
            }),
        },
      });
    }
  };

  const PatientRows = () => {
    return (
      <TableBody>
        {patients?.map((patient, index) => (
          <PatientRow
            key={index}
            patient={patient}
            index={index}
            setChecked={setChecked}
            patientRemoved={patientRemoved}
          />
        ))}
      </TableBody>
    );
  };

  const printChart = () => {
    window.print();
  };

  if (error || errorSend) {
    return <ErrorPopover />;
  }

  return (
    <Box className={styles.root}>
      <Formik initialValues={initialValues} onSubmit={submit} onReset={onReset}>
        {({ values, handleSubmit, handleChange, handleReset }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              style={{ marginBottom: "10px", marginTop: "10px" }}
              className={styles.hideForPrinting}
            >
              <Grid item xs={12} sm={4} md={4} xl={2}>
                <div>
                  <TextField
                    placeholder="Patient name"
                    id="searchtext"
                    name="searchtext"
                    variant="outlined"
                    className={isDesktop ? styles.mainInputs : styles.mainInputsMobile}
                    onChange={handleChange}
                    onBlur={() => {
                      hideDisplay();
                    }}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        //Enter Key
                        setReturned(true);
                        handleSubmit();
                        e.preventDefault();
                      } else {
                        changeDisplay(values);
                      }
                    }}
                    size="medium"
                    value={values.searchtext}
                    InputProps={{
                      className: styles.patientNameInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {typing ? (
                            <KeyboardReturnIcon />
                          ) : returned ? (
                            <ClearIcon
                              className={styles.clearButtonPatientSearch}
                              onClick={() => {
                                values.searchtext = "";
                                handleSubmit();
                              }}
                            />
                          ) : (
                            <ClearIcon className={styles.clearIconPatientSearch} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={5} md={5} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      id="overdueOnly"
                      name="overdueOnly"
                      checked={values.overdueOnly}
                      onChange={() => {
                        values.overdueOnly = !values.overdueOnly;
                        handleSubmit();
                      }}
                    />
                  }
                  className={`${styles.overdueOnlyLabel}`}
                  label="Display Patients Due for HbA1c tests"
                  style={{ marginLeft: isDesktop ? "10px" : "0px" }}
                />
              </Grid>

              <Grid item xs={4} sm={3} md={3} xl={2}>
                {clearVisible && (
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    style={{
                      marginLeft: "8px",
                      backgroundColor: theme.colorSchemes.light.palette.secondary.light,
                      borderRadius: "28px",
                      minWidth: "130px",
                    }}
                  >
                    Clear Search
                  </Button>
                )}
              </Grid>

              <Grid item md />
              <Grid item xs={12} sm={3} md={4} xl={2}>
                <HeaderWithSampleButton
                  checkboxLabel="Send Recall Letter"
                  checked={allRecall}
                  onCheckbox={recallAllChecked}
                  sampleFormLinkLabel="Example recall letter"
                  sampleFormUrl={Constants.DIABETES_RECALL_LETTER_EXAMPLE}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={4} xl={2}>
                <HeaderWithSampleButton
                  checkboxLabel="Send Request Form"
                  checked={allRequestForms}
                  onCheckbox={referralFormAllChecked}
                  sampleFormLinkLabel="Example letter with request form"
                  sampleFormUrl={Constants.DIABETES_REFERRAL_FORM_EXAMPLE}
                />
              </Grid>
              <Grid item xs={9} sm={5} md={3} xl={1}>
                <Button
                  color="secondary"
                  className={styles.sentHba1cPatientsButton}
                  size="small"
                  disabled={loadingSend || !sendPatientsEnabled}
                  onClick={sendToPatients}
                >
                  {loadingSend ? (
                    <CircularProgress size={16} />
                  ) : (
                    <>
                      <MailOutlineIcon /> &nbsp;Send to patients
                    </>
                  )}
                </Button>
              </Grid>
              <Grid item xs={3} sm={1} md={1} xl={1} display="flex" justifyContent="flex-end">
                <PrintMenuIcon title="Print" onClick={printChart} />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: "fixed" }}>
          <TableHead className={!isDesktop ? styles.displayForPrintingOnly : ""}>
            {/*isDesktop && (
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell key={column.id} className={`${styles.tableHeadCellWhite} ${column.style}`}>
                      {column.id == "recall" || column.id == "requestForm" ? column.labelTop : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
              )*/}
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell key={column.id} className={`${styles.tableHeadCellWhite} ${column.style}`}>
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {patients && patients?.length > 0 && <PatientRows />}
          {!loading && patients?.length == 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8} align={"center"}>
                  <Typography variant="h6">No records found</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter>
            {!usePaginator && loading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <Loading />
                </TableCell>
              </TableRow>
            )}
            {!usePaginator && showLoadMore && !loading && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={`${styles.loadMoreButton} ${styles.hideForPrinting}`}
                    onClick={loadMoreData}
                    endIcon={<ExpandMoreIcon />}
                  >
                    Load more Patients
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {usePaginator && loading && <Loading />}
      {usePaginator && !loading && (
        <TablePagination
          className={styles.hideForPrinting}
          rowsPerPageOptions={rowsPerPage}
          component="div"
          count={totalCount}
          rowsPerPage={pageSize}
          page={start - 1}
          onPageChange={(_, newPage) => setStart(newPage + 1)}
          onRowsPerPageChange={(event) => {
            setPageSize(parseInt(event.target.value, 10));
            setStart(1);
          }}
        />
      )}
    </Box>
  );
};

export default CPDdiabetesTab3;
