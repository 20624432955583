import React, { useContext } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles, withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { Box, IconButton, Menu, MenuItem, Theme, Typography } from "@mui/material";
import theme from "../../theme";
import AppContext from "../../../context/AppContext";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RefreshIcon from "@mui/icons-material/Refresh";
import classes from "../../CumulativeGraph/CumulativeGraph.module.css";
import authService from "../../../services/authService";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles(() => ({
  notificationsTitle: {
    fontWeight: "bold",
    fontSize: "0.8125rem",
  },
  announcement: {
    backgroundColor: theme.colorSchemes.light.palette.primary.light,
    borderRadius: "5px",
    padding: "5px",
    width: "250px",
    whiteSpace: "break-spaces",
    cursor: "default",
  },
  announcementText: {
    fontSize: "0.8rem !important",
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
  withoutHover: {
    backgroundColor: "transparent !important",
    cursor: "default !important",
  },
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

interface TooltipProps {
  title?: string;
  text: string;
  buttonColor?: string;
}

const TextInfo = (info: any) => {
  switch (info.info) {
    case "ProviderInfo":
      return (
        <>
          {"If you cannot see your provider number contact us using the 'Give us Feedback' form via your "}
          <MenuIcon fontSize="small" />
          {" account menu."}
        </>
      );
    case "PreviewMode":
      return (
        <>
          {
            "Preview mode allows the user to preview the result by hovering over an individual test on the eResults homepage. This function is only available on the desktop version of eResults."
          }
        </>
      );
    case "NewTab":
      return <>{"Open New tab will allows the user to open results in new tab when they click on each episode."}</>;
    case "EnrollMFA":
      return <>{"Enable your Multi Factor Authentication."}</>;
    case "AtomicGraph":
      return (
        <span style={{ fontSize: "14px" }}>
          How to use the zoom functions:
          <br />- click on the <ZoomInIcon fontSize="inherit" /> button to zoom in
          <br />- click on the <ZoomOutIcon fontSize="inherit" /> button to zoom out
          <br />- point your mouse cursor anywhere within the chart and;
          <br />- scroll up the mouse wheel to zoom in or,
          <br />- scroll down the mouse wheel to zoom out
          <br />- while zoomed in, click and drag the mouse left/right to move
          <br />- click on the <RefreshIcon className={classes.refreshIcon} fontSize="inherit" /> button to reset
        </span>
      );
    default:
      return <>{info.info}</>;
  }
};
const Tooltips: React.FC<TooltipProps> = (props) => {
  const classes = useStyles();
  const isDesktop = useContext(AppContext).isDesktop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [buttonColor, setButtonColor] = React.useState(props.buttonColor);
  const mobility = authService.isMobility();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setButtonColor("#FFF");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonIconStyle = {
    cursor: "pointer",
    fill: props.buttonColor ? (open ? buttonColor : props.buttonColor) : theme.colorSchemes.light.palette.primary.dark,
  };

  return mobility || !isDesktop ? (
    <>
      <Tooltip title={props.title} open={true}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <InfoIcon style={commonIconStyle} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        id="tooltip-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem style={{ pointerEvents: "none" }} className={`${classes.notificationsTitle} ${classes.withoutHover}`}>
          <Box className={classes.announcement}>
            <Typography className={classes.announcementText}>
              <TextInfo info={props.text} />
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <HtmlTooltip color="action" placement="right" style={{ paddingTop: "5px" }} title={<TextInfo info={props.text} />}>
      <InfoIcon style={commonIconStyle} fontSize="small" />
    </HtmlTooltip>
  );
};

export default Tooltips;
