import { gql } from "@apollo/client";

export default gql`
  query GetPrintableCombineAtomic($patientId: Int!, $labnoDigitOnly: String, $ids: [Float!]) {
    patient(id: $patientId) {
      fullnameTitleCase
      dob
      sex
      mobile
      title
      firstname
      surname
      fullAddress
      street
      city
      state
      postcode
      fullname
      homePhone
    }
    combineAtomic(patientId: $patientId, labNumber: $labnoDigitOnly, panelIds: $ids) {
      reportId
      reportDateCollected
      reportDateCreated
      reportTestName
      reportLabNumberDigitOnly
      reportUrno
      lab {
        address
        suburb
        phone
      }
      atomicData {
        name
        value
        measurement
        range
        loinc
        abnormal
      }
    }
  }
`;
