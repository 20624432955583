import { gql } from "@apollo/client";

export default gql`
  mutation UpdateAtomicStatus($input: UpdateAtomicStatusArg!) {
    updateAtomicStatus(input: $input) {
      atomicId
      csStatus
      csUserId
      csDatetime
      approval
      approverUserId
      approverDatetime
      comment
      csName
      noActionRequired
      actionRequired
      dCF
    }
  }
`;
