import { gql } from "@apollo/client";

export default gql`
  query GetOrdersHistory(
    $providers: [String!]
    $providerNo: String
    $firstname: String
    $surname: String
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $startDob: DateTimeISO
    $endDob: DateTimeISO
    $start: Float
    $pageSize: Float
  ) {
    ordersHistory(
      providers: $providers
      providerNo: $providerNo
      firstname: $firstname
      surname: $surname
      startDate: $startDate
      endDate: $endDate
      startDob: $startDob
      endDob: $endDob
      start: $start
      pageSize: $pageSize
    ) {
      id
      eOrderId
      dateReceived
      patientFirstname
      patientSurname
      patientId
      dob
      tests
    }
  }
`;
