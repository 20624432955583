import { gql } from "@apollo/client";
export default gql`
  query GetPseudonyms($searchTerm: String!) {
    Pseudonyms(searchTerm: $searchTerm) {
      pseudonyms {
        test_name
        panel_code
        report_paneln
        report_panelid
        all_panels
      }
    }
  }
`;
