const exportObj = {
  // When enabled, this will make real calls to 'mark as read' for reports
  ENABLE_MARK_AS_VIEWED: true,

  // Show debug output for when 'mark as read'. Can act as a dry-run if
  // ENABLE_MARK_AS_VIEWED is false
  DEBUG_MARK_AS_VIEWED: false,
};

export default exportObj;
