import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import theme from "../theme";
import authService from "../../services/authService";
import { useQuery } from "@apollo/client";
import Loading from "../Loading/Loading";
import styles from "./CPDprograms.module.css";
import { useParams } from "react-router-dom";
import {
  GetCytologyPatientDemographic,
  GetCytologyPatientDemographic_getCytologyPatientDemographic,
} from "./types/GetCytologyPatientDemographic";
import GetCytologyPatientDemographicQuery from "./GetCytologyPatientDemographicQuery";
import AppContext from "../../context/AppContext";
import { NO_DB_CONNECTION } from "../../constants";
import { cloneDeep, uniqBy } from "lodash";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

interface PathParams {
  id: string;
}

type ChartData = {
  label: string;
  group: string;
  value: number;
};

export type CpdCytologyTabProps = {
  chart1: string;
  chart2: string;
  isLoading: (value: boolean) => void;
};

const CPDcytologyTab1: React.FC<CpdCytologyTabProps> = (props) => {
  const chartRef = useRef<any>(null);
  const providers = authService.getCpdProviderValues();
  const params: PathParams = useParams() as PathParams;
  const setError = useContext(AppContext).setError;

  const [patientDemographic, SetPatientDemographic] = useState<
    GetCytologyPatientDemographic_getCytologyPatientDemographic[]
  >([]);

  const { data, loading, error } = useQuery<GetCytologyPatientDemographic>(GetCytologyPatientDemographicQuery, {
    variables: {
      id: parseInt(params.id),
      providers: providers,
    },
  });

  useEffect(() => {
    if (data) {
      SetPatientDemographic(data?.getCytologyPatientDemographic);
      setError("");
    }

    props.isLoading(loading);
  }, [data, loading]);

  const non1618Data: ChartData[] = [];
  const hpv1618Data: ChartData[] = [];

  patientDemographic.map((d) => {
    non1618Data.push({
      label: d.patientAgeGroup,
      group: d.doctorCode,
      value: d.non1618,
    });

    hpv1618Data.push({
      label: d.patientAgeGroup,
      group: d.doctorCode,
      value: d.hpv1618,
    });
  });

  non1618Data.sort((a, b) => {
    if (a.label === b.label) {
      return a.group > b.group ? -1 : 1;
    } else {
      return a.label < b.label ? -1 : 1;
    }
  });

  hpv1618Data.sort((a, b) => {
    if (a.label === b.label) {
      return a.group > b.group ? -1 : 1;
    } else {
      return a.label < b.label ? -1 : 1;
    }
  });

  const non1618GraphData = {
    labels: uniqBy(non1618Data, (d) => d.label).map((d) => d.label.toLocaleUpperCase()),
    datasets: [
      {
        axis: "y",
        label: "You %",
        data: non1618Data.filter((d) => d.group === "You").map((d) => d.value * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.main,
        color: "white",
      },
      {
        axis: "y",
        label: "National %",
        data: non1618Data.filter((d) => d.group === "National").map((d) => d.value * 100),
        backgroundColor: theme.colorSchemes.light.palette.primary.dark,
        color: "white",
      },
    ],
  };

  const hpv1618GraphData = cloneDeep(non1618GraphData);
  hpv1618GraphData.labels = uniqBy(hpv1618Data, (d) => d.label).map((d) => d.label.toLocaleUpperCase());
  hpv1618GraphData.datasets[0].data = hpv1618Data.filter((d) => d.group === "You").map((d) => d.value * 100);
  hpv1618GraphData.datasets[1].data = hpv1618Data.filter((d) => d.group === "National").map((d) => d.value * 100);

  const nonOptions = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value: number) {
          const theValue = value !== 0 ? (value === 100 ? value : value.toFixed(1)) : "";
          return theValue + "%";
        },
        color: "#ffffff",
        font: {
          weight: "bold",
        } as const,
      },
      title: {
        display: true,
        text: "HPV Positivity Rate  & Patient Age NON 16/18",
        font: {
          size: 14,
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
      },
    },
    tooltip: {
      filter: (item: any) => {
        if (item.dataset.label.includes("hide")) {
          return false;
        }
        return true;
      },
      enabled: true,
    },
    scales: {
      x: {
        min: 0,
        max: 100,
      },
    },
  };

  const hpvOptions = cloneDeep(nonOptions);
  hpvOptions.plugins.title.text = "HPV Positivity Rate & Patient Age 16/18";

  if (error) {
    setError(NO_DB_CONNECTION);
  }

  if (loading) return <Loading />;

  return (
    <Box mt={[3, 12]} ml={[2, 4]} mr={[2, 4]}>
      <Box className={styles.cytoTabPageHeader}>CST Patient Demographics</Box>
      <Grid container spacing={3} justifyContent={"center"} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={6}>
          <Bar id={props.chart1} ref={chartRef} width={"100%"} options={nonOptions} data={non1618GraphData} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Bar id={props.chart2} ref={chartRef} width={"100%"} options={hpvOptions} data={hpv1618GraphData} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            The graphs do not include any patients under 25 years of age although results for these patients are
            included in the other areas of this report.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDcytologyTab1;
