import { gql } from "@apollo/client";

export default gql`
  query getQueryAnnouncement {
    announcement {
      announce {
        subject
        content
        display_start_date
        display_end_date
        post_date
      }
    }
  }
`;
