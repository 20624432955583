import { gql } from "@apollo/client";
export default gql`
  query GetEpisodeReports(
    $patientId: Int
    $providers: [String!]
    $labnoDigitOnly: String
    $includePdf: Boolean
    $ids: [Int!]
    $allPatients: Boolean
    $showRemovedPanels: Boolean
    $sortBy: String
  ) {
    episodereports(
      patientId: $patientId
      providers: $providers
      labnoDigitOnly: $labnoDigitOnly
      includePdf: $includePdf
      ids: $ids
      allPatients: $allPatients
      showRemovedPanels: $showRemovedPanels
      sortBy: $sortBy
    ) {
      reports {
        reportid
        labnumber
        labid
        dateCollected
        dateCreated
        dateReferred
        dateViewed
        hasAbnormalStatus
        interimStatus
        reportingProviderNo
        referredProviderNo
        referredFirstname
        referredSurname
        testcode
        testname
        report
        cumulative
        panelId
        providerNo
        approvalStatus
        ccdoctors {
          provider
          firstname
          surname
          title
          fullnameTitleCase
        }
        urno
        pdf
        lab {
          address
          suburb
          phone
          fullAddressTitleCase
        }
        pdfExist
        pdfType
        panelDepartmentCode
        urgentStatus
      }
      histoImages {
        file
        size
        dateCreated
        thumbnailBase64data
        thumbnailMimetype
      }
    }
  }
`;
