import { gql } from "@apollo/client";
export default gql`
  query GetNotificationCount {
    getNotificationCount {
      total
      urgent
      abnormal
    }
  }
`;
