import { gql } from "@apollo/client";

export default gql`
  query GetPDFReport($reporttype: String!, $labnumber: String!) {
    pdfreport(reporttype: $reporttype, labnumber: $labnumber) {
      reportpdf
      reportsize
    }
  }
`;
