import { gql } from "@apollo/client";
export default gql`
  query GetUsersEmailConfig($userid: Float!) {
    getUsersEmailConfig(userid: $userid) {
      config {
        userid
        notificationTypeId
        duration
        pendingEmail
      }
    }
  }
`;
