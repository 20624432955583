export const validateDvaCode = (dvaCode: string): boolean => {
  if (!dvaCode) return false;

  /**
   * https://stackoverflow.com/questions/64903940/validate-australian-dva-numbers-which-may-have-variable-length
   * String length should be 8 or 9
   * First char should be N, V, Q, W, S or T
   * The next part should be letters or space and can have up to 3 characters
   * Next part should be number and can have up to 6 number
   * If the string length is 9 then last char is a letter, if 8 then it must be a number
   */
  if (dvaCode.length === 9) {
    const matches = dvaCode.match(/^[NVQWST][A-Z\s]{1,3}[0-9]{1,6}[A-Z]$/);
    return !!matches;
  } else if (dvaCode.length === 8) {
    const matches = dvaCode.match(/^[NVQWST][A-Z\s]{1,3}[0-9]{1,6}$/);
    return !!matches;
  }
  return false;
};
