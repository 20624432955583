import React, { useContext } from "react";
import { Card, CardContent, Typography, Grid, Button, Box, AppBar, Link } from "@mui/material";
import MainNavigation from "../MainNavigation/MainNavigation";
import CpdStyledHeader from "./CpdStyledHeader";
import { makeStyles } from "@mui/styles";
import cpdDiabetes from "../../assets/cpd-diabetes.png";
import cpdSkin from "../../assets/cpd-skin.png";
import cpdCytology from "../../assets/cpd-cytology.png";
import theme from "../theme";
import authService from "../../services/authService";
import { useHistory } from "react-router-dom";
import { getCpdProgram, getCpdProgram_getCpdProgram } from "./types/getCpdProgram";
import { useQuery } from "@apollo/client";
import GetCpdProgramQuery from "./GetCpdProgramQuery";
import { useEffect, useState } from "react";
import styles from "./CPDprograms.module.css";
import Loading from "../Loading/Loading";
import AppContext from "../../context/AppContext";

export type CPDprogramsProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontWeight: "bold",
  },
  header2: {
    color: theme.colorSchemes.light.palette.primary.main,
  },
  welcomeLogo: {
    width: "300px",
  },
  pointer: {
    cursor: "pointer",
  },
  container: {
    position: "relative",
  },
  shape: {
    borderStyle: "solid",
    msTransform: "rotate(360deg)",
    oTransform: "rotate(360deg)",
    webkitTransform: "rotate(360deg)",
    transform: "rotate(360deg)",
    borderColor: "transparent " + theme.colorSchemes.light.palette.primary.dark + " transparent transparent",
    float: "right",
    right: "0px",
    height: "0px",
    width: "0px",
    borderWidth: "0 120px 120px 0",
    position: "absolute",
    display: "flex",
  },
  shapeText: {
    transform: "rotate(45deg)",
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "bold",
    position: "relative",
    right: "-60px",
    top: "5px",
    textAlign: "center",
    margin: "auto",
  },
}));

const CPDprogramsPage: React.FC<CPDprogramsProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const mobility = authService.isMobility();
  const offsetHeight = mobility ? 161 : 138;
  const isDesktop = useContext(AppContext).isDesktop;

  const cpdPanel = authService.getCPDEnrolled();

  const { data, loading } = useQuery<getCpdProgram>(GetCpdProgramQuery);

  const [cpdPrograms, setCpdPrograms] = useState<getCpdProgram_getCpdProgram[]>([]);

  const newsletterUrl = "https://www.clinicallabs.com.au/doctor/gp-services/newsletters/";

  useEffect(() => {
    if (data) {
      setCpdPrograms(data.getCpdProgram);
    }
  });

  const onOpen = (programId: number, mainUrl: string) => {
    let url = "/cpd-programs";

    switch (programId) {
      case 1:
        url = "/cpd-diabetes";
        break;
      case 2:
        url = "/cpd-skin";
        break;
      case 3:
        url = "/cpd-cytology";
        break;
      default:
        window.open(mainUrl);
        return;
    }
    history.push(url);
  };

  return (
    <>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation
          subHeader="SELECT YOUR CPD PROGRAM"
          showBackButton
          mainHeader={CpdStyledHeader()}
          backText="PATIENTS"
          backPath="/patients"
          hideProviders={true}
          isCpd
          displayACL={isDesktop ? true : false}
        />
      </AppBar>
      <div style={{ height: offsetHeight }} />
      <Box mt={5}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container spacing={5} justifyContent={"center"} alignItems="center">
            <Grid item xs={12} md={12} lg={10} xl={8}>
              <Grid container spacing={5} justifyContent={"space-evenly"} alignItems="center">
                {cpdPrograms.map((elem) => (
                  <Grid item xs={12} md={6} lg={4} key={cpdPrograms.indexOf(elem)} style={{ flexBasis: "0px" }}>
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      className={`${classes.header} ${classes.pointer}`}
                      onClick={() =>
                        onOpen(cpdPanel.includes(elem.id) ? elem.id : 0, elem.url || "https://www.clinicallabs.com.au/")
                      }
                    >
                      {elem.short_name}
                    </Typography>
                    <Typography
                      variant="h4"
                      textAlign={"center"}
                      className={`${classes.header2} ${classes.pointer}`}
                      onClick={() =>
                        onOpen(cpdPanel.includes(elem.id) ? elem.id : 0, elem.url || "https://www.clinicallabs.com.au/")
                      }
                    >
                      Evaluation Program
                    </Typography>
                    <Card style={{ marginTop: "10px", borderRadius: "25px 25px 4px 4px" }}>
                      <CardContent
                        style={{ padding: "0px" }}
                        onClick={() =>
                          onOpen(
                            cpdPanel.includes(elem.id) ? elem.id : 0,
                            elem.url || "https://www.clinicallabs.com.au/",
                          )
                        }
                      >
                        <div className={classes.container}>
                          <div className={classes.shape}>
                            <div className={classes.shapeText}>{`${elem.hours} Hours`}</div>
                          </div>
                          <img
                            src={elem.id === 1 ? cpdDiabetes : elem.id == 2 ? cpdSkin : cpdCytology}
                            alt="acl logo"
                            className={`${classes.welcomeLogo} ${classes.pointer}`}
                          />
                        </div>
                        <Box textAlign={"center"} mt={2.5} mb={2.5}>
                          {cpdPanel.includes(elem.id) ? (
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={!elem.enabled}
                              onClick={() =>
                                onOpen(elem.id ? elem.id : 0, elem.url || "https://www.clinicallabs.com.au/")
                              }
                            >
                              Open
                            </Button>
                          ) : (
                            <Button
                              color="warning"
                              variant="contained"
                              disabled={!elem.enabled}
                              target="_blank"
                              href={elem.url || "https://www.clinicallabs.com.au/"}
                            >
                              Register
                            </Button>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} key={"links"} mt={2} justifyContent={"center"} alignItems={"center"}>
              <Grid container justifyContent={"center"} alignItems="center">
                <Grid item xs={8} sm={8} md={12} textAlign={"center"}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Please Note: CPD Programs are eligible for Australian Clinical Labs referrers only
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={5} textAlign={"center"} mt={4}>
                  <Link href={newsletterUrl} target="_blank" style={{ textDecoration: "underline" }}>
                    Need more CPD Hours? Read Clinical Labs&apos; Pathology Focus and log your Educational Activities
                    hours using the RACGP MyCPD app.
                  </Link>
                  <br />
                  <Box mt={4} mb={4}>
                    <Button color="primary" variant="contained" target="_blank" href={newsletterUrl}>
                      View Newsletter Archive
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
export default CPDprogramsPage;
