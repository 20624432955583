import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/styles";
import AppContext from "../../context/AppContext";
import { isTenantVet } from "../../services/tenantService";
import imageAcl from "../../assets/clinicallabs logo.png";
import imageVet from "../../assets/vet-logo.png";
import styles from "../MainNavigation/MainNavigation.module.css";

const StyledLink = styled((props) => <Link {...props} />)({
  "&:active": {
    textDecorationLine: "none",
    textDecorationColor: "inherit",
    textDecorationThickness: "inherit",
  },
});

function CpdStyledHeader(): JSX.Element {
  const isDesktop = useContext(AppContext).isDesktop;

  return (
    <StyledLink to="/cpd-programs">
      {!isDesktop ? (
        <Box display="inline">
          <img
            src={isTenantVet() ? imageVet : imageAcl}
            alt="acl logo"
            className={isTenantVet() ? styles.logo_156px : styles.logo_185px}
          />
        </Box>
      ) : (
        <>
          <Typography display="inline" style={{ fontSize: "30px", color: "#ff8000" }}>
            CPD
          </Typography>{" "}
          <Typography display="inline" style={{ fontSize: "30px", color: "white" }}>
            Program Portal
          </Typography>
        </>
      )}
    </StyledLink>
  );
}

export default CpdStyledHeader;
