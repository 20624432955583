import React, { useContext, useEffect, useRef } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TableFooter,
  Typography,
} from "@mui/material";
import styles from "./CPDprograms.module.css";
import {
  GetCytologyResultsBreakdown,
  GetCytologyResultsBreakdown_getCytologyResultsBreakdown_glandular,
  GetCytologyResultsBreakdown_getCytologyResultsBreakdown_hiGrade,
  GetCytologyResultsBreakdown_getCytologyResultsBreakdown_loGrade,
  GetCytologyResultsBreakdown_getCytologyResultsBreakdown_organisms,
  GetCytologyResultsBreakdown_getCytologyResultsBreakdown_sti,
} from "./types/GetCytologyResultsBreakdown";
import GetCpdCytologyResultsBreakdownQuery from "./GetCpdCytologyResultsBreakdownQuery";
import authService from "../../services/authService";
import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import { useQuery } from "@apollo/client";
import AppContext from "../../context/AppContext";
import { NO_DB_CONNECTION } from "../../constants";
import * as htmlToImage from "html-to-image";

type CytologyResultTableProps = {
  header?: string | JSX.Element;
  notes?: string | JSX.Element;
  loading?: boolean;
  data:
    | GetCytologyResultsBreakdown_getCytologyResultsBreakdown_hiGrade[]
    | GetCytologyResultsBreakdown_getCytologyResultsBreakdown_loGrade[]
    | GetCytologyResultsBreakdown_getCytologyResultsBreakdown_organisms[]
    | GetCytologyResultsBreakdown_getCytologyResultsBreakdown_glandular[]
    | GetCytologyResultsBreakdown_getCytologyResultsBreakdown_sti[]
    | undefined;
};

const CytologyResultTable = (props: CytologyResultTableProps) => {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table" style={{ tableLayout: "fixed" }}>
        {props.header && (
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} className={styles.cytoTableHeader}>
                {props.header}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.loading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Loading />
              </TableCell>
            </TableRow>
          ) : (
            props.data &&
            props.data.map((row) => {
              return (
                <TableRow key={row.heading}>
                  <TableCell>{row.heading}</TableCell>
                  <TableCell>{`${Intl.NumberFormat("en-AU", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(row.pcnt * 100)} %`}</TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>{row.total}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
        {props.notes && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} style={{ border: "none" }}>
                {props.notes}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

interface PathParams {
  id: string;
}

export type CpdCytologyTabProps = {
  chart1: (value: string) => void;
  isLoading: (value: boolean) => void;
  isActioned: boolean;
};

const CPDcytologyTab2: React.FC<CpdCytologyTabProps> = (props) => {
  const providers = authService.getCpdProviderValues();
  const params: PathParams = useParams() as PathParams;
  const setError = useContext(AppContext).setError;
  const domEl = useRef(null);

  const { data, loading, error } = useQuery<GetCytologyResultsBreakdown>(GetCpdCytologyResultsBreakdownQuery, {
    variables: {
      id: parseInt(params.id),
      providers: providers,
    },
  });

  useEffect(() => {
    if (data && props.isActioned) {
      (async () => {
        const dataUrl = await htmlToImage.toPng(domEl.current as unknown as HTMLElement);
        props.chart1(dataUrl);
      })();
      setError("");
    }

    props.isLoading(loading);
  }, [data, loading, props.isActioned]);

  if (error) {
    setError(NO_DB_CONNECTION);
  }

  return (
    <Box mt={[3, 12]} ml={[2, 4]} mr={[2, 4]}>
      <Box className={styles.cytoTabPageHeader}>Results Breakdown</Box>
      <Grid container columnSpacing={3} rowSpacing={1} id="domEl" ref={domEl} width={props.isActioned ? "60%" : "auto"}>
        <Grid item xs={12} mt={2}>
          <Box className={styles.cytoSectionHeader}>Squamous Cell Abnormalities</Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <CytologyResultTable header="High Grade" data={data?.getCytologyResultsBreakdown.hiGrade} loading={loading} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CytologyResultTable header="Low Grade" data={data?.getCytologyResultsBreakdown.loGrade} loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <Typography mt={1} mb={1} color={"#4e5962"}>
            The data represents each sub-category diagnosed as a total of all the cervical abnormalities diagnosed.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Box className={styles.cytoSectionHeader}>Organisms</Box>
          <CytologyResultTable
            data={data?.getCytologyResultsBreakdown.organisms}
            loading={loading}
            notes={
              <>
                <Typography mt={1}>
                  This section represents organisms identified during routine cytology screening.
                </Typography>
                <Typography mt={1}>The percentage figure is calculated as:</Typography>
                <Typography mt={1} pb={1} borderBottom={"solid 1px"} width={"fit-content"}>
                  Total number of cases with organisms identified
                </Typography>
                <Typography mt={1} mb={1}>
                  Number of cases with a specific organism identified
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Box className={styles.cytoSectionHeader}>Glandular Abnormalities</Box>
          <CytologyResultTable
            data={data?.getCytologyResultsBreakdown.glandular}
            loading={loading}
            notes={
              <Typography mt={1} mb={1}>
                This data represents the cervical or endometrial abnormalities diagnosed as a percentage of the total
                glandular abnormalities diagnosed.
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Box className={styles.cytoSectionHeader}>STI</Box>
          <CytologyResultTable
            data={data?.getCytologyResultsBreakdown.sti}
            loading={loading}
            notes={
              <>
                <Typography mt={1}>This section represent results via molecular test methods only.</Typography>
                <Typography mt={1}>The percentage figure is calculated as below:</Typography>
                <Typography mt={1} pb={1} borderBottom={"solid 1px"} width={"fit-content"}>
                  Number of cases positive for STI (Gonorrhoea or Chlamydia)
                </Typography>
                <Typography mt={1}>All cases tested for STI</Typography>
              </>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CPDcytologyTab2;
