import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TableFooter,
  CircularProgress,
  Chip,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import MainNavigation from "../MainNavigation/MainNavigation";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import styles from "./PreviousTestRequests.module.css";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@mui/material";
import { GetOrdersHistory, GetOrdersHistory_ordersHistory } from "./types/GetOrdersHistory";
import GetOrdersHistoryQuery from "./GetOrdersHistoryQuery";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { cloneDeep } from "lodash";
import get from "lodash/get";
import GetOrderPdfQuery from "./GetOrderPdfQuery";
import { GetOrderPdf } from "./types/GetOrderPdf";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate, dateFromNow } from "../../utils/date";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "../theme";
import { isTenantVet } from "../../services/tenantService";
import { b64toBlob } from "../PrintableReports/pdfUtils";
import { downloadHelper } from "../../utils/downloadHelper";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const columns = [
  {
    id: "patientName",
    label: isTenantVet() ? "Owner, Animal/Herd" : "Surname, Given name(s)",
    width: "8%",
    backgroundColor: theme.colorSchemes.light.palette.primary.main,
  },
  {
    id: "dob",
    label: "Birth date",
    width: "6%",
    backgroundColor: theme.colorSchemes.light.palette.primary.main,
  },
  {
    id: "tests",
    label: "Panels",
    width: "28%",
    backgroundColor: theme.colorSchemes.light.palette.primary.main,
  },
  {
    id: "date",
    label: "Received",
    width: "6%",
    backgroundColor: theme.colorSchemes.light.palette.primary.main,
  },
  {
    id: "action",
    label: "Action",
    width: "6%",
    backgroundColor: theme.colorSchemes.light.palette.primary.main,
  },
];

export const PreviousTestRequestsBox: React.FC<any> = () => {
  const hist = useHistory();
  const { setError, isDesktop } = useContext(AppContext);
  const [start, setStart] = useState(1);
  const [ordersHistory, setOrdersHistory] = useState<GetOrdersHistory_ordersHistory[]>([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [filename, setFilename] = useState("");
  const [providerNo, setProviderNo] = useState<any>(null);
  const [firstname, setFirstname] = useState<any>(null);
  const [surname, setSurname] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [startDob, setStartDob] = useState<any>(null);
  const [endDob, setEndDob] = useState<any>(null);
  const [formProviderNo, setFormProviderNo] = useState("0");
  const [formFirstname, setFormFirstname] = useState("");
  const [formSurname, setFormSurname] = useState("");
  const [formStartDate, setFormStartDate] = useState<string | number | Dayjs | Date | null | undefined>(null);
  const [formEndDate, setFormEndDate] = useState<string | number | Dayjs | Date | null | undefined>(null);
  const [formDob, setFormDob] = useState<string | number | Dayjs | Date | null | undefined>(null);
  const [showClear, setShowClear] = useState(false);
  const [providers] = useState<any>(authService.getProviders());
  const pageSize = 50;
  const client = useApolloClient();
  const isMobility = authService.isMobility();
  const {
    data: ordersHistoryData,
    loading: ordersHistoryLoading,
    error: ordersHistoryError,
    fetchMore,
  } = useQuery<GetOrdersHistory>(GetOrdersHistoryQuery, {
    variables: {
      providers: providers,
      providerNo: providerNo,
      firstname: firstname,
      surname: surname,
      startDate: startDate,
      endDate: endDate,
      startDob: startDob,
      endDob: endDob,
      start: start,
      pageSize: pageSize,
    },
    fetchPolicy: "network-only",
  });

  const [getOrderPdf, { data: pdfData, loading: pdfLoading, error: pdfError }] = useLazyQuery<GetOrderPdf>(
    GetOrderPdfQuery,
    {
      variables: { eorderId: "", id: 0 },
      fetchPolicy: "network-only",
    },
  );

  const loadMoreData = async () => {
    const nextPage = start + 1;
    setStart(nextPage);
    setError("");

    try {
      await fetchMore({
        variables: {
          start: nextPage,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleBack = async () => {
    if (authService.getExpired()) {
      authService.clearAll();
      client.resetStore();
      hist.push("/login");
    } else if (get(hist.location, "state.from")) {
      hist.push(get(hist.location, "state.from", ""));
    } else {
      hist.goBack();
    }
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    const mProviderNo = formProviderNo !== "0" ? formProviderNo : null;
    const mFirstname = formFirstname !== "" ? formFirstname : null;
    const mSurname = formSurname !== "" ? formSurname : null;
    const mStartDate = formStartDate ? dayjs(formStartDate.toString()).format("YYYY-MM-DD") + "T00:00:00Z" : null;
    const mEndDate = formEndDate ? dayjs(formEndDate.toString()).format("YYYY-MM-DD") + "T23:59:59Z" : null;
    const mStartDob = formDob ? dayjs(formDob.toString()).format("YYYY-MM-DD") + "T00:00:00Z" : null;
    const mEndDob = formDob ? dayjs(formDob.toString()).format("YYYY-MM-DD") + "T23:59:59Z" : null;

    if (mProviderNo || mFirstname || mSurname || mStartDate || mEndDate || mStartDob || mEndDob) {
      const nextPage = 1;

      if (
        mProviderNo !== providerNo ||
        mFirstname !== firstname ||
        mSurname !== surname ||
        mStartDate !== startDate ||
        mEndDate !== endDate ||
        mStartDob !== startDob ||
        mEndDob !== endDob
      ) {
        setOrdersHistory([]);
      }

      setFilename("");
      setShowLoadMore(false);
      setError("");
      setStart(nextPage);
      setProviderNo(mProviderNo);
      setFirstname(mFirstname);
      setSurname(mSurname);
      setStartDate(mStartDate);
      setEndDate(mEndDate);
      setStartDob(mStartDob);
      setEndDob(mEndDob);
      setShowClear(true);
    }

    return false;
  };

  const handleClear = async () => {
    setOrdersHistory([]);
    setShowLoadMore(false);
    setError("");

    const nextPage = 1;
    setStart(nextPage);
    setProviderNo(null);
    setFirstname(null);
    setSurname(null);
    setStartDate(null);
    setEndDate(null);
    setStartDob(null);
    setEndDob(null);
    setFormProviderNo("0");
    setFormFirstname("");
    setFormSurname("");
    setFormStartDate(null);
    setFormEndDate(null);
    setFormDob(null);
    setShowClear(false);
  };

  useEffect(() => {
    if (ordersHistoryData && ordersHistoryData.ordersHistory.length > 0) {
      const newOrdersHistory = cloneDeep(ordersHistory);
      newOrdersHistory.push(...ordersHistoryData.ordersHistory);

      const uniqueIds: number[] = [];

      const uniqueOrders = newOrdersHistory.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id as number);

        if (!isDuplicate) {
          uniqueIds.push(element.id as number);

          return true;
        }

        return false;
      });

      setOrdersHistory(uniqueOrders);

      if (ordersHistoryData.ordersHistory.length === pageSize) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }
    }
  }, [ordersHistoryData]);

  useEffect(() => {
    if (pdfData && pdfData.getPdf && filename !== "") {
      const blobData = b64toBlob(pdfData.getPdf, "application/pdf");
      downloadHelper(blobData, `${filename}.pdf`);
      setFilename("");
    }
  }, [pdfData, filename]);

  const handlePdf = (eorderId: string, id: number) => {
    getOrderPdf({ variables: { eorderId: eorderId, id: id } });
    setFilename(eorderId);
  };

  const selectProviderNo = (event: SelectChangeEvent<string | undefined>) => {
    if (event.target.value !== undefined) {
      const selectedValue = event.target.value as string;
      setFormProviderNo(selectedValue);
    }
  };

  const handleChangeStartDate = (date: Date | null) => {
    setFormStartDate(date);
  };

  const handleChangeEndDate = (date: Date | null) => {
    setFormEndDate(date);
  };

  const handleChangeDob = (date: Date | null) => {
    setFormDob(date);
  };

  const properName = (phrase: string) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const properDob = (dateString: string | null) => {
    dateString = dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");

    return formatDate(dateString, "DD MMM YYYY", false);
  };

  if (ordersHistoryError || pdfError) {
    return <ErrorPopover />;
  }

  const properPanels = (panels: string) => {
    const tests = panels.split(",").sort();

    return tests.map((item: string, index) => {
      return (
        <Chip
          key={index}
          label={
            <Typography variant="caption" color={"inherit"}>
              {item}
            </Typography>
          }
          size="small"
          className={styles.chipRead}
        />
      );
    });
  };

  const formatDateReceived = (dateString: string | null): string => {
    dateString = dayjs(dateString, "DD/MM/YYYY hh:mm:ss A").format("YYYY-MM-DD HH:mm:ssZ");

    return dayjs(dateString || "") >= dayjs().startOf("day").utc()
      ? dateFromNow(dateString || "-", true)
      : dayjs(dateString || "", "YYYY-MM-DD HH:mm:ssZ") >= dayjs().subtract(1, "days").startOf("day").utc() &&
          dayjs(dateString || "", "YYYY-MM-DD HH:mm:ssZ") < dayjs().startOf("day").utc()
        ? "Yesterday"
        : dayjs(dateString || "").year() !== dayjs().utc().year()
          ? formatDate(dateString || "", "DD MMM  YYYY", false)
          : formatDate(dateString || "", "DD MMM", false);
  };

  return (
    <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
      <Grid item xs={12}>
        <AuthLayout title="" heading="Previous eOrders" maxWidth={"100%"}>
          <Box mt={4}>
            <Box textAlign={"right"} mb={5} mt={-8}>
              <Link color="primary" onClick={handleBack} className={styles.backLink}>
                Back
              </Link>
            </Box>
            <form onSubmit={handleSearch}>
              <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    margin="dense"
                    id="firstname"
                    label="Patient Given name"
                    type="text"
                    fullWidth
                    name="firstname"
                    variant="outlined"
                    value={formFirstname}
                    onChange={(event) => {
                      setFormFirstname(event.target.value);
                    }}
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    margin="dense"
                    id="surname"
                    label="Patient Surname"
                    type="text"
                    fullWidth
                    name="surname"
                    variant="outlined"
                    value={formSurname}
                    onChange={(event) => {
                      setFormSurname(event.target.value);
                    }}
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    id="providerNo"
                    variant="outlined"
                    value={formProviderNo}
                    fullWidth
                    onChange={selectProviderNo}
                    className={styles.searchField}
                    size="medium"
                  >
                    <MenuItem value={"0"}>Provider</MenuItem>
                    {providers.sort().map((item: any) => {
                      return (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    display: { xs: "none", md: "block" },
                  }}
                ></Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Received from"
                      value={formStartDate as Dayjs | null | undefined}
                      onChange={(value: Dayjs | null) => {
                        handleChangeStartDate(value as Date | null);
                      }}
                      slotProps={{
                        textField: {
                          placeholder: "DD/MM/YYYY",
                          variant: "outlined",
                          margin: "normal",
                          className: styles.searchField,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Received up to"
                      value={formEndDate as Dayjs | null | undefined}
                      onChange={(value: Dayjs | null) => {
                        handleChangeEndDate(value as Date | null);
                      }}
                      slotProps={{
                        textField: {
                          placeholder: "DD/MM/YYYY",
                          variant: "outlined",
                          margin: "normal",
                          className: styles.searchField,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      label="Birth date"
                      value={formDob as Dayjs | null | undefined}
                      onChange={(value: Dayjs | null) => {
                        handleChangeDob(value as Date | null);
                      }}
                      slotProps={{
                        textField: {
                          placeholder: "DD/MM/YYYY",
                          variant: "outlined",
                          margin: "normal",
                          className: styles.searchField,
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Button type="submit" variant="contained" color="primary">
                    Search
                  </Button>
                  {showClear && (
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={handleClear}
                      style={{ marginLeft: "8px" }}
                    >
                      Clear
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
            <Paper className={styles.root}>
              <TableContainer className={styles.container}>
                <Table stickyHeader aria-label="sticky table" style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        if (isDesktop == false || isMobility == true) {
                          //change patientName width 18
                          if (column.id == "patientName") {
                            column.width = "18%";
                          }
                          //chagne dob to 11%
                          if (column.id == "dob") {
                            column.width = "12%";
                          }
                          //change date to 10%
                          if (column.id == "tests") {
                            column.width = "50%";
                          }
                          if (column.id == "date") {
                            column.width = "20%";
                          }
                          //skip action
                          if (column.id == "action") {
                            return <></>;
                          }
                        }
                        return (
                          <TableCell
                            key={column.id}
                            style={{
                              width: column.width,
                              backgroundColor: column.backgroundColor,
                              color: "#ffffff",
                              fontWeight: "bold",
                              padding: "16px",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  {ordersHistory && ordersHistory.length > 0 && (
                    <TableBody>
                      {ordersHistory.map((odh: GetOrdersHistory_ordersHistory, index) => {
                        return (
                          <TableRow
                            key={index}
                            onClick={() => {
                              if ((!isDesktop || isMobility) && !pdfLoading) {
                                handlePdf(odh.eOrderId as string, odh.id as number);
                              }
                            }}
                          >
                            {(!isDesktop || isMobility) && pdfLoading && (odh.eOrderId as string) === filename ? (
                              <TableCell colSpan={4} style={{ verticalAlign: "top", padding: "16px" }}>
                                <Loading />
                              </TableCell>
                            ) : (
                              <>
                                <TableCell style={{ verticalAlign: "top", padding: "16px" }}>
                                  {properName(odh.patientSurname + ", " + odh.patientFirstname)}
                                </TableCell>
                                <TableCell style={{ verticalAlign: "top", padding: "16px" }}>
                                  {properDob(odh.dob)}
                                </TableCell>
                                <TableCell style={{ verticalAlign: "top", padding: "16px" }}>
                                  {properPanels(odh.tests as string)}
                                </TableCell>
                                <TableCell style={{ verticalAlign: "top", padding: "16px" }}>
                                  {formatDateReceived(odh.dateReceived)}
                                </TableCell>
                                {isDesktop && !isMobility && (
                                  <TableCell style={{ verticalAlign: "top", padding: "16px" }}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={styles.loadMoreButton}
                                      onClick={() => handlePdf(odh.eOrderId as string, odh.id as number)}
                                      disabled={pdfLoading}
                                      size="small"
                                    >
                                      {pdfLoading && (odh.eOrderId as string) === filename ? (
                                        <CircularProgress color="secondary" size={"1.2rem"} />
                                      ) : (
                                        "View"
                                      )}
                                    </Button>
                                  </TableCell>
                                )}
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                  {!ordersHistoryLoading && ordersHistory && ordersHistory.length == 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={5} align={"center"}>
                          <Typography variant="h6">No records found</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    {ordersHistoryLoading && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}
                    {showLoadMore && !ordersHistoryLoading && (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            className={styles.loadMoreButton}
                            onClick={loadMoreData}
                            endIcon={<ExpandMoreIcon />}
                          >
                            Load more eOrders
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </AuthLayout>
      </Grid>
    </Grid>
  );
};

const PreviousTestRequests: React.FC<any> = () => {
  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <PreviousTestRequestsBox />
    </Fragment>
  );
};

export default PreviousTestRequests;
