import { gql } from "@apollo/client";

export default gql`
  query GetDiabeticMonthlyRollover($providernos: [String!]!) {
    getDiabeticMonthlyRollover(providernos: $providernos) {
      month
      diabeticCount
      stateBenchmark
    }
  }
`;
