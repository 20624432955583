import { gql } from "@apollo/client";

export default gql`
  query GetDoctorData($provider: String!) {
    getDoctorData(provider: $provider) {
      doctorCode
      status
      providerNumber
      specialtyCode
      surname
      givenName
      middleName
      title
      sex
      addressLine1
      addressLine2
      city
      postcode
      practiceTelephone
      emailAddress
      practiceId
      practiceName
    }
  }
`;
