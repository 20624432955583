import React, { useContext } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import theme from "../theme";
import AppContext from "../../context/AppContext";
const useStyles = makeStyles(() => ({
  productLabel: {
    paddingTop: "8px",
    paddingBottom: "8px",
    backgroundColor: theme.colorSchemes.light.palette.primary.main + " !important",
    borderTopRightRadius: "100px",
    borderBottomRightRadius: "100px",
    color: "white",
    paddingLeft: "16px",
    height: "28px",
  },
  closeButton: {
    fontWeight: "bold",
    fontSize: "18px !important",
    color: "#232224",
    padding: "12px !important",
  },
}));

interface DialogBoxTitleProps {
  title: string;
  supplementaryTitle?: string;
  width?: any;
  marginTop?: any;
  marginLeft?: any;
  closeButton?: boolean;
  handleClose?: () => void;
}

const DialogBoxTitle: React.FC<DialogBoxTitleProps> = (props) => {
  const classes = useStyles();
  const isDesktop = useContext(AppContext).isDesktop;
  return (
    <Box display="flex" justifyContent="space-between" align-content="strech" marginTop={props.marginTop}>
      <Box
        className={classes.productLabel}
        sx={{ marginLeft: props.marginLeft || -4 }}
        width={props.width || 500}
        bgcolor="primary.main"
        py={1}
        mt={1}
        ml={-2}
      >
        <Typography variant={!isDesktop ? "body1" : "h4"}>
          <b>{props.title}</b> {props.supplementaryTitle}
        </Typography>
      </Box>
      {props.closeButton && (
        <IconButton onClick={props.handleClose} className={classes.closeButton}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default DialogBoxTitle;
