import { gql } from "@apollo/client";

export default gql`
  query GetMfaProviders {
    getMfaProviders {
      id
      name
      digits
    }
  }
`;
