import React, { useContext } from "react";
import { Grid, Typography, Box } from "@mui/material";
// import { useTheme } from "@mui/styles";
// import styles from "./PatientDetailHeader.module.css";
import { useHistory, useLocation } from "react-router-dom";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { GetPatient_patient } from "../PatientDetailsPage/types/GetPatient";
import AppContext from "../../context/AppContext";
import { formatDate } from "../../utils/date";
import authService from "../../services/authService";
import CommonHeader from "../GeneralHeader/CommonHeader";
import { isTenantVet } from "../../services/tenantService";

type PatientDetailHeaderProps = {
  patient: GetPatient_patient;
  latestUrNumber?: string;
  ward?: string;
  referringDoctor?: string;
};

const PatientDetailItem: React.FC<any> = ({ name, value }) => (
  <Box pr={[0, 2]}>
    <Typography display="inline" variant="body2" style={{ color: "#c8aee1" }}>
      {name}:
    </Typography>
    <Typography variant="body2" display="inline">
      &nbsp;&nbsp;{value}
    </Typography>
  </Box>
);

const PatientDetailHeader: React.FC<PatientDetailHeaderProps> = ({
  patient,
  latestUrNumber,
  ward,
  referringDoctor,
}) => {
  const isDesktop = useContext(AppContext).isDesktop;
  const history = useHistory();
  const location = useLocation<{ search: string }>();
  // const theme = useTheme<Theme>();
  const mobility = authService.isMobility();

  const navigateToPatientsView = () => {
    if (!isDesktop || mobility) {
      if (location.state) {
        history.push(
          `/${authService.isClinicalTrial() && !mobility ? "patient-episodes" : "patients"}${location.state.search}`,
        );
      } else {
        history.push(`/${authService.isClinicalTrial() && !mobility ? "patient-episodes" : "patients"}`);
      }
    } else if (location.state) {
      history.push(
        `/${authService.isClinicalTrial() && !mobility ? "patient-episodes" : "patients"}${location.state.search}`,
      );
    } else if (history.length > 1) {
      history.goBack();
    } else {
      history.push(`/${authService.isClinicalTrial() && !mobility ? "patient-episodes" : "patients"}`);
    }
  };

  const onBack = () => {
    document.body.style.overflowY = "auto";

    if (
      isDesktop &&
      !mobility &&
      authService.getOpenNewTab() &&
      window.opener !== null &&
      window.opener !== undefined &&
      window.opener.open &&
      !window.opener.closed &&
      window.location.origin === window.opener.location.origin
    ) {
      if (window.opener === null && authService.getOpenNewTab()) {
        try {
          window.close();
        } catch {
          window.open(`/patients`, "_self");
        }
      } else {
        try {
          window.close();
        } catch {
          navigateToPatientsView();
        }
      }
    } else {
      if (authService.getOpenNewTab()) {
        try {
          window.close();
        } catch {
          navigateToPatientsView();
        }
      } else {
        navigateToPatientsView();
      }
    }
  };

  const patientDetailsList = [
    {
      title: "Gender",
      value: patient.sex,
    },
    {
      title: "Born",
      value: formatDate(patient.dob, "DD MMM YYYY", true),
    },
    {
      title: "Address",
      value: patient.fullAddress,
    },
    {
      title: "Phone",
      value: patient.mobile,
    },
    {
      title: "UR number",
      value: latestUrNumber,
    },
    {
      title: "Ward",
      value: ward,
    },
    {
      title: "Referring doctor",
      value: referringDoctor,
    },
  ];

  if (isTenantVet()) {
    patientDetailsList.splice(
      0,
      0,
      {
        title: "Owner",
        value: patient.surname,
      },
      {
        title: "Species",
        value: patient.species,
      },
    );
  }

  return (
    <CommonHeader
      title={isTenantVet() ? patient.surname : patient.fullnameTitleCase}
      title2={isTenantVet() ? (patient.firstname ? patient.firstname : latestUrNumber) : ""}
      onLinkClick={onBack}
      infoBar={patientDetailsList.map(
        (detail) =>
          detail.value && (
            <Grid key={detail.title} item xs={12} sm="auto">
              <PatientDetailItem name={detail.title} value={detail.value} />
            </Grid>
          ),
      )}
    ></CommonHeader>
  );
};

export default PatientDetailHeader;
