import React, { useState, Fragment, useContext } from "react";
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useHistory, useLocation } from "react-router";
import authService from "../../services/authService";
import styles from "./AllPatientSearch.module.css";
import queryString from "query-string";
import AdvancedSearch from "../../model/advanced-search";
import AppContext from "../../context/AppContext";
//import { SearchBarProps } from '../SearchReportBar/SearchReportBar';

type AllowAllPatientsProps = {
  allPatients: boolean;
  setAllPatient: (allPatients: boolean) => void;
  isAdvanceSearch: boolean;
  isOpen: boolean;
};

const AllowAllPatients: React.FC<AllowAllPatientsProps> = (props) => {
  const allowAllPatients = authService.getAllowAllPatients();
  //const [checked, setChecked] = useState(props.allPatients);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isDesktop = useContext(AppContext).isDesktop;

  const handleChange = () => {
    if (!props.allPatients) {
      if (!authService.getAllowAllPatientsAcknownledged()) {
        setOpen(true);
      } else {
        //setChecked(true);
        //props.allPatients = true;
        props.setAllPatient(true);
        // startSearch();
      }
    } else {
      props.setAllPatient(false);
      // startSearch();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAndAccept = () => {
    setOpen(false);
    authService.setAllowAllPatientsAcknownledged(true);
    props.setAllPatient(true);
    startSearch();
  };
  const startSearch = () => {
    const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));
    let isSearchPage = false;

    if (typeof advancedSearch.fullname !== "undefined") {
      isSearchPage = true;
    }

    if (isSearchPage && !props.isAdvanceSearch && !props.isOpen) {
      advancedSearch.allpatients = !props.allPatients ? true : false;
      history.push(`/patients?${advancedSearch.toQueryString()}`);
    } else {
      let isAdvanceSarchPage = false;
      advancedSearch.allpatients = !props.allPatients ? true : false;

      if (authService.getAllowAllPatientsAcknownledged()) {
        advancedSearch.ward = "";
        advancedSearch.wardCode = undefined;
      }

      if (advancedSearch.allpatients) {
        advancedSearch.ward = "";
        advancedSearch.wardCode = undefined;
      }

      Object.keys(advancedSearch).forEach((key) => {
        if (typeof (advancedSearch as any)[key] !== "undefined") {
          isAdvanceSarchPage = true;
        }
      });

      if (
        advancedSearch.allpatients &&
        !advancedSearch.fullname &&
        !advancedSearch.labnumber &&
        !advancedSearch.reference &&
        !advancedSearch.urno
      ) {
        isAdvanceSarchPage = false;
      } else if (
        !advancedSearch.allpatients &&
        !advancedSearch.fullname &&
        !advancedSearch.labnumber &&
        !advancedSearch.panel &&
        !advancedSearch.reference &&
        !advancedSearch.urno &&
        !advancedSearch.ward &&
        advancedSearch.dob === null &&
        advancedSearch.paneldept === 0 &&
        advancedSearch.referredStartDate === null &&
        advancedSearch.referredEndDate === null
      ) {
        isAdvanceSarchPage = false;
      } else {
        isAdvanceSarchPage = true;
      }

      if (isAdvanceSarchPage) {
        // advancedSearch.allpatients = !props.allPatients?true:false
        // patientSearch.allPatients = !props.allPatients?true:false

        history.push(`/patients?${advancedSearch.toQueryString()}`);
      }
    }

    /* patientSearch.fullname = patientSearch.searchtext;
    if (props.values.searchtext === '') {
      props.values.birthdate = '';
    }
    // if (props.values.allPatients && !props.values.searchtext) {
    //   props.values.allPatients = false;
    //   setChecked(false)
    // }
    patientSearch.allPatients = props.allPatients;
    //console.log(values.birthdate !== '')
    //console.log(parseInt(values.birthdate))
    if (props.values.birthdate !== '') {
      if ((props.values.birthdate.toString().length === 4) || (props.values.birthdate.toString().length === 0)) {
        patientSearch.dateRange = parseInt(props.values.birthdate);
      }
    } */
    /*if (props.values.allPatients && !props.values.searchtext) {
      return
    }*/
  };
  return (
    <Fragment>
      <Box>
        {allowAllPatients && (
          <FormControlLabel
            control={<Switch color="primary" checked={props.allPatients} onChange={handleChange} />}
            className={`${styles.allPatientLabel}`}
            label="Search patients from all doctors"
            style={{ marginLeft: isDesktop ? "10px" : "0px" }}
          />
        )}
      </Box>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: styles.dialogBorder }}
        >
          <DialogTitle id="alert-dialog-title">
            {
              <Fragment>
                <Typography className={styles.mainTitle}>Specialist access</Typography>
                <Typography className={styles.subTitle}>Search patients from all doctors</Typography>
              </Fragment>
            }{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This Patient’s results are not associated with your account. By accessing this result you are
              acknowledging that you have received this patient’s consent and you are involved in their treatment. All
              access is tracked and illegal access will be prosecuted.
            </DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: styles.actionsBox }}>
            <Button
              onClick={handleCloseAndAccept}
              color="primary"
              autoFocus
              className={`${styles.ovalButton} ${styles.agreeButton}`}
            >
              Yes, I agree
            </Button>
            <Button onClick={handleClose} color="primary" className={`${styles.ovalButton} ${styles.cancelButton}`}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default AllowAllPatients;
