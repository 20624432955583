import { gql } from "@apollo/client";

export default gql`
  mutation AddAtomicComment($input: AtomicCommentAddArg!) {
    addAtomicComment(input: $input) {
      atomicId
      csStatus
      csUserId
      csDatetime
      approval
      approverUserId
      approverName
      approverDatetime
      comment
      csName
      noActionRequired
      actionRequired
      dCF
    }
  }
`;
