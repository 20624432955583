import { gql } from "@apollo/client";

export default gql`
  query GetPrintableAuditLog($reportIds: [Float!], $action: String!, $labNoDigitOnly: String!, $auditLog: Boolean!) {
    getAuditLogs(reportIds: $reportIds, action: $action, labNoDigitOnly: $labNoDigitOnly, auditLog: $auditLog) {
      id
      username
      date
      action
      value
      type
      searchKey1
      searchKey2
    }
  }
`;
