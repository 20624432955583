import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { formatDate } from "../../utils/date";
import authService from "../../services/authService";
import CommonHeader from "./CommonHeader";

const GeneralHeader: React.FC = () => {
  const isDesktop = useContext(AppContext);
  const history = useHistory();
  const location = useLocation<{ search: string }>();

  const lastlogin: string = authService.getLastLogin();

  const onBack = () => {
    if (location.state) {
      history.push(`/patients${location.state.search}`);
    } else {
      history.push(`/patients`);
    }
  };

  return (
    <CommonHeader
      title="Group Admin"
      onLinkClick={onBack}
      infoBar={
        <Box display="flex" justifyContent="flex-end" flexDirection={isDesktop ? "row" : "column"} width={1}>
          <Box pr={[0, 2]} ml={2}>
            <Typography display="inline" variant="body2" style={{ color: "#c8aee1" }}>
              Last Login:
            </Typography>
            <Typography variant="body2" display="inline">
              &nbsp;&nbsp;
              {formatDate(lastlogin, "DD MMMM YYYY hh:mm A", false)}
            </Typography>
          </Box>
        </Box>
      }
    ></CommonHeader>
  );
};

export default GeneralHeader;
