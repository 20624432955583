import { gql } from "@apollo/client";
export default gql`
  query GetNotificationTypes {
    getNotificationTypes {
      data {
        id
        typename
      }
    }
  }
`;
