import authService from "../../services/authService";
import * as Constants from "../../constants";

const username: string = authService.getUsername() || "thisusernameisimpossibletouse";
const ACCOUNT_LOCKED_ERROR_MESSAGE = "Account is Locked";
const IP_LOCKED_ERROR_MESSAGE = "IP Address is Locked";
const INVALID_LOGIN_ERROR_MESSAGE = "Invalid login details";
const IP_NOT_ALLOWED_ERROR_MESSAGE = "IP Address Not Allowed";
const ACCOUNT_DISABLED_ERROR_MESSAGE = "Account disabled";
const NO_PROVIDERS_ERROR_MESSAGE = "No Provider Codes Defined";
const NOT_PART_OF_EARLY_ACCESS = "Account Not Part of Early Access";
const MULTIPLE_ACCOUNTS_ERROR_MESSAGE = "Multiple accounts found";
const JWT_TOKEN_EXPIRED = "jwt expired";
const INVALID_SIGNATURE = "invalid signature";
const PASSWORD_USED = "This password has been used";

export type ChangePasswordFormValues = {
  oldpassword: string;
  password: string;
  passwordconfirm: string;
  message: string;
};

export type ChangePasswordFormErrors = {
  oldpassword?: string;
  password?: string;
  passwordconfirm?: string;
  message?: string;
};

type ChangePasswordRuleListItem = {
  valid: number;
  text: string;
  re: RegExp;
};

export const validationRules: ChangePasswordRuleListItem[] = [
  {
    valid: 0,
    text: "Be between 6 and 20 characters in length",
    re: /^.{6,20}$/,
  },
  {
    valid: 0,
    text: "Contain at least 1 upper and 1 lower case letter",
    re: /(?=.*[a-z])(?=.*[A-Z])/,
  },
  {
    valid: 0,
    text: "Contain at least 1 non-alpahumeric character",
    re: /[@$!%*#?&\(\)\/:;'",\._\-~\[\]\|`<>]/ /* eslint-disable-line */,
  },
  {
    valid: 0,
    text: "Contain at least 1 numeric character",
    re: /.*[0-9]/,
  },
  {
    valid: 0,
    text: "NOT contain your user name",
    re: new RegExp("^((?!" + username + ").)*$", "i"),
  },
];

let validationCounter = 0;
export const validatePassword = (values: ChangePasswordFormValues): ChangePasswordFormErrors => {
  const errors: ChangePasswordFormErrors = {};
  if (!values.oldpassword) {
    errors.oldpassword = "required" + (validationCounter++).toString();
  }
  if (!values.password) {
    errors.password = "required" + (validationCounter++).toString();
  }
  if (!values.passwordconfirm) {
    errors.passwordconfirm = "required" + (validationCounter++).toString();
  }
  if (values.passwordconfirm !== values.password) {
    errors.message = "Passwords do not match";
    errors.passwordconfirm = "required" + (validationCounter++).toString();
  }

  // only validate new password field once a value is entered
  // http://jira.pl.pathlabs.com.au/browse/ER7-137
  if (values.password) {
    validationRules.forEach((v) => (v.valid = values.password.match(v.re) ? 1 : -1));
    const e = validationRules.find((v) => v.valid === -1);
    if (e !== undefined) {
      errors.password = e.text + (validationCounter++).toString();
    }
  }

  return errors;
};

export const convertError = (error: string): string => {
  if (error === NOT_PART_OF_EARLY_ACCESS) {
    return "Your account is not enabled for this system. Please contact 1300 669 961 for assistance during business hours.";
  } else if (error === ACCOUNT_LOCKED_ERROR_MESSAGE) {
    return "Your account has been blocked for 30 mins due to too many failed login attempts. Please contact 1300 669 961 for assistance during business hours.";
  } else if (error === IP_LOCKED_ERROR_MESSAGE) {
    return "Your account has been blocked for 30 mins due to too many failed login attempts. Please contact 1300 669 961 for assistance during business hours.";
  } else if (error === INVALID_LOGIN_ERROR_MESSAGE) {
    return "Your attempt to log in has failed due to an incorrect username or password. Please check your login details and try again.";
  } else if (error === ACCOUNT_DISABLED_ERROR_MESSAGE) {
    return "Your attempt to log in has failed because your account is not enabled. Please contact <a href='mailto:ehealth.helpdesk@clinicallabs.com.au?subject=eResults%20Account%20Disabled'>technical support</a> immediately to have your account rectified.";
  } else if (error === IP_NOT_ALLOWED_ERROR_MESSAGE) {
    return "Your attempt to log in has failed because you are attempting to login from a network that is not approved by your account manager. If you believe this is incorrect, please contact technical support immediately to have your account rectified.";
  } else if (error === NO_PROVIDERS_ERROR_MESSAGE) {
    return "Your attempt to log in has failed due to your account having no associated Doctor Codes. Please contact technical support immediately to have your account rectified.";
  } else if (error === MULTIPLE_ACCOUNTS_ERROR_MESSAGE) {
    return "Your attempt to log in has failed because multiple accounts share the same email. Please use username instead or contact technical support immediately to have your account rectified.";
  } else if (error === JWT_TOKEN_EXPIRED || error === INVALID_SIGNATURE) {
    return "The password reset link you are trying to use is expired. Please go to login page and use Forgot Password option to create a new reset link.";
  } else if (error === PASSWORD_USED) {
    return "The password you entered has been used. Please enter a new password.";
  } else {
    return Constants.NO_DB_CONNECTION;
  }
};
