/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  GetCombinedReport_combinedReport,
  GetCombinedReport_combinedReport_atomicData,
} from "../components/CombinedReport/types/GetCombinedReport";

// Reference: https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

type CumulativeContextProps = {
  isCumulative: boolean;
  setIsCumulative: React.Dispatch<React.SetStateAction<boolean>>;
  panelId: number;
  setPanelId: React.Dispatch<React.SetStateAction<number>>;
  testname: string;
  setTestname: React.Dispatch<React.SetStateAction<string>>;
  showPanelRep: boolean;
  setShowPanelRep: React.Dispatch<React.SetStateAction<boolean>>;
  showIndGraph: boolean;
  setShowIndGraph: React.Dispatch<React.SetStateAction<boolean>>;
  showCulGraph: boolean;
  setShowCulGraph: React.Dispatch<React.SetStateAction<boolean>>;
  atomics: (GetCombinedReport_combinedReport | null)[];
  setAtomics: React.Dispatch<React.SetStateAction<(GetCombinedReport_combinedReport | null)[]>>;
  isCommentsOpen: boolean;
  setIsCommentsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPlainComment: boolean;
  setIsPlainComment: React.Dispatch<React.SetStateAction<boolean>>;
  currentReportId: number;
  setCurrentReportId: React.Dispatch<React.SetStateAction<number>>;
  allPanels: any[];
  setAllPanels: React.Dispatch<React.SetStateAction<string[]>>;
  currentCsStatus: number;
  setCurrentCsStatus: React.Dispatch<React.SetStateAction<number>>;
  isAtomicLoading: boolean;
  setIsAtomicLoading: React.Dispatch<React.SetStateAction<boolean>>;
  random: number;
  setRandom: React.Dispatch<React.SetStateAction<number>>;
  currentAtomic: GetCombinedReport_combinedReport_atomicData | null;
  setCurrentAtomic: React.Dispatch<React.SetStateAction<GetCombinedReport_combinedReport_atomicData | null>>;
  showCommentsExtra: boolean;
  setShowCommentsExtra: React.Dispatch<React.SetStateAction<boolean>>;
  repeatAtomics: any[];
  setRepeatAtomics: React.Dispatch<React.SetStateAction<number[]>>;
};

export const CumulativeContext = React.createContext<CumulativeContextProps>({
  isCumulative: false,
  setIsCumulative: () => {},
  panelId: 0,
  setPanelId: () => {},
  testname: "",
  setTestname: () => {},
  showPanelRep: false,
  setShowPanelRep: () => {},
  showIndGraph: false,
  setShowIndGraph: () => {},
  showCulGraph: false,
  setShowCulGraph: () => {},
  atomics: [],
  setAtomics: () => {},
  isCommentsOpen: false,
  setIsCommentsOpen: () => {},
  isPlainComment: false,
  setIsPlainComment: () => {},
  currentReportId: 0,
  setCurrentReportId: () => {},
  currentCsStatus: 0,
  setCurrentCsStatus: () => {},
  isAtomicLoading: false,
  setIsAtomicLoading: () => {},
  random: 0,
  setRandom: () => {},
  currentAtomic: null,
  setCurrentAtomic: () => {},
  showCommentsExtra: true,
  setShowCommentsExtra: () => {},
  repeatAtomics: [],
  setRepeatAtomics: () => {},
  allPanels: [],
  setAllPanels: () => {},
});

export const CumulativeContextProvider: React.FC<RouteComponentProps> = (props) => {
  const [isCumulative, setIsCumulative] = useState(false);
  const [showPanelRep, setShowPanelRep] = useState(false);
  const [showIndGraph, setShowIndGraph] = useState(false);
  const [showCulGraph, setShowCulGraph] = useState(false);
  const [panelId, setPanelId] = useState(0);
  const [testname, setTestname] = useState("");
  const [atomics, setAtomics] = useState<(GetCombinedReport_combinedReport | null)[]>([]);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isPlainComment, setIsPlainComment] = useState(false);
  const [currentReportId, setCurrentReportId] = useState<number>(0);
  const [currentCsStatus, setCurrentCsStatus] = useState(0);
  const [isAtomicLoading, setIsAtomicLoading] = useState(false);
  const [random, setRandom] = useState<number>(0);
  const [currentAtomic, setCurrentAtomic] = useState<GetCombinedReport_combinedReport_atomicData | null>(null);
  const [showCommentsExtra, setShowCommentsExtra] = useState(true);
  const [repeatAtomics, setRepeatAtomics] = useState<any[]>([]);
  const [allPanels, setAllPanels] = useState<any[]>([]);

  return (
    <CumulativeContext.Provider
      value={{
        isCumulative,
        setIsCumulative,
        panelId,
        setPanelId,
        testname,
        setTestname,
        showPanelRep,
        setShowPanelRep,
        showIndGraph,
        setShowIndGraph,
        showCulGraph,
        setShowCulGraph,
        atomics,
        setAtomics,
        isCommentsOpen,
        setIsCommentsOpen,
        isPlainComment,
        setIsPlainComment,
        currentReportId,
        setCurrentReportId,
        currentCsStatus,
        setCurrentCsStatus,
        isAtomicLoading,
        setIsAtomicLoading,
        random,
        setRandom,
        currentAtomic,
        setCurrentAtomic,
        showCommentsExtra,
        setShowCommentsExtra,
        repeatAtomics,
        setRepeatAtomics,
        allPanels,
        setAllPanels,
      }}
    >
      {props.children}
    </CumulativeContext.Provider>
  );
};

export default withRouter(CumulativeContextProvider);
