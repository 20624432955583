import { gql } from "@apollo/client";
export default gql`
  query GetStudy($providerNumber: [String!]!) {
    getStudy(provider_number: $providerNumber) {
      id
      provider_number
      study_code
      study_name
      date_created
      type_description
      type_code
      email
      CRC
      SCRC
      PI
    }
  }
`;
