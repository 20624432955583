import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Button, Card, CardContent, Grid, Tab, TabProps, Tabs, Typography } from "@mui/material";
import CpdStyledHeader from "./CpdStyledHeader";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./CPDprograms.module.css";
import { useLazyQuery, useQuery } from "@apollo/client";
import AppContext from "../../context/AppContext";
import authService from "../../services/authService";
import { Link, useHistory } from "react-router-dom";
import { omit } from "lodash";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArticleIcon from "@mui/icons-material/Article";
import theme from "../theme";
import { makeStyles } from "@mui/styles";
import { getCytologyReport, getCytologyReport_getCytologyReport_rows } from "./types/getCytologyReport";
import getCytologyQuery from "./GetCytologyReportQuery";
import { CPD_PROGRAMS } from "./types";
import GetReportCountQuery from "./GetReportCountQuery";
import { getCpdReportCount } from "./types/getCpdReportCount";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export type CPDcytologyProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

type LinkTabProps = TabProps<Link> & {
  isActive: boolean;
  label?: string;
  href?: string;
  to?: string;
};

const useStyles = makeStyles(() => ({
  downloadcsv: {
    color: theme.colorSchemes.light.palette.primary.main + " !important",
  },
  clearButton: {
    cursor: "pointer",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontWeight: "bold",
  },
  welcomeLogo: {
    width: "400px",
    height: "400px",
  },
  container: {
    width: "400px",
    height: "400px",
    position: "relative",
  },
  shape: {
    borderStyle: "solid",
    msTransform: "rotate(360deg)",
    oTransform: "rotate(360deg)",
    webkitTransform: "rotate(360deg)",
    transform: "rotate(360deg)",
    borderColor: "transparent " + theme.colorSchemes.light.palette.primary.dark + " transparent transparent",
    float: "right",
    right: "0px",
    height: "0px",
    width: "0px",
    borderWidth: "0 120px 120px 0",
    position: "absolute",
    display: "flex",
  },
  shapeText: {
    transform: "rotate(45deg)",
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "bold",
    position: "relative",
    right: "-60px",
    top: "5px",
    textAlign: "center",
    margin: "auto",
  },
  resetButtonMob: {
    backgroundColor: "#979797 !important",
    marginLeft: "2px",
    color: "#ffffff",
  },
  clearIcon: {
    opacity: "0",
  },
  input: {
    height: "46px",
  },
}));

const CPDcytologyPage: React.FC<CPDcytologyProps> = () => {
  function LinkTab(props: LinkTabProps) {
    const linkprops = omit(props, ["isActive"]);
    return <Tab component={Link} className={`${styles.tabHeader} ${styles.tabHeaderSelected}`} {...linkprops} />;
  }

  const classes = useStyles();
  const { isDesktop } = useContext(AppContext);
  const history = useHistory();
  const tabPage = 0;
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const cytoReportStart = localStorage.getItem("cytoReportStart");
  const cytoReportEnd = localStorage.getItem("cytoReportEnd");
  const providers = authService.getCpdProviderValues();
  const [tableData, setTableData] = useState<getCytologyReport_getCytologyReport_rows[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);
  const [tableDataPage, setTableDataPage] = useState<number>(0);
  const [reportCount, setReportCount] = useState<number>(0);
  const [reportStartDate, setreportStartDate] = useState<string | number | Dayjs | Date | null | undefined>(
    cytoReportStart ? dayjs(cytoReportStart) : dayjs().subtract(1, "year").startOf("year"),
  );
  const [reportEndDate, setreportEndDate] = useState<string | number | Dayjs | Date | null | undefined>(
    cytoReportEnd ? dayjs(cytoReportEnd) : dayjs(),
  );

  const [sortModel, setSortModel] = useState<string[]>([]);
  const [getCytologyReportQuery, { data, loading, error: reportListError }] = useLazyQuery<getCytologyReport>(
    getCytologyQuery,
    {},
  );
  const [pageSize, setPageSize] = useState<number>(50);
  const { data: reportCountData } = useQuery<getCpdReportCount>(GetReportCountQuery, {
    variables: {
      cpdProgram: CPD_PROGRAMS.CYTOLOGY_EVALUATION_PROGRAM,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setTableData([]);
    getCytologyReportQuery({
      variables: {
        page: tableDataPage,
        providers: providers,
        sort: sortModel,
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        pageSize: pageSize,
      },
    });
  }, [sortModel, tableDataPage, pageSize]);
  useEffect(() => {
    setReportCount(reportCountData?.getCpdReportCount.reportCount || 0);
  }, [reportCountData]);

  const handleSearch = () => {
    getCytologyReportQuery({
      variables: {
        page: tableDataPage,
        providers: providers,
        sort: sortModel,
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        pageSize: pageSize,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data.getCytologyReport.rows as getCytologyReport_getCytologyReport_rows[]);
      setTableDataCount(data.getCytologyReport.rowCount);
    }
  }, [data]);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length > 0) {
      setSortModel([sortModel[0].field, sortModel[0].sort || ""]);
    }
  }, []);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      if (reportEndDate && dayjs(date).isAfter(reportEndDate)) {
        setreportEndDate(date);
        setreportStartDate(date);
        return;
      }

      setreportStartDate(date);
      localStorage.setItem("cytoReportStart", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setreportStartDate("");
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      if (reportStartDate && !dayjs(date).isAfter(reportStartDate)) {
        setreportStartDate(date);
        setreportEndDate(date);
        return;
      }

      setreportEndDate(date);
      localStorage.setItem("cytoReportEnd", dayjs(date).format("YYYY-MM-DD"));
    } else {
      setreportEndDate("");
    }
  };

  const handleOpenLinkClick = async (event: React.MouseEvent, params: any) => {
    localStorage.setItem("CytologyReportProvider", params.row.providerno);
    localStorage.setItem("CytologyReportPeriod", params.row.reportdate);
    history.push(`/cpd-cytology-report/${params.id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      sortable: false,
      renderCell: () => {
        return (
          <>
            <ArticleIcon className={classes.downloadcsv} fontSize="small"></ArticleIcon>
          </>
        );
      },
    },
    {
      field: "reportdate",
      headerName: "Reporting Month",
      flex: 2,
      renderCell(params) {
        return (
          <Link
            color="var(--mui-palette-primary-main)"
            href="#"
            to="#"
            onClick={(event) => handleOpenLinkClick(event, params)}
          >
            {dayjs(params?.value as string).format("MMMM YYYY")}
          </Link>
        );
      },
    },
    {
      field: "providerno",
      headerName: "Provider Number",
      flex: 1,
    },
    {
      field: "datecreated",
      headerName: "Created",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value as unknown as string).format("DD MMM YYYY") : ""),
    },
    {
      field: "first_viewed",
      headerName: "First Viewed",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value as unknown as string).format("DD MMM YYYY") : ""),
    },
    {
      field: "last_viewed",
      headerName: "Last Viewed",
      flex: 1,
      valueFormatter: (value) => (value ? dayjs(value as unknown as string).format("DD MMM YYYY") : ""),
    },
    {
      field: "viewcount",
      headerName: "Report Viewed",
      flex: 1,
    },
  ];

  return (
    <>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation
          subHeader="Cytology Evaluation Program"
          showBackButton
          mainHeader={CpdStyledHeader()}
          backText="HOME"
          backPath="/cpd-programs"
          hideSeparator
          isCpd
          displayACL={isDesktop ? true : false}
        />
      </AppBar>
      <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />
      <Tabs
        sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        value={tabPage}
        aria-label="Cytology Evaluation Program"
        className={styles.tabPanel}
      >
        <LinkTab isActive={tabPage === 0} label="View Cytology Reports" to="#" />
      </Tabs>

      <Grid container sx={{ overflowY: "hidden", overflowX: "hidden", ml: 2, mr: 2, mt: 0, width: "auto" }}>
        <Grid item xs={12} sx={{ mt: 2 }}></Grid>
        <Grid item xs={11} md={6}>
          <Box sx={{ mr: 1, ml: 1, display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                label="Report Start Date"
                value={reportStartDate as Dayjs | null | undefined}
                onChange={(newValue: Dayjs | null) => {
                  handleStartDateChange(newValue as Date | null);
                }}
                slotProps={{ textField: { size: "small", fullWidth: true, style: { backgroundColor: "#FFF" } } }}
                className={`${styles.DateInput}`}
              />
              <DatePicker
                format="DD/MM/YYYY"
                label="Report End Date"
                value={reportEndDate as Dayjs | null | undefined}
                onChange={(newValue: Dayjs | null) => {
                  handleEndDateChange(newValue as Date | null);
                }}
                slotProps={{ textField: { size: "small", fullWidth: true, style: { backgroundColor: "#FFF" } } }}
                className={`${styles.DateInput}`}
              />
            </LocalizationProvider>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={`${styles.submitButton}`}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box
            sx={isDesktop ? { ml: 2 } : { m: 2, p: 1 }}
            style={{
              backgroundColor: theme.colorSchemes.light.palette.primary.dark,
              height: "45px",
              color: "var(--mui-palette-grey-100)",
              display: "flex",
              alignItems: "center",
              width: isDesktop ? "380px" : "250px",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant={isDesktop ? "body1" : "body2"}
              style={{ color: theme.colorSchemes.light.palette.text.primary }}
            >
              Current number of Cervical Screening Tests this year: {reportCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mr: 1, ml: 1, minHeight: "70vh" }}>
          <Box style={{ height: "100%", width: "100%", border: "none" }}>
            {isDesktop ? (
              <DataGrid
                sx={{
                  ".hightBackground": { background: "var(--mui-palette-primary-light)" },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                  },
                  "& .MuiDataGrid-main & .MuiDataGrid-root": {
                    display: "none",
                  },
                  "& .MuiTablePagination-select": { paddingRight: "24px" },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                }}
                getRowClassName={(params) => {
                  return params.row.viewcount == 0 ? "hightBackground" : "";
                }}
                paginationModel={{ page: tableDataPage, pageSize: pageSize }}
                onPaginationModelChange={(paginationModel) => {
                  setTableDataPage(paginationModel.page);
                  setPageSize(paginationModel.pageSize);
                }}
                disableColumnMenu
                rows={tableData}
                columns={columns}
                paginationMode="server"
                rowCount={tableDataCount}
                loading={loading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            ) : (
              <Box pt={2}>
                {tableData.length == 0 ? (
                  <Box pt={3} display={"flex"} justifyContent={"center"}>
                    <Typography variant="body1">No Rows</Typography>
                  </Box>
                ) : (
                  ""
                )}
                {tableData.map((data, index) => (
                  <Card sx={{ marginBottom: "20px" }} key={index}>
                    <CardContent className={styles.mobileCard}>
                      <Box className={`${styles.mobileRow} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Box>
                          <ArticleIcon className={classes.downloadcsv} fontSize="small"></ArticleIcon>
                        </Box>
                        <Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2">Reporting Month</Typography>
                            <Typography variant="body2">
                              {dayjs(data.reportdate as string).format("MMMM YYYY")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Provider Number</Typography>
                        <Typography variant="body2">{data.providerno}</Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Created</Typography>
                        <Typography variant="body2">
                          {data.datecreated ? dayjs(data.datecreated).format("DD  MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">First Viewed</Typography>
                        <Typography variant="body2">
                          {data.first_viewed ? dayjs(data.first_viewed).format("DD  MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Last Viewed</Typography>
                        <Typography variant="body2">
                          {data.last_viewed ? dayjs(data.last_viewed).format("DD  MMM YYYY") : ""}
                        </Typography>
                      </Box>
                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">View Count</Typography>
                        <Typography variant="body2">{data.viewcount}</Typography>
                      </Box>

                      <Box className={`${styles.mobileCell} ${data.viewcount > 0 ? styles.mobileRead : ""}`}>
                        <Typography variant="body2">Open Report</Typography>
                        <Box>
                          <ArticleIcon
                            className={classes.downloadcsv}
                            fontSize="large"
                            onClick={(event) => handleOpenLinkClick(event, { row: data, id: data.id })}
                          ></ArticleIcon>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            )}
            {reportListError && <ErrorPopover />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CPDcytologyPage;
