import React, { useEffect, useRef } from "react";
import { MenuItem, FormControl, InputLabel, ListItemText, Select, IconButton, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
// using @mui/material version of MenuItem causes layout bugs, so @mui/material version is used
import ClearIcon from "@mui/icons-material/Clear";
import AdvancedSearch from "../../model/advanced-search";
import queryString from "query-string";
import { useLocation } from "react-router";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: "16px",
    height: "35px",

    width: "100%",
  },
}));

const FilterStatusFormControl: React.FC<any> = ({ selectedStatuses, handleStatusChange, setSelectedStatuses }: any) => {
  const statuses = [
    { value: "Pending", description: "Pending" },
    { value: "Ready for partial review", description: "Ready for partial review" },
    { value: "Ready for review", description: "Ready for review" },
    { value: "Partially reviewed", description: "Partially Reviewed" },
    { value: "Reviewed / Approved", description: "Reviewed / Approved" },
    { value: "Printed", description: "Printed" },
    { value: "Offline", description: "Offline" },
  ];
  const location = useLocation();
  const classes = useStyles();
  const selectRef = useRef<Element>(null);
  const handleClearClick = () => {
    handleStatusChange({ target: { value: [] } });
  };
  useEffect(() => {
    const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));
    let currentStatus = null;

    if (advancedSearch.status) {
      currentStatus = advancedSearch.status.filter(function (el) {
        return el != "";
      });
    }

    if (currentStatus && currentStatus.length > 0 && selectedStatuses.length == 0) {
      setSelectedStatuses(advancedSearch.status);
    }
  }, []);
  return (
    <FormControl variant="outlined" style={{ width: "100%" }} size="small">
      <InputLabel id="test-select-label" style={{ paddingRight: "0px", marginLeft: "8px" }}>
        Statuses
      </InputLabel>
      <Select
        ref={selectRef}
        style={{ paddingRight: "0px", marginLeft: "8px", width: "100%", height: "38px" }}
        variant="outlined"
        value={selectedStatuses}
        onChange={handleStatusChange}
        renderValue={(value) => (value as string[]).join(", ")}
        labelId="test-select-label-test"
        label={"Statuses"}
        multiple
        endAdornment={
          <IconButton
            //sx={{ visibility: selectedStatuses ? "visible" : "hidden", position: "absolute", right: "25px" }}
            style={{ visibility: selectedStatuses ? "visible" : "hidden", position: "absolute", right: "25px" }}
            onClick={handleClearClick}
          >
            <ClearIcon />
          </IconButton>
        }
        size="small"
        MenuProps={{
          anchorEl: selectRef.current,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "top", horizontal: "center" },
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
              width: 300,
            },
          },
        }}
      >
        {statuses.map((s) => (
          <MenuItem key={s.value} value={s.value} dense className={classes.menuItem}>
            <Checkbox color="primary" checked={selectedStatuses.indexOf(s.value) > -1} />
            <ListItemText primary={s.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterStatusFormControl;
