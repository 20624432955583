import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import styles from "./SearchReportBar.module.css";
import AppContext from "../../context/AppContext";
// import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import assets from "../../assets/sahIcon.png";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";

export default function NotificationBar(): JSX.Element {
  const isDesktop = useContext(AppContext).isDesktop;

  let val = true;

  if (document.cookie.includes("notified=true")) {
    val = false;
  }
  const [openConfirm, setOpenConfirm] = React.useState(val);
  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleOkay = () => {
    document.cookie = "notified=true;expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setOpenConfirm(false);
  };

  return (
    <div>
      <Dialog
        open={openConfirm}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <DialogBoxTitle title="Welcome to eResults" width={260} closeButton handleClose={handleClose} />
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
          {isDesktop ? (
            <>
              <Box ml={3} mt={1}>
                <Typography component="div" className={styles.notificationTextMiddle}>
                  {" "}
                  Add this page to your Bookmarks / Favourites{" "}
                </Typography>
              </Box>
              <Box ml={3}>
                <Typography component="div" className={styles.notificationTextBtm}>
                  Press Ctrl + D on Windows or Command + D on Mac{" "}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box ml={1} mt={1}>
                <Typography className={styles.notificationTextMiddle}> Add to Home Screen </Typography>
              </Box>
              <Box ml={1} mt={1}>
                <Typography className={styles.notificationTextBtmMob}>
                  {" "}
                  IOS (Safari) - Press &nbsp;
                  <img src={assets} alt="" /> &nbsp;then &apos;Add to home Screen&apos;{" "}
                  <AddIcon className={styles.notiIcon} />{" "}
                </Typography>
              </Box>
              <Box ml={1}>
                <Typography className={styles.notificationTextBtmMob}>
                  {" "}
                  Android(Chrome)-Press <MoreVertIcon className={styles.notiIcon} /> then &apos;Add to home Screen&apos;
                </Typography>
              </Box>
            </>
          )}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained" onClick={handleOkay}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
