import React from "react";
import styles from "./ribbon.module.css";

const Ribbon: React.FC = () => {
  const env: string = process.env.REACT_APP_ENV || "";

  const isProduction = (): boolean => !env || env === "production" || env === "prod";

  return (
    <div>{!isProduction() && <div className={`${styles.ribbon} ${styles[env]}`}>{process.env.REACT_APP_ENV}</div>}</div>
  );
};

export default Ribbon;
