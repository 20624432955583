import React, { useContext } from "react";
import styles from "./CombinedReport.module.css";
import { Box, Button } from "@mui/material";
import authService from "../../services/authService";
import AbnormalAtomicMutation from "./UpdateAtomicAbnormalMutation";
import { CLINCAL_TRIAL_USER_TYPE } from "../../constants";
import { UpdateAbnormalAtomic, UpdateAbnormalAtomicVariables } from "./types/UpdateAbnormalAtomic";
import { useMutation } from "@apollo/client";
import { CumulativeContext } from "../../context/CumulativeContext";
import { cloneDeep } from "lodash";

export interface IAppProps {
  atomic: any;
  onReviewButtonClick: any;
}

const ReviewButton: React.FC<IAppProps> = (props: IAppProps) => {
  const { atomic } = props;
  const { atomics, setAtomics } = useContext(CumulativeContext);

  const [updateAtomicAbnormalMutation, { loading: abnormalAtomicLoading }] = useMutation<
    UpdateAbnormalAtomic,
    UpdateAbnormalAtomicVariables
  >(AbnormalAtomicMutation);

  const reviewClickHandler = () => {
    updateAtomicAbnormalMutation({
      variables: {
        input: {
          atomicId: atomic?.atomicId || 0,
          abnormal: "A",
        },
      },
    }).then((atm: any) => {
      const newAtomics = cloneDeep(atomics);
      atm.data.updateAbnormalAtomic.forEach((m: any) => {
        console.log(m);
        newAtomics.forEach((newAtomic: any) => {
          newAtomic?.atomicData?.forEach((a: any) => {
            if (a && a?.atomicId === m?.atomicId) {
              a.abnormal = m.abnormal;
            }
          });
        });

        if (props.onReviewButtonClick) {
          props.onReviewButtonClick({ reportId: m?.reportId });
        }
      });

      setAtomics(newAtomics);
    });
  };

  const reviewButton = !atomic.abnormal && authService.getClinicalTrial() === CLINCAL_TRIAL_USER_TYPE.Approver && (
    <Button
      className={styles.indeterminateButton}
      onClick={() => reviewClickHandler()}
      disabled={abnormalAtomicLoading}
    >
      Review
    </Button>
  );

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
      <Box display="flex" flexDirection="row" alignItems="center">
        {reviewButton}
      </Box>
    </Box>
  );
};

export default ReviewButton;
