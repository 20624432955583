import { gql } from "@apollo/client";

export default gql`
  query GetLabs($active: Boolean!) {
    getLabs(active: $active) {
      id
      name
      shortname
    }
  }
`;
