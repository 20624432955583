import { gql } from "@apollo/client";
export default gql`
  mutation CreateStudy($input: CreateStudyInput!) {
    createStudy(input: $input) {
      study_code
      provider_number
      CRC
      SCRC
      PI
    }
  }
`;
