import { gql } from "@apollo/client";

export default gql`
  query getSkinAuditReport(
    $pageSize: Float
    $page: Float
    $reportStartDate: String
    $reportEndDate: String
    $providers: [String!]
    $sort: [String!]
  ) {
    getSkinAuditReport(
      pageSize: $pageSize
      page: $page
      reportStartDate: $reportStartDate
      reportEndDate: $reportEndDate
      providers: $providers
      sort: $sort
    ) {
      rows {
        id
        name
        reporting_month
        date_created
        first_viewed
        last_viewed
        viewCount
        providerno
      }
      rowCount
    }
  }
`;
