export const validateMedicare = (medicareNo: string): boolean => {
  if (!medicareNo) return false;

  const raw = medicareNo.replace(/\s/g, "").replace(/\//g, "");

  if (raw.length > 9 && raw.length < 12) {
    const matches = raw.match(/^(\d{8})(\d)/);

    if (!matches) return false;

    const base: any = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];
    let sum = 0;

    for (const i in weights) {
      sum += base[i] * weights[i];
    }

    return sum % 10 === parseInt(checkDigit, 10);
  } else return false;
};
