import { gql } from "@apollo/client";

export default gql`
  query getCpdReportCount($cpdProgram: Int!, $year: Int) {
    getCpdReportCount(cpdProgram: $cpdProgram, year: $year) {
      loginCount
      reportCount
    }
  }
`;
