import React, { useEffect, useContext } from "react";
import useAxios from "axios-hooks";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import AppContext from "../../context/AppContext";
import InnerHTML from "dangerously-set-html-content";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

type PrintableCombineCtAtomicOrdersProps = {
  orders: any[];
};

const PrintableCombineCtAtomicOrders: React.FC<PrintableCombineCtAtomicOrdersProps> = ({ orders }) => {
  const setError = useContext(AppContext).setError;

  // eslint-disable-next-line
  const [{ data, loading, error }] = useAxios({
    url: `${process.env.REACT_APP_ACL_REPORT_URL}/ctreport`,
    method: "POST",
    data: {
      output: "html",
      data: {
        tz: dayjs.tz.guess(),
        orders: orders,
      },
      template: "ctreport-template.html",
    },
  });

  // TEXT
  useEffect(() => {
    const iframe: any = window.parent.document.getElementById("printFrame");
    if (iframe && iframe.contentWindow && data && data.data) {
      setTimeout(() => {
        window.parent.postMessage("eResults2.0 Print Ready", "*");
        iframe.scrolling = "no";
        iframe.contentWindow.focus();
        iframe.contentWindow.document.title = `Reports Combined`;
        if (document.queryCommandSupported("print")) {
          iframe.contentWindow.document.execCommand("print", false, null);
        } else {
          iframe.contentWindow.print();
        }
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loading />;

  if (error) {
    setError(`There has been an error. Please refresh the page to try again ${error.message}`);
    window.parent.postMessage("eResults2.0 Print Ready", "*");
    return <ErrorPopover />;
  } else {
    return <InnerHTML html={data.data} />;
  }
};

export default PrintableCombineCtAtomicOrders;
