enum AuditAction {
  LOGIN = "LOGIN",
  VIEW = "VIEW",
  ADMIN_VIEW = "ADMIN_VIEW",
  PRINT = "PRINT",
  PRINT_COMPRESSED = "PRINT_COMPRESSED",
  PATIENT_SEARCH = "PATIENT_SEARCH",
  CUMULATIVE_VIEW = "CUMULATIVE_VIEW",
  EPISODE_VIEW = "EPISODE_VIEW",
  COMBINED_VIEW = "COMBINED_VIEW",
  TEST_SEARCH_VIEW = "TEST_SEARCH_VIEW",
  CLINTRIALS_ATOMIC_CHANGE = "CLINTRIALS_ATOMIC_CHANGE",
  CLINTRIALS_PANEL_CHANGE = "CLINTRIALS_PANEL_CHANGE",
  EMAIL_NOTIFICATION = "EMAIL_NOTIFICATION",
  STUDY_LIST = "STUDY_LIST",
  STUDY_LIST_GET_LIST = "STUDY_LIST_GET_LIST",
  EORDER_REQUEST = "EORDER_REQUEST",
  DOWNLOAD = "DOWNLOAD",
}

export default AuditAction;
