import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import styles from "./EpisodeListTable.module.css";
import { isTenantVet } from "../../services/tenantService";

const PatientEpisodesListHeader: React.FC<any> = () => {
  return (
    <Box mx={[0, 3]} mb={0.2} px={2} py={1} className={styles.headerRow} sx={{ display: { xs: "none", md: "block" } }}>
      <Grid container alignItems="flex-start">
        <Grid item xs={"auto"} sm={"auto"} md={"auto"}>
          <Box mr={2} width={28}>
            &nbsp;
          </Box>
        </Grid>
        <Grid container item xs sm md>
          <Grid item xs sm md lg={2}>
            <Typography variant="caption" color="grey.100">
              {isTenantVet() ? "Owner, Animal/Herd" : "Surname, Given name(s)"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1}>
            <Typography variant="caption" color="grey.100">
              Birth date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1}>
            <Typography variant="caption" color="grey.100">
              Lab Number
            </Typography>
          </Grid>
          <Grid item xs={12} lg>
            <Typography variant="caption" color="grey.100">
              Panels
            </Typography>
          </Grid>
          <Grid item xs={12} lg={1}>
            <Typography variant="caption" color="grey.100">
              Referrer
            </Typography>
          </Grid>
          <Grid item sm={2} md={2} lg={1}>
            <Typography align="right" variant="caption" color="grey.100">
              Reported
            </Typography>
          </Grid>
          <Grid item sm={2} md={2} lg={1}>
            <Typography align="right" variant="caption" color="grey.100">
              Status
            </Typography>
          </Grid>
          <Grid item sm={1} md={1} lg={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"right"}>
              <Typography align="right" variant="caption" color="grey.100">
                Print
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientEpisodesListHeader;
