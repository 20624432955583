import { gql } from "@apollo/client";

export default gql`
  query GetPrintableCombineCtAtomicAuditLog(
    $patientId: Int!
    $labnoDigitOnly: String!
    $ids: [Float!]
    $action: String!
    $auditLog: Boolean!
    $queryAction: String!
  ) {
    patient(id: $patientId) {
      fullnameTitleCase
      dob
      sex
      mobile
      title
      firstname
      surname
      fullAddress
      street
      city
      state
      postcode
      fullname
      homePhone
    }
    ctcombineAtomic(patientId: $patientId, labNumber: $labnoDigitOnly, panelIds: $ids, queryAction: $queryAction) {
      reportId
      reportDateCollected
      reportDateCreated
      reportTestName
      reportLabNumberDigitOnly
      reportUrno
      labid
      lab {
        address
        suburb
        phone
      }
      csPanelApprovalStatus
      csPanelApproverUserId
      csPanelApproverUsername
      csPanelApprovalDatetime
      atomicData {
        name
        value
        value_type
        measurement
        range
        loinc
        abnormal
        csApproval
        csApproverUserId
        csApproverUsername
        csApproverDatetime
        csStatus
        csStatusName
        atomicId
        report
        commentFlag
        csComment
        csCommentDate
        csCommentUsername
        csComments {
          id
          date
          username
          comment
          dCF
          actionRequired
          noActionRequired
        }
      }
    }
    getAuditLogs(reportIds: $ids, action: $action, labNoDigitOnly: $labnoDigitOnly, auditLog: $auditLog) {
      id
      username
      date
      action
      value
      type
      searchKey1
      searchKey2
    }
  }
`;
