import { gql } from "@apollo/client";
export default gql`
  query GetPanelComments($panelId: Int!) {
    getPanelComments(panelId: $panelId) {
      id
      username
      date
      action
      value
      type
      searchKey1
      searchKey2
    }
  }
`;
