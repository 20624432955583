import React, { useState, useEffect } from "react";
import { Divider, Menu, MenuItem, IconButton, Tooltip, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { getQueryAnnouncement } from "../Announcement/types/getQueryAnnouncement";
import getAnnouncements from "../Announcement/getAnnouncement";
import DialogBoxAnnouncements from "../DialogBox/DialogBoxAnnouncements";
import { GetNotificationCount } from "./types/GetNotificationCount";
import getNotificationsCountQuery from "./GetNotificationCountQuery";
import Loading from "../Loading/Loading";
import theme from "../theme";

const useStyles = makeStyles(() => ({
  notificationsBadge: {
    backgroundColor: theme.colorSchemes.light.palette.error.main,
    color: "#ffffff",
  },
  notificationsIcon: {
    color: "#ffffff",
  },
  notificationsIconActive: {
    color: theme.colorSchemes.light.palette.primary.dark,
  },
  notificationsTitle: {
    fontWeight: "bold",
    fontSize: "0.8125rem",
  },
  notificationsNew: {
    color: "#ffffff !important",
    backgroundColor: theme.colorSchemes.light.palette.primary.main + " !important",
    width: "100%",
    justifyContent: "left !important",
  },
  notificationsUrgent: {
    color: "#ffffff !important",
    backgroundColor: theme.colorSchemes.light.palette.primary.dark + " !important",
    width: "100%",
    justifyContent: "left !important",
  },
  notificationsAbnormal: {
    color: "#ffffff !important",
    backgroundColor: theme.colorSchemes.light.palette.error.main + " !important",
    width: "100%",
    justifyContent: "left !important",
  },
  countNew: {
    color: theme.colorSchemes.light.palette.primary.main + " !important",
    backgroundColor: "#ffffff !important",
    fontWeight: "bold",
    width: "30px !important",
    height: "30px !important",
  },
  countUrgent: {
    color: theme.colorSchemes.light.palette.primary.dark + " !important",
    backgroundColor: "#ffffff !important",
    fontWeight: "bold",
    width: "30px !important",
    height: "30px !important",
  },
  countAbnormal: {
    color: theme.colorSchemes.light.palette.error.main + " !important",
    backgroundColor: "#ffffff !important",
    fontWeight: "bold",
    width: "30px !important",
    height: "30px !important",
  },
  announcement: {
    backgroundColor: theme.colorSchemes.light.palette.primary.light,
    borderRadius: "5px",
    padding: "5px",
    width: "150px",
    whiteSpace: "break-spaces",
    cursor: "default",
  },
  announcementText: {
    fontSize: "0.8rem !important",
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
  announcementSubject: {
    fontSize: "0.7rem !important",
    marginTop: "3px !important",
    marginBottom: "3px !important",
  },
  withoutHover: {
    backgroundColor: "transparent !important",
    cursor: "default !important",
  },
}));

export type NotificationsMenuProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
  isDesktop?: boolean;
};

const NotificationsMenu: React.FC<NotificationsMenuProps> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data } = useQuery<getQueryAnnouncement>(getAnnouncements, {});
  const [isAnnouncementAvai, setIsAnnouncementAvai] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeResults, setActiveResults] = useState(false);
  const [total, setTotal] = useState(0);
  const [urgent, setUrgent] = useState(0);
  const [abnormal, setAbnormal] = useState(0);
  const [title, setTitle] = useState("Announcement");

  const handleAnnoucementClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (data) {
      if (data.announcement.announce.length > 0) {
        setIsAnnouncementAvai(true);

        if (data.announcement.announce.length > 1) {
          setTitle("Announcements");
        }
      }
    } else {
      setIsAnnouncementAvai(false);
    }
  }, [data]);

  const { data: notificatiosnCountData, loading: notificatiosnCountLoading } = useQuery<GetNotificationCount>(
    getNotificationsCountQuery,
    {
      pollInterval: 15 * 60 * 1000, // refresh data every 15 min
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (notificatiosnCountData && notificatiosnCountData.getNotificationCount) {
      setActiveResults(notificatiosnCountData.getNotificationCount.total > 0 ? true : false);
      setTotal(notificatiosnCountData.getNotificationCount.total);
      setUrgent(notificatiosnCountData.getNotificationCount.urgent);
      setAbnormal(notificatiosnCountData.getNotificationCount.abnormal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificatiosnCountData]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          style={{ marginLeft: props.isDesktop ? "15px" : "0px" }}
        >
          <NotificationsIcon className={activeResults ? classes.notificationsIconActive : classes.notificationsIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem style={{ pointerEvents: "none" }} className={`${classes.notificationsTitle} ${classes.withoutHover}`}>
          Daily results summary
        </MenuItem>
        <Divider />
        {notificatiosnCountLoading ? (
          <MenuItem className={classes.withoutHover}>
            <Loading iconStyle={{ width: "24px", height: "24px" }} mt={0} boxStyle={{ paddingRight: "10px" }} />
          </MenuItem>
        ) : (
          [
            <MenuItem style={{ pointerEvents: "none" }} key={"resulttoday"} className={classes.withoutHover}>
              <Chip
                avatar={<Avatar className={classes.countNew}>{total > 999 ? "999+" : total}</Avatar>}
                label="New Results Today"
                className={classes.notificationsNew}
              />
            </MenuItem>,
            <MenuItem style={{ pointerEvents: "none" }} key={"urgentresult"} className={classes.withoutHover}>
              <Chip
                avatar={<Avatar className={classes.countUrgent}>{urgent > 999 ? "999+" : urgent}</Avatar>}
                label="Urgent Results"
                className={classes.notificationsUrgent}
              />
            </MenuItem>,
            <MenuItem style={{ pointerEvents: "none" }} key={"abnormalresult"} className={classes.withoutHover}>
              <Chip
                avatar={<Avatar className={classes.countAbnormal}>{abnormal > 999 ? "999+" : abnormal}</Avatar>}
                label="Abnormal Results"
                className={classes.notificationsAbnormal}
              />
            </MenuItem>,
          ]
        )}
        {isAnnouncementAvai && [
          <Divider key={"diverbar2"} />,
          <MenuItem key={"announcement"} style={{ pointerEvents: "none", background: "inherit" }}>
            <Box className={classes.announcement}>
              <Typography className={classes.announcementText}>
                <strong>{title}</strong>
              </Typography>
              {data?.announcement.announce.map((an, i) => {
                return (
                  <React.Fragment key={i}>
                    <Divider />
                    <Typography className={classes.announcementSubject}>
                      <strong>{an.subject}</strong> <br />
                      Click{" "}
                      <u style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={handleAnnoucementClick}>
                        here
                      </u>{" "}
                      to learn more
                    </Typography>
                  </React.Fragment>
                );
              })}
            </Box>
          </MenuItem>,
        ]}
      </Menu>
      <DialogBoxAnnouncements
        open={dialogOpen}
        onClose={handleDialogClose}
        data={data as getQueryAnnouncement}
        title={title}
      ></DialogBoxAnnouncements>
    </>
  );
};

export default NotificationsMenu;
