import { gql } from "@apollo/client";
export default gql`
  query GetPatientEpisodes(
    $patientId: Int
    $providers: [String!]
    $allPatients: Boolean
    $sortBy: String
    $showRemovedPanels: Boolean
  ) {
    patientepisodes(
      patientId: $patientId
      providers: $providers
      allPatients: $allPatients
      sortBy: $sortBy
      showRemovedPanels: $showRemovedPanels
    ) {
      episodes {
        labnoDigitOnly
        panels {
          reportid
          labnumber
          labid
          dateCollected
          dateCreated
          dateReferred
          dateViewed
          hasAbnormalStatus
          interimStatus
          testcode
          testname
          testnameProperCase
          urno
          ward
          referringDoctor
          suburb
          address
          phone
          referredProviderNo
          reportingProviderNo
          urgentStatus
        }
      }
    }
  }
`;
