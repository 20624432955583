import React, { useContext } from "react";
import MuiAlert from "@mui/material/Alert";
import AppContext from "../../../context/AppContext";
import { Snackbar } from "@mui/material";

const SuccessPopover: React.FC = () => {
  const { success, setSuccess, setSuccessAcknowledged } = useContext(AppContext);

  return (
    <Snackbar
      open={success !== ""}
      autoHideDuration={8000}
      onClose={(_event, reason) => {
        if (reason === "clickaway") {
          return;
        }

        setSuccess("");
        setSuccessAcknowledged(true);
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="success"
        onClose={() => {
          setSuccess("");
          setSuccessAcknowledged(true);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: success }} />
      </MuiAlert>
    </Snackbar>
  );
};

export default SuccessPopover;
