import { gql } from "@apollo/client";
//import AdvancedSearch from '../../model/advanced-search';

export default gql`
  query GetEpisodes($providers: [String!], $limit: Int, $lastReportId: Int, $adSearch: AdvancedSearch) {
    episodesResult(providers: $providers, limit: $limit, lastReportId: $lastReportId, adSearch: $adSearch) {
      lastReport {
        reportid
      }
      episodes {
        labid
        reportid
        patientid
        fullnameTitleCase
        dob
        dateCreated
        allEpisodeTests
        referrer
        isRead
        labnoDigitOnly
        reportingProviderNo
        hasAbnormalStatus
        panels {
          labid
          reportid
          labnumber
          testname
          lastViewed
          firstViewed
          hasAbnormalStatus
          interimStatus
          isRead
          dateCreated
        }
        urgentStatus
      }
    }
  }
`;
