import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GetPatientExtended as GetPatientQueryType,
  GetPatientExtended_patient,
  GetPatientExtended_latestReport,
} from "./types/GetPatientExtended";
import GetPatientExtended from "./GetPatientExtended";
import Loading from "../Loading/Loading";
import { Typography, Box, Grid } from "@mui/material";
import styles from "../PatientListTable/PatientListTable.module.css";
import { formatDate } from "../../utils/date";
import { isTenantVet } from "../../services/tenantService";

const mapPatients: Map<
  number,
  {
    patient: GetPatientExtended_patient | undefined;
    latestReport: GetPatientExtended_latestReport | undefined;
  }
> = new Map();

const PatientDetailsTooltip: React.FC<{
  patientId: number;
  allPatients: boolean;
}> = ({ patientId, allPatients }) => {
  const [patientLatestReport, setPatientLatestReport] = useState<
    | {
        patient: GetPatientExtended_patient | undefined;
        latestReport: GetPatientExtended_latestReport | undefined;
      }
    | undefined
  >(mapPatients.get(patientId));

  const [getPatient, { data: patientData, loading, error }] = useLazyQuery<GetPatientQueryType>(GetPatientExtended, {
    variables: { patientId: patientId, allPatients: allPatients },
  });

  useEffect(() => {
    if (patientLatestReport === undefined) {
      getPatient({
        variables: { patientId: patientId, allPatients: allPatients },
      });
    }
    if (patientData) {
      const pat = {
        patient: patientData.patient,
        latestReport: patientData.latestReport,
      };
      setPatientLatestReport(pat);
      mapPatients.set(patientId, pat);
    }
  }, [patientData, setPatientLatestReport, patientId]);

  if (loading) return <Loading mt={0} />;
  if (error) {
    return (
      <Typography display={"inline"} variant={"inherit"}>
        There was an error
      </Typography>
    );
  }
  return (
    <Fragment>
      {patientLatestReport && patientLatestReport.patient && (
        <Box>
          {isTenantVet() ? (
            <>
              <Grid container>
                <Grid item xs={3}>
                  <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                    Owner
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display={"inline"} variant={"inherit"}>
                    {patientLatestReport.patient.surname}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                    Animal
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography display={"inline"} variant={"inherit"}>
                    {patientLatestReport.patient.firstname}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography display={"inline"} className={`${styles.patientDetailName}`} variant={"body1"}>
                {patientLatestReport.patient.fullnameTitleCase}
              </Typography>
              <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                {patientLatestReport.patient.sex === "M"
                  ? "(Male)"
                  : patientLatestReport.patient.sex === "F"
                    ? "(Female)"
                    : ""}
              </Typography>
            </Box>
          )}
          {isTenantVet() ? (
            <Grid container>
              <Grid item xs={3}>
                <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                  Species
                </Typography>
              </Grid>
              <Grid item>
                <Typography display={"inline"} variant={"inherit"}>
                  {patientLatestReport.patient.species}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid container>
            <Grid item xs={3}>
              <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                Born
              </Typography>
            </Grid>
            <Grid item>
              <Typography display={"inline"} variant={"inherit"}>
                {formatDate(patientLatestReport.patient.dob, "DD MMM, YYYY", true)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={3}>
              <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                Address
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={"inherit"} display={"block"}>
                {patientLatestReport.patient.fullAddress}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={3}>
              <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                Phone
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"inherit"}>{patientLatestReport.patient.mobile}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {patientLatestReport && patientLatestReport.latestReport && (
        <Box>
          {patientLatestReport.latestReport.urno && (
            <Grid container>
              <Grid item xs={3}>
                <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                  Ur No:
                </Typography>
              </Grid>
              <Grid item>
                <Typography display={"inline"} variant={"inherit"}>
                  {patientLatestReport.latestReport.urno}
                </Typography>
              </Grid>
            </Grid>
          )}

          {patientLatestReport.latestReport.ward && (
            <Grid container>
              <Grid item xs={3}>
                <Typography display={"inline"} className={`${styles.patientDetailSub}`} variant={"inherit"}>
                  Ward:
                </Typography>
              </Grid>
              <Grid item>
                <Typography display={"inline"} variant={"inherit"}>
                  {patientLatestReport.latestReport.ward}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default PatientDetailsTooltip;
