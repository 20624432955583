import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetPrintableReports,
  GetPrintableReportsVariables,
  GetPrintableReports_episodereports_reports as Report,
  GetPrintableReports_patient as Patient,
} from "./types/GetPrintableReports";
import getPrintableReports from "./GetPrintableReportQuery";
import authService from "../../services/authService";
import { useParams, useLocation } from "react-router";
import get from "lodash/get";
import PrintableReports from "./PrintableReports";
import queryString from "query-string";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { INTERUM_STATUS } from "../../constants";

type PathParams = {
  id: string;
  labnoDigitOnly: string;
  allPatients: string;
};

const queryVariables = (params: PathParams, reportIds: number[]): GetPrintableReportsVariables => ({
  patientId: parseInt(params.id, 10),
  providers: authService.getProviders(),
  labnoDigitOnly: params.labnoDigitOnly,
  includePdf: true,
  allPatients: params.allPatients === "1" ? true : false,
  ids: reportIds,
});

const getReportIds = (search: any): string[] | null => (search.ids ? search.ids.split(",") : null);

const hasValidData = (data: GetPrintableReports | undefined) =>
  data && data.episodereports && data.episodereports.reports;

const getFrameLoc = (search: any): string => (search.frame ? search.frame : "printFrame");

const PrintableReportsPage: React.FC<any> = () => {
  const params: PathParams = useParams() as PathParams;
  const location = useLocation();
  const reportIds = getReportIds(queryString.parse(location.search));
  const { data, loading } = useQuery<GetPrintableReports>(getPrintableReports, {
    variables: queryVariables(params, reportIds ? reportIds.map((a) => Number(a)) : []),
  });
  const frameLoc = getFrameLoc(queryString.parse(location.search));

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });
  const value = JSON.stringify({
    reportIds: reportIds,
    useragent: navigator.userAgent,
  });
  const action = "PRINT";

  useEffect(() => {
    if (!loading && data) {
      auditLog({
        variables: {
          audit: {
            value,
            action,
          },
        },
      });
    }
  }, [data, loading, auditLog, value]);

  const reportsToPrint = () => {
    const reports = get(data, "episodereports.reports", [] as Report[]);

    const reportsWithFinalStatus: Report[] = reports.filter(
      (report: Report) =>
        report.interimStatus === INTERUM_STATUS.Final ||
        report.interimStatus === INTERUM_STATUS.Preliminary ||
        report.interimStatus === INTERUM_STATUS.FinalCorrected,
    );
    if (!reportIds) return reportsWithFinalStatus;

    return reportsWithFinalStatus.filter((report) => reportIds.includes(`${report.reportid}`));
  };

  if (loading || !hasValidData(data)) return null;

  const patient: Patient = get(data, "patient") || ({} as Patient);

  return <PrintableReports reports={reportsToPrint()} patient={patient} frame={frameLoc} />;
};

export default PrintableReportsPage;
