import React from "react";
import { Dialog, DialogContent, CircularProgress, DialogContentText, DialogTitle } from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";

type LoadingDialogProps = {
  message: string;
  setOpen: boolean;
};

const LoadingDialog: React.FC<LoadingDialogProps> = (props) => {
  return (
    <Dialog open={props.setOpen} aria-labelledby="form-dialog-title">
      <DialogTitle>
        <DialogBoxTitle title="eResults" width={260} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }} variant="h4">
          {props.message}
        </DialogContentText>
        <DialogContentText style={{ textAlign: "center" }}>
          <CircularProgress />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
