/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";
import { withRouter } from "react-router";
import AdvancedSearch from "../model/advanced-search";

type PatientContextProps = {
  patientIds: string[];
  setPatientIds: React.Dispatch<React.SetStateAction<string[]>>;
  providers: string[];
  setProviders: React.Dispatch<React.SetStateAction<string[]>>;
  advancedSearch: AdvancedSearch;
  setAdvancedSearch: React.Dispatch<React.SetStateAction<AdvancedSearch>>;
  searchVariables: any;
  setSearchVariables: React.Dispatch<React.SetStateAction<any>>;
  searchNow: boolean;
  setSearchNow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PatientContext = React.createContext<PatientContextProps>({
  patientIds: [],
  setPatientIds: () => {},
  providers: [],
  setProviders: () => {},
  advancedSearch: new AdvancedSearch(),
  setAdvancedSearch: () => {},
  searchVariables: {},
  setSearchVariables: () => {},
  searchNow: false,
  setSearchNow: () => {},
});

export const PatientsContextProvider: React.FC<any> = (props) => {
  const [patientIds, setPatientIds] = useState(new Array<string>());
  const [providers, setProviders] = useState(new Array<string>());
  const [advancedSearch, setAdvancedSearch] = useState(new AdvancedSearch());
  const [searchVariables, setSearchVariables] = useState({});
  const [searchNow, setSearchNow] = useState(false);

  return (
    <PatientContext.Provider
      value={{
        patientIds,
        setPatientIds,
        providers,
        setProviders,
        advancedSearch,
        setAdvancedSearch,
        searchVariables,
        setSearchVariables,
        searchNow,
        setSearchNow,
      }}
    >
      {props.children}
    </PatientContext.Provider>
  );
};

export default withRouter(PatientsContextProvider);
