import { ApolloClient, ApolloLink, from, InMemoryCache, HttpLink } from "@apollo/client";
import get from "lodash/get";
import authService, { getFingerprint, setFingerprint, SET_AUTH, persistCognitoToken } from "./services/authService";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_ACL_BFF_URL,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  try {
    const cognitoToken = authService.fetchToken()?.cognitoToken;

    const token = authService.fetchToken()?.accessToken;
    const isCognito = authService.isCognito();
    if (token && token != "undefined" && !isCognito) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          "x-client-fgp": getFingerprint(),
        },
        fetchOptions: {
          credentials: "include",
        },
      }));
    }
    if (cognitoToken && isCognito) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "cognito-access-token": cognitoToken.COGNITO_ACCESS_TOKEN,
          "cognito-refresh-token": cognitoToken.COGNITO_REFRESH_TOKEN,
          "cognito-idtoken": cognitoToken.COGNITO_IDTOKEN,
        },
        fetchOptions: {
          credentials: "include",
        },
      }));
    }
  } catch (err) {
    console.error("Error updating request", err);
  }

  return forward(operation);
});

const errorHandler = onError(({ graphQLErrors, operation }) => {
  const errorCode = get(graphQLErrors, "[0].extensions.code");
  if (errorCode === "UNAUTHENTICATED") {
    const isCognito = authService.isCognito();
    authService.clearAll();

    operation.getContext().cache.writeQuery({
      query: SET_AUTH,
      data: { auth: { isAuth: false, isCognito: isCognito, __typename: "Auth" } },
    });
  }
});

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const headers = operation.getContext().response.headers;
    const fingerprint = headers.get("x-client-fgp");
    const newAccessTokenHeader = operation.getContext().response.accessToken;
    const newIdTokenHeader = operation.getContext().response.newIdToken;
    if (newIdTokenHeader && newAccessTokenHeader) {
      persistCognitoToken(newAccessTokenHeader, newIdTokenHeader);
    }
    if (fingerprint) {
      setFingerprint(fingerprint);
    }
    return data;
  });
});

export const client = new ApolloClient({
  link: from([authMiddleware, errorHandler, responseLink.concat(httpLink)]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: "include",
});
