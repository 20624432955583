import { gql } from "@apollo/client";

export default gql`
  query getSkinReportLink($id: Int!) {
    getSkinReportLink(id: $id) {
      url
      expiredAt
    }
  }
`;
