import React from "react";
import styles from "./CombinedReport.module.css";
import { Typography } from "@mui/material";
import { ValueWithLabel } from "../ValueWithLabel/ValueWithLabel";
import { APPROVAL_STATUS } from "../../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export interface IAppProps {
  episode: any;
}

const EpisodeStatus: React.FC<IAppProps> = (props: IAppProps) => {
  const { episode } = props;
  const status = episode.csPanelApprovalStatus ?? 1;
  const changedOn = episode.csPanelApproverDatetime
    ? dayjs.utc(episode.csPanelApproverDatetime).local().format("DD MMM YYYY HH:mm A")
    : "";

  return (
    <Typography className={styles.approverText}>
      {status !== APPROVAL_STATUS.New && (
        <ValueWithLabel label="Approved By:" value={episode.csPanelApproverName} width={350} />
      )}
      {status !== APPROVAL_STATUS.New && <ValueWithLabel label="Approved On:" value={changedOn} width={350} />}
    </Typography>
  );
};

export default EpisodeStatus;
