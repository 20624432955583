import React, { useContext, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import AppContext from "../../../context/AppContext";
import { Snackbar } from "@mui/material";
import * as Constants from "../../../constants";

interface ErrorPopoverProps {
  errorMessage?: any;
}

const ErrorPopover: React.FC<ErrorPopoverProps> = ({ errorMessage }) => {
  const { error, setError, setErrorAcknowledged } = useContext(AppContext);

  useEffect(() => {
    if (typeof errorMessage === "string") {
      setError(errorMessage);
    } else {
      setError(Constants.NO_DB_CONNECTION);
    }
  }, [errorMessage, setError]);

  return (
    <Snackbar
      open={error !== ""}
      autoHideDuration={6000}
      onClose={(_event, reason) => {
        if (reason === "clickaway") {
          return;
        }

        setError("");
        setErrorAcknowledged(true);
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="error"
        onClose={() => {
          setError("");
          setErrorAcknowledged(true);
        }}
      >
        {error}
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorPopover;
