import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./Feedback.module.css";
import get from "lodash/get";
import { Formik } from "formik";

import { UserFeedback, UserFeedbackVariables } from "./types/UserFeedback";
import { MutationTuple, useMutation, useQuery } from "@apollo/client";
import { GraphQLError } from "graphql";
import FeedbackMutation from "./FeedbackMutation";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { isTenantVet } from "../../services/tenantService";
import { getCpdProgram, getCpdProgram_getCpdProgram } from "../CPDprograms/types/getCpdProgram";
import GetCpdProgramQuery from "../CPDprograms/GetCpdProgramQuery";
import { GetLabs, GetLabs_getLabs } from "./types/GetLabs";
import GetLabsQuery from "./GetLabsQuery";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";

export type userFeedbackInputValues = {
  phone: string;
  email: string;
  type: string;
  feedbackText: string;
  state: string;
};

interface FeedbackPageProps {
  open: boolean;
  fullscreen: boolean;
  onClose: () => void;
}

const checkBoxChecked = false;

const FeedbackDialog: React.FC<FeedbackPageProps> = (props) => {
  const [openInfo, setOpenInfo] = useState("none");
  const [remChecked, setRemChecked] = useState(checkBoxChecked);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();
  const { labId } = useContext(PatientDetailsContext);

  const providers: string[] = authService.getProviders();
  const username: string = authService.getUsername();

  const [typeValue, setTypeValue] = React.useState("comments");
  const [cpdFeedbackOption, setCpdFeedbackOption] = useState(
    window.location.pathname.includes("diabetes")
      ? "1"
      : window.location.pathname.includes("skin")
        ? "2"
        : window.location.pathname.includes("cytology")
          ? "3"
          : "",
  );
  const [labOption, setLabOption] = useState((labId as unknown as string) || "");

  const { data } = useQuery<getCpdProgram>(GetCpdProgramQuery);
  const { data: dataLabs } = useQuery<GetLabs>(GetLabsQuery, {
    variables: {
      active: true,
    },
    // fetchPolicy: "no-cache",
  });

  const [cpdPrograms, setCpdPrograms] = useState<getCpdProgram_getCpdProgram[]>([]);
  const [labs, setLabs] = useState<GetLabs_getLabs[]>([]);

  useEffect(() => {
    if (data) {
      setCpdPrograms(data.getCpdProgram);
    }

    if (dataLabs) {
      setLabs(dataLabs.getLabs);
    }
  });

  useEffect(() => {
    if (labId > 0) {
      setLabOption(labId as unknown as string);
    } else {
      setLabOption("");
    }
  }, [labId]);

  const feedbacktext = useRef<any>(null);

  const handleClose = () => {
    setFeedbackMessage("");
    props.onClose();
  };

  const handleChangeType = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTypeValue(event.target.value);
  };

  const handleClear = () => {
    setFeedbackMessage("");
  };

  const selectLabOption = (event: SelectChangeEvent<string>) => {
    setLabOption(event.target.value as string);
  };

  const selectCpdFeedback = (event: SelectChangeEvent<string>) => {
    setCpdFeedbackOption(event.target.value as string);
  };

  const openInfoField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (openInfo === "none") setOpenInfo("block");
    else setOpenInfo("none");
    setRemChecked(event.target.checked);
  };

  const initialValues: userFeedbackInputValues = {
    phone: "",
    email: "",
    feedbackText: "",
    type: "",
    state: "",
  };

  const [userFeedback, { loading, error }] = useMutation<UserFeedback, UserFeedbackVariables>(FeedbackMutation);

  if (error) {
    console.error("Error", error);
  }

  const isCPDPage = () => {
    if (
      window.location.pathname.includes("cpd") ||
      window.location.pathname.includes("diabetes") ||
      window.location.pathname.includes("skin") ||
      window.location.pathname.includes("cytology")
    ) {
      return true;
    }

    return false;
  };

  const submit = (values: userFeedbackInputValues) => {
    // eslint-disable-next-line no-console
    let phno = values.phone;
    let eml = values.email;
    const state = authService.getState() ? authService.getState() : "";

    if (!remChecked) {
      phno = "";
      eml = "";
    }

    onSubmit(
      providers[0],
      phno,
      eml,
      username,
      "",
      typeValue + "-" + labs.find((val) => val.id === parseInt(labOption))?.shortname,
      values.feedbackText,
      state,
      isCPDPage()
        ? "CPD - " + cpdPrograms.find((val) => val.id === parseInt(cpdFeedbackOption))?.short_name
        : (isTenantVet() ? "Gribbles " : "") + "eResults",
      userFeedback,
    );
  };

  const onSubmit = async (
    providerno: string,
    phone: string,
    email: string,
    name: string,
    surname: string,
    type: string,
    text: string,
    state: string,
    source: string,
    userFeedback: MutationTuple<UserFeedback, UserFeedbackVariables>[0],
  ) => {
    try {
      const result = await userFeedback({
        variables: {
          input: {
            providerno,
            phone,
            email,
            name,
            surname,
            type,
            text,
            state,
            source,
          },
        },
      });

      if (get(result, "data.userFeedback.message")) {
        setFeedbackMessage("Feedback sent successfully.");
        // eslint-disable-next-line no-console
      }
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      }
    }
  };

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <DialogBoxTitle title="User Feedback" width={200} closeButton handleClose={handleClose} />
            </DialogTitle>

            <DialogContent>
              <Box m={2}>
                <Box display="flex" flexDirection="column">
                  <RadioGroup
                    aria-label="Feedback Type"
                    name="type"
                    value={typeValue}
                    onChange={handleChangeType}
                    onClick={handleClear}
                  >
                    <FormControlLabel value="usersupport" control={<Radio required={true} />} label="User Support" />
                    <FormControlLabel value="bugreports" control={<Radio required={true} />} label="Bug Reports" />
                    <FormControlLabel
                      value="featurereq"
                      control={<Radio required={true} />}
                      label="Feature Requests/Enhancements"
                    />
                  </RadioGroup>
                </Box>
                <Box>
                  <Typography className={styles.menuTitle}>Pathology Provider</Typography>
                  <Select
                    id="labId"
                    value={labOption}
                    onChange={selectLabOption}
                    variant="outlined"
                    name="labOption"
                    placeholder="Select Pathology Provider"
                    required
                    className={`${isDesktop ? styles.selectInput : styles.selectInputMobile} ${styles.detailInputs}`}
                  >
                    {labs.map((f) => (
                      <MenuItem key={f.id} value={f.id}>
                        {f.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {isCPDPage() ? (
                  <Box>
                    <Typography className={styles.menuTitle}>
                      Please select CPD Program for your feedback (Required)
                    </Typography>
                    <Select
                      id="cpdFeedbackOption"
                      value={cpdFeedbackOption}
                      onChange={selectCpdFeedback}
                      variant="outlined"
                      name="cpdFeedbackOption"
                      placeholder="Select CPD Feedback"
                      required
                      className={`${isDesktop ? styles.selectInput : styles.selectInputMobile} ${styles.detailInputs}`}
                    >
                      {cpdPrograms.map((f) => (
                        <MenuItem key={f.id} value={f.id}>
                          {f.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : (
                  ""
                )}

                <Box>
                  <Typography className={styles.menuTitle}>Feedback (Required)</Typography>
                  <TextareaAutosize
                    aria-label="minimum height"
                    name="feedbackText"
                    id="feedbackText"
                    onChange={handleChange}
                    minRows={6}
                    cols={isDesktop ? 70 : 40}
                    placeholder="Type feedback here"
                    ref={feedbacktext}
                    autoFocus
                    className={styles.feedBackTextArea}
                  />
                  <Typography className={styles.menuTitle}>Contact Details</Typography>
                </Box>
              </Box>
              <Box>
                <Checkbox
                  name="contact"
                  color="primary"
                  disabled={false}
                  onChange={openInfoField}
                  checked={remChecked}
                />
                Include my contact
              </Box>
              <Box display={openInfo} ml={2} mr={5}>
                <TextField
                  margin="dense"
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  margin="dense"
                  id="email"
                  name="email"
                  label="Email Address."
                  type="text"
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Box>
              {isDesktop && !isMobility && (
                <Box ml={2} mt={1} mb={2} display="flex" justifyContent="space-between">
                  <Typography className={styles.menuTitle}>
                    For all other enquiries please{" "}
                    <a href="https://www.clinicallabs.com.au/contact-us/" target="_blank" rel="noopener noreferrer">
                      click here
                    </a>
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Box ml={2} mt={1} mb={2} display="flex" justifyContent="space-between">
                <Box>
                  <Typography className={styles.successMessage}>{feedbackMessage}</Typography>
                </Box>
                <Button type="submit" color="primary" variant="contained" disabled={loading}>
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default FeedbackDialog;
