import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GetPrintableCombineAtomic_patient as Patient,
  GetPrintableCombineAtomicVariables,
  GetPrintableCombineAtomic,
} from "./types/GetPrintableCombineAtomic";
import getPrintableCombineAtomic from "./GetPrintableCombineAtomicQuery";
// import authService from "../../services/authService";
import { useParams, useLocation } from "react-router";
import get from "lodash/get";
import queryString from "query-string";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import PrintableCombineAtomic from "./PrintableCombineAtomic";

type PathParams = {
  patientId: string;
  labnoDigitOnly: string;
  allPatients: string;
};

const getReportIds = (search: any): number[] | null =>
  search.ids
    ? search.ids.split(",").map((item: string) => {
        return parseInt(item, 10);
      })
    : null;

const getDownloadMode = (search: any): number => (search.downloadMode ? Number(search.downloadMode) : 0);

const queryVariables = (params: PathParams, reportIds: number[]): GetPrintableCombineAtomicVariables => ({
  patientId: parseInt(params.patientId, 10),
  labnoDigitOnly: params.labnoDigitOnly,
  ids: reportIds,
});

const hasValidData = (data: GetPrintableCombineAtomic | undefined) => data && data.combineAtomic;

const PrintableCombineAtomicPage: React.FC<any> = () => {
  const params: PathParams = useParams() as PathParams;
  const location = useLocation();
  const reportIds = getReportIds(queryString.parse(location.search));
  const downloadMode = getDownloadMode(queryString.parse(location.search));
  const { data, loading } = useQuery<GetPrintableCombineAtomic>(getPrintableCombineAtomic, {
    variables: queryVariables(params, reportIds || []),
  });

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });
  const value = JSON.stringify({
    reportIds: reportIds,
    useragent: navigator.userAgent,
  });
  const action = "PRINT_COMPRESSED";

  useEffect(() => {
    if (!loading && data) {
      auditLog({
        variables: {
          audit: {
            value,
            action,
          },
        },
      });
    }
  }, [data, loading, auditLog, value]);

  if (loading || !hasValidData(data)) return null;

  const patient: Patient = get(data, "patient") || ({} as Patient);

  const atomics = data && data.combineAtomic ? data.combineAtomic : [];

  return (
    <PrintableCombineAtomic
      atomics={atomics}
      patient={patient}
      downloadMode={downloadMode}
      isMultiPatientReport={false}
    />
  );
};

export default PrintableCombineAtomicPage;
